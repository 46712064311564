import {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {ApartmentsApi} from 'api';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {useDebounce, usePrevious} from 'shared/hooks';
import {ApartmentsFilter, ApartmentsModel, WithPagination} from 'shared/models';
import {bem} from 'shared/utils';

import {ParkingPlacesTable, ParkingPlacesHeader} from './components';
import {initialFilter} from './constants';
import styles from './ParkingPlacesList.module.scss';
import {addId} from './utils';

const sn = bem('parkingPlacesList', styles);

export const ParkingPlacesList = () => {
  const [parkingPlaces, setParkingPlaces] = useState<WithPagination<ApartmentsModel> | null>(null);
  const [paramsFilter, setParamsFilter] = useState<ApartmentsFilter>(initialFilter);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);

  const {apartmentComplexId, parkingId, parkingSectionId} = useParams<{
    apartmentComplexId: string;
    parkingId: string;
    parkingSectionId: string;
  }>();
  const isActiveFilter = !!paramsFilter.apartmentNumber || !!paramsFilter.phoneNumber || !!paramsFilter.tenantName;
  const prevIsActiveFilter = usePrevious(isActiveFilter);

  useEffect(() => {
    if (!isActiveFilter) {
      getParkingPlaces();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchApartments();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [isActiveFilter, paramsFilter, paginationModel]);

  const searchApartments = useDebounce(async () => {
    if (loading) return;
    getParkingPlaces();
  }, DELAY);

  const getParkingPlaces = async () => {
    if (apartmentComplexId && parkingId && parkingSectionId) {
      try {
        setLoading(true);
        const payload = {
          ...paramsFilter,
          size: paginationModel.pageSize,
          page: paginationModel.page + 1,
        };
        const data = await ApartmentsApi.getApartments(apartmentComplexId, parkingId, parkingSectionId, payload);
        setParkingPlaces({...data, items: addId(data.items)});
      } finally {
        setLoading(false);
      }
    }
  };

  const updateParkingPlaceList = () => {
    getParkingPlaces();
    setParamsFilter(initialFilter);
  };

  return (
    <div className={styles.parkingPlacesList}>
      <ParkingPlacesHeader
        paramsFilter={paramsFilter}
        updateApartmentsFilter={setParamsFilter}
        onAfterImport={updateParkingPlaceList}
      />
      <ParkingPlacesTable
        isActiveFilter={isActiveFilter}
        loading={loading}
        parkingPlaces={parkingPlaces}
        paginationModel={paginationModel}
        updatePaginationModel={setPaginationModel}
        updateParkingPlaceList={updateParkingPlaceList}
      />
    </div>
  );
};
