import {useEffect, useState} from 'react';

import {CamerasApi} from 'api/services/camerasService';
import {CameraForm} from 'shared/components/CameraForm';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {Modal} from 'shared/components/Modal';
import {useToast} from 'shared/hooks';
import {CameraInfoModel, CameraItemModel, NewCamera} from 'shared/models';

import styles from './EditCamera.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAfterEdit: (camera: CameraInfoModel) => void;
  editingCamera: CameraItemModel | null;
};

const confirmPayload = {
  title: 'Подтверждение действия',
  description: 'Вы хотите сохранить данные камеры',
  acceptText: 'Вернуться',
};

const EditCamera = ({isOpen, onClose, onAfterEdit, editingCamera}: Props) => {
  const {showToast} = useToast();
  const {confirm} = useConfirm();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cameraInfo, setCameraInfo] = useState<CameraInfoModel | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  const getCameraInfo = async () => {
    if (editingCamera) {
      try {
        setLoading(true);
        const cameraInfo = await CamerasApi.getCamera(editingCamera.cameraId);
        setCameraInfo(cameraInfo);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      getCameraInfo();
    }
  }, [isOpen]);

  const onCancel = async () => {
    if (isDirty) {
      if (!(await confirm(confirmPayload))) {
        onClose();
      }
    } else onClose();

    setCameraInfo(null);
  };

  const onSave = async (values: Partial<NewCamera>) => {
    if (editingCamera) {
      try {
        setSaving(true);
        const updatedCamera = await CamerasApi.editCamera(editingCamera?.cameraId, values);
        onAfterEdit(updatedCamera);
        showToast({
          title: 'Камера отредактирована',
          message: `Камера ${values.name} успешно отредактирована.`,
        });
      } catch (err) {
        showToast({
          title: 'Камера  не отредактирована',
          message: `Ошибка редактирования камеры ${values.name}`,
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      title="Редактирование камеры"
      maxWidth="lg"
    >
      <div className={styles.editCamera}>
        <CameraForm
          saving={saving}
          loading={loading}
          updateIsDirty={setIsDirty}
          dirty={isDirty}
          onSave={onSave}
          onCancel={onCancel}
          initialCameraInfo={cameraInfo}
        />
      </div>
    </Modal>
  );
};

export {EditCamera};
