import {GridCellParams, GridPaginationModel} from '@mui/x-data-grid';
import {useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {AddUser, Table} from 'shared/components';
import {EditService} from 'shared/components/EditExpirationDate';
import {routes} from 'shared/constants/routes';
import {AccountRoles, ApartmentsModel, OneApartmentModel, ServiceModel, WithPagination} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './ApartmentsTable.module.scss';
import {getColumns} from './columns';

type Props = {
  isActiveFilter: boolean;
  loading: boolean;
  apartments: WithPagination<ApartmentsModel> | null;
  paginationModel: GridPaginationModel;
  updatePaginationModel: (value: GridPaginationModel) => void;
  onAfterUpdate: () => void;
};

const sn = bem('apartmentsTable', styles);

export const ApartmentsTable = ({
  loading,
  apartments,
  paginationModel,
  updatePaginationModel,
  onAfterUpdate,
}: Props) => {
  const navigate = useNavigate();
  const {apartmentComplexId, courtyardId, houseId} = useParams<{
    apartmentComplexId: string;
    courtyardId: string;
    houseId: string;
  }>();
  const [isOpenAddTenant, setIsOpenAddTenant] = useState(false);
  const [apartmentId, setApartmentId] = useState<number | null>(null);
  const [isEditingService, setIsEditingService] = useState(false);
  const [editableService, setEditableService] = useState<ServiceModel | null>(null);
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);

  const onCellClick = (params: GridCellParams<OneApartmentModel>) => {
    if (params.field === 'edit') {
      navigate(generatePath(routes.apartment, {apartmentComplexId, courtyardId, houseId, apartmentId: params.id}));
    }
    if (params.field === 'editService') {
      if (isDisabled) return;
      setApartmentId(params.row.apartmentId);
      setEditableService(params.row.services?.[0]);
      setIsEditingService(true);
    }
  };

  const onAddOwnerClick = (params: GridCellParams<ApartmentsModel>) => {
    setApartmentId(params.row.apartmentId);
    setIsOpenAddTenant(true);
  };

  const onClose = () => {
    setApartmentId(null);
    setIsOpenAddTenant(false);
  };

  const onCloseEditService = () => {
    setIsEditingService(false);
    setEditableService(null);
    setApartmentId(null);
  };

  return (
    <div className={styles.apartmentsTable}>
      <Table
        className={sn('table')}
        loading={loading}
        columns={getColumns({onAddOwnerClick, isDisabled})}
        data={apartments}
        paginationModel={paginationModel}
        updatePaginationModel={updatePaginationModel}
        onCellClick={onCellClick}
        getRowClassName={() => sn('row')}
      />
      <AddUser
        asOwner
        isOpen={isOpenAddTenant}
        onClose={onClose}
        apartmentId={apartmentId}
        onAfterAdd={onAfterUpdate}
        title="Добавление жильца"
      />
      <EditService
        isOpen={isEditingService}
        onClose={onCloseEditService}
        onAfterChange={onAfterUpdate}
        service={editableService}
        id={apartmentId}
      />
    </div>
  );
};
