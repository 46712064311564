import {ParkingSectionConstructorFloor, ParkingSectionConstructorModel} from 'shared/models';

export const getListSeats = (first: number, last: number): number[] => {
  const result = [];
  let prev = first;
  while (prev <= last) {
    result.push(prev);
    prev += 1;
  }
  return result;
};

export const getFloorRange = (index: number, quantitySeats: number, asArray?: boolean) => {
  let start: number;
  let end: number;

  if (index === 0) {
    start = index + 1;
    end = quantitySeats;
  } else {
    start = quantitySeats * index + 1;
    end = quantitySeats * (index + 1);
  }

  if (asArray) {
    return [start, end];
  }

  if (!asArray) {
    `${start} — ${end}`;
  }

  return asArray ? [start, end] : `${start} — ${end}`;
};

export const getInitFloors = (floorsCount: number, settings: ParkingSectionConstructorModel) =>
  [...Array(floorsCount)].map((_, i) => {
    const [start, end] = getFloorRange(i, settings.parkingSpacesPerFloorCount, true) as number[];

    return {
      floorNumber: i + 1,
      quantitySeatsOnFloor: settings.parkingSpacesPerFloorCount,
      range: `${start} — ${end}`,
      apartmentNumbers: getListSeats(start, end),
    };
  });

const updateFloorsRange = (floors: ParkingSectionConstructorFloor[]) => {
  let prevFloorEndSeatsNumber: null | number = null;
  return floors.map((f, i) => {
    let start = 0;
    let end = 0;

    if (i === 0) {
      start = i + 1;
      end = f.quantitySeatsOnFloor;
      prevFloorEndSeatsNumber = f.quantitySeatsOnFloor;
    } else if (prevFloorEndSeatsNumber) {
      start = prevFloorEndSeatsNumber + 1;
      end = f.quantitySeatsOnFloor + prevFloorEndSeatsNumber + 1;
    }
    prevFloorEndSeatsNumber = f.quantitySeatsOnFloor;

    return {
      ...f,
      range: `${start} — ${end}`,
      apartmentNumbers: getListSeats(start, end),
    };
  });
};

export const updateFloors = (
  settings: ParkingSectionConstructorModel,
  updatedFloor?: ParkingSectionConstructorFloor,
) => {
  const preparedFloors = settings.floors.map((floor) =>
    floor.floorNumber === updatedFloor?.floorNumber ? updatedFloor : {...floor},
  );

  return updateFloorsRange(preparedFloors);
};
