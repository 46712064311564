import React from 'react';
import {bem} from 'shared/utils';
import styles from './AddressBlock.module.scss';
import {ApartmentComplex, Entrance, Parking, ParkingSection} from './components';

const sn = bem('addressBlock', styles);

export const AddressBlock = () => {
  return (
    <div className={sn()}>
      <div className={sn('autocompleteRow')}>
        <div className={sn('autocompleteItem')}>
          <ApartmentComplex />
        </div>
        <div className={sn('autocompleteItem')}>
          <Parking />
        </div>
        <div className={sn('autocompleteItem')}>
          <ParkingSection />
        </div>
        <div className={sn('autocompleteItem')}>
          <Entrance />
        </div>
      </div>
    </div>
  );
};
