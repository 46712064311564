export const getUtf8FileName = (name: string) => {
  const utf8FilenameRegex = /filename\*?=([^']*'')?([^;]*)/;
  let filename = '';
  const matches = utf8FilenameRegex.exec(name);
  if (matches != null && matches[2]) {
    filename = decodeURIComponent(matches[2].replace(/['"]/g, ''));
  }
  return filename;
};

export const downloadAsFile = (data: Blob, name: string, type: string) => {
  const a = document.createElement('a');
  const file = new Blob([data], {type});
  a.href = URL.createObjectURL(file);
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
