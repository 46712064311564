import {Button} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {FC, useState, ChangeEvent, useEffect} from 'react';

import {OfficesApi} from 'api';
import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {SearchInput} from 'shared/components/SearchInput';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {useBreadcrumbs, useDebounce, useModal, usePrevious, useToast} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {OfficeModel, WithPagination} from 'shared/models';
import {bem} from 'shared/utils';

import {AddOffice} from './components/AddOffice';
import {EditOffice} from './components/EditOffice';
import {columns} from './components/OfficesTable/columns';
import {confirmDeletePayload, failedDeleteMessage, getSuccessDeleteMessage} from './constants';
import styles from './Offices.module.scss';
import {initialOffices} from './utils';

const sn = bem('offices', styles);

export const Offices: FC = () => {
  const [searchOfficeValue, setSearchOfficeValue] = useState('');
  const [editingOfficeId, setEditingOfficeId] = useState<number | null>(null);
  const [offices, setOffices] = useState<WithPagination<OfficeModel>>(initialOffices);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);

  const {confirm} = useConfirm();
  const {showToast} = useToast();
  const {openModal: onAddOffice, isOpen: isAdding, closeModal: closeAddingModal} = useModal();
  const {openModal: onEditOffice, isOpen: isEditing, closeModal: closeEditingModal} = useModal();
  const {officesBreadcrumbs} = useBreadcrumbs();
  const isActiveFilter = searchOfficeValue.length >= 3;
  const prevIsActiveFilter = usePrevious(isActiveFilter);

  useEffect(() => {
    if (!isActiveFilter) {
      getOffices();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchOffice();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [searchOfficeValue, isActiveFilter, paginationModel]);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchOfficeValue(event.target.value);
  };

  const getOffices = async () => {
    try {
      setLoading(true);
      const payload = {size: paginationModel.pageSize, page: paginationModel.page + 1};
      const res = await OfficesApi.getOffices(payload);
      const preparedOffices = res.items.map((office) => ({...office, id: office.officeId}));
      setOffices({...res, items: preparedOffices});
    } finally {
      setLoading(false);
    }
  };

  const searchOffice = useDebounce(async () => {
    try {
      setLoading(true);
      const payload = {
        searchQuery: searchOfficeValue,
        size: paginationModel.pageSize,
        page: paginationModel.page + 1,
      };
      const res = await OfficesApi.getOffices(payload);
      const preparedOffices = res.items.map((office) => ({...office, id: office.officeId}));
      setOffices({...res, items: preparedOffices});
    } finally {
      setLoading(false);
    }
  }, DELAY);

  const deleteOffice = async (id: number, address: string) => {
    try {
      setLoading(true);
      await OfficesApi.deleteOffice(id);
      showToast(getSuccessDeleteMessage(address));
      const preparedOffices = offices.items.filter((office) => office.officeId !== id);
      setOffices({...offices, items: preparedOffices});
    } catch (err) {
      showToast(failedDeleteMessage);
    } finally {
      setLoading(false);
    }
  };

  const onAfterAdd = () => {
    getOffices();
  };

  const onAfterEdit = (updatedOffice: OfficeModel) => {
    const updatedOffices = offices.items.map((office) =>
      office.officeId === editingOfficeId ? {...updatedOffice, id: updatedOffice.officeId} : office,
    );
    setOffices({...offices, items: updatedOffices});
  };

  const onCellClick = async (params: GridCellParams<OfficeModel>) => {
    if (params.field === 'delete') {
      if (await confirm(confirmDeletePayload)) {
        deleteOffice(params.row.officeId, params.row.address);
      }
    }
    if (params.field === 'edit') {
      setEditingOfficeId(params.row.officeId);
      onEditOffice();
      return;
    }
  };
  const onCloseEditModal = () => {
    closeEditingModal();
    setEditingOfficeId(null);
  };

  return (
    <div className={styles.offices}>
      <TitleBlock
        title="Офисы"
        breadcrumbs={officesBreadcrumbs}
        action={
          <Button variant="contained" color="secondary" className={sn('addOffice')} onClick={onAddOffice}>
            Добавить офис
          </Button>
        }
      />
      <SearchInput value={searchOfficeValue} onChange={onSearch} placeholder="Найти офис (мин 3 символа)" />
      <div className={sn('tableContainer')}>
        <Table
          loading={loading}
          data={offices}
          className={sn('table')}
          columns={columns}
          onCellClick={onCellClick}
          paginationModel={paginationModel}
          updatePaginationModel={setPaginationModel}
        />
      </div>
      <AddOffice isOpen={isAdding} closeModal={closeAddingModal} onAfterAdd={onAfterAdd} />
      <EditOffice
        isOpen={isEditing}
        closeModal={onCloseEditModal}
        editingOfficeId={editingOfficeId}
        onAfterEdit={onAfterEdit}
      />
    </div>
  );
};
