import {IntercomType} from 'shared/models';

export type NewIntercomFormValues = {
  sipNumber: string;
  name: string;
  login: string;
  password: string;
  host: string;
  port: string;
  model: null | number;
  type: null | IntercomType;
  courtyardId: number | null;
  courtyardGateId: number | null;
  buildingId: string | null;
  entryNumber: number | null;
  cameraIds?: number[];
  isInvisible: boolean;
  apartmentComplexId?: number | null;
};

export const initialValues: NewIntercomFormValues = {
  sipNumber: '',
  name: '',
  login: '',
  password: '',
  host: '',
  port: '',
  model: null,
  type: null,
  apartmentComplexId: null,
  courtyardGateId: null,
  courtyardId: null,
  buildingId: null,
  entryNumber: null,
  cameraIds: [],
  isInvisible: false,
};
