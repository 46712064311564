import {ChangeEvent} from 'react';
import {SearchInput} from 'shared/components/SearchInput';
import {useRootDispatch, useRootSelector} from 'store';
import {bem} from 'shared/utils';
import styles from './SearchBlock.module.scss';
import {setSearchAddress, setSearchName} from '../../store';

const sn = bem('searchBlock', styles);

export const SearchBlock = () => {
  const searchAddress = useRootSelector((state) => state.accessDevices.searchAddress);
  const searchName = useRootSelector((state) => state.accessDevices.searchName);
  const dispatch = useRootDispatch();

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchName(e.target.value));
  };

  const onAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchAddress(e.target.value));
  };

  return (
    <div className={sn()}>
      <div>
        <SearchInput placeholder="Название" value={searchName} onChange={onNameChange} />
      </div>
      <div className={sn('address')}>
        <SearchInput placeholder="Адрес (мин 3 символа)" value={searchAddress} onChange={onAddressChange} />
      </div>
    </div>
  );
};
