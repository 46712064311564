import {generatePath, useParams} from 'react-router';

import {routes} from '../constants/routes';

export type BreadcrumbsList = {
  label?: string;
  href?: string;
};

export const useBreadcrumbs = () => {
  const {
    apartmentComplexId = '',
    apartmentId = '',
    houseId = '',
    courtyardId = '',
    intercomId = '',
    parkingId = '',
    parkingSectionId = '',
    parkingPlaceId = '',
  } = useParams<{
    apartmentComplexId: string;
    courtyardId: string;
    houseId: string;
    apartmentId: string;
    intercomId: string;
    parkingId: string;
    parkingSectionId: string;
    parkingPlaceId: string;
  }>();

  const apartmentComplexesBreadcrumbs: BreadcrumbsList[] = [
    {label: 'Жилые комплексы', href: routes.apartmentComplexes},
  ];
  const courtyardBreadcrumbs = apartmentComplexesBreadcrumbs.concat([
    {label: 'Дворы', href: generatePath(routes.courtyards, {apartmentComplexId})},
  ]);
  const gateBreadcrumbs = courtyardBreadcrumbs.concat([
    {label: 'Калитки', href: generatePath(routes.gates, {apartmentComplexId, courtyardId})},
  ]);
  const houseBreadcrumbs = courtyardBreadcrumbs.concat([
    {label: 'Дома', href: generatePath(routes.houses, {apartmentComplexId, courtyardId})},
  ]);
  const apartmentsBreadcrumbs = houseBreadcrumbs.concat([
    {label: 'Квартиры', href: generatePath(routes.apartments, {apartmentComplexId, courtyardId, houseId})},
  ]);
  const apartmentBreadcrumbs = (label: string) =>
    apartmentsBreadcrumbs.concat([
      {label, href: generatePath(routes.apartment, {apartmentComplexId, courtyardId, houseId, apartmentId})},
    ]);
  const intercomsBreadcrumbs = [{label: 'Домофоны', href: routes.intercoms}];
  const intercomBreadcrumbs = (label: string) =>
    intercomsBreadcrumbs.concat([{label, href: generatePath(routes.intercoms, {intercomId})}]);
  const camerasBreadcrumbs = [{label: 'Камеры', href: routes.cameras}];
  const officesBreadcrumbs = [{label: 'Офисы', href: routes.offices}];
  const employeesBreadcrumbs = [{label: 'Сотрудники', href: routes.employees}];
  const activeApartmentsBreadcrumbs = [{label: 'Активные квартиры', href: routes.activeApartments}];

  const activeParkingBreadcrumbs = [{label: 'Активный паркинг', href: routes.activeParkingPlaces}];
  const parkingBreadcrumbs = apartmentComplexesBreadcrumbs.concat([
    {label: 'Парковки', href: generatePath(routes.parking, {apartmentComplexId})},
  ]);
  const parkingSectionListBreadcrumbs = parkingBreadcrumbs.concat([
    {label: 'Паркинги', href: generatePath(routes.parkingSectionList, {apartmentComplexId, parkingId})},
  ]);
  const parkingPlacesListBreadcrumbs = parkingSectionListBreadcrumbs.concat([
    {
      label: 'Парковочные места',
      href: generatePath(routes.parkingPlacesList, {apartmentComplexId, parkingId, parkingSectionId}),
    },
  ]);
  const parkingPlaceBreadcrumbs = (label: string) =>
    parkingPlacesListBreadcrumbs.concat([
      {
        label,
        href: generatePath(routes.parkingPlace, {apartmentComplexId, parkingId, parkingSectionId, parkingPlaceId}),
      },
    ]);
  const accessDevicesBreadcrumbs: BreadcrumbsList[] = [{label: 'Устройства доступа', href: generatePath(routes.accessDevices)}];
  const accessDeviceBreadcrumbs = (label: string) => accessDevicesBreadcrumbs.concat([{label}]);

  return {
    apartmentComplexesBreadcrumbs,
    courtyardBreadcrumbs,
    parkingBreadcrumbs,
    gateBreadcrumbs,
    houseBreadcrumbs,
    apartmentsBreadcrumbs,
    apartmentBreadcrumbs,
    intercomsBreadcrumbs,
    intercomBreadcrumbs,
    camerasBreadcrumbs,
    officesBreadcrumbs,
    employeesBreadcrumbs,
    activeApartmentsBreadcrumbs,
    activeParkingBreadcrumbs,
    parkingSectionListBreadcrumbs,
    parkingPlacesListBreadcrumbs,
    parkingPlaceBreadcrumbs,
    accessDeviceBreadcrumbs,
  };
};
