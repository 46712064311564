import {ApartmentAutocomplete} from 'shared/components';
import React, {useEffect, useState} from 'react';
import {ApartmentComplexesApi} from 'api';
import {ApartmentComplexesModel} from 'shared/models';
import {useFormikContext} from 'formik';
import {AccessDeviceFormValues} from '../../../../types';

export const ApartmentComplex = () => {
  const [apartmentComplex, setApartmentComplexes] = useState<ApartmentComplexesModel | null | undefined>(undefined);
  const {setFieldValue, values, errors, validateField} = useFormikContext<AccessDeviceFormValues>();

  const updateApartmentComplex = (model: ApartmentComplexesModel | null) => {
    setFieldValue('address', {...values.address, apartmentComplexId: model?.id || null});
    validateField('address.apartmentComplexId');
    setApartmentComplexes(model);
  };

  const getApartmentComplexes = async (id: number) => {
    const res = await ApartmentComplexesApi.getApartmentComplex(id);
    setApartmentComplexes({
      id: res.apartmentComplexId!,
      name: res.name,
      city: '',
      activeApartmentsCount: 0,
      address: '',
    });
  };

  useEffect(() => {
    if (apartmentComplex === undefined && values.address.apartmentComplexId) {
      getApartmentComplexes(values.address.apartmentComplexId);
    }
  }, [apartmentComplex, values.address.apartmentComplexId]);

  return (
    <ApartmentAutocomplete
      apartmentComplex={apartmentComplex || null}
      updateApartmentComplex={updateApartmentComplex}
      errorText={errors?.address?.apartmentComplexId}
    />
  );
};
