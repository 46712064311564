import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Autocomplete, Checkbox, TextField} from '@mui/material';
import {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';

import {ApartmentComplexesApi} from 'api';
import {DELAY} from 'shared/constants/delay';
import {useDebounce} from 'shared/hooks';
import {TiedApartmentComplexes} from 'shared/models';

type Props = {
  defaultValue: TiedApartmentComplexes[];
  updateValues: (values: TiedApartmentComplexes[]) => void;
};

export const ServicedApartmentComplexes = ({updateValues, defaultValue}: Props) => {
  const [options, setOptions] = useState<TiedApartmentComplexes[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentValue, setCurrentValue] = useState<TiedApartmentComplexes[]>(defaultValue);
  const [loading, setLoading] = useState(false);

  const getApartmentComplexes = useDebounce(async () => {
    try {
      setLoading(true);
      const payload = {query: searchValue, size: 100, page: 1};
      const res = await ApartmentComplexesApi.getApartmentComplexes(payload);
      const preparedOptions = res.items.map((complex) => ({value: complex.id, description: complex.name}));
      setOptions(preparedOptions);
    } finally {
      setLoading(false);
    }
  }, DELAY);

  useEffect(() => {
    getApartmentComplexes();
  }, [searchValue]);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onChangeAutocomplete = (event: SyntheticEvent<Element, Event>, values: TiedApartmentComplexes[]) => {
    setCurrentValue(values);
    updateValues(values);
  };

  return (
    <Autocomplete
      multiple
      id="apartmentComplexes"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.description}
      value={currentValue}
      defaultValue={defaultValue}
      loading={loading}
      onChange={onChangeAutocomplete}
      renderOption={(props, option, {selected}) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
          />
          {option.description}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} onChange={onChangeInput} label="Обслуживаемые ЖК" placeholder="ЖК" />
      )}
    />
  );
};
