import {WithPagination, CameraItemModel} from 'shared/models';

export const initialCameras: WithPagination<CameraItemModel> = {
  count: 0,
  items: [],
  total: 0,
};

export const confirmPayload = {
  title: 'Удаление камеры',
  description: 'Все данные этой камеры будут безвозвратно удалены из системы',
  acceptText: 'Удалить',
};

export const confirmUnlink = {
  title: 'Отвязывание камеры',
  description: 'Камера будт отвязана от устройств',
  acceptText: 'Отвязать',
};

export const successDeleteMessage = {
  title: 'Камера удалена',
  message: 'Камера была успешно удалена',
};

export const successUnlinkMessage = {
  title: 'Камера отвязана',
  message: 'Камера была успешно отвязана',
};

export const failedDeleteMessage = {
  title: 'Камера не удалена',
  message: 'Ошибка при удалении камеры',
  isError: true,
}

export const failedUnlinkMessage = {
  title: 'Камера не отвязана',
  message: 'Ошибка при отвязвынии камеры',
  isError: true,
};
