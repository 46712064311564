import {Search} from '@mui/icons-material';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {ChangeEvent, FC} from 'react';

import {bem} from 'shared/utils';

import styles from './SearchInput.module.scss';

const sn = bem('searchInput', styles);

type Props = {
  placeholder?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
};

export const SearchInput: FC<Props> = (props) => {
  const {placeholder, value, onChange: setValue, type = 'text'} = props;

  return (
    <div className={styles.searchInput}>
      <TextField
        type={type}
        sx={{backgroundColor: 'background.paper', height: 48}}
        variant="outlined"
        className={sn('field')}
        placeholder={placeholder}
        value={value}
        onChange={setValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Search className={sn('icon')} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
