import {CourtyardModel, WithPagination} from 'shared/models';

export const initialCourtyards: WithPagination<CourtyardModel> = {
  count: 0,
  items: [],
  total: 0,
};

export const getPreparedCourtyards = (courtyards: CourtyardModel[]) => {
  return courtyards.map(c => ({...c, id: c.courtyardId}));
}