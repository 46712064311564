import {Button} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {FC, useState, useEffect} from 'react';
import {useNavigate} from 'react-router';

import {CamerasApi} from 'api/services/camerasService';
import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {routes} from 'shared/constants/routes';
import {useBreadcrumbs, useDebounce, useModal, usePrevious, useToast} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {CameraItemModel, RequestWithPagination, SearchCameras} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './Cameras.module.scss';
import {columns} from './columns';
import {EditCamera} from './components/EditCamera';
import {SearchBlock} from './components/SearchBlock/SearchBlock';
import {
  confirmPayload,
  confirmUnlink,
  failedDeleteMessage,
  failedUnlinkMessage,
  initialCameras,
  successDeleteMessage,
  successUnlinkMessage,
} from './constants';

const sn = bem('cameras', styles);

export const Cameras: FC = () => {
  const [editingCamera, setEditingCamera] = useState<CameraItemModel | null>(null);
  const [nameSearch, setNameSearch] = useState('');
  const [addressSearch, setAddressSearch] = useState('');
  const [cameras, setCameras] = useState(initialCameras);
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);
  const [filterLoading, setFilterLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const {showToast} = useToast();
  const {confirm} = useConfirm();
  const {isOpen, openModal, closeModal} = useModal();
  const {camerasBreadcrumbs} = useBreadcrumbs();
  const isActiveFilter = nameSearch.length >= 3 || addressSearch;
  const prevIsActiveFilter = usePrevious(isActiveFilter);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isActiveFilter) {
      getCameras();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchCameras();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [nameSearch, addressSearch, isActiveFilter, paginationModel]);

  const addCamera = () => {
    navigate(routes.addNewCamera);
  };

  const onCellClick = async (params: GridCellParams<CameraItemModel>) => {
    if (params.field === 'edit') {
      setEditingCamera(params.row);
      openModal();
      return;
    }
    if (params.field === 'delete') {
      if (params.row.cameraId && (await confirm(confirmPayload))) {
        deleteCamera(params.row.cameraId);
        return;
      }
    }
    if (params.field === 'unlink') {
      if (params.row.cameraId && (await confirm(confirmUnlink))) {
        unlinkCamera(params.row.cameraId);
        return;
      }
    }
  };

  const deleteCamera = async (id: number) => {
    try {
      setLoading(true);
      await CamerasApi.deleteCamera(id);
      const filteredAllCameras = cameras.items.filter((c) => c.cameraId !== id);
      setCameras({...cameras, items: filteredAllCameras});
      showToast(successDeleteMessage);
    } catch (err) {
      showToast(failedDeleteMessage);
    } finally {
      setLoading(false);
    }
  };

  const unlinkCamera = async (id: number) => {
    try {
      setLoading(true);
      await CamerasApi.unlinkCamera(id);
      showToast(successUnlinkMessage);
    } catch (err) {
      showToast(failedUnlinkMessage);
    } finally {
      setLoading(false);
    }
  };

  const getCameras = async () => {
    try {
      setLoading(true);
      const payload = {size: paginationModel.pageSize, page: paginationModel.page + 1};
      const res = await CamerasApi.getCameras(payload);
      const preparedCameras = res.items.map((camera) => ({...camera, id: camera.cameraId}));
      setCameras({...res, items: preparedCameras});
    } finally {
      setLoading(false);
    }
  };

  const searchCameras = useDebounce(async () => {
    try {
      setFilterLoading(true);
      const payload = getPreparedPayload();
      const res = await CamerasApi.getCameras(payload);
      const preparedCameras = res.items.map((camera) => ({...camera, id: camera.cameraId}));
      setCameras({...res, items: preparedCameras});
    } finally {
      setFilterLoading(false);
    }
  }, DELAY);

  const getPreparedPayload = () => {
    const payload: RequestWithPagination<SearchCameras> = {
      size: paginationModel.pageSize,
      page: paginationModel.page + 1,
    };
    if (nameSearch.length >= 3) payload.name = nameSearch;
    if (addressSearch.length >= 3) payload.address = addressSearch;
    return payload;
  };

  const onAfterEdit = () => {
    if (isActiveFilter) {
      searchCameras();
    } else {
      getCameras();
    }
    closeModal();
  };

  return (
    <>
      <div className={styles.cameras}>
        <TitleBlock
          title="Камеры"
          breadcrumbs={camerasBreadcrumbs}
          action={
            <Button variant="contained" color="secondary" className={sn('addCamera')} onClick={addCamera}>
              Добавить камеру
            </Button>
          }
        />
        <SearchBlock
          nameSearch={nameSearch}
          addressSearch={addressSearch}
          updateNameSearch={setNameSearch}
          updateAddressSearch={setAddressSearch}
        />
        <Table
          data={cameras}
          className={sn('table')}
          loading={loading || filterLoading}
          onCellClick={onCellClick}
          columns={columns}
          paginationModel={paginationModel}
          updatePaginationModel={setPaginationModel}
        />
      </div>
      <EditCamera isOpen={isOpen} onAfterEdit={onAfterEdit} editingCamera={editingCamera} onClose={closeModal} />
    </>
  );
};
