import {Button} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {ChangeEvent, FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';

import {AccountsApi} from 'api';
import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {SearchInput} from 'shared/components/SearchInput';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {routes} from 'shared/constants/routes';
import {useDebounce, useBreadcrumbs, useModal, useToast, usePrevious} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccountModel} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './Accounts.module.scss';
import {EditAccount} from './components/EditAccount';
import {
  confirmBlock,
  confirmResetPassword,
  confirmUnblock,
  failedBlockAccountMessage,
  failedDeleteMessage,
  failedResetPasswordMessage,
  failedUnblockAccountMessage,
  getSuccessBlockAccountMessage,
  getSuccessDeleteMessage,
  getSuccessResetPasswordMessage,
  getSuccessUnblockAccountMessage,
  initialAccountList,
} from './constants';
import {columns} from './utils';

const sn = bem('accounts', styles);

export const Accounts: FC = () => {
  const [accounts, setAccounts] = useState(initialAccountList);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);
  const [searchAccount, setSearchAccount] = useState<string>('');
  const {isOpen, openModal, closeModal} = useModal();
  const [editAccountId, setEditAccountId] = useState<number | null>(null);

  const navigate = useNavigate();
  const {showToast} = useToast();
  const {confirm} = useConfirm();
  const {employeesBreadcrumbs} = useBreadcrumbs();
  const isActiveFilter = searchAccount.length >= 3;
  const prevIsActiveFilter = usePrevious(isActiveFilter);

  useEffect(() => {
    if (!isActiveFilter) {
      getAccounts();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchAccounts();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [searchAccount, isActiveFilter, paginationModel]);

  const deleteAccount = async (id: number, name: string) => {
    try {
      setLoading(true);
      await AccountsApi.deleteAccount(id);
      const preparedAccounts = accounts.items.filter((account) => account.id !== id);
      setAccounts({...accounts, items: preparedAccounts});
      showToast(getSuccessDeleteMessage(name));
    } catch (err) {
      showToast(failedDeleteMessage);
    } finally {
      setLoading(false);
    }
  };

  const getAccounts = async () => {
    try {
      setLoading(true);
      const payload = {
        page: paginationModel.page + 1,
        size: paginationModel.pageSize,
        withBlocked: true,
      };
      const res = await AccountsApi.getAccounts(payload);
      setAccounts(res);
    } finally {
      setLoading(false);
    }
  };

  const searchAccounts = useDebounce(async () => {
    if (searchAccount && searchAccount.length < 3) return;
    try {
      setLoading(true);
      const payload = {
        page: paginationModel.page + 1,
        size: paginationModel.pageSize,
        searchQuery: String(searchAccount),
        withBlocked: true,
      };
      const res = await AccountsApi.getAccounts(payload);
      setAccounts(res);
    } finally {
      setLoading(false);
    }
  }, DELAY);

  const onAccountSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchAccount(event.target.value);
  };

  const addAccount = () => {
    navigate(routes.addNewEmployees);
  };

  const onResetPassword = async (id: number, name: string) => {
    try {
      setLoading(true);
      await AccountsApi.resetPassword(id);
      showToast(getSuccessResetPasswordMessage(name));
      getAccounts();
    } catch (err) {
      showToast(failedResetPasswordMessage);
    } finally {
      setLoading(false);
    }
  };

  const blockAccount = async (id: number, name: string) => {
    try {
      setLoading(true);
      await AccountsApi.blockAccount(id);
      showToast(getSuccessBlockAccountMessage(name));
      getAccounts();
    } catch (err) {
      showToast(failedBlockAccountMessage);
    } finally {
      setLoading(false);
    }
  };

  const unblockAccount = async (id: number, name: string) => {
    try {
      setLoading(true);
      await AccountsApi.unblockAccount(id);
      showToast(getSuccessUnblockAccountMessage(name));
      getAccounts();
    } catch (err) {
      showToast(failedUnblockAccountMessage);
    } finally {
      setLoading(false);
    }
  };

  const onCellClick = async (params: GridCellParams<AccountModel>) => {
    if (params.field === 'delete') {
      const confirmPayload = {
        title: 'Удаление сотрудника',
        description: `Данные сотрудника <strong>${params.row.fullName}</strong> будут удалены из системы`,
        acceptText: 'Удалить',
      };
      if (await confirm(confirmPayload)) {
        deleteAccount(params.row.id, params.row.fullName);
      }
    }
    if (params.field === 'edit') {
      setEditAccountId(params.row.id);
      openModal();
      return;
    }
    if (params.field === 'access') {
      if (params.row.active) {
        if (await confirm(confirmBlock)) {
          blockAccount(params.row.id, params.row.fullName);
        }
      } else {
        if (await confirm(confirmUnblock)) {
          unblockAccount(params.row.id, params.row.fullName);
        }
      }
      return;
    }
    if (params.field === 'resetPassword') {
      if (await confirm(confirmResetPassword)) {
        onResetPassword(params.row.id, params.row.fullName);
      }
      return;
    }
  };

  const onClose = () => {
    closeModal();
    setEditAccountId(null);
  };

  return (
    <div className={styles.accounts}>
      <TitleBlock
        title="Сотрудники Регион"
        breadcrumbs={employeesBreadcrumbs}
        action={
          <Button variant="contained" color="secondary" className={sn('addAccount')} onClick={addAccount}>
            Добавить сотрудника
          </Button>
        }
      />
      <div className={sn('search')}>
        <SearchInput placeholder="Найти сотрудника (мин 3 символа)" value={searchAccount} onChange={onAccountSearch} />
      </div>
      <Table
        loading={loading}
        data={accounts}
        className={sn('table')}
        columns={columns}
        onCellClick={onCellClick}
        paginationModel={paginationModel}
        updatePaginationModel={setPaginationModel}
      />
      <EditAccount isOpen={isOpen} onClose={onClose} editAccountId={editAccountId} />
    </div>
  );
};
