import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {List, ListItem, ListItemButton, ListItemText} from '@mui/material';
import {FC} from 'react';
import {useLocation, useNavigate} from 'react-router';

import {bem} from 'shared/utils';

import styles from './SidebarSection.module.scss';

import {NavMenu} from '../../constants';

type Props = {
  item: NavMenu;
};

export const SidebarSection: FC<Props> = ({item}) => {
  const sn = bem('sidebarSection', styles);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.sidebarSection}>
      <p className={sn('name')}>{item.name}</p>
      <List>
        {item.subItems.map(({name, route}) => (
          <ListItem
            key={name}
            disablePadding
            onClick={() => navigate(route)}
            className={sn('item', {isActive: location.pathname.includes(route)})}
          >
            <ListItemButton>
              <ListItemText primary={name} />
              <ArrowForwardIosIcon fontSize="medium" className={sn('icon')} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
