import {GridCellParams} from '@mui/x-data-grid';

import {Table} from 'shared/components';
import {AccountRoles, OneApartmentTenants} from 'shared/models';
import {checkAccessForRole} from 'shared/utils';

import {getColumns} from './columns';
import styles from './TenantsTable.module.scss';
import {preparedItems} from './utils';

type Props = {
  tenants: OneApartmentTenants[];
  onCellClick: (params: GridCellParams<OneApartmentTenants>) => void;
  loading: boolean;
};

export const TenantsTable = ({tenants, onCellClick, loading}: Props) => {
  const data = tenants?.length ? {total: tenants.length, items: preparedItems(tenants), count: tenants.length} : null;
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);

  return (
    <div className={styles.tenantsTable}>
      <Table columns={getColumns({isDisabled})} data={data} onCellClick={onCellClick} loading={loading} />
    </div>
  );
};
