import {Formik, FormikProps} from 'formik';
import {SyntheticEvent, useEffect, useRef, useState} from 'react';

import {AddressAutocomplete, FormikTextField, Loader} from 'shared/components';
import {Modal} from 'shared/components/Modal';
import {AddressOptionType, NewOffice, OfficeInfoModel, TiedApartmentComplexes} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './OfficeForm.module.scss';
import {validation} from './validation';

import {FormObserver} from '../../../../shared/components/FormObserver';
import {initialValues} from '../../constants';
import {ServicedApartmentComplexes} from '../ServicedApartmentComplexes/ServicedApartmentComplexes';

const sn = bem('officeForm', styles);

export type FormValues = {
  streetId: number | null;
  location: string;
  phoneNumber: string;
  email: string;
  apartmentComplexes: TiedApartmentComplexes[];
};

type Props = {
  loading?: boolean;
  saving: boolean;
  updateDirty?: (value: boolean) => void;
  title: string;
  isOpen: boolean;
  closeModal: () => void;
  onSave: (values: NewOffice) => void;
  editingOffice?: OfficeInfoModel | null;
};

const OfficeForm = ({loading, saving, updateDirty, title, isOpen, closeModal, editingOffice, onSave}: Props) => {
  const formik = useRef<FormikProps<FormValues>>(null);
  const [dirty, setDirty] = useState(false);
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (editingOffice && formik.current?.values) {
      formik.current?.resetForm({values: editingOffice});
      setAddress(editingOffice.streetAddress);
    }
  }, [editingOffice]);

  const onChangeForm = (values: FormikProps<FormValues>) => {
    if (values.dirty !== dirty) {
      setDirty(values.dirty);
      if (updateDirty) updateDirty(values.dirty);
    }
  };

  const onCancel = () => {
    closeModal();
    setAddress('');
    setDirty(false);
  };

  const updateServicedAP = (values: TiedApartmentComplexes[]) => {
    formik.current?.setFieldValue('apartmentComplexes', values);
  };

  const onBeforeSave = () => {
    if (formik.current?.values) {
      const {apartmentComplexes, ...rest} = formik.current.values;
      const apartmentComplexIds = apartmentComplexes.map((a) => a.value);
      const payload: NewOffice = {...rest, apartmentComplexIds};
      onSave(payload);
      setAddress('');
      setDirty(false);
    }
  };

  const onAddressChange = (value: string) => {
    if (formik.current) {
      setAddress(value);
      if (formik.current.values.streetId) {
        formik.current.setFieldValue('streetId', null);
      }
    }
  };

  const onAddressSelect = (event: SyntheticEvent<Element, Event>, newValue: unknown) => {
    const value = newValue as AddressOptionType;
    if (value?.fullAddress) {
      setAddress(value.fullAddress);
      formik.current?.setFieldValue('streetId', Number(value.id));
    } else {
      formik.current?.setFieldValue('streetId', null);
      setAddress('');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      onSave={onBeforeSave}
      onCancel={onCancel}
      title={title}
      disableSave={saving || loading}
      closeButton
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      maxWidth="lg"
    >
      {loading ? (
        <div className={sn('loadingWrap')}>
          <Loader />
        </div>
      ) : (
        <Formik
          innerRef={formik}
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={onBeforeSave}
          ref={formik}
        >
          <div className={styles.officeForm}>
            <FormObserver onChange={onChangeForm} />
            <AddressAutocomplete
              name="streetAddress"
              label="Адрес офиса *"
              classNames={sn('address')}
              value={address}
              onAddressChange={onAddressChange}
              onAddressSelect={onAddressSelect}
              error={Boolean(formik.current?.errors.streetId)}
              helperText={formik.current?.errors.streetId}
            />
            <FormikTextField classNames={sn('city')} name="location" required label="Номер дома" />
            <FormikTextField classNames={sn('phone')} name="phoneNumber" required label="Телефон администратора" />
            <FormikTextField classNames={sn('email')} name="email" required label="Электронная почта офиса" />
            <div className={sn('apartmentComplexes')}>
              <ServicedApartmentComplexes
                defaultValue={editingOffice?.apartmentComplexes || []}
                updateValues={updateServicedAP}
              />
            </div>
          </div>
        </Formik>
      )}
    </Modal>
  );
};

export {OfficeForm};
