import {FC} from 'react';
import {Link} from 'react-router-dom';

import LogoIcon from 'assets/icons/logo.svg';
import {routes} from 'shared/constants/routes';

import style from './Logo.module.scss';

const Logo: FC = () => {
  return (
    <div className={style.logo}>
      <Link to={routes.home}>
        <div>
          <img src={LogoIcon} className={style.logo__image} alt="" />
        </div>
      </Link>
    </div>
  );
};

export {Logo};
