import {FC} from 'react';
import {ReportType} from 'shared/models';
import {LoadReport} from '../../shared/components/LoadReport';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import styles from './ActiveApartmentsReport.module.scss';
import {bem} from 'shared/utils';
import {useBreadcrumbs} from 'shared/hooks';

const sn = bem('activeApartmentsReport', styles);

export const ActiveApartmentsReport: FC = () => {
  const {activeApartmentsBreadcrumbs} = useBreadcrumbs();

  return (
    <div className={sn()}>
      <TitleBlock title="Отчет по активным квартирам" breadcrumbs={activeApartmentsBreadcrumbs} />
      <LoadReport reportType={ReportType.condominium} />
    </div>
  );
};
