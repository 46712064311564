import {apiClient} from 'api/client';
import {EnableServicePayload, OneApartmentModel, ServiceType} from 'shared/models';

class OneApartmentService {
  private getBasePath(apartmentId: string, values: string) {
    return `/properties/apartments/${apartmentId}${values}`;
  }

  private getManagingPath(apartmentId: string, values: string) {
    return `/managing/apartments/${apartmentId}${values}`;
  }

  getApartment(apartmentId: string): Promise<OneApartmentModel> {
    return apiClient.get(this.getBasePath(apartmentId, '')).then((res) => res.data);
  }

  deleteTenant(apartmentId: string, clientId: number) {
    return apiClient.delete(this.getManagingPath(apartmentId, '/remove-tenant'), {params: {clientId}}).then((res) => res.data);
  }

  enableService(apartmentId: string, serviceType: ServiceType, payload: EnableServicePayload) {
    return apiClient.post(this.getBasePath(apartmentId, `/services/${serviceType}/enable`), payload).then((res) => res.data);
  }

  disableService(apartmentId: string, serviceType: ServiceType) {
    return apiClient.post(this.getBasePath(apartmentId, `/services/${serviceType}/disable`)).then((res) => res.data);
  }

}

export const OneApartmentApi = new OneApartmentService();
