import {object, string} from 'yup';

export const validation = (withoutAddress: boolean) =>
  object().shape({
    name: string()
      .trim()
      .min(3, 'Название ЖК не может быть меньше 3 символов')
      .max(50, 'Название ЖК не может быть больше 50 символов')
      .required('Введите название'),
    streetId: withoutAddress ? string().nullable() : string().required('Выберите адрес из выпадающего списка'),
    streetNumber: withoutAddress
      ? string().nullable()
      : string().trim().max(8, 'Номер дома не может быть больше 8 символов').required('Введите номер дома'),
  });
