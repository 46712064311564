import axios from 'axios';

import env from 'shared/constants/env';

import {registerAuthInterceptor, registerErrorHandlingInterceptor} from './interceptors/auth';

export const apiClient = axios.create({baseURL: env.API_URL, withCredentials: true});

registerAuthInterceptor(apiClient);
registerErrorHandlingInterceptor(apiClient);
