import * as Yup from 'yup';

import {isOnlySpecialChars} from 'shared/utils/checkOnlySpecialChars';

export const validation = Yup.object().shape({
  name: Yup.string()
    .max(100, 'Название калитки не может быть больше 100 символов')
    .trim()
    .required('Введите название')
    .test('only spec', 'Название калитки не может состоять только из специальных символов', (value) => {
      return !isOnlySpecialChars(value);
    }),
  streetId: Yup.string().required('Выберите адрес из выпадающего списка'),
  streetNumber: Yup.string().max(8, 'Номер дома не может быть больше 8 символов').required('Введите номер дома'),
});
