import {GridCellParams, GridPaginationModel} from '@mui/x-data-grid';
import {generatePath, useNavigate, useParams} from 'react-router';

import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {routes} from 'shared/constants/routes';
import {AccountRoles, ParkingSectionItemModel, WithPagination} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {getColumns} from './columns';
import styles from './ParkingSectionTable.module.scss';

type Props = {
  loading: boolean;
  parkingSectionList: WithPagination<ParkingSectionItemModel>;
  deleteParkingSection: (values: ParkingSectionItemModel) => void;
  paginationModel: GridPaginationModel;
  updatePaginationModel: (value: GridPaginationModel) => void;
};

const confirmDeletePayload = {
  title: 'Удаление паркинга',
  description: 'Все данные этого паркинга будут безвозвратно удалены из системы',
  acceptText: 'Удалить',
};

const sn = bem('parkingSectionTable', styles);

export const ParkingSectionTable = ({
  loading,
  parkingSectionList,
  deleteParkingSection,
  paginationModel,
  updatePaginationModel,
}: Props) => {
  const navigate = useNavigate();
  const {apartmentComplexId, parkingId} = useParams<{
    apartmentComplexId: string;
    parkingId: string;
  }>();
  const {confirm} = useConfirm();
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);

  const onCellClick = (params: GridCellParams<ParkingSectionItemModel>) => {
    const pathParams = {apartmentComplexId, parkingId, parkingSectionId: params.row.id};
    if (params.field === 'name') {
      navigate(generatePath(routes.parkingPlacesList, pathParams));
    }
    if (params.field === 'edit') {
      if (isDisabled) return;
      navigate(generatePath(routes.editParkingSection, pathParams));
    }
    if (params.field === 'delete') {
      if (isDisabled) return;
      onDeleteClick(params.row);
    }
  };

  const onDeleteClick = async (values: ParkingSectionItemModel) => {
    if (await confirm(confirmDeletePayload)) {
      deleteParkingSection(values);
    }
  };

  return (
    <div className={styles.housesTable}>
      <Table
        data={parkingSectionList}
        loading={loading}
        className={sn('table')}
        columns={getColumns({isDisabled})}
        onCellClick={onCellClick}
        paginationModel={paginationModel}
        updatePaginationModel={updatePaginationModel}
      />
    </div>
  );
};
