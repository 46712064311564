import {useState} from 'react';
import {useNavigate} from 'react-router';

import {CamerasApi} from 'api/services/camerasService';
import {CameraForm} from 'shared/components/CameraForm';
import {routes} from 'shared/constants/routes';
import {useToast} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {NewCamera} from 'shared/models';

import styles from './AddNewCamera.module.scss';

export const AddNewCamera = () => {
  const navigate = useNavigate();
  const {showToast} = useToast();
  const [saving, setSaving] = useState(false);

  const onSave = async (payload: Partial<NewCamera>) => {
    try {
      setSaving(true);
      await CamerasApi.addCamera(payload);
      navigate(routes.cameras);
      showToast({
        title: 'Камера добавлена',
        message: `Камера ${payload.name} успешно добавлена.`,
      });
    } catch (err) {
      showToast({
        title: 'Камера  не добавлена',
        message: `Ошибка сохранения камеры ${payload.name}`,
        isError: true,
      });
    } finally {
      setSaving(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div className={styles.addNewCamera}>
      <TitleBlock title="Добавление камеры" />
      <CameraForm saving={saving} onSave={onSave} onCancel={onCancel} />
    </div>
  );
};
