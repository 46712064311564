import {Formik, FormikHelpers, FormikProps} from 'formik';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';

import {AccountsApi} from 'api';
import {AccountForm} from 'shared/components/AccountForm';
import {Modal} from 'shared/components/Modal';
import {routes} from 'shared/constants/routes';
import {useToast} from 'shared/hooks';
import {AccountRoles, BaseAccountModel} from 'shared/models';

import {validation} from '../../../AddNewAccount/validation';

type Props = {
  isOpen: boolean;
  editAccountId: number | null;
  onClose: () => void;
};

const initialEditValues = {
  email: '',
  phoneNumber: '',
  firstName: '',
  secondName: '',
  lastName: '',
  role: AccountRoles.user,
};

export const EditAccount = ({isOpen, editAccountId, onClose}: Props) => {
  const navigate = useNavigate();
  const {showToast} = useToast();
  const formik = useRef<FormikProps<BaseAccountModel>>(null);
  const [loading, setLoading] = useState(false);

  const submit = async (values: BaseAccountModel, {setSubmitting}: FormikHelpers<BaseAccountModel>) => {
    if (editAccountId) {
      setSubmitting(true);
      try {
        await AccountsApi.editAccount(editAccountId, values);
        navigate(routes.employees);
        showToast({
          title: 'Сотрудник обновлен',
          message: `Данные сотрудника: ${values?.firstName} ${values?.secondName} успешно обновлены.`,
        });
      } catch (err) {
        showToast({
          title: 'Сотрудник не обновлен',
          message: `Ошибка обновления данных сотрудника ${values?.firstName} ${values?.secondName}`,
          isError: true,
        });
      }
      setSubmitting(false);
      onClose();
    }
  };

  const getAccountInfo = async () => {
    if (editAccountId) {
      try {
        setLoading(true);
        const res = await AccountsApi.getAccount(editAccountId);
        if (formik.current) {
          formik.current?.resetForm({values: res});
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAccountInfo();
  }, [editAccountId]);

  const onCancel = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      title="Редактирование сотрудника"
      maxWidth="lg"
    >
      <Formik
        innerRef={formik}
        onSubmit={submit}
        initialValues={initialEditValues}
        validationSchema={validation(true)}
        validateOnChange={false}
      >
        <AccountForm isEditing onCancel={onCancel} loading={loading} />
      </Formik>
    </Modal>
  );
};
