import {Formik, FormikHelpers} from 'formik';
import {useRef} from 'react';
import {useNavigate} from 'react-router';

import {AccountsApi} from 'api';
import {AccountForm} from 'shared/components/AccountForm';
import {routes} from 'shared/constants/routes';
import {useBreadcrumbs, useToast} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {NewAccount} from 'shared/models';

import styles from './AddNewAccount.module.scss';
import {initialValues} from './utils';
import {validation} from './validation';

export const AddNewAccount = () => {
  const navigate = useNavigate();
  const {showToast} = useToast();
  const formik = useRef(null);
  const {employeesBreadcrumbs} = useBreadcrumbs();

  const submit = async (values: NewAccount, {setSubmitting}: FormikHelpers<NewAccount>) => {
    setSubmitting(true);
    try {
      await AccountsApi.addAccount(values);
      navigate(routes.employees);
      showToast({
        title: 'Сотрудник добавлен',
        message: `Сотрудник ${values?.firstName} ${values?.secondName} успешно добавлена.`,
      });
    } catch (err) {
      showToast({
        title: 'Сотрудник не добавлен',
        message: `Ошибка сохранения данных сотрудника ${values?.firstName} ${values?.secondName}`,
        isError: true,
      });
    }
    setSubmitting(false);
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div className={styles.addNewAccount}>
      <TitleBlock title="Добавление сотрудника" breadcrumbs={employeesBreadcrumbs} />
      <Formik
        className={styles.editCameraModalForm}
        innerRef={formik}
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={validation}
        validateOnChange={false}
      >
        <AccountForm onCancel={onCancel} />
      </Formik>
    </div>
  );
};
