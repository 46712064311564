import {AxiosPromise} from 'axios';

import {apiClient} from 'api/client';
import {
  WithPagination,
  ParkingSectionItemModel,
  RequestWithPagination,
  ParkingSectionModel,
  ParkingSectionCreateModel,
} from 'shared/models';

class ParkingSectionService {
  private getBasePath(apartmentComplexId: string, courtyardId: string, value: string) {
    return `/properties/apartment-complexes/${apartmentComplexId}/courtyards/${courtyardId}/buildings/parking-lots${value}`;
  }

  private getImportPath(buildingId: string, value: string) {
    return `/managing/tenants/import/${buildingId}${value}`;
  }

  getParkingSectionList(
    apartmentComplexId: string,
    parkingId: string,
    payload?: RequestWithPagination,
  ): Promise<WithPagination<ParkingSectionItemModel>> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, parkingId, ''), {params: payload})
      .then((res) => res.data);
  }

  deleteParkingSection(apartmentComplexId: string, courtyardId: string, parkingId: number) {
    return apiClient
      .delete(`/properties/apartment-complexes/${apartmentComplexId}/courtyards/${courtyardId}/buildings/${parkingId}`)
      .then((res) => res.data);
  }

  getParkingSectionEntrance(apartmentComplexId: string, courtyardId: string, parkingId: string): Promise<number[]> {
    return apiClient.get(this.getBasePath(apartmentComplexId, courtyardId, `/${parkingId}/entrances`)).then((res) => res.data);
  }

  getEditingParkingSection(
    apartmentComplexId: string,
    courtyardId: string,
    parkingSectionId: string,
  ): Promise<ParkingSectionModel> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, courtyardId, `/${parkingSectionId}`))
      .then((res) => res.data);
  }

  addParkingSection(
    apartmentComplexId: string,
    courtyardId: string,
    payload: ParkingSectionCreateModel,
  ): Promise<ParkingSectionModel> {
    return apiClient.post(this.getBasePath(apartmentComplexId, courtyardId, ''), payload).then((res) => res.data);
  }

  updateParkingSection(
    apartmentComplexId: string,
    courtyardId: string,
    parkingSectionId: string,
    payload: ParkingSectionCreateModel,
  ): Promise<ParkingSectionModel> {
    return apiClient
      .put(this.getBasePath(apartmentComplexId, courtyardId, `/${parkingSectionId}`), payload)
      .then((res) => res.data);
  }

  getImportTemplate(buildingId: string): AxiosPromise {
    return apiClient.get(this.getImportPath(buildingId, '/template'), {responseType: 'arraybuffer'});
  }

  importTenants(buildingId: string, data: File): Promise<ParkingSectionItemModel> {
    return apiClient.post(
      this.getImportPath(buildingId, ''),
      {file: data},
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}

export const ParkingSectionApi = new ParkingSectionService();
