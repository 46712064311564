import {FormikProps, useFormikContext} from 'formik';
import {useEffect} from 'react';

type Props<T> = {
  onChange?: (values: FormikProps<T>) => void;
};

export const FormObserver = <T,>({onChange}: Props<T>) => {
  const formikProps = useFormikContext<T>();

  useEffect(() => {
    if (onChange) onChange(formikProps);
  }, [formikProps]);

  return null;
};
