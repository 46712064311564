import {MenuItem, Select} from '@mui/material';
import {GridRenderCellParams} from '@mui/x-data-grid';

import {FloorType} from '../../../../types';

type Props = {
  params: GridRenderCellParams;
  updateFloorType: (id: number, value: FloorType) => void;
};

export const FloorTypeSelect = ({params, updateFloorType}: Props) => {
  return (
    <Select
      style={{backgroundColor: 'transparent', minWidth: 150}}
      value={params.value}
      label={params.value === FloorType.residential ? 'Жилой' : 'Нежилой'}
      onChange={(e) => updateFloorType(params.row.id, e.target.value)}
    >
      <MenuItem value={FloorType.residential}>Жилой</MenuItem>
      <MenuItem value={FloorType.commercial}>Коммерческий</MenuItem>
      <MenuItem value={FloorType.technical}>Технический</MenuItem>
      <MenuItem value={FloorType.office}>Офисный</MenuItem>
    </Select>
  );
};
