import {apiClient} from 'api/client';
import {
  NewOffice,
  OfficeInfoModel,
  OfficeModel,
  RequestWithPagination,
  SearchOffices,
  WithPagination,
} from 'shared/models';

class OfficesService {
  private getBasePath(value: string) {
    return `/managing/offices${value}`;
  }

  getOffices(payload?: RequestWithPagination<SearchOffices>): Promise<WithPagination<OfficeModel>> {
    return apiClient.get(this.getBasePath(''), {params: payload}).then((res) => res.data);
  }

  getOffice(id: number): Promise<OfficeInfoModel> {
    return apiClient.get(this.getBasePath(`/${id}`)).then((res) => res.data);
  }

  addOffice(payload: Partial<NewOffice>): Promise<OfficeModel> {
    return apiClient.post(this.getBasePath(''), payload).then((res) => res.data);
  }

  editOffice(id: number, payload: Partial<NewOffice>): Promise<OfficeModel> {
    return apiClient.put(this.getBasePath(`/${id}`), payload).then((res) => res.data);
  }

  deleteOffice(id: number) {
    return apiClient.delete(this.getBasePath(`/${id}`)).then((res) => res.data);
  }
}

export const OfficesApi = new OfficesService();
