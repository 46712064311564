import {
  ApartmentComplexesModel,
  CameraItemModel,
  IntercomModel,
  BaseHouseModel,
  ApartmentsModel,
  OneApartmentTenants,
  CourtyardModel,
  OfficeModel,
  AccountModel, AccessDeviceModel, ParkingSectionFloor, PaymentModel,
} from 'shared/models';

import {Floors} from '../components/HouseConstructor/types';

export interface FullStreetsModel {
  id: number;
  parentId: number;
  fullAddress: string;
}

export type WithPagination<T> = {
  total: number;
  count: number;
  items: T[];
};

export type RequestWithPagination<P = {}> = P & {
  size?: number;
  page?: number;
  searchQuery?: string;
};

export type TableData = WithPagination<
  | ApartmentComplexesModel
  | CameraItemModel
  | IntercomModel
  | Floors
  | BaseHouseModel
  | ApartmentsModel
  | OneApartmentTenants
  | CourtyardModel
  | OfficeModel
  | AccountModel
  | AccessDeviceModel
  | ParkingSectionFloor
  | PaymentModel
>;

export type AddressOptionType = FullStreetsModel;

export enum BuildingType {
  townhouse = 0,
  condominium = 1,
  parkingLot= 5,
}