import {ApartmentComplexesApi, CourtyardsServiceApi, GatesApi, HouseApi, ParkingSectionApi} from 'api';

import {BaseHouseModel, EntranceModel, FloorModel} from '../../models';
import {CourtyardType} from '../../constants/common';

export type HouseModelAutocomplete = BaseHouseModel & {name: string};
export type EntryModelAutocomplete = EntranceModel & {name: string};
export type FloorModelAutocomplete = FloorModel & {name: string};

export const searchApartmentComplexes = () => async (name: string) => {
  const res = await ApartmentComplexesApi.searchApartmentComplexes({query: name});
  return res.items;
};

export const searchCourtyards = (id?: number | null) => async (name: string) => {
  if (!id) return [];
  const res = await CourtyardsServiceApi.getCourtyards(String(id), {
    courtyardName: name,
    courtyardType: CourtyardType.courtyard,
  });
  return res.items;
};

export const searchGates = (apartmentComplexId?: number, courtyardId?: number) => async (name: string) => {
  if (!apartmentComplexId || !courtyardId) return [];
  const res = await GatesApi.searchGates(String(apartmentComplexId), String(courtyardId), {name});
  return res.items;
};

export const searchHouse =
  (apartmentComplexId?: number | null, courtyardId?: number | null) => async (name: string) => {
    if (!apartmentComplexId || !courtyardId) return [];
    // TODO: getHouses заменить на апи поиска
    const res = await HouseApi.getHouses(String(apartmentComplexId), String(courtyardId));
    return res.items.map((house) => ({...house, name: String(house.number)})) as HouseModelAutocomplete[];
  };

export const searchParkingSection =
  (apartmentComplexId?: number | null, parkingId?: number | null) => async (name: string) => {
    if (!apartmentComplexId || !parkingId) return [];
    const res = await ParkingSectionApi.getParkingSectionList(String(apartmentComplexId), String(parkingId), {
      searchQuery: name,
    });
    return res.items;
  };

export const getHouse = async (apartmentComplexId?: number, courtyardId?: number, buildingId?: string) => {
  if (!apartmentComplexId || !courtyardId || !buildingId) return null;
  return await HouseApi.getEditingHouse(String(apartmentComplexId), String(courtyardId), buildingId);
};
