import {bem} from 'shared/utils';
import styles from './AccessDevices.module.scss';
import {AccessDevicesHeader} from './components/AccessDevicesHeader';
import {SearchBlock} from './components/SearchBlock/SearchBlock';
import {AccessDevicesApi} from 'api/services/accessDevicesService';
import {useDispatch} from 'react-redux';
import {setAccessDeviceData, setIdEditAccessDevice} from './store';
import {useDebounce, useToast} from 'shared/hooks';
import {useEffect, useState} from 'react';
import {useRootSelector} from '../../store';
import {AddAccessDevice} from './components/AddAccessDevice';
import {EditAccessDevice} from './components/EditAccessDevice';
import {DELAY} from 'shared/constants/delay';
import {AccessDevicesListTable} from './components/AccessDevicesListTable/AccessDevicesListTable';

const sn = bem('accessDevices', styles);

export const AccessDevices = () => {
  const dispatch = useDispatch();
  const paginationModel = useRootSelector((state) => state.accessDevices.paginationModel);
  const searchAddress = useRootSelector((state) => state.accessDevices.searchAddress);
  const searchName = useRootSelector((state) => state.accessDevices.searchName);
  const isAddAccessDevice = useRootSelector((state) => state.accessDevices.isAddAccessDevice);
  const idEditAccessDevice = useRootSelector((state) => state.accessDevices.idEditAccessDevice);
  const [loading, setLoading] = useState(false);
  const {showToast} = useToast();

  useEffect(() => {
    if (searchAddress || searchName) {
      searchAccessDevices();
    } else {
      getAccessDevices();
    }
  }, [searchAddress, searchName, paginationModel]);

  const getAccessDevices = async () => {
    if (searchAddress && searchAddress.length < 3) return;
    try {
      setLoading(true);
      const data = await AccessDevicesApi.getAccessDevices({
        address: searchAddress || undefined,
        name: searchName || undefined,
        page: paginationModel.page + 1,
        size: paginationModel.pageSize,
      });
      dispatch(setAccessDeviceData(data));
    } catch (err) {
      showToast({
        title: 'Ошибка',
        message: 'Ошибка загрузки устройств доступа',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const searchAccessDevices = useDebounce(getAccessDevices, DELAY);

  const onCloseEdit = () => {
    dispatch(setIdEditAccessDevice(null));
  };

  return (
    <div className={sn()}>
      <AccessDevicesHeader />
      <SearchBlock />
      <AccessDevicesListTable loading={loading} />
      {isAddAccessDevice && <AddAccessDevice onAfterAdd={getAccessDevices} />}
      {idEditAccessDevice && (
        <EditAccessDevice onAfterUpdate={getAccessDevices} onClose={onCloseEdit} accessDeviceId={idEditAccessDevice} />
      )}
    </div>
  );
};
