import {apiClient} from 'api/client';
import {
  AccountInfoModel,
  AccountModel,
  BaseAccountModel,
  NewAccount,
  RequestWithPagination,
  SearchAccounts,
  WithPagination,
} from 'shared/models';

class AccountsService {
  private getBasePath(value: string) {
    return `/managing/accounts${value}`;
  }

  getAccounts(payload?: RequestWithPagination<SearchAccounts>): Promise<WithPagination<AccountModel>> {
    return apiClient.get(this.getBasePath(''), {params: payload}).then((res) => res.data);
  }

  getAccount(id: number): Promise<AccountInfoModel> {
    return apiClient.get(this.getBasePath(`/${id}`)).then((res) => res.data);
  }

  addAccount(payload: Partial<NewAccount>): Promise<AccountInfoModel> {
    return apiClient.post(this.getBasePath(''), payload).then((res) => res.data);
  }

  resetPassword(id: number) {
    return apiClient.post(this.getBasePath(`/${id}/reset-password`)).then((res) => res.data);
  }

  editAccount(id: number, payload: Partial<BaseAccountModel>): Promise<AccountInfoModel> {
    return apiClient.put(this.getBasePath(`/${id}`), payload).then((res) => res.data);
  }

  deleteAccount(id: number) {
    return apiClient.delete(this.getBasePath(`/${id}`)).then((res) => res.data);
  }

  blockAccount(id: number) {
    return apiClient.patch(this.getBasePath(`/${id}/block`)).then((res) => res.data);
  }

  unblockAccount(id: number) {
    return apiClient.patch(this.getBasePath(`/${id}/unblock`)).then((res) => res.data);
  }

}

export const AccountsApi = new AccountsService();
