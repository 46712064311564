import {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {ApartmentsApi} from 'api';
import {Loader} from 'shared/components';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {useDebounce, usePrevious} from 'shared/hooks';
import {ApartmentsFilter, ApartmentsModel, HouseInfoModel, WithPagination} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './Apartments.module.scss';
import {ApartmentsTable, EntryTabs, ApartmentsHeader} from './components';
import {initialApartmentsFilter} from './constants';
import {addId, getSortedEntries} from './utils';

const sn = bem('apartments', styles);

export const Apartments = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [houseInfo, setHouseInfo] = useState<HouseInfoModel | null>(null);
  const [apartments, setApartments] = useState<WithPagination<ApartmentsModel> | null>(null);
  const [apartmentsFilter, setApartmentsFilter] = useState<ApartmentsFilter>(initialApartmentsFilter);
  const [loading, setLoading] = useState(false);
  const [entriesLoading, setEntriesLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);

  const {apartmentComplexId, courtyardId, houseId} = useParams<{
    apartmentComplexId: string;
    courtyardId: string;
    houseId: string;
  }>();
  const isActiveFilter =
    !!apartmentsFilter.apartmentNumber || !!apartmentsFilter.phoneNumber || !!apartmentsFilter.tenantName;
  const prevIsActiveFilter = usePrevious(isActiveFilter);

  useEffect(() => {
    if (!isActiveFilter) {
      getApartments();
    }
    if (!houseInfo) getEntryTabs();
  }, [isActiveFilter, paginationModel, apartmentsFilter.entrySectionNumber]);

  useEffect(() => {
    if (isActiveFilter) {
      searchApartments();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [isActiveFilter, apartmentsFilter, paginationModel]);

  const searchApartments = useDebounce(async () => {
    if (loading) return;
    getApartments();
  }, DELAY);

  const getApartments = async () => {
    if (apartmentComplexId && courtyardId && houseId) {
      try {
        setLoading(true);
        const payload = {
          ...apartmentsFilter,
          entrySectionNumber: isActiveFilter ? undefined : apartmentsFilter.entrySectionNumber,
          size: paginationModel.pageSize,
          page: paginationModel.page + 1,
        };
        const apartments = await ApartmentsApi.getApartments(apartmentComplexId, courtyardId, houseId, payload);
        const apartmentsWithId = {...apartments, items: addId(apartments.items)};
        setApartments(apartmentsWithId);
      } finally {
        setLoading(false);
      }
    }
  };

  const getEntryTabs = async () => {
    if (apartmentComplexId && courtyardId && houseId) {
      try {
        setEntriesLoading(true);
        const houseInfo = await ApartmentsApi.getHouseInfo(apartmentComplexId, courtyardId, houseId);
        setHouseInfo(houseInfo);
      } finally {
        setEntriesLoading(false);
      }
    }
  };

  const onChangeTab = (tabIndex: number) => {
    setCurrentTab(tabIndex);
    setApartmentsFilter({...apartmentsFilter, entrySectionNumber: tabIndex + 1});
    setPaginationModel(initPaginationModel);
  };

  const onAfterUpdate = () => {
    getApartments();
  };

  const onAfterImport = () => {
    getApartments();
  };

  return (
    <div className={styles.apartments}>
      <ApartmentsHeader
        apartmentsFilter={apartmentsFilter}
        updateApartmentsFilter={setApartmentsFilter}
        onAfterImport={onAfterImport}
      />
      {entriesLoading ? (
        <div className={sn('entriesLoader')}>
          <Loader />
        </div>
      ) : (
        <>
          {isActiveFilter ? (
            <div className={sn('entriesLoader')} />
          ) : (
            <EntryTabs
              entries={getSortedEntries(houseInfo?.entries)}
              currentTab={currentTab}
              onChangeTab={onChangeTab}
            />
          )}
          <ApartmentsTable
            isActiveFilter={isActiveFilter}
            loading={loading}
            apartments={apartments}
            paginationModel={paginationModel}
            updatePaginationModel={setPaginationModel}
            onAfterUpdate={onAfterUpdate}
          />
        </>
      )}
    </div>
  );
};
