import {apiClient} from 'api/client';
import {WithPagination, FullStreetsModel, GetFullStreetPayload} from 'shared/models';

class UtilsService {
  getAddressObject() {
    return apiClient.get('/dictionaries/address-objects').then((res) => res.data);
  }

  getFullStreet(payload: GetFullStreetPayload): Promise<WithPagination<FullStreetsModel>> {
    return apiClient.get('/dictionaries/address-objects/full/streets', {params: payload}).then((res) => res.data);
  }

  getDeviceModels(): Promise<any> {
    return apiClient.get('/dictionaries/types/sip-panel/device-models').then((res) => res.data);
  }
}

export const UtilsApi = new UtilsService();
