import React, {useEffect, useState} from 'react';

import {HouseApi} from 'api';
import styles from 'modules/AddNewIntercom/AddIntercom.module.scss';

import {usePrevious} from '../../hooks';
import {bem} from '../../utils';
import {AutocompleteField} from '../AutocompleteField';
import {HouseModelAutocomplete, searchHouse} from '../AutocompleteField/utils';

type Props = {
  house: HouseModelAutocomplete | null;
  updateHouse: (value: HouseModelAutocomplete | null) => void;
  apartmentComplexId?: number | null;
  courtyardId?: number | null;
  showHouse: boolean;
  label?: string;
  errorText?: string;
};

const sn = bem('addNewIntercom', styles);

export const HouseAutocomplete = ({
  house,
  updateHouse,
  apartmentComplexId,
  courtyardId,
  showHouse,
  label = 'Номер дома',
  errorText,
}: Props) => {
  const [defaultHouses, setDefaultHouses] = useState<HouseModelAutocomplete[]>([]);
  const [loadingDefaultHouses, setLoadingDefaultHouses] = useState(false);

  const prevParentId = usePrevious(courtyardId);

  useEffect(() => {
    if (courtyardId) {
      getInitHouses();
    }
    if ((house && !courtyardId) || (prevParentId && prevParentId !== courtyardId)) {
      onChangeHouse(null);
    }
  }, [courtyardId, prevParentId, house]);

  const getInitHouses = async () => {
    if (!apartmentComplexId || !courtyardId) return;
    try {
      setLoadingDefaultHouses(true);
      const res = await HouseApi.getHouses(String(apartmentComplexId), String(courtyardId));
      const preparedHouses = res.items.map((house) => ({...house, name: String(house.number)}));
      setDefaultHouses(preparedHouses);
    } finally {
      setLoadingDefaultHouses(false);
    }
  };

  const onChangeHouse = (value: HouseModelAutocomplete | null) => {
    updateHouse(value);
  };

  return (
    <AutocompleteField<HouseModelAutocomplete>
      label={label}
      classNames={sn('autocomplete', 'small')}
      value={house}
      updateValue={onChangeHouse}
      getOptions={searchHouse(apartmentComplexId, courtyardId)}
      loadingDefaultOptions={loadingDefaultHouses}
      defaultOptions={defaultHouses}
      required={showHouse}
      disabled={!courtyardId}
      errorText={errorText}
    />
  );
};
