import {FC} from 'react';

import {useRootSelector} from 'store';

import {SidebarSection} from './components/SidebarSection';
import {NAV_MENU} from './constants';
import styles from './Sidebar.module.scss';
import {getFilteredMenu} from './utils';

export const Sidebar: FC = () => {
  const {profile} = useRootSelector((state) => state.auth);

  const filteredMenu = getFilteredMenu(NAV_MENU, profile?.role);

  return (
    <div className={styles.sidebar}>
      {filteredMenu.map((item) => (
        <SidebarSection key={item.name} item={item} />
      ))}
    </div>
  );
};
