import {bem} from 'shared/utils';

import styles from './AccessDenied.module.scss';

const sn = bem('accessDenied', styles);

export const AccessDenied = () => {
  return (
    <div className={sn()}>
      <h1 className={sn('title')}>Нет доступа.</h1>
      <p>Пожалуйста, обратитесь к администратору.</p>
    </div>
  );
};
