import {Delete as DeleteSvg} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

import {OfficeModel} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './OfficesTable.module.scss';

const sn = bem('officesTable', styles);

export const columns: GridColDef[] = [
  {
    field: 'city',
    headerName: 'Город',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 200,
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'address',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Адрес',
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'phoneNumber',
    headerName: 'Телефон администратора',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 210,
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Эл. почта офиса',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 200,
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'apartmentComplexes',
    headerName: 'Привязанные ЖК',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    renderCell: (params: GridRenderCellParams<OfficeModel>) => (
      <div className={sn('apartmentComplexes')}>{params.value}</div>
    ),
    maxWidth: 200,
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'edit',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: '',
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 50,
    renderCell: () => (
      <div className={sn('cell_edit')}>
        <EditIcon />
      </div>
    ),
  },
  {
    field: 'delete',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: '',
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 50,
    renderCell: () => (
      <div className={sn('cell_delete')}>
        <DeleteSvg />
      </div>
    ),
  },
];
