import {useState} from 'react';
import {useParams} from 'react-router';

import {GatesApi} from 'api';
import {useToast} from 'shared/hooks';
import {GateModel, WithPagination} from 'shared/models';

import {FormValues, GateForm} from '../GateForm';

type Props = {
  isOpen: boolean;
  gates: WithPagination<GateModel>;
  updateGates: (courtyards: WithPagination<GateModel>) => void;
  closeModal: () => void;
};

export const AddGate = ({isOpen, gates, updateGates, closeModal}: Props) => {
  const {apartmentComplexId, courtyardId} = useParams<{apartmentComplexId: string; courtyardId: string}>();
  const [saving, setSaving] = useState(false);
  const {showToast} = useToast();

  const onClose = () => {
    closeModal();
  };

  const addGate = (newGate: GateModel) => {
    const updatedGates = [...gates.items, {...newGate, id: newGate.courtyardGateId}];
    updateGates({...gates, items: updatedGates});
  };

  const onSave = async (values: FormValues) => {
    if (apartmentComplexId && courtyardId) {
      try {
        setSaving(true);
        const {streetAddress, ...payload} = values;
        const newGate = await GatesApi.addGate(apartmentComplexId, courtyardId, payload);
        if (newGate) addGate(newGate);
        showToast({
          title: 'Калитка добавлена',
          message: `Калитка ${values.name} по адресу ${streetAddress} успешно добавлена.`,
        });
        onClose();
      } catch (err) {
        showToast({
          title: 'Калитка не добавлена',
          message: 'Не удалось добавить калитку.',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <GateForm
      isOpen={isOpen}
      title="Добавить калитку"
      onSave={onSave}
      saving={saving}
      closeModal={closeModal}
      apartmentComplexId={apartmentComplexId}
      courtyardId={courtyardId}
    />
  );
};
