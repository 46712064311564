import {useState} from 'react';
import {useNavigate} from 'react-router';

import {IntercomsApi} from 'api';
import {IntercomForm} from 'shared/components/IntercomForm/IntercomForm';
import {routes} from 'shared/constants/routes';
import {useToast} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {NewIntercom} from 'shared/models';

import styles from './AddIntercom.module.scss';

export const AddNewIntercom = () => {
  const navigate = useNavigate();
  const {showToast} = useToast();
  const [saving, setSaving] = useState(false);

  const onSave = async (payload: Partial<NewIntercom>) => {
    try {
      setSaving(true);
      const response = await IntercomsApi.addIntercom(payload);
      showToast({
        title: 'Домофон добавлен',
        message: `Домофон ${payload.name} успешно добавлен.`,
      });
      navigate(routes.intercom);
      navigate(`${routes.intercoms}/${response.sipPanelId}`);
    } catch (err) {
      showToast({
        title: 'Домофон не добавлен',
        message: 'Ошибка сохранения домофона',
        isError: true,
      });
    } finally {
      setSaving(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div className={styles.addNewIntercom}>
      <TitleBlock title="Добавление домофона" />
      <IntercomForm saving={saving} onSave={onSave} onCancel={onCancel} />
    </div>
  );
};
