import {GateModel, WithPagination} from 'shared/models';

export const initialGates: WithPagination<GateModel> = {
  count: 0,
  items: [],
  total: 0,
};

export const getPreparedGates = (gates: GateModel[]) => {
  return gates.map((c) => ({...c, id: c.courtyardGateId}));
};
