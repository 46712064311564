import {AccountRoles} from '../../models';

export const ROLES = [
  {value: AccountRoles.user, name: 'Роль не назначена'},
  {value: AccountRoles.admin, name: 'Администратор'},
  {value: AccountRoles.superAdmin, name: 'Супер-администратор'},
];

export const passwordHelperText =
  'Пароль должен содержать цифры, буквы латинского алфавита в верхнем и нижнем регистрах, а также спец. символы. Длина пароля должны быть от 6 до 30 символов.';
