import React, {useEffect, useState} from 'react';

import {HouseApi} from 'api';
import styles from 'modules/AddNewIntercom/AddIntercom.module.scss';

import {usePrevious} from '../../hooks';
import {bem} from '../../utils';
import {AutocompleteField} from '../AutocompleteField';
import {EntryModelAutocomplete} from '../AutocompleteField/utils';

type Props = {
  selectedEntryNumber: number | null | undefined;
  entry: EntryModelAutocomplete | null;
  updateEntry: (value: EntryModelAutocomplete | null) => void;
  apartmentComplexId?: number;
  houseId?: string;
  courtyardId?: number;
  required: boolean;
  label?: string;
  errorText?: string;
};

const sn = bem('addNewIntercom', styles);

export const EntryAutocomplete = ({
  selectedEntryNumber,
  entry,
  updateEntry,
  apartmentComplexId,
  courtyardId,
  houseId,
  required,
  label = 'Номер подъезда',
  errorText,
}: Props) => {
  const [defaultEntryOptions, setDefaultEntryOptions] = useState<EntryModelAutocomplete[]>([]);
  const [loadingDefaultEntries, setLoadingDefaultEntries] = useState(false);

  const prevParentId = usePrevious(houseId);

  useEffect(() => {
    if (selectedEntryNumber) {
      onChangeEntry({
        floors: [],
        entryNumber: selectedEntryNumber,
        name: String(selectedEntryNumber),
      });
    }
  }, [selectedEntryNumber]);

  useEffect(() => {
    if (houseId) {
      getEntries();
    }
    if ((!houseId && entry) || (prevParentId && prevParentId !== houseId)) {
      onChangeEntry(null);
    }
  }, [houseId, prevParentId]);

  const getEntries = async () => {
    if (!apartmentComplexId || !courtyardId || !houseId) return [];
    try {
      setLoadingDefaultEntries(true);
      const res = await HouseApi.getHouse(String(apartmentComplexId), String(courtyardId), String(houseId));
      const options = res.entries.map((entry) => ({
        ...entry,
        floors: [],
        name: String(entry.entryNumber),
      })) as EntryModelAutocomplete[];
      setDefaultEntryOptions(options);
    } finally {
      setLoadingDefaultEntries(false);
    }
  };

  const onChangeEntry = (value: EntryModelAutocomplete | null) => {
    updateEntry(value);
  };

  return (
    <AutocompleteField<EntryModelAutocomplete>
      label={label}
      classNames={sn('autocomplete', 'small')}
      value={entry}
      updateValue={onChangeEntry}
      defaultOptions={defaultEntryOptions}
      loadingDefaultOptions={loadingDefaultEntries}
      required={required}
      disabled={!houseId}
      errorText={errorText}
    />
  );
};
