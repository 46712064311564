import {GridCellParams} from '@mui/x-data-grid';
import {useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {CourtyardsServiceApi} from 'api/services';
import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {routes} from 'shared/constants/routes';
import {useDebounce, useModal, usePrevious, useToast} from 'shared/hooks';
import {AccountRoles, CourtyardModel} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {getColumns} from './columns';
import {AddCourtyard} from './components/AddCourtyard';
import {CourtyardsHeader} from './components/CourtyardsHeader';
import {EditCourtyard} from './components/EditCourtyard';
import {confirmDeletePayload, failedDeleteMessage, getSuccessDeleteMessage} from './constants';
import styles from './Courtyards.module.scss';
import {getPreparedCourtyards, initialCourtyards} from './utils';
import {CourtyardType} from 'shared/constants/common';

const sn = bem('courtyards', styles);

export const Courtyards = () => {
  const [editingCourtyard, setEditingCourtyard] = useState<CourtyardModel | null>(null);
  const [courtyards, setCourtyards] = useState(initialCourtyards);
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);
  const [courtyardsSearch, setCourtyardsSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const {apartmentComplexId} = useParams<{apartmentComplexId: string}>();
  const {isOpen, openModal, closeModal} = useModal();
  const navigate = useNavigate();
  const {confirm} = useConfirm();
  const {showToast} = useToast();
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);
  const isActiveFilter = courtyardsSearch.length >= 3;
  const prevIsActiveFilter = usePrevious(isActiveFilter);

  useEffect(() => {
    if (!isActiveFilter) {
      getCourtyards();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchCourtyards();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [isActiveFilter, courtyardsSearch, paginationModel]);

  const getCourtyards = async () => {
    if (apartmentComplexId) {
      try {
        setLoading(true);
        const payload = {
          size: paginationModel.pageSize,
          page: paginationModel.page + 1,
          courtyardType: CourtyardType.courtyard,
        };
        const res = await CourtyardsServiceApi.getCourtyards(apartmentComplexId, payload);
        const preparedCourtyards = getPreparedCourtyards(res.items);
        setCourtyards({...res, items: preparedCourtyards});
      } finally {
        setLoading(false);
      }
    }
  };

  const searchCourtyards = useDebounce(async () => {
    if (courtyardsSearch && courtyardsSearch.length < 3) return;
    if (apartmentComplexId) {
      try {
        setLoading(true);
        const payload = {
          size: paginationModel.pageSize,
          page: paginationModel.page + 1,
          courtyardName: courtyardsSearch,
          courtyardType: CourtyardType.courtyard,
        };
        const res = await CourtyardsServiceApi.getCourtyards(apartmentComplexId, payload);
        const preparedCourtyards = getPreparedCourtyards(res.items);
        setCourtyards({...res, items: preparedCourtyards});
      } finally {
        setLoading(false);
      }
    }
  }, DELAY);

  const deleteCourtyard = async (values: CourtyardModel) => {
    if (apartmentComplexId && values.id) {
      try {
        setDeleting(true);
        await CourtyardsServiceApi.deleteCourtyard(apartmentComplexId, values.id);
        const filteredAllCourtyards = courtyards.items.filter((c) => c.courtyardId !== values.id);
        setCourtyards({...courtyards, items: filteredAllCourtyards});
        showToast(getSuccessDeleteMessage(values.name));
      } catch (err) {
        showToast(failedDeleteMessage);
      } finally {
        setDeleting(false);
      }
    }
  };

  const onCellClick = async (params: GridCellParams<CourtyardModel>) => {
    if (params.field === 'edit') {
      if (isDisabled) return;
      setEditingCourtyard(params.row);
      return;
    }
    if (params.field === 'delete' && params.row.id) {
      if (isDisabled) return;
      if (await confirm(confirmDeletePayload)) {
        deleteCourtyard(params.row);
      }
      return;
    }
    if (params.field === 'gates' && params.row.id) {
      navigate(generatePath(routes.gates, {apartmentComplexId, courtyardId: params.id}));
      return;
    }
    navigate(generatePath(routes.houses, {apartmentComplexId, courtyardId: params.id}));
  };

  const closeEditCourtyard = () => {
    setEditingCourtyard(null);
  };

  return (
    <div className={styles.courtyards}>
      <CourtyardsHeader
        courtyardsSearch={courtyardsSearch}
        updateCourtyardsSearch={setCourtyardsSearch}
        openModal={openModal}
      />
      <Table
        data={courtyards}
        className={sn('table')}
        loading={loading || deleting}
        paginationModel={paginationModel}
        updatePaginationModel={setPaginationModel}
        onCellClick={onCellClick}
        columns={getColumns({isDisabled})}
      />
      <AddCourtyard isOpen={isOpen} closeModal={closeModal} updateCourtyards={setCourtyards} courtyards={courtyards} />
      <EditCourtyard
        isOpen={!!editingCourtyard}
        courtyard={editingCourtyard}
        closeModal={closeEditCourtyard}
        updateCourtyards={setCourtyards}
        courtyards={courtyards}
      />
    </div>
  );
};
