import {Entry, Floors, HouseSettings} from 'shared/components/HouseConstructor/types';

import {EditHouseModel, EntranceModel, FloorModel} from '../../shared/models';

const getCalcApartmentRange = (apartmentNumbers: number[]) => {
  if (apartmentNumbers.length) {
    return `${apartmentNumbers[0]} - ${apartmentNumbers[apartmentNumbers.length - 1]}`;
  }
  return '—';
};

export const getPreparedFloors = (floors: FloorModel[]): Floors[] => {
  return floors.map((f, index) => ({
    id: index,
    floorNumber: f.floorNumber,
    floorType: f.floorType,
    quantityApartments: f.apartmentNumbers.length,
    apartmentRange: getCalcApartmentRange(f.apartmentNumbers),
    apartmentNumbers: f.apartmentNumbers,
  }));
};

const getListNumbersApartments = (floors: FloorModel[]): number[] => {
  return floors.reduce<number[]>((acc, floor) => acc.concat(floor.apartmentNumbers), []);
};

export const getPreparedEntries = (entries: EntranceModel[]): Entry[] => {
  return entries.map((entry, index) => {
    const quantityFloors = entry.floors.length;
    const listNumbersApartments = getListNumbersApartments(entry.floors);
    const quantityNumbersApartments = listNumbersApartments.length;

    return {
      id: index,
      entryNumber: entry.entryNumber,
      quantityFloors,
      quantityApartmentsOnFloor: entry.floors[0].apartmentNumbers.length,
      sameNumberApartments: false,
      firstApartmentNumber: listNumbersApartments[0],
      lastApartmentNumber: listNumbersApartments[quantityNumbersApartments - 1],
      floors: getPreparedFloors(entry.floors),
    };
  });
};

export const getPreparedHouseSettings = (house: EditHouseModel): HouseSettings => {
  return {
    address: house.streetAddress,
    streetId: house.streetId,
    streetNumber: house.streetNumber,
    quantityEntries: house.entries.length,
    quantityFloors: house.floorsCount,
    quantityApartmentsOnFloor: house.apartmentsPerFloorCount,
    entries: getPreparedEntries(house.entries),
    tariff: house.tariff
  };
};
