import {ParkingSectionAutocomplete} from 'shared/components/ParkingSectionAutocomplete';
import React, {useEffect, useState} from 'react';
import {ParkingSectionItemModel} from 'shared/models';
import {useFormikContext} from 'formik';
import {ParkingSectionApi} from 'api';
import {AccessDeviceFormValues} from '../../../../types';

export const ParkingSection = () => {
  const [parkingSection, setParkingSection] = useState<ParkingSectionItemModel | null | undefined>(undefined);
  const {setFieldValue, values, errors, validateField} = useFormikContext<AccessDeviceFormValues>();

  const updateParkingSection = (model: ParkingSectionItemModel | null) => {
    setParkingSection(model);
    const preparedAddress = {...values.address, buildingId: model?.buildingId || null};
    validateField('address.buildingId');
    setFieldValue('address', preparedAddress);
  };

  const getParkingSection = async (apartmentComplexId: number, parkingId: number, parkingSectionId: number) => {
    const res = await ParkingSectionApi.getParkingSectionList(String(apartmentComplexId), String(parkingId));
    const _parkingSection = res.items.find((item) => item.buildingId === parkingSectionId);
    if (_parkingSection) {
      setParkingSection(_parkingSection);
    }
  };

  useEffect(() => {
    if (
      parkingSection === undefined &&
      values.address.apartmentComplexId &&
      values.address.courtyardId &&
      values.address.buildingId
    ) {
      getParkingSection(values.address.apartmentComplexId, values.address.courtyardId, values.address.buildingId);
    }
  }, [parkingSection, values.address.apartmentComplexId, values.address.courtyardId, values.address.buildingId]);

  return (
    <ParkingSectionAutocomplete
      apartmentComplexId={values.address.apartmentComplexId}
      selectedBuildingId={values.address.buildingId}
      parkingSection={parkingSection || null}
      updateParkingSection={updateParkingSection}
      parkingId={values.address?.courtyardId}
      errorText={errors?.address?.buildingId}
      disable={!values.address?.courtyardId}
      required
    />
  );
};
