import {load} from '@fingerprintjs/fingerprintjs';

import {getTokenFromLS} from '../../shared/utils/tokensHandlers';

export interface AuthProvider {
  getToken(): string;
  getFingerprint(): Promise<string>;
}

const initFingerprint = () => {
  return load()
    .then((fp) => fp.get())
    .then((result) => result.visitorId);
};

export const authProvider = {
  fingerprint: '',
  getToken() {
    return getTokenFromLS();
  },
  async getFingerprint() {
    if (!this.fingerprint) {
      this.fingerprint = await initFingerprint();
      return this.fingerprint;
    }
    return this.fingerprint;
  },
};
