import {Button, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import {ChangeEvent, SyntheticEvent, useState} from 'react';
import {useParams} from 'react-router';

import {ParkingSectionApi} from 'api';
import {
  setEntrancesCount,
  setFloors,
  setName,
  setParkingSpacesPerFloorCount,
  setStreetAddress,
  setStreetId,
  setStreetNumber,
  setTariff,
} from 'modules/ParkingSectionConstructor/store';
import {AddressAutocomplete, Loader} from 'shared/components';
import {useDebounce} from 'shared/hooks';
import {FullStreetsModel} from 'shared/models';
import {bem} from 'shared/utils';
import {useRootDispatch, useRootSelector} from 'store';

import styles from './FirstStep.module.scss';
import {firstStepSchema} from './validate';

import {ParkingSectionTab} from '../../constants';
import {getInitFloors} from '../../utils';


type Props = {
  onChangeTab: (value: number) => void;
  onCancel: () => void;
};

const sn = bem('firstStep', styles);

export const FirstStep = ({onChangeTab, onCancel}: Props) => {
  const {apartmentComplexId, parkingId, parkingSectionId} = useParams<{
    apartmentComplexId: string;
    parkingId: string;
    parkingSectionId: string
  }>();
  const settings = useRootSelector((state) => state.parkingSectionConstructor.parkingSection);
  const dispatch = useRootDispatch();
  const [handlingDuplicateName, setHandlingDuplicateName] = useState(false);
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    entrancesCount: '',
    floorsCount: '',
    parkingSpacesPerFloorCount: '',
    tariff: '',
  });

  const onNextStep = async () => {
    const {floors, ...values} = settings;
    const preparedValues = {...values, floorsCount: floors.length};
    const isValid = await firstStepSchema.isValid(preparedValues);

    if (isValid && !isDuplicateName) {
      onChangeTab(ParkingSectionTab.second);
    }

    if (!isValid) {
      Object.keys(errors).map((fieldName) => {
        validateField(fieldName as keyof typeof errors, preparedValues[fieldName as keyof typeof values]);
      });
    }
  };

  const validateField = async (fieldName: keyof typeof errors, value: any) => {
    try {
      await firstStepSchema.pick([fieldName]).validate({[fieldName]: value});
      if (errors[fieldName]) {
        setErrors({...errors, [fieldName]: ''});
      }
    } catch (err) {
      const _err = err as { errors: string[]; value: any; path: string };
      if (_err && _err.errors && _err.path) {
        setErrors({...errors, [_err.path]: _err.errors[0]});
        errors[fieldName] = _err.errors[0];
      }
    }
  };

  const checkDuplicateName = useDebounce(async (name: string) => {
    if (apartmentComplexId && parkingId) {
      try {
        setHandlingDuplicateName(true);
        const parkingSectionList = await ParkingSectionApi.getParkingSectionList(apartmentComplexId, parkingId, {
          searchQuery: name.toLowerCase().trim(),
        });
        const searchedParkingSection = parkingSectionList.items.find(
          (item) => item.name.toLowerCase().trim() === name.toLowerCase().trim(),
        );

        if (searchedParkingSection && String(searchedParkingSection.buildingId) !== parkingSectionId) {
          setErrors({...errors, name: 'Паркинг с таким названием уже существует!'});
          setIsDuplicateName(true);
        } else if (isDuplicateName) {
          setIsDuplicateName(false);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setHandlingDuplicateName(false);
      }
    }
  }, 500);

  const onChangeAddress = (value: string) => {
    dispatch(setStreetAddress(value));
  };

  const onAddressSelect = (e: SyntheticEvent<Element, Event>, selectedAddress: FullStreetsModel | null) => {
    const id = selectedAddress?.id || null;
    dispatch(setStreetAddress(selectedAddress?.fullAddress || ''));
    dispatch(setStreetId(id));
  };


  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    validateField('name', e.target.value);
    dispatch(setName(e.target.value));
    if (e.target.value) checkDuplicateName(e.target.value);
  };

  const onChangeQuantityAccessDevices = (e: ChangeEvent<HTMLInputElement>) => {
    const entrancesCount = Number(e.target.value);
    validateField('entrancesCount', entrancesCount);
    if (isNaN(entrancesCount)) return;
    dispatch(setEntrancesCount(entrancesCount));
  };

  const onChangeTariff = (e: ChangeEvent<HTMLInputElement>) => {
    const tariff = Number(e.target.value);
    validateField('tariff', tariff);
    if (isNaN(tariff)) return;
    dispatch(setTariff(tariff));
  };

  const onChangeNumber = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setStreetNumber(e.target.value));
  };

  const onChangeFloorQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    const floorsCount = Number(e.target.value);
    if (isNaN(floorsCount) || floorsCount > 1000) return;
    validateField('floorsCount', floorsCount);
    const preparedFloors = getInitFloors(floorsCount, settings);
    dispatch(setFloors(preparedFloors));
  };

  const onChangeQuantitySeatsOnFloor = (e: ChangeEvent<HTMLInputElement>) => {
    const quantitySeatsOnFloor = Number(e.target.value);
    if (isNaN(quantitySeatsOnFloor) || quantitySeatsOnFloor > 5000) return;
    validateField('parkingSpacesPerFloorCount', quantitySeatsOnFloor);
    dispatch(setParkingSpacesPerFloorCount(quantitySeatsOnFloor));

    const preparedFloors = getInitFloors(settings.floors.length, {
      ...settings,
      parkingSpacesPerFloorCount: quantitySeatsOnFloor,
    });
    dispatch(setFloors(preparedFloors));
  };

  return (
    <div className={styles.firstStep}>
      <div className={sn('container')}>
        <div className={sn('formContainer')}>
          <div className={sn('formItem')}>
            <TextField
              name="name"
              label="Название паркинга"
              margin="normal"
              required
              value={settings.name}
              onChange={onChangeName}
              className={sn('name')}
              error={!!errors.name}
              helperText={errors.name}
              InputProps={{
                endAdornment: handlingDuplicateName ? (
                  <div className={sn('nameLoader')}>
                    <Loader size={25}/>
                  </div>
                ) : null,
              }}
            />
          </div>
          <div className={sn('address')}>
            <AddressAutocomplete
              name="address"
              label="Адрес"
              value={settings.streetAddress || ''}
              onAddressChange={onChangeAddress}
              onAddressSelect={onAddressSelect}
            />
          </div>
          <div className={sn('row')}>
            <div className={sn('formItem')}>
              <TextField
                name="streetNumber"
                label="Номер строения"
                margin="normal"
                value={String(settings.streetNumber || '')}
                onChange={onChangeNumber}
              />
            </div>
            <div className={sn('formItem')}>
              <TextField
                required
                name="quantityAccessDevices"
                label="Количество въездов"
                margin="normal"
                value={settings.entrancesCount}
                onChange={onChangeQuantityAccessDevices}
                error={!!errors.entrancesCount}
                helperText={errors.entrancesCount}
              />
            </div>
          </div>
          <div className={sn('row')}>
            <TextField
              name="tariff"
              label="Цена по тарифу"
              margin="normal"
              required
              value={settings.tariff || ''}
              onChange={onChangeTariff}
              error={!!errors.tariff}
              helperText={errors.tariff}
            />
          </div>
        </div>
        <div className={sn('defaultValue')}>
          <Typography variant="h4">Данные паркинга по умолчанию</Typography>
        </div>
        <div className={sn('formContainer')}>
          <div className={sn('row')}>
            <div className={sn('formItem')}>
              <TextField
                required
                name="quantityFloors"
                label="Количество этажей"
                margin="normal"
                value={settings.floors.length}
                onChange={onChangeFloorQuantity}
                error={!!errors.floorsCount}
                helperText={errors.floorsCount}
              />
            </div>
            <div className={sn('formItem')}>
              <TextField
                required
                name="quantitySeatsOnFloor"
                label="Кол-во парк. мест на этаже"
                margin="normal"
                value={settings.parkingSpacesPerFloorCount}
                onChange={onChangeQuantitySeatsOnFloor}
                error={!!errors.parkingSpacesPerFloorCount}
                helperText={errors.parkingSpacesPerFloorCount}
              />
            </div>
          </div>
        </div>

      </div>
      <div className={sn('footer')}>
        <div className={sn('footer__actions')}>
          <Button variant="contained" className={sn('footer__actions_cancel')} onClick={onCancel}>
            Отмена
          </Button>
          <Button variant="contained" color="secondary" onClick={onNextStep}>
            Следующий шаг
          </Button>
        </div>
      </div>
    </div>
  );
};
