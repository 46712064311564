import {Formik, FormikProps} from 'formik';
import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';

import {CameraCreateModel, CameraInfoModel, CameraLocationType, CameraProtocol} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './CameraForm.module.scss';
import {
  CameraAddressType,
  initialCourtyardAddress,
  initialParkingAddress,
  initialValues,
  URL_PATTERN,
} from './constants';
import {validation} from './validation';

import {Loader} from '../Loader';
import {FormValues} from './components/FormValues';
import {Footer} from './components/Footer';
import {
  courtyardAddressValidate,
  getActiveCourtyardAddressType,
  getActiveParkingAddressType,
  getAutocompleteCourtyardAddress,
  getAutocompleteParkingAddress,
  parkingAddressValidate,
  preparePayload,
} from './utils';
import {
  CameraAddress,
  CameraCourtyardAddress,
  CameraParkingAddress,
  CourtyardAddressErrors,
  ParkingAddressErrors,
} from './types';
import {FormObserver} from '../FormObserver';
import {Tab, Tabs} from '@mui/material';
import {CourtyardAddress} from './components/CourtyardAddress';
import {ParkingAddress} from './components/ParkingAddress';

const sn = bem('cameraForm', styles);

type Props = {
  saving: boolean;
  loading?: boolean;
  updateIsDirty?: (value: boolean) => void;
  dirty?: boolean;
  initialCameraInfo?: CameraInfoModel | null;
  onSave: (payload: Partial<CameraCreateModel>) => void;
  onCancel: () => void;
};

// TODO: добавить стор
export const CameraForm = ({saving, loading, updateIsDirty, dirty, initialCameraInfo, onSave, onCancel}: Props) => {
  const [cameraAddressType, setCameraAddressType] = useState<CameraAddressType>(CameraAddressType.courtyard);
  const [currentTab, setCurrentTab] = useState(CameraLocationType.courtyard);
  const formik = useRef<FormikProps<CameraCreateModel>>(null);
  const [isRTSPLink, setIsRTSPLink] = useState(false);
  const [parkingAddressValues, setInitialParkingAddress] = useState<CameraParkingAddress>({...initialParkingAddress});
  const [courtyardAddressValues, setInitialCourtyardAddress] =
    useState<CameraCourtyardAddress>(initialCourtyardAddress);
  const [parkingAddressErrors, setParkingAddressErrors] = useState<ParkingAddressErrors>({
    parkingErrorText: '',
    parkingSectionErrorText: '',
    entryNumberErrorText: '',
    floorNumberErrorText: '',
  });
  const [courtyardAddressErrors, setCourtyardAddressErrors] = useState<CourtyardAddressErrors>({
    courtyardErrorText: '',
    courtyardGateErrorText: '',
    buildingErrorText: '',
    entryNumberErrorText: '',
    floorNumberErrorText: '',
  });

  useEffect(() => {
    if (initialCameraInfo) {
      if (initialCameraInfo.address.location === CameraLocationType.courtyard) {
        setCurrentTab(CameraLocationType.courtyard);
        initializeCourtyardAddress(initialCameraInfo);
      }
      if (initialCameraInfo.address.location === CameraLocationType.parking) {
        setCurrentTab(CameraLocationType.parking);
        initializeParkingAddress(initialCameraInfo);
      }
    }

    initializeFormik();

    return () => {
      setInitialCourtyardAddress(initialCourtyardAddress);
      setInitialParkingAddress(initialParkingAddress);
    };
  }, [initialCameraInfo]);

  const initializeCourtyardAddress = async (initialCameraInfo: CameraInfoModel) => {
    const preparedValue = {
      models: {...initialCourtyardAddress.models},
      ids: {...initialCameraInfo.address},
    };
    const addressWithAutocomplete = await getAutocompleteCourtyardAddress(preparedValue);
    setCameraAddressType(getActiveCourtyardAddressType(addressWithAutocomplete));
    setInitialCourtyardAddress(preparedValue);
  };

  const initializeParkingAddress = async (initialCameraInfo: CameraInfoModel) => {
    const {courtyardId, buildingId} = initialCameraInfo.address;
    const preparedIds = {...initialCameraInfo.address, parkingId: courtyardId, parkingSectionId: buildingId};
    const preparedValue = {models: {...initialParkingAddress.models}, ids: preparedIds};
    const addressWithAutocomplete = await getAutocompleteParkingAddress(preparedValue);
    setCameraAddressType(getActiveParkingAddressType(addressWithAutocomplete));
    setInitialParkingAddress(preparedValue);
  };

  const initializeFormik = () => {
    if (initialCameraInfo && formik.current) {
      const {address, ...rest} = initialCameraInfo;
      if (!initialCameraInfo.url) {
        rest.url = URL_PATTERN;
      }
      if (initialCameraInfo.port === 0 && initialCameraInfo.protocol === CameraProtocol.link) {
        rest.port = null;
      }
      rest.location = initialCameraInfo.address.location;
      formik.current.resetForm({values: rest});
    }
  };

  const onChangeForm = (props: FormikProps<CameraCreateModel>) => {
    setIsRTSPLink(props.values.protocol === CameraProtocol.link);
  };

  const onChangeTab = (e: SyntheticEvent<Element, Event>, tab: CameraLocationType) => {
    setCurrentTab(tab);
    if (tab === CameraLocationType.courtyard) {
      setCameraAddressType(getActiveCourtyardAddressType(courtyardAddressValues));
    } else {
      setCameraAddressType(getActiveParkingAddressType(parkingAddressValues));
    }
    formik.current?.setFieldValue('location', tab);
  };

  const onBeforeSave = async () => {
    if (formik.current) {

      const preparedAddress = {} as CameraAddress;
      if (currentTab === CameraLocationType.courtyard) {
        preparedAddress.courtyardId = courtyardAddressValues.ids.courtyardId;
        preparedAddress.courtyardGateId = courtyardAddressValues.ids.courtyardGateId;
        preparedAddress.buildingId = courtyardAddressValues.ids.buildingId;
        preparedAddress.entryNumber = courtyardAddressValues.ids.entryNumber;
        preparedAddress.floorNumber = courtyardAddressValues.ids.floorNumber;
      } else {
        preparedAddress.courtyardId = parkingAddressValues.ids.parkingId;
        preparedAddress.buildingId = parkingAddressValues.ids.parkingSectionId;
        preparedAddress.entryNumber = parkingAddressValues.ids.entryNumber;
        preparedAddress.floorNumber = parkingAddressValues.ids.floorNumber;
      }

      const payload = preparePayload(formik.current.values, cameraAddressType, preparedAddress);
      onSave(payload);
    }
  };

  const onSubmit = () => {
    formik.current?.validateForm();

    const isValidAddress =
      currentTab === CameraLocationType.parking
        ? parkingAddressValidate(parkingAddressValues, setParkingAddressErrors, cameraAddressType)
        : courtyardAddressValidate(courtyardAddressValues, setCourtyardAddressErrors, cameraAddressType);

    if (!isValidAddress) return;

    onBeforeSave();
  };

  return (
    <Formik
      onSubmit={onBeforeSave}
      innerRef={formik}
      initialValues={initialValues}
      validationSchema={() => validation(isRTSPLink)}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className={sn()}>
          <div>
            <FormObserver onChange={onChangeForm} />
            <FormValues />
            <>
              <div className={sn('tabs')}>
                <Tabs value={currentTab} onChange={onChangeTab}>
                  <Tab label="Двор" value={CameraLocationType.courtyard} />
                  <Tab label="Парковка" value={CameraLocationType.parking} />
                </Tabs>
              </div>

              {currentTab === CameraLocationType.courtyard && (
                <CourtyardAddress
                  selectedAddress={courtyardAddressValues}
                  updateSelectedAddress={setInitialCourtyardAddress}
                  cameraAddressType={cameraAddressType}
                  updateCameraAddressType={setCameraAddressType}
                  courtyardAddressErrors={courtyardAddressErrors}
                  updateCourtyardAddressErrors={setCourtyardAddressErrors}
                />
              )}

              {currentTab === CameraLocationType.parking && (
                <ParkingAddress
                  selectedAddress={parkingAddressValues}
                  updateSelectedAddress={setInitialParkingAddress}
                  cameraAddressType={cameraAddressType}
                  updateCameraAddressType={setCameraAddressType}
                  parkingAddressErrors={parkingAddressErrors}
                  updateParkingAddressErrors={setParkingAddressErrors}
                />
              )}
            </>
          </div>
          <Footer saving={saving} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
      )}
    </Formik>
  );
};
