import {memo} from 'react';
import {Outlet} from 'react-router';

import {Header} from 'shared/layouts/Base/components/Header';
import {Sidebar} from 'shared/layouts/Base/components/Sidebar';

import styles from './Base.module.scss';

import {useScrollToTop} from '../../hooks';

export const BaseLayout = memo(() => {
  useScrollToTop();

  return (
    <div className={styles.baseLayout}>
      <Header />
      <div className={styles.baseLayout__content}>
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
});
