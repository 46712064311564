import {apiClient} from 'api/client';
import {
  WithPagination,
  PaymentModel
} from 'shared/models';

class PaymentListsService {
  private getBasePath(value: string) {
    return `payments${value}`;
  }

  getPayments(params: {
    page: number;
    size: number;
    phone?: string;
    paymentId?: string;
  }): Promise<WithPagination<PaymentModel>> {
    return apiClient.get(this.getBasePath(''), {params}).then((res) => res.data);
  }
}

export const PaymentListsApi = new PaymentListsService();