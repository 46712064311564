import {Table} from 'shared/components';

import {columns} from './columns';
import styles from './EntryTable.module.scss';

import {Entry, FloorType, HouseSettings} from '../../../../types';
import {getUpdatedEntry, getUpdatedRestEntries} from '../../../../utils';

type Props = {
  entry: Entry;
  houseSettings: HouseSettings;
  updateHouseSettings: (value: HouseSettings) => void;
};

export const EntryTable = ({entry, houseSettings, updateHouseSettings}: Props) => {
  const thisEntry = houseSettings.entries[entry.id];

  const updateFloorType = (floorIndex: number, value: FloorType) => {
    thisEntry.floors[floorIndex].floorType = value;
    houseSettings.entries[entry.id] = getUpdatedEntry(thisEntry);
    houseSettings.entries = getUpdatedRestEntries(houseSettings.entries, entry.id);
    updateHouseSettings({...houseSettings});
  };

  const updateApartmentQuantity = (floorIndex: number, value: string) => {
    thisEntry.floors[floorIndex].quantityApartments = Number(value);
    houseSettings.entries[entry.id] = getUpdatedEntry(thisEntry);
    houseSettings.entries = getUpdatedRestEntries(houseSettings.entries, entry.id);
    updateHouseSettings({...houseSettings});
  };

  return (
    <div className={styles.entryTable}>
      <Table
        style={{overflow: 'hidden', maxHeight: 'calc(100vh - 500px)'}}
        data={{items: entry.floors, total: entry.floors.length, count: entry.floors.length}}
        columns={columns({updateFloorType, updateApartmentQuantity})}
      />
    </div>
  );
};
