import {useEffect, useState} from 'react';

import {OfficesApi} from 'api';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {useToast} from 'shared/hooks';
import {NewOffice, OfficeInfoModel, OfficeModel} from 'shared/models';

import {OfficeForm} from '../OfficeForm';

type Props = {
  isOpen: boolean;
  editingOfficeId: number | null;
  closeModal: () => void;
  onAfterEdit: (updatedOffice: OfficeModel) => void;
};

const confirmPayload = {
  title: 'Подтверждение действия',
  description: 'Вы действительно хотите выйти из редактирования офиса без сохранения изменений?',
  acceptText: 'Выйти без сохранения',
};

export const EditOffice = ({isOpen, editingOfficeId, closeModal, onAfterEdit}: Props) => {
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [officeInfo, setOfficeInfo] = useState<OfficeInfoModel | null>(null);
  const {showToast} = useToast();
  const {confirm} = useConfirm();

  const onSave = async (payload: NewOffice) => {
    if (editingOfficeId) {
      try {
        setSaving(true);
        const res = await OfficesApi.editOffice(editingOfficeId, payload);
        showToast({
          title: 'Офис отредактирован',
          message: `Офис по адресу ${res.address} отредактирован`,
          isError: false,
        });
        onAfterEdit(res);
        closeModal();
      } catch (err) {
        showToast({
          title: 'Офис не отредактирован',
          message: 'Ошибка при редактировании офиса',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const getOffice = async () => {
    if (editingOfficeId) {
      try {
        setLoading(true);
        const res = await OfficesApi.getOffice(editingOfficeId);
        setOfficeInfo(res);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getOffice();
  }, [editingOfficeId]);

  const onCancel = async () => {
    if (!isDirty) {
      closeModal();
      return;
    }
    if (await confirm(confirmPayload)) {
      closeModal();
    }
  };

  return (
    <OfficeForm
      loading={loading}
      saving={saving}
      updateDirty={setIsDirty}
      editingOffice={officeInfo}
      title="Редактирование офиса"
      isOpen={isOpen}
      closeModal={onCancel}
      onSave={onSave}
    />
  );
};
