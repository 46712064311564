import {CircularProgress} from '@mui/material';

import styles from './Loader.module.scss';

import {bem} from '../../utils';

const sn = bem('loader', styles);

type Props = {
  size?: number;
};

export const Loader = ({size = 35}: Props) => {
  return (
    <div className={sn('loaderWrap')}>
      <CircularProgress color="inherit" size={size} />
    </div>
  );
};
