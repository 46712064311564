import {FC} from 'react';

import {bem} from 'shared/utils';

import styles from './OneLine.module.scss';

const sn = bem('oneLine', styles);

export const OneLine: FC = () => {
  return (
    <div className={styles.oneLine}>
      <div className={sn('line')}></div>
    </div>
  );
};
