import {Button} from '@mui/material';
import {ChangeEvent} from 'react';

import {SearchInput} from 'shared/components/SearchInput';
import {useBreadcrumbs} from 'shared/hooks';
import {AccountRoles} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './CourtyardsHeader.module.scss';
import {generatePath, useNavigate, useParams} from 'react-router';
import {routes} from 'shared/constants/routes';
import {useLocation} from 'react-router-dom';
import {TitleTabs} from 'shared/ui/TitleTabs/TitleTabs';

type Props = {
  courtyardsSearch: string;
  updateCourtyardsSearch: (value: string) => void;
  openModal: () => void;
};

const sn = bem('courtyardsHeader', styles);

export const CourtyardsHeader = ({courtyardsSearch, updateCourtyardsSearch, openModal}: Props) => {
  const {courtyardBreadcrumbs} = useBreadcrumbs();
  const {apartmentComplexId} = useParams<{
    apartmentComplexId: string;
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const activeTab = location.pathname === routes.parking ? 'parking' : 'courtyard';
  const onSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    updateCourtyardsSearch(event.target.value);
  };

  const goToCourtyard = () => {
    if (location.pathname !== routes.courtyards) {
      navigate(generatePath(routes.courtyards, {apartmentComplexId}));
    }
  };

  const goToParking = () => {
    if (location.pathname !== routes.parking) {
      navigate(generatePath(routes.parking, {apartmentComplexId}));
    }
  };

  return (
    <>
      <TitleTabs
        breadcrumbs={courtyardBreadcrumbs}
        goToCourtyard={goToCourtyard}
        goToParking={goToParking}
        activeTab={activeTab}
        action={
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={openModal}
            disabled={!checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin])}
          >
            Добавить двор
          </Button>
        }
      />
      <div className={sn('search')}>
        <SearchInput placeholder="Найти двор (мин 3 символа)" onChange={onSearchInput} value={courtyardsSearch} />
      </div>
    </>
  );
};
