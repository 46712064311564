import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {routes} from 'shared/constants/routes';
import {Button} from '@mui/material';
import {bem} from 'shared/utils';
import styles from './AccessDevicesHeader.module.scss';
import {useRootDispatch} from 'store';
import {setIsAddAccessDevice} from 'modules/AccessDevices/store';

const sn = bem('accessDevicesHeader', styles);

export const AccessDevicesHeader = () => {
  const dispatch = useRootDispatch();

  const addAccessDevice = () => {
    dispatch(setIsAddAccessDevice(true));
  };

  return (
    <TitleBlock
      title="Устройства доступа"
      breadcrumbs={[{label: 'Устройства доступа', href: routes.accessDevices}]}
      action={
        <Button variant="contained" color="secondary" className={sn('addHome')} onClick={addAccessDevice}>
          Добавить УД
        </Button>
      }
    />
  );
};
