import {useEffect, useState} from 'react';

import {
  Address,
  ApartmentComplexesModel,
  CourtyardModel,
  GateModel,
  IntercomInfoModel,
  IntercomType,
} from '../../../../models';
import {ApartmentAutocomplete} from '../../../ApartmentAutocomplete';
import {EntryModelAutocomplete, HouseModelAutocomplete} from '../../../AutocompleteField/utils';
import {CourtyardAutocomplete} from '../../../CourtyardAutocomplete';
import {EntryAutocomplete} from '../../../EntryAutocomplete';
import {GateAutocomplete} from '../../../GateAutocomplete';
import {HouseAutocomplete} from '../../../HouseAutocomplete';
import {useFormikContext} from 'formik';
import {ApartmentComplexesApi, CourtyardsServiceApi, GatesApi, HouseApi} from 'api';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {bem} from 'shared/utils';
import styles from './AddressBlock.module.scss';
import {NewIntercomFormValues} from 'modules/AddNewIntercom/utils';

type Props = {
  intercom?: IntercomInfoModel | null;
  intercomType: IntercomType;
  updateIntercomType: (value: IntercomType) => void;
};

const sn = bem('intercomAddress', styles);

export const AddressBlock = ({intercom, intercomType, updateIntercomType}: Props) => {
  const [apartmentComplex, setApartmentComplex] = useState<ApartmentComplexesModel | null>(null);
  const [courtyard, setCourtyard] = useState<CourtyardModel | null>(null);
  const [house, setHouse] = useState<HouseModelAutocomplete | null>(null);
  const [gate, setGate] = useState<GateModel | null>(null);
  const [entry, setEntry] = useState<EntryModelAutocomplete | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const {setFieldValue, errors, setErrors} = useFormikContext<NewIntercomFormValues>();

  useEffect(() => {
    if (intercom?.address) {
      setSelectedAddress(intercom?.address);
      initAutocomplete();
    }
  }, [intercom?.address]);

  const initAutocomplete = async () => {
    if (intercom?.address.apartmentComplexId) {
      await getDefaultApartmentComplex(intercom?.address.apartmentComplexId);
    }
    if (intercom?.address.apartmentComplexId && intercom.address.courtyardId) {
      await getDefaultCourtyard(intercom?.address.apartmentComplexId, intercom.address.courtyardId);
    }
    if (intercom?.address.apartmentComplexId && intercom.address.courtyardId) {
      await getDefaultHouse(
        intercom?.address.apartmentComplexId,
        intercom.address.courtyardId,
        intercom.address.buildingId,
      );
    }
    if (intercom?.address.apartmentComplexId && intercom.address.courtyardId && intercom.address.courtyardGateId) {
      await getDefaultGate(
        intercom?.address.apartmentComplexId,
        intercom.address.courtyardId,
        intercom.address.courtyardGateId,
      );
    }
  };

  const updateApartmentComplex = (value: ApartmentComplexesModel | null) => {
    setApartmentComplex(value);
    setFieldValue('apartmentComplexId', value?.id || null);
    if (selectedAddress?.apartmentComplexId) {
      setSelectedAddress({...selectedAddress, apartmentComplexId: null});
    }
  };

  const updateCourtyard = (value: CourtyardModel | null) => {
    setCourtyard(value);
    setFieldValue('courtyardId', value?.courtyardId || null);
    if (selectedAddress?.courtyardId) {
      setSelectedAddress({...selectedAddress, courtyardId: null});
    }
  };

  const updateGate = (value: GateModel | null) => {
    setGate(value);
    setFieldValue('courtyardGateId', value?.courtyardGateId || null);
    if (selectedAddress?.courtyardGateId) {
      setSelectedAddress({...selectedAddress, courtyardGateId: null});
    }
  };

  const updateHouse = (value: HouseModelAutocomplete | null) => {
    setHouse(value);
    setFieldValue('buildingId', value?.buildingId || null);
    if (selectedAddress?.buildingId) {
      setSelectedAddress({...selectedAddress, buildingId: null});
    }
  };

  const updateEntry = (value: EntryModelAutocomplete | null) => {
    setEntry(value);
    setFieldValue('entryNumber', value?.entryNumber || null);
    if (selectedAddress?.entryNumber) {
      setSelectedAddress({...selectedAddress, entryNumber: null});
    }
  };

  const getDefaultApartmentComplex = async (apartmentComplexId: number | null) => {
    if (apartmentComplexId) {
      const data = await ApartmentComplexesApi.getApartmentComplex(apartmentComplexId);
      setApartmentComplex({
        id: data.apartmentComplexId!,
        name: data.name,
        city: '',
        activeApartmentsCount: 0,
        address: '',
      });
    }
  };

  const getDefaultCourtyard = async (apartmentComplexId: number | null, courtyardId: number | null) => {
    if (courtyardId && apartmentComplexId) {
      const data = await CourtyardsServiceApi.getCourtyard(apartmentComplexId, courtyardId);
      setCourtyard(data);
    }
  };

  const getDefaultHouse = async (
    apartmentComplexId: number | null,
    courtyardId: number | null,
    houseId: number | null,
  ) => {
    if (apartmentComplexId && courtyardId && houseId) {
      const data = await HouseApi.getHouses(String(apartmentComplexId), String(courtyardId));
      const house = data.items.find((h) => String(h.buildingId) === String(houseId));
      if (house) {
        setHouse({...house, name: String(house.number)});
      }
    }
  };

  const getDefaultGate = async (
    apartmentComplexId: number | null,
    courtyardId: number | null,
    gateId: number | null,
  ) => {
    if (apartmentComplexId && courtyardId && gateId) {
      const data = await GatesApi.getGate(String(apartmentComplexId), String(courtyardId), gateId);
      setGate(data);
    }
  };

  const updateType = (type: IntercomType) => {
    setErrors({});
    updateIntercomType(type)
  }
  return (
    <div className={sn()}>
      <FormControl sx={{m: 3}} variant="standard" className={sn('intercomType')}>
        <RadioGroup
          aria-labelledby="demo-error-radios"
          className={sn('intercomTypeGroup')}
          name="quiz"
          value={intercomType}
        >
          <FormControlLabel
            value={IntercomType.entry}
            onClick={() => updateType(IntercomType.entry)}
            control={<Radio />}
            label="Подъезд"
          />
          <FormControlLabel
            value={IntercomType.gate}
            onClick={() => updateType(IntercomType.gate)}
            control={<Radio />}
            label="Калитка"
          />
        </RadioGroup>
      </FormControl>
      <ApartmentAutocomplete
        apartmentComplex={apartmentComplex}
        updateApartmentComplex={updateApartmentComplex}
        errorText={errors.apartmentComplexId}
      />
      <CourtyardAutocomplete
        courtyard={courtyard}
        updateCourtyard={updateCourtyard}
        apartmentComplexId={apartmentComplex?.id}
        disable={!apartmentComplex?.id}
        errorText={errors.courtyardId}
      />
      {intercomType === IntercomType.entry && (
        <>
          <HouseAutocomplete
            showHouse
            house={house}
            updateHouse={updateHouse}
            apartmentComplexId={apartmentComplex?.id}
            courtyardId={courtyard?.courtyardId}
            errorText={errors.buildingId}
          />
          <EntryAutocomplete
            selectedEntryNumber={selectedAddress?.entryNumber}
            entry={entry}
            updateEntry={updateEntry}
            apartmentComplexId={apartmentComplex?.id}
            courtyardId={courtyard?.courtyardId}
            houseId={house?.buildingId}
            required={!intercom && intercomType === IntercomType.entry}
            errorText={errors.entryNumber}
          />
        </>
      )}
      {intercomType === IntercomType.gate && (
        <GateAutocomplete
          gate={gate}
          updateGate={updateGate}
          apartmentComplexId={apartmentComplex?.id}
          courtyardId={courtyard?.courtyardId}
          required={!intercom && intercomType === IntercomType.gate}
          errorText={errors.courtyardGateId}
        />
      )}
    </div>
  );
};
