import {Table} from 'shared/components';
import {useRootDispatch, useRootSelector} from 'store';
import {columns} from './columns';
import {setFloors} from '../../../../store';
import {ParkingSectionFloor} from 'shared/models';
import {getFloorRange, getListSeats, updateFloors} from '../../../../utils';

export const ParkingSectionTable = () => {
  const settings = useRootSelector((state) => state.parkingSectionConstructor.parkingSection);
  const dispatch = useRootDispatch();

  const withId = (items: ParkingSectionFloor[]) => items.map((item) => ({...item, id: item.floorNumber}));

  const updateApartmentQuantity = (floorNumber: number, newSeatsCount: number) => {
    if (isNaN(newSeatsCount) || newSeatsCount > 30000) return;
    const updatedFloor = {
      floorNumber: floorNumber,
      quantitySeatsOnFloor: newSeatsCount,
      range: getFloorRange(floorNumber - 1, newSeatsCount) as string,
      apartmentNumbers: getListSeats(floorNumber, newSeatsCount * floorNumber),
    };

    const preparedFloors = updateFloors(settings, updatedFloor);
    dispatch(setFloors(preparedFloors));
  };

  return (
    <>
      <Table
        style={{overflow: 'hidden', maxHeight: 'calc(100vh - 500px)'}}
        data={{
          items: withId(settings.floors),
          total: settings.floors.length,
          count: settings.floors.length,
        }}
        columns={columns({updateApartmentQuantity})}
      />
    </>
  );
};
