import {GridCellParams, GridPaginationModel} from '@mui/x-data-grid';
import {useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {AddUser, Table} from 'shared/components';
import {EditService} from 'shared/components/EditExpirationDate';
import {routes} from 'shared/constants/routes';
import {ApartmentsModel, WithPagination, AccountRoles, ServiceModel} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {getColumns} from './columns';
import styles from './ParkingPlacesTable.module.scss';

type Props = {
  isActiveFilter: boolean;
  loading: boolean;
  parkingPlaces: WithPagination<ApartmentsModel> | null;
  paginationModel: GridPaginationModel;
  updatePaginationModel: (value: GridPaginationModel) => void;
  updateParkingPlaceList: () => void;
};

const sn = bem('parkingPlacesTable', styles);

export const ParkingPlacesTable = ({
  loading,
  parkingPlaces,
  paginationModel,
  updatePaginationModel,
  updateParkingPlaceList,
}: Props) => {
  const navigate = useNavigate();
  const {apartmentComplexId, parkingId, parkingSectionId} = useParams<{
    apartmentComplexId: string;
    parkingId: string;
    parkingSectionId: string;
  }>();
  const [isOpen, setIsOpen] = useState(false);
  const [parkingPlacesId, setParkingPlacesId] = useState<number | null>(null);
  const [isEditingService, setIsEditingService] = useState(false);
  const [editableService, setEditableService] = useState<ServiceModel | null>(null);
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);

  const onCellClick = (params: GridCellParams) => {
    if (params.field === 'edit') {
      navigate(
        generatePath(routes.parkingPlace, {apartmentComplexId, parkingId, parkingSectionId, parkingPlaceId: params.id}),
      );
    }
    if (params.field === 'editService') {
      if (isDisabled) return;
      setParkingPlacesId(params.row.apartmentId);
      setEditableService(params.row.services?.[0]);
      setIsEditingService(true);
    }
  };

  const onCloseEditService = () => {
    setIsEditingService(false);
    setEditableService(null);
    setParkingPlacesId(null);
  };

  const onAddOwnerClick = (params: GridCellParams<ApartmentsModel>) => {
    setParkingPlacesId(params.row.apartmentId);
    setIsOpen(true);
  };

  const onClose = () => {
    setParkingPlacesId(null);
    setIsOpen(false);
  };

  return (
    <div className={styles.parkingPlacesTable}>
      <Table
        className={sn('table')}
        loading={loading}
        columns={getColumns({onAddOwnerClick, isDisabled})}
        data={parkingPlaces}
        paginationModel={paginationModel}
        updatePaginationModel={updatePaginationModel}
        onCellClick={onCellClick}
        getRowClassName={() => sn('row')}
      />
      <AddUser
        asOwner
        isOpen={isOpen}
        onClose={onClose}
        apartmentId={parkingPlacesId}
        onAfterAdd={updateParkingPlaceList}
        title="Добавление пользователя"
      />
      <EditService
        isOpen={isEditingService}
        onClose={onCloseEditService}
        onAfterChange={updateParkingPlaceList}
        service={editableService}
        id={parkingPlacesId}
      />
    </div>
  );
};
