import {ParkingModel, WithPagination} from 'shared/models';

export const initialParking: WithPagination<ParkingModel> = {
  count: 0,
  items: [],
  total: 0,
};

export const getPreparedParking = (parking: ParkingModel[]) => {
  return parking.map(c => ({...c, id: c.courtyardId}));
}