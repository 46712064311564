import {TextField} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

import {bem} from 'shared/utils';

import styles from './EntryTable.module.scss';
import {FloorTypeSelect} from './FloorTypeSelect';

import {FloorType} from '../../../../types';

const sn = bem('entryTable', styles);

interface Columns {
  updateFloorType: (floorIndex: number, value: FloorType) => void;
  updateApartmentQuantity: (floorIndex: number, value: string) => void;
}

export const columns = ({updateFloorType, updateApartmentQuantity}: Columns): GridColDef[] => [
  {
    field: 'floorNumber',
    headerName: 'Этажи',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 300,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <div className={sn('floorNumber')}>{params.value} этаж</div>,
  },
  {
    field: 'floorType',
    headerName: 'Тип этажа',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 300,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <FloorTypeSelect updateFloorType={updateFloorType} params={params} />,
  },
  {
    field: 'quantityApartments',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Кол-во квартир',
    maxWidth: 300,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) =>
      params.row.floorType === FloorType.residential ? (
        <TextField
          className={sn('quantityApartments')}
          variant="outlined"
          type="number"
          size="small"
          value={params.value.toString()}
          onChange={(e) => updateApartmentQuantity(params.row.id, e.target.value)}
        />
      ) : (
        <div className={sn('quantityApartments_empty')}>—</div>
      ),
  },
  {
    field: 'apartmentRange',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Квартиры',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) =>
      params.row.floorType === FloorType.residential ? (
        <div className={sn('apartmentRange')}>{params.value}</div>
      ) : (
        <div className={sn('apartmentRange_empty')}>—</div>
      ),
  },
];
