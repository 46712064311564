import {Apartment as ApartmentSvg, Delete as DeleteSvg, Edit as EditIcon} from '@mui/icons-material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import classNames from 'classnames';

import styles from 'shared/components/Table/Table.module.scss';
import {bem} from 'shared/utils';

const sn = bem('table', styles);

type Props = {
  isDisabled: boolean;
};

export const getColumns = ({isDisabled}: Props): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Название жилого комплекса',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      renderCell: (params: GridRenderCellParams) => (
        <div className={sn('cell_name')}>
          <ApartmentSvg />
          {params.value}
        </div>
      ),
      maxWidth: 300,
      flex: 1,
    },
    {
      field: 'city',
      headerName: 'Город',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      width: 200,
    },
    {
      field: 'address',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Адрес',
      flex: 1,
    },
    {
      field: 'parkingLotsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во паркингов',
      width: 150,
    },
    {
      field: 'activeParkingSpacesCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во исп-мых парк. мест',
      width: 200,
    },
    {
      field: 'condominiumsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во домов',
      width: 120,
    },
    {
      field: 'activeApartmentsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во заселенных квартир',
      width: 210,
    },
    {
      field: 'edit',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: '',
      width: 50,
      renderCell: () => (
        <div className={sn('cell_edit', classNames({cell_disabled: isDisabled}))}>
          <EditIcon />
        </div>
      ),
    },
    {
      field: 'delete',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: '',
      width: 50,
      renderCell: () => (
        <div className={sn('cell_delete', classNames({cell_disabled: isDisabled}))}>
          <DeleteSvg />
        </div>
      ),
    },
  ];
};
