import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {FC, MouseEvent, useState, ReactNode, PropsWithChildren} from 'react';

import {bem} from 'shared/utils';

import styles from './Dropdown.module.scss';

type Option = {
  id: number;
  option: ReactNode;
};

type DropdownProps = {
  options: Option[];
  info?: ReactNode;
} & PropsWithChildren;

const sn = bem('dropdown', styles);

const Dropdown: FC<DropdownProps> = ({info, options, children}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdown}>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.dropdown}
      >
        <div>
          {info}
          {options.map((option, index) => (
            <MenuItem key={index} onClick={handleClose}>
              {option.option}
            </MenuItem>
          ))}
        </div>
      </Menu>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {children}
        {isOpen ? <KeyboardArrowUpIcon className={sn('icon')} /> : <KeyboardArrowDownIcon className={sn('icon')} />}
      </Button>
    </div>
  );
};

export {Dropdown};
