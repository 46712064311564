import {createAsyncThunk} from '@reduxjs/toolkit';

import {AuthApi} from 'api';
import {SignInPayload} from 'shared/models';
import {removeTokenFromLS, setTokenToLS} from 'shared/utils/tokensHandlers';

export const signIn = createAsyncThunk('auth/signIn', async (values: SignInPayload) => {
  const {accessToken} = await AuthApi.signIn(values);
  setTokenToLS(accessToken);
  return !!accessToken;
});

export const signOut = createAsyncThunk('auth/signOut', async () => {
  await AuthApi.signOut();
  removeTokenFromLS();
});
