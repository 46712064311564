import {Button} from '@mui/material';
import {FC, useState, useEffect} from 'react';
import {useNavigate} from 'react-router';

import {IntercomsApi} from 'api';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {routes} from 'shared/constants/routes';
import {useBreadcrumbs, useDebounce, usePrevious} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {IntercomModel, RequestWithPagination, SearchIntercoms, WithPagination} from 'shared/models';
import {bem} from 'shared/utils';

import {IntercomsTable} from './components/IntercomsTable';
import {SearchBlock} from './components/SearchBlock';
import {initialIntercoms} from './constants';
import styles from './Intercoms.module.scss';
import {getPreparedIntercoms} from './utils';

const sn = bem('intercoms', styles);

export const Intercoms: FC = () => {
  const [nameSearch, setNameSearch] = useState('');
  const [sipSearch, setSipSearch] = useState('');
  const [addressSearch, setAddressSearch] = useState('');
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);
  const [loading, setLoading] = useState(false);
  const [intercoms, setIntercoms] = useState<WithPagination<IntercomModel>>(initialIntercoms);

  const navigate = useNavigate();
  const isActiveFilter = nameSearch.length >= 3 || addressSearch || sipSearch;
  const prevIsActiveFilter = usePrevious(isActiveFilter);
  const {intercomsBreadcrumbs} = useBreadcrumbs();

  useEffect(() => {
    if (!isActiveFilter) {
      getIntercoms();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchIntercoms();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [nameSearch, addressSearch, sipSearch, isActiveFilter, paginationModel]);

  const addNewIntercom = () => {
    navigate(routes.addNewIntercom);
  };

  const getIntercoms = async () => {
    try {
      setLoading(true);
      const payload = {size: paginationModel.pageSize, page: paginationModel.page + 1};
      const res = await IntercomsApi.getIntercoms(payload);
      const preparedIntercoms = getPreparedIntercoms(res.items);
      setIntercoms({...res, items: preparedIntercoms});
    } finally {
      setLoading(false);
    }
  };

  const searchIntercoms = useDebounce(async () => {
    try {
      setLoading(true);
      const payload = getPreparedPayload();
      const intercoms = await IntercomsApi.searchIntercoms(payload);
      const preparedIntercoms = intercoms.items.map((intercom) => ({...intercom, id: intercom.sipPanelId}));
      setIntercoms({...intercoms, items: preparedIntercoms});
    } finally {
      setLoading(false);
    }
  }, DELAY);

  const getPreparedPayload = () => {
    const payload: RequestWithPagination<SearchIntercoms> = {
      size: paginationModel.pageSize,
      page: paginationModel.page + 1,
      sipNumber: sipSearch,
      address: addressSearch,
    };
    if (nameSearch.length >= 3) payload.name = nameSearch;
    return payload;
  };

  const onAfterUpdate = () => {
    getIntercoms();
  };

  return (
    <>
      <div className={styles.intercoms}>
        <TitleBlock
          title="Домофоны"
          breadcrumbs={intercomsBreadcrumbs}
          action={
            <Button variant="contained" color="secondary" className={sn('addHome')} onClick={addNewIntercom}>
              Добавить домофон
            </Button>
          }
        />
        <SearchBlock
          nameSearch={nameSearch}
          sipSearch={sipSearch}
          addressSearch={addressSearch}
          updateNameSearch={setNameSearch}
          updateSipSearch={setSipSearch}
          updateAddressSearch={setAddressSearch}
        />
        <IntercomsTable
          loading={loading}
          intercoms={intercoms}
          paginationModel={paginationModel}
          updatePaginationModel={setPaginationModel}
          onAfterUpdate={onAfterUpdate}
        />
      </div>
    </>
  );
};
