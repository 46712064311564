import {ThemeProvider} from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';

import {ToastProvider} from 'shared/utils/ToastContext';

import {theme} from './assets/styles/theme';
import {MainRoutes} from './MainRoutes';
import {ConfirmationProvider} from './shared/components/Confirmation/ConfirmationProvider';
import {store} from './store';

if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_SENTRY_ENV) {
  Sentry.init({
    enabled: !!import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.2,
  });
}

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <ConfirmationProvider>
            <ToastProvider>
              <MainRoutes />
            </ToastProvider>
          </ConfirmationProvider>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
};
