import {bem} from 'shared/utils';

import styles from './PaymentsTable.module.scss';

const sn = bem('paymentsListTable', styles);


export const columns = () => [
  {
    field: 'id',
    headerName: 'ID',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 400,
    sortable: false,
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Сумма',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    sortable: false,
    valueGetter: (params: { row: { amount: any; currency: any; }; }) => {
      return `${params.row.amount || ''} ${params.row.currency.symbol || ''}`;
    },
    flex: 1,
  },
  {
    field: 'clientAccountPhone',
    headerName: 'Телефон',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    valueGetter: (params: { row: { clientAccount: any; }; }) => {
      return `${params.row.clientAccount.phoneNumber || ''}`;
    },
    sortable: false,
    flex: 1,
  },
  {
    field: 'clientAccountName',
    headerName: 'Пользователь',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    valueGetter: (params: { row: { clientAccount: any; }; }) => {
      return `${params.row.clientAccount.clientName || ''}`;
    },
    sortable: false,
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Описание',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    sortable: false,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Статус',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    sortable: false,
    valueGetter: (params: { row: { status: any; }; }) => {
      return `${params.row.status.name || ''}`;
    },
    flex: 1,
  },
  {
    field: 'paymentType',
    headerName: 'Тип платежа',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    sortable: false,
    valueGetter: (params: { row: { paymentType: any; }; }) => {
      return `${params.row.paymentType?.name || ''}`;
    },
    flex: 1,
  },
  {
    field: 'createdDateTime',
    headerName: 'Дата создания',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    sortable: false,
    flex: 1,
  }
];
