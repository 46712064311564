import {apiClient} from 'api/client';
import {ApartmentsModel, ApartmentsFilter, HouseInfoModel, RequestWithPagination, WithPagination} from 'shared/models';

class ApartmentsService {
  private getBasePath(apartmentComplexId: string, courtyardId: string, buildingId: string, values: string) {
    return `/properties/apartment-complexes/${apartmentComplexId}/courtyards/${courtyardId}/buildings/${buildingId}${values}`;
  }

  getApartments(
    apartmentComplexId: string,
    courtyardId: string,
    buildingId: string,
    payload: RequestWithPagination<ApartmentsFilter>,
  ): Promise<WithPagination<ApartmentsModel>> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, courtyardId, buildingId, '/apartments'), {params: payload})
      .then((res) => res.data);
  }

  getHouseInfo(apartmentComplexId: string, courtyardId: string, buildingId: string): Promise<HouseInfoModel> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, courtyardId, '', `condominiums/${buildingId}`))
      .then((res) => res.data);
  }
}

export const ApartmentsApi = new ApartmentsService();
