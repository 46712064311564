import {FormValues} from './components/OfficeForm';

export const initialValues: FormValues = {
  streetId: null,
  location: '',
  phoneNumber: '',
  email: '',
  apartmentComplexes: [],
};

export const getSuccessDeleteMessage = (address: string) => ({
  title: 'Офис удален',
  message: `Офис по адресу ${address} удален`,
  isError: false,
});

export const failedDeleteMessage = {
  title: 'Офис не удален',
  message: 'Ошибка удаления офиса',
  isError: true,
};

export const confirmDeletePayload = {
  title: 'Удаление офиса',
  description: 'Все данные этого офиса будут безвозвратно удалены из системы',
  acceptText: 'Удалить',
};
