import {Button} from '@mui/material';
import {ChangeEvent} from 'react';
import {useParams} from 'react-router';

import {SearchInput} from 'shared/components/SearchInput';
import {UsersImport} from 'shared/components/UsersImport';
import {useBreadcrumbs, useModal} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccountRoles, ApartmentsFilter, BuildingType} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './ApartmentsHeader.module.scss';

const sn = bem('apartmentsHeader', styles);

type Props = {
  apartmentsFilter: ApartmentsFilter;
  updateApartmentsFilter: (value: ApartmentsFilter) => void;
  onAfterImport: () => void;
};

export const ApartmentsHeader = ({apartmentsFilter, updateApartmentsFilter, onAfterImport}: Props) => {
  const {houseId} = useParams<{houseId: string}>();
  const {apartmentsBreadcrumbs} = useBreadcrumbs();
  const {isOpen, openModal, closeModal} = useModal();

  const onTenantSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateApartmentsFilter({...apartmentsFilter, tenantName: event.target.value});
  };

  const onApartmentSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateApartmentsFilter({...apartmentsFilter, apartmentNumber: event.target.value});
  };

  const onPhoneSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateApartmentsFilter({...apartmentsFilter, phoneNumber: event.target.value});
  };

  return (
    <>
      <TitleBlock
        title="Квартиры"
        breadcrumbs={apartmentsBreadcrumbs}
        action={
          <Button
            variant="contained"
            color="secondary"
            className={sn('import')}
            onClick={openModal}
            disabled={!checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin])}
          >
            Импорт жильцов
          </Button>
        }
      />
      <div className={sn('searchBlock')}>
        <SearchInput placeholder="Найти по телефону" value={apartmentsFilter.phoneNumber} onChange={onPhoneSearch} />
        <SearchInput placeholder="Найти жильца" value={apartmentsFilter.tenantName} onChange={onTenantSearch} />
        <SearchInput
          placeholder="Найти квартиру"
          value={apartmentsFilter.apartmentNumber}
          onChange={onApartmentSearch}
        />
      </div>
      <UsersImport
        onAfterImport={onAfterImport}
        buildingType={BuildingType.condominium}
        id={houseId}
        isOpen={isOpen}
        title="Импорт жильцов"
        onClose={closeModal}
      />
    </>
  );
};
