import {Checkbox, FormControlLabel, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';

import {AddressAutocomplete, Loader} from 'shared/components';
import {Modal} from 'shared/components/Modal';
import {useDebounce} from 'shared/hooks';
import {AddressOptionType, ApartmentComplexType} from 'shared/models';
import {bem, checkAvailability, isInvalidWhitespaceInput} from 'shared/utils';

import {validation} from './validation';

import styles from './ApartmentComplexForm.module.scss';
import {ERROR_NAME_NOT_AVAILABLE, initialValues} from './constants';

const sn = bem('apartmentComplexForm', styles);

type Props = {
  isOpen: boolean;
  loading: boolean;
  title: string;
  closeModal: () => void;
  initApartmentComplex: FormValues | null;
  onSave: (values: FormValues) => void;
};

export type FormValues = {
  name: string;
  streetId: number | null;
  streetNumber: string;
  streetAddress: string;
  type?: ApartmentComplexType;
};

export const ApartmentComplexForm = ({isOpen, closeModal, title, initApartmentComplex, loading, onSave}: Props) => {
  const [isNameAvailable, setIsNameAvailable] = useState(true);
  const [loadingName, setLoadingName] = useState(false);
  const initWithoutAddress = initApartmentComplex ? !initApartmentComplex.streetAddress : false;
  const [withoutAddress, setWithoutAddress] = useState(initWithoutAddress);
  const validationSchema = validation(withoutAddress);
  const isNew = !initApartmentComplex;

  const onSubmit = async (values: FormValues) => {
    await onSave(values);
    onCancel();
  };

  const {handleSubmit, values, setValues, touched, errors, resetForm, setFieldValue, handleBlur} = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: onSubmit,
    validateOnBlur: false,
    validateOnChange: true,
  });

  const disableSave = loading || loadingName || !isNameAvailable;

  useEffect(() => {
    if (initApartmentComplex) {
      setValues(initApartmentComplex);
      setWithoutAddress(initWithoutAddress);
    }
  }, [initApartmentComplex, initWithoutAddress]);

  const onCancel = () => {
    resetForm();
    closeModal();
    setWithoutAddress(false);
  };

  const onAddressChange = (value: string) => {
    setFieldValue('streetAddress', value);
    setFieldValue('streetId', null);
  };

  const onAddressSelect = (event: SyntheticEvent<Element, Event>, newValue: unknown) => {
    const value = newValue as AddressOptionType;
    if (value?.fullAddress) {
      setFieldValue('streetAddress', value.fullAddress);
      setFieldValue('streetId', Number(value.id));
    } else {
      setFieldValue('streetId', null);
      setFieldValue('streetAddress', '');
    }
  };

  const searchName = useDebounce(async (value) => {
    checkAvailability({name: value}, 'apartment', setIsNameAvailable, setLoadingName);
  }, 1000);

  const checkName = (value: string) => {
    if (value === initApartmentComplex?.name) {
      setLoadingName(false);
      return;
    }
    searchName(value);
  };

  const onNameChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isInvalidWhitespaceInput(value)) {
      setFieldValue('name', value);
      setLoadingName(true);
      setIsNameAvailable(true);
      checkName(value);
    }
  };

  const onStreetNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isInvalidWhitespaceInput(value)) setFieldValue('streetNumber', value);
  };

  const onChangeType = (event: ChangeEvent<HTMLInputElement>) => {
    setWithoutAddress(event.target.checked);
    const type = event.target.checked ? ApartmentComplexType.parkingContainer : ApartmentComplexType.apartmentComplex;
    setFieldValue('type', type);
    if (event.target.checked) {
      setFieldValue('streetNumber', undefined);
      setFieldValue('streetAddress', undefined);
      setFieldValue('streetId', undefined);
    }
  };

  return (
    <Modal
      maxWidth="sm"
      isOpen={isOpen}
      onClose={onCancel}
      onSave={handleSubmit}
      onCancel={onCancel}
      title={title}
      closeButton
      loading={loading}
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      disableSave={disableSave}
    >
      {loading ? (
        <div className={sn('wrapLoader')}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={sn('formItem formItemName')}>
            <TextField
              name="name"
              autoFocus
              margin="dense"
              label="Название ЖК"
              type="text"
              fullWidth
              required
              value={values.name}
              onBlur={handleBlur}
              onChange={onNameChange}
              error={touched.name && (Boolean(errors.name) || !isNameAvailable)}
              helperText={touched.name && (errors.name || (!isNameAvailable && ERROR_NAME_NOT_AVAILABLE))}
            />
          </div>
          {!withoutAddress && (
            <div className={sn('formItem')}>
              <AddressAutocomplete
                name="streetAddress"
                label="Адрес"
                value={values.streetAddress}
                required={!withoutAddress}
                onAddressChange={onAddressChange}
                onAddressSelect={onAddressSelect}
                error={Boolean(errors.streetId)}
                helperText={errors.streetId}
              />
            </div>
          )}

          {!withoutAddress && (
            <div className={sn('formItem')}>
              <TextField
                name="streetNumber"
                margin="dense"
                label="Номер дома"
                type="text"
                fullWidth
                className={sn('item')}
                required={!withoutAddress}
                value={values.streetNumber}
                onBlur={handleBlur}
                onChange={onStreetNumberChange}
                error={touched.streetNumber && Boolean(errors.streetNumber)}
                helperText={touched.streetNumber && errors.streetNumber}
              />
            </div>
          )}

          {(initApartmentComplex?.type === ApartmentComplexType.parkingContainer || isNew) && (
            <FormControlLabel
              control={<Checkbox checked={withoutAddress} onChange={onChangeType} />}
              label="Без адреса"
            />
          )}
        </>
      )}
    </Modal>
  );
};
