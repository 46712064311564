export const confirmDeletePayload = {
  title: 'Удаление ЖК',
  description: 'Все данные этого ЖК будут безвозвратно удалены из системы',
  acceptText: 'Удалить',
};

export const getSuccessDeleteMessage = (name: string, streetAddress: string) => ({
  title: 'ЖК удалён',
  message: `ЖК ${name} по адресу ${streetAddress || ''} успешно удалён.`,
});

export const failedDeleteMessage = {
  title: 'ЖК не удалён',
  message: 'Не удалось удалить ЖК.',
  isError: true,
};
