import React, {useEffect, useState} from 'react';

import styles from 'modules/AddNewIntercom/AddIntercom.module.scss';

import {bem} from '../../utils';
import {AutocompleteField} from '../AutocompleteField';
import {ParkingSectionApi} from 'api';
import {usePrevious} from "../../hooks";

type Props = {
  selectedEntranceNumber?: number | null;
  entrance: ParkingSectionEntrance | null;
  updateEntrance: (value: ParkingSectionEntrance | null) => void;
  required: boolean;
  disabled?: boolean;
  errorText?: string;
  apartmentComplexId?: number;
  parkingId?: number;
  parkingSectionId?: number;
};

const sn = bem('addNewIntercom', styles);

export type ParkingSectionEntrance = {name: string; value: string; label: string};

export const ParkingSectionEntranceAutocomplete = ({
  entrance,
  apartmentComplexId,
  parkingId,
  parkingSectionId,
  updateEntrance,
  ...props
}: Props) => {
  const [defaultEntranceOptions, setDefaultEntranceOptions] = useState<ParkingSectionEntrance[]>([]);
  const prevParentId = usePrevious(parkingSectionId);

  const getDefaultValues = async (apartmentComplexId: string, parkingId: string, parkingSectionId: string) => {
    const res = await ParkingSectionApi.getParkingSectionEntrance(apartmentComplexId, parkingId, parkingSectionId);
    const entranceOptions = res.sort((a,b) => a > b ? 1 : -1).map((entrance) => ({
      name: String(entrance),
      value: String(entrance),
      label: String(entrance),
    }));

    setDefaultEntranceOptions(entranceOptions);
  };

  useEffect(() => {
    if ((entrance && !parkingId) || (prevParentId && prevParentId !== parkingSectionId)) {
      onChangeEntry(null);
    }
  }, [entrance, parkingId, prevParentId, parkingSectionId]);

  useEffect(() => {
    if (apartmentComplexId && parkingId && parkingSectionId) {
      getDefaultValues(String(apartmentComplexId), String(parkingId), String(parkingSectionId));
    }
  }, [apartmentComplexId, parkingId, parkingSectionId]);

  const onChangeEntry = (value: ParkingSectionEntrance | null) => {
    updateEntrance(value);
  };

  return (
    <AutocompleteField<ParkingSectionEntrance>
      label="Номер въезда"
      classNames={sn('autocomplete', 'small')}
      value={entrance}
      updateValue={onChangeEntry}
      defaultOptions={defaultEntranceOptions}
      {...props}
    />
  );
};
