import {ParkingSectionEntranceAutocomplete} from 'shared/components/ParkingSectionEntranceAutocomplete';
import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {AccessDeviceFormValues} from '../../../../types';

type ParkingSectionEntrance = {name: string; value: string; label: string};

export const Entrance = () => {
  const [entrance, setEntrance] = useState<ParkingSectionEntrance | null | undefined>(undefined);
  const {setFieldValue, values, errors, validateField} = useFormikContext<AccessDeviceFormValues>();

  useEffect(() => {
    if (entrance !== undefined && !values.address.buildingId) {
      updateEntrance(null);
    }
  }, [values.address.buildingId]);

  useEffect(() => {
    if (entrance === undefined && values.address.entranceNumber) {
      const entranceNumber = values.address.entranceNumber;
      updateEntrance({label: String(entranceNumber), name: String(entranceNumber), value: String(entranceNumber)});
    }
  }, [entrance, values.address.entranceNumber]);

  const updateEntrance = (model: ParkingSectionEntrance | null) => {
    setFieldValue('address', {...values.address, entranceNumber: model ? Number(model.value) : null});
    validateField('address.entranceNumber')
    setEntrance(model);
  };

  return (
    <ParkingSectionEntranceAutocomplete
      required
      selectedEntranceNumber={values.address.entranceNumber}
      entrance={entrance || null}
      apartmentComplexId={values.address.apartmentComplexId || undefined}
      parkingId={values.address.courtyardId || undefined}
      parkingSectionId={values.address.buildingId || undefined}
      updateEntrance={updateEntrance}
      disabled={!values.address.buildingId}
      errorText={errors?.address?.entranceNumber}
    />
  );
};
