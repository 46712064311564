import {Modal} from 'shared/components/Modal';
import {AccessDeviceForm} from './components/AccessDeviceForm';
import React, {useEffect, useRef} from 'react';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {validation} from './components/AccessDeviceForm/validation';
import {Formik, FormikProps} from 'formik';
import {AccessDeviceModel} from 'shared/models';
import {exitConfirmPayload, initialValues} from './constants';
import {AccessDeviceFormValues} from './types';

type Props = {
  title: string;
  saving: boolean;
  onSave: (values: AccessDeviceFormValues) => void;
  onClose: () => void;
  isOpen: boolean;
  accessDevice?: AccessDeviceModel | null;
};

export const AccessDeviceModal = ({title, onSave, saving, isOpen, onClose, accessDevice}: Props) => {
  const {confirm} = useConfirm();
  const formik = useRef<FormikProps<AccessDeviceFormValues>>(null);

  useEffect(() => {
    if (accessDevice && formik.current) {
      formik.current.resetForm({values: {...accessDevice}});
    }
  }, [accessDevice]);

  const onBeforeSave = async () => {
    const errorValues = (await formik.current?.validateForm()) || {};
    if (formik.current && !Object.values(errorValues).length) {
      onSave(formik.current.values);
    }
  };

  const closeModal = async () => {
    if (!formik.current?.dirty) {
      onClose();
      return;
    }
    if (await confirm(exitConfirmPayload)) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      onSave={onBeforeSave}
      onCancel={closeModal}
      loading={saving}
      title={title}
      disableSave={saving}
      closeButton
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      maxWidth="lg"
    >
      <Formik
        ref={formik}
        innerRef={formik}
        initialValues={initialValues}
        validationSchema={validation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onBeforeSave}
      >
        <AccessDeviceForm />
      </Formik>
    </Modal>
  );
};
