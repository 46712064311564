import {AxiosPromise} from 'axios';

import {apiClient} from 'api/client';
import {
  HouseSettingsPayload,
  BaseHouseModel,
  WithPagination,
  HouseInfoModel,
  EditHouseModel,
  RequestWithPagination,
  SearchHouse,
  BuildingType,
} from 'shared/models';

class HouseService {
  private getBasePath(apartmentComplexId: string, courtyardId: string, value: string) {
    return `/properties/apartment-complexes/${apartmentComplexId}/courtyards/${courtyardId}/buildings${value}`;
  }

  private getImportPath(buildingId: string, value: string) {
    return `/managing/tenants/import/${buildingId}${value}`;
  }

  getHouse(apartmentComplexId: string, courtyardId: string, houseId: string): Promise<HouseInfoModel> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, courtyardId, `/condominiums/${houseId}`))
      .then((res) => res.data);
  }

  deleteHouse(apartmentComplexId: string, courtyardId: string, houseId: string) {
    return apiClient.delete(this.getBasePath(apartmentComplexId, courtyardId, `/${houseId}`)).then((res) => res.data);
  }

  getHouses(
    apartmentComplexId: string,
    courtyardId: string,
    payload?: RequestWithPagination<SearchHouse>,
  ): Promise<WithPagination<BaseHouseModel>> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, courtyardId, '/condominiums'), {params: payload})
      .then((res) => res.data);
  }

  getEditingHouse(apartmentComplexId: string, courtyardId: string, houseId: string): Promise<EditHouseModel> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, courtyardId, `/condominiums/${houseId}/to-edit`))
      .then((res) => res.data);
  }

  addHouse(apartmentComplexId: string, courtyardId: string, payload: HouseSettingsPayload): Promise<BaseHouseModel> {
    return apiClient
      .post(this.getBasePath(apartmentComplexId, courtyardId, '/condominiums'), payload)
      .then((res) => res.data);
  }

  editHouse(
    apartmentComplexId: string,
    courtyardId: string,
    houseId: string,
    payload: HouseSettingsPayload,
  ): Promise<BaseHouseModel> {
    return apiClient
      .put(this.getBasePath(apartmentComplexId, courtyardId, `/condominiums/${houseId}`), payload)
      .then((res) => res.data);
  }

  getImportTemplate(buildingId: string, buildingType: BuildingType): AxiosPromise {
    return apiClient.get(this.getImportPath(buildingId, `/template?buildingType=${buildingType}`), {
      responseType: 'arraybuffer',
    });
  }

  importTenants(buildingId: string, data: File, buildingType: BuildingType): Promise<HouseInfoModel> {
    return apiClient.post(
      this.getImportPath(buildingId, `?buildingType=${buildingType}`),
      {file: data},
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}

export const HouseApi = new HouseService();
