import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

import {ReactComponent as IntercomIcon} from 'assets/icons/intercom.svg';
import KeyIcon from 'assets/icons/keysvg.svg';
import OffIntercomIcon from 'assets/icons/off-intercom.svg';
import ReloadIcon from 'assets/icons/refresh.svg';
import {DeviceModel, IntercomModel} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './IntercomsTable.module.scss';

const sn = bem('intercomsTable', styles);

type Props = {
  onAfterUpdate: () => void;
  isEdit?: boolean;
  isCard?: boolean;
  deviceModels?: DeviceModel[];
};

export const getColumns = ({onAfterUpdate, isEdit, isCard, deviceModels}: Props): GridColDef[] =>
  isEdit
    ? baseColumns(onAfterUpdate, isCard, deviceModels).concat(extraColumns)
    : baseColumns(onAfterUpdate, isCard, deviceModels);

const extraColumns = [
  {
    field: 'edit',
    headerName: '',
    headerClassName: sn('emptyHeader'),
    cellClassName: sn('cell_edit'),
    renderCell: () => (
      <div>
        <EditIcon />
      </div>
    ),
    maxWidth: 50,
    sortable: false,
    filterable: false,
    flex: 0.2,
  },
  {
    field: 'delete',
    headerName: '',
    headerClassName: sn('emptyHeader'),
    cellClassName: sn('cell_delete'),
    renderCell: () => (
      <div>
        <DeleteIcon />
      </div>
    ),
    maxWidth: 50,
    sortable: false,
    filterable: false,
    flex: 0.2,
  },
];

const baseColumns = (onAfterUpdate: () => void, isCard = false, deviceModels?: DeviceModel[]) => {
  const columns = [
    {
      field: 'isConnected',
      headerName: 'Подключено',
      headerClassName: sn('column'),
      cellClassName: sn('cell_checkbox'),
      renderCell: (params: GridRenderCellParams) => (
        <div className={sn('cell_checkbox')}>{params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</div>
      ),
      sortable: false,
      filterable: false,
      flex: 0.6,
      maxWidth: 100,
    },
    {
      field: 'sipNumber',
      headerName: 'SIP-номер',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      sortable: false,
      filterable: false,
      maxWidth: 100,
      flex: 0.6,
    },
    {
      field: 'name',
      headerName: 'Название домофона',
      renderCell: (params: GridRenderCellParams) => (
        <div className={sn('intercom')}>
          <IntercomIcon />
          {params.value}
        </div>
      ),
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      sortable: false,
      filterable: false,
      flex: 0.9,
      maxWidth: 400,
    },
    {
      field: 'address',
      headerName: 'Адрес',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IntercomModel>) => (
        <Tooltip title={`${params?.row?.address?.address || params?.row?.address || ''}`}>
          <div>
            {typeof params?.row?.address?.address === 'string'
              ? params?.row?.address?.address
              : String(params?.row?.address) || ''}
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'model',
      headerName: 'Модель устройства',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      sortable: false,
      filterable: false,
      flex: 0.7,
      maxWidth: 200,
      renderCell: (params: GridRenderCellParams<IntercomModel>) => (
        <>{deviceModels ? deviceModels?.[params.row.model]?.description || '' : params.row.model}</>
      ),
    },
    {
      field: 'unlink',
      headerName: '',
      headerClassName: sn('actionHeader'),
      cellClassName: sn('cell_edit'),
      sortable: false,
      filterable: false,
      flex: 1,
      maxWidth: 50,
      renderCell: () => (
        <Tooltip title={'Отвязать домофон'}>
          <img src={OffIntercomIcon} alt="Intercom" />
        </Tooltip>
      ),
    },
    {
      field: 'open',
      headerName: '',
      headerClassName: sn('actionHeader'),
      cellClassName: sn('cell_edit'),
      sortable: false,
      filterable: false,
      flex: 1,
      maxWidth: 50,
      renderCell: () => (
        <Tooltip title={'Открыть дверь'}>
          <img src={KeyIcon} alt="Intercom" />
        </Tooltip>
      ),
    },
    {
      field: 'reload',
      headerName: '',
      headerClassName: sn('actionHeader'),
      cellClassName: sn('cell_edit'),
      sortable: false,
      filterable: false,
      flex: 1,
      maxWidth: 50,
      renderCell: () => (
        <Tooltip title={'Перезапустить домофон'}>
          <img src={ReloadIcon} alt="Intercom" />
        </Tooltip>
      ),
    },
  ];

  if (isCard) {
    columns.splice(2, 0, {
      field: 'sipPassword',
      headerName: 'SIP-пароль',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      sortable: false,
      filterable: false,
      maxWidth: 350,
      flex: 1,
    });
    columns.splice(4, 1);
    columns[4].maxWidth = 350;
  }

  return columns;
};
