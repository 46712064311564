import {useState} from 'react';

import {ApartmentComplexesApi} from 'api';
import {useToast} from 'shared/hooks';
import {ApartmentComplexesModel, WithPagination} from 'shared/models';

import {ApartmentComplexForm, FormValues} from '../ApartmentComplexForm';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  apartmentComplexes: WithPagination<ApartmentComplexesModel>;
  updateApartmentComplexes: (value: WithPagination<ApartmentComplexesModel>) => void;
};

export const AddApartmentComplex = ({isOpen, closeModal, apartmentComplexes, updateApartmentComplexes}: Props) => {
  const [loading, setLoading] = useState(false);
  const {showToast} = useToast();

  const onSave = async (values: FormValues) => {
    try {
      setLoading(true);
      const newApartmentComplex = await ApartmentComplexesApi.addApartmentComplex({
        name: values.name,
        streetNumber: values.streetNumber || undefined,
        streetId: values.streetId || undefined,
        type: values.type,
      });
      const updatedApartmentComplex = {
        total: apartmentComplexes.total + 1,
        count: apartmentComplexes.count + 1,
        items: [newApartmentComplex, ...apartmentComplexes.items],
      };
      updateApartmentComplexes(updatedApartmentComplex);
      closeModal();
      showToast({
        title: 'ЖК добавлен',
        message: `ЖК ${values.name} ${values.streetAddress ? 'по адресу ' + values.streetAddress : ''} успешно добавлен.`,
      });
    } catch (err) {
      showToast({
        title: 'ЖК не создан',
        message: 'Не удалось добавить ЖК.',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ApartmentComplexForm
      loading={loading}
      isOpen={isOpen}
      title="Добавление ЖК"
      closeModal={closeModal}
      initApartmentComplex={null}
      onSave={onSave}
    />
  );
};
