import {Button} from '@mui/material';
import {ChangeEvent} from 'react';

import {SearchInput} from 'shared/components/SearchInput';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccountRoles} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './GatesHeader.module.scss';

import {useBreadcrumbs} from '../../../../shared/hooks';

type Props = {
  nameSearch: string;
  updateNameSearch: (value: string) => void;
  addressSearch: string;
  updateAddressSearch: (value: string) => void;
  openModal: () => void;
};

const sn = bem('gates', styles);

export const GatesHeader = ({nameSearch, updateNameSearch, addressSearch, updateAddressSearch, openModal}: Props) => {
  const {gateBreadcrumbs} = useBreadcrumbs();

  const onNameSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateNameSearch(event.target.value);
  };

  const onAddressSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateAddressSearch(event.target.value);
  };

  return (
    <>
      <TitleBlock
        title="Калитки"
        breadcrumbs={gateBreadcrumbs}
        action={
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={openModal}
            disabled={!checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin])}
          >
            Добавить калитку
          </Button>
        }
      />
      <div className={sn('searchBlock')}>
        <div className={sn('search')}>
          <SearchInput placeholder="Поиск по названию (мин 3 символа)" onChange={onNameSearch} value={nameSearch} />
        </div>
        <div className={sn('search')}>
          <SearchInput placeholder="Поиск по адресу" onChange={onAddressSearch} value={addressSearch} />
        </div>
      </div>
    </>
  );
};
