import {UserAvatar} from 'shared/components/UserAvatar';
import {bem} from 'shared/utils';

import styles from './UserInfo.module.scss';

import {ProfileModel} from '../../models';

const sn = bem('userInfo', styles);

type Props = {
  profile: ProfileModel;
};

export const UserInfo = ({profile}: Props) => {

  return (
    <div className={styles.userInfo}>
      <div className={sn('container')}>
        <UserAvatar size="small" />
        <span>{`${profile.firstName} ${profile.secondName} ${profile.lastName}`}</span>
      </div>
      <span className={sn('phone')}>{profile.phoneNumber}</span>
      <div className={sn('line')}></div>
    </div>
  );
};
