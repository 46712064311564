import {Formik, FormikProps} from 'formik';
import {useEffect, useRef, useState} from 'react';

import {ClientsApi} from 'api';
import {FormikTextField, Loader} from 'shared/components';
import {Modal} from 'shared/components/Modal';
import {ClientInfoModel, ClientRoles} from 'shared/models';
import {excludeEmptyField, bem} from 'shared/utils';

import styles from './EditUser.module.scss';
import {validation} from './validation';

type Props = {
  isOpen: boolean;
  clientId: number | null;
  onClose: () => void;
  onAfterEditing: () => void;
  title: string;
};

const sn = bem('editUser', styles);

const initialValues = {
  phoneNumber: '',
  firstName: '',
  secondName: '',
  lastName: '',
  role: ClientRoles.client,
};

export const EditUser = ({isOpen, clientId, onClose, onAfterEditing, title}: Props) => {
  const formik = useRef<FormikProps<ClientInfoModel>>(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState<ClientInfoModel | null>(null);

  const getUserInfo = async () => {
    if (clientId) {
      try {
        setLoading(true);
        const res = await ClientsApi.getClient(clientId);
        if (res) setUser(res);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [clientId]);

  useEffect(() => {
    if (user && formik.current) {
      formik.current.setValues(user);
    }
  }, [user]);

  const onSave = async () => {
    if (clientId && formik.current?.values) {
      try {
        await formik.current.validateForm();
        if (!formik.current.isValid) return;
        setSaving(true);
        await ClientsApi.updateClient(clientId, excludeEmptyField(formik.current.values, 'email'));
        onAfterEditing();
        onClose();
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      closeButton={true}
      cancelButtonText={'Отменить'}
      saveButtonText={'Сохранить'}
      onCancel={onClose}
      onSave={onSave}
      disableSave={saving}
      maxWidth="md"
    >
      {loading ? (
        <Loader />
      ) : (
        <Formik
          onSubmit={onSave}
          innerRef={formik}
          className={styles.addUser}
          initialValues={initialValues}
          validationSchema={validation}
        >
          <div className={sn('form')}>
            <FormikTextField name="lastName" label="Фамилия" />
            <FormikTextField name="firstName" label="Имя" required />
            <FormikTextField name="secondName" label="Отчество" />
            <FormikTextField name="phoneNumber" label="Телефон" required />
            <FormikTextField name="email" label="Email" />
          </div>
        </Formik>
      )}
    </Modal>
  );
};
