import {ChangeEvent} from 'react';

import {SearchInput} from 'shared/components/SearchInput';
import {bem} from 'shared/utils';

import styles from '../../Cameras.module.scss';

const sn = bem('cameras', styles);

type Props = {
  nameSearch: string;
  addressSearch: string;
  updateNameSearch: (value: string) => void;
  updateAddressSearch: (value: string) => void;
};

export const SearchBlock = ({nameSearch, addressSearch, updateNameSearch, updateAddressSearch}: Props) => {
  const onNameSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateNameSearch(event.target.value);
  };

  const onAddressSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateAddressSearch(event.target.value);
  };

  return (
    <div className={sn('search')}>
      <SearchInput placeholder="Название (мин 3 символа)" value={nameSearch} onChange={onNameSearch} />
      <SearchInput placeholder="Адрес (мин 3 символа)" value={addressSearch} onChange={onAddressSearch} />
    </div>
  );
};
