/* eslint-disable @typescript-eslint/naming-convention */
import {createTheme} from '@mui/material';
import {ruRU} from '@mui/material/locale';

import {getCssVar} from 'shared/utils';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: getCssVar('--primary'),
      },
      secondary: {
        main: getCssVar('--accent'),
      },
      background: {
        default: getCssVar('--bg'),
        paper: getCssVar('--white'),
      },
    },
  },
  {
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: getCssVar('--bgLightest'),
            color: getCssVar('--primaryLightest'),
            minWidth: 232,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: 'none',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          subtitle1: {
            margin: '8px 0 0 0',
            color: `${getCssVar('--secondary')}`,
          },
          h4: {
            margin: '20px 0 0 0',
            fontSize: 16,
            lineHeight: '23px',
            color: getCssVar('--darkText'),
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: '48px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: getCssVar('--accent'),
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthLg: {
            maxWidth: '1000px',
            width: '100%',
          },
          paperWidthMd: {
            width: '836px',
          },
          paperWidthSm: {
            width: '600px',
          },
          paper: {
            backgroundColor: getCssVar('--white'),
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
            borderRadius: 'none',
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-withBorderColor': {
              borderColor: getCssVar('--bgLightest'),
              borderRight: `1px solid ${getCssVar('--bgLightest')}`,
              '&:last-child': {
                borderRight: 'none',
              },
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          },
          cellFocusOutline: {
            '& .MuiDataGrid-cellContent:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cellContent:focus-within': {
              outline: 'none',
            },
          },
          columnHeaderFocusOutline: {
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
          },
          columnHeader: {
            backgroundColor: getCssVar('--bgLightest'),
          },
          footerContainer: {
            backgroundColor: getCssVar('--bgLightest'),
          },
          columnHeaderTitle: {
            color: getCssVar('--primaryLight'),
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              color: getCssVar('--primaryLightest'),
            },
          },
        },
      },
    },
  },
  ruRU,
);

export {theme};
