import {Address} from './utils';

export interface CameraItemModel {
  id?: number | string;
  cameraId: number;
  name: string;
  address: string;
  location: CameraLocationType;
  linkedToDoorPhone?: boolean;
}

export interface CameraModel {
  cameraId: number;
  name: string;
  protocol: CameraProtocol;
  type: CameraType;
  password: string;
  host: string;
  port: number;
  path: string;
  url: string;
  completeUrl: string;
  address: {
    location: CameraLocationType;
    apartmentComplexId: number | null;
    courtyardId: number | null;
    courtyardGateId: number | null;
    buildingId: number | null;
    entryNumber: number | null;
    floorNumber: number | null;
  };
}

export type CameraForLinkingItemModel = {
  cameraId: number;
  name: string;
  address: string;
  linkedToDoorPhone: boolean;
};

export type CamerasForLinkingPayload = {
  size: number;
  page: number;
  buildingId?: number;
  entryNumber?: number;
  courtyardGateId?: number;
  cameraLocation: CameraLocationType;
}

// export interface CameraUpdateModel {
//   name: string;
//   protocol: CameraProtocol;
//   type: CameraType;
//   login: string;
//   password: string;
//   host: string;
//   port: number
//   path: string;
//   url: string; // '{Protocol}://{Login}:{Password}@{Host}:{Port}{Path}';
//   location: CameraLocationType;
//   courtyardId: number | null;
//   courtyardGateId: number | null;
//   buildingId: number | null;
//   entryNumber: number | null;
//   floorNumber: number | null;
// }

export interface CameraCreateModel {
  name: string;
  protocol: CameraProtocol;
  type: CameraType;
  login?: string;
  password?: string;
  host: string;
  port: number | null;
  path: string;
  url?: string; // '{Protocol}://{Login}:{Password}@{Host}:{Port}{Path}'
  location: CameraLocationType;
  courtyardId?: number | null;
  courtyardGateId?: number | null;
  buildingId?: number | null;
  entryNumber?: number | null;
  floorNumber?: number | null;
}

export enum CameraProtocol {
  standard,
  custom,
  link,
}

export type CameraProtocolType = {value: CameraProtocol; description: string};

export const CameraProtocols = [
  {
    value: CameraProtocol.standard,
    description: 'RTSP (Standard)',
  },
  {
    value: CameraProtocol.custom,
    description: 'RTSP (Custom)',
  },
  {
    value: CameraProtocol.link,
    description: 'RTSP (Link)',
  },
];

export enum CameraType {
  common,
  sipPanelCamera,
}

export interface CameraInfoModel {
  address: Address;
  cameraId: number;
  name: string;
  protocol: CameraProtocol;
  type: CameraType;
  location: CameraLocationType;
  host: string;
  port: number | null;
  path: string;
  url: string;
  completeUrl: string;
  login: string;
  password: string;
  courtyardId: number;
  courtyardGateId: number;
  buildingId: number;
  entryNumber: number;
  floorNumber: number;
}

// TODO: заменить NewCamera на CameraCreateModel
export interface NewCamera {
  address: Address;
  name: string;
  protocol: CameraProtocol;
  type: CameraType;
  location: CameraLocationType;
  host: string;
  port: number | null;
  path: string;
  url?: string; // '{Protocol}://{Login}:{Password}@{Host}:{Port}{Path}'
  login?: string;
  password?: string;
  courtyardId?: number | null;
  courtyardGateId?: number | null;
  buildingId?: number | null;
  entryNumber?: number | null;
  floorNumber?: number | null;
}

export type SearchCameras = {
  type?: CameraType;
  linkedToDoorPhone?: boolean;
  linked?: boolean;
  name?: string;
  address?: string;
};

export enum CameraLocationType {
  courtyard,
  parking,
}
