import {Button} from '@mui/material';
import {ChangeEvent} from 'react';
import {useParams} from 'react-router';

import {SearchInput} from 'shared/components/SearchInput';
import {UsersImport} from 'shared/components/UsersImport';
import {useBreadcrumbs, useModal} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccountRoles, ApartmentsFilter, BuildingType} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './ParkingPlacesHeader.module.scss';

const sn = bem('parkingPlacesHeader', styles);

type Props = {
  paramsFilter: ApartmentsFilter;
  updateApartmentsFilter: (value: ApartmentsFilter) => void;
  onAfterImport: () => void;
};

export const ParkingPlacesHeader = ({paramsFilter, updateApartmentsFilter, onAfterImport}: Props) => {
  const {parkingSectionId} = useParams<{parkingSectionId: string}>();
  const {parkingPlacesListBreadcrumbs} = useBreadcrumbs();
  const {isOpen, openModal, closeModal} = useModal();

  const onTenantSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateApartmentsFilter({...paramsFilter, tenantName: event.target.value});
  };

  const onApartmentSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateApartmentsFilter({...paramsFilter, apartmentNumber: event.target.value});
  };

  const onPhoneSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateApartmentsFilter({...paramsFilter, phoneNumber: event.target.value});
  };

  return (
    <div>
      <TitleBlock
        title="Парковочные места"
        breadcrumbs={parkingPlacesListBreadcrumbs}
        action={
          <Button
            variant="contained"
            color="secondary"
            className={sn('import')}
            onClick={openModal}
            disabled={!checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin])}
          >
            Импорт пользователей
          </Button>
        }
      />
      <div className={sn('searchBlock')}>
        <SearchInput placeholder="Найти по телефону" value={paramsFilter.phoneNumber} onChange={onPhoneSearch} />
        <SearchInput placeholder="Найти пользователя" value={paramsFilter.tenantName} onChange={onTenantSearch} />
        <SearchInput
          placeholder="Найти парковочное место"
          value={paramsFilter.apartmentNumber}
          onChange={onApartmentSearch}
          type="number"
        />
      </div>
      <UsersImport
        onAfterImport={onAfterImport}
        id={parkingSectionId}
        buildingType={BuildingType.parkingLot}
        isOpen={isOpen}
        title="Импорт пользователей"
        onClose={closeModal}
      />
    </div>
  );
};
