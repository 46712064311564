import {CircularProgress} from '@mui/material';
import {
  DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  DataGridProps,
  GridEditCellProps,
} from '@mui/x-data-grid';

import tableStyles from './Table.module.scss';

import {TableData} from '../../models';
import {bem} from '../../utils';
import {NotFoundComponent} from '../NotFoundComponent';

type Props = Partial<DataGridProps> & {
  data: TableData | null;
  columns: GridColDef[];
  onCellClick?: (params: GridCellParams) => void;
  paginationModel?: GridPaginationModel;
  updatePaginationModel?: (model: GridPaginationModel) => void;
  pageSizeOptions?: number[];
  styles?: string;
  onEditCellChangeCommitted?: (params: GridEditCellProps) => void;
  loading?: boolean;
  className?: string;
};

const sn = bem('table', tableStyles);

export const Table = ({data, paginationModel, updatePaginationModel, styles, loading, ...props}: Props) => {
  const hideFooterPagination = !paginationModel || !data;

  const handlePaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
    if (details.reason === 'setPaginationModel' && updatePaginationModel) updatePaginationModel(model);
  };

  return (
    <>
      {!!data?.items?.length && (
        <div className={styles}>
          <DataGrid
            {...props}
            loading={loading}
            disableRowSelectionOnClick
            rows={data.items}
            rowCount={data.total}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            keepNonExistentRowsSelected
            hideFooterPagination={hideFooterPagination}
          />
        </div>
      )}
      {!data?.items?.length && !loading && <NotFoundComponent />}
      {!data?.items?.length && loading && (
        <div className={sn('loaderWrap')}>
          <CircularProgress color="inherit" size={30} />
        </div>
      )}
    </>
  );
};
