import {Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import {GridCloseIcon} from '@mui/x-data-grid';
import {FC, FormEvent} from 'react';

import {bem} from 'shared/utils';

import style from './Modal.module.scss';
import {LoadingButton} from '@mui/lab';

interface Props {
  isOpen: boolean;
  onClose: (props: unknown) => void;
  onSave?: (props?: FormEvent<HTMLFormElement>) => void;
  onCancel?: (props: unknown) => void;
  title?: string;
  closeButton?: boolean;
  cancelButtonText?: string;
  saveButtonText?: string;
  maxWidth?: Breakpoint;
  children: React.ReactNode;
  disableSave?: boolean;
  isLarge?: boolean;
  loading?: boolean;
}

const sn = bem('modal', style);

export const Modal: FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  onCancel,
  title,
  closeButton = true,
  cancelButtonText = 'Cancel',
  saveButtonText = 'Save',
  children,
  maxWidth,
  disableSave,
  loading,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className={style.modal} maxWidth={maxWidth || 'sm'}>
      {closeButton && (
        <IconButton className={sn('closeButton')} aria-label="close" onClick={onClose}>
          <GridCloseIcon />
        </IconButton>
      )}
      <div className={sn('content')}>
        {title && <DialogTitle className={sn('title')}>{title}</DialogTitle>}
        <DialogContent className={sn('content__dialogContent')}>{children}</DialogContent>
        <DialogActions className={sn('actions')}>
          {onCancel && (
            <Button variant="contained" onClick={onCancel}>
              {cancelButtonText}
            </Button>
          )}
          {onSave && (
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={() => onSave()}
              color="secondary"
              disabled={disableSave}
              loading={loading}
            >
              {saveButtonText}
            </LoadingButton>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};
