import React, {memo, useEffect, useState} from 'react';

import {ParkingSectionApi} from 'api';
import styles from 'modules/AddNewIntercom/AddIntercom.module.scss';

import {usePrevious} from '../../hooks';
import {bem} from '../../utils';
import {AutocompleteField} from '../AutocompleteField';
import {searchParkingSection} from '../AutocompleteField/utils';
import {ParkingSectionItemModel} from '../../models';

type Props = {
  selectedBuildingId: number | null | undefined;
  parkingSection: ParkingSectionItemModel | null;
  updateParkingSection: (value: ParkingSectionItemModel | null) => void;
  apartmentComplexId?: number | null;
  parkingId?: number | null;
  required: boolean;
  errorText?: string;
  disable?: boolean;
};

const sn = bem('addNewIntercom', styles);

export const ParkingSectionAutocomplete = memo(
  ({
    selectedBuildingId,
    parkingSection,
    updateParkingSection,
    apartmentComplexId,
    parkingId,
    ...props
  }: Props) => {
    const [defaultParkingSection, setDefaultParkingSection] = useState<ParkingSectionItemModel[]>([]);
    const [loadingDefaultParkingSection, setLoadingDefaultParkingSection] = useState(false);

    const prevParentId = usePrevious(parkingId);

    useEffect(() => {
      if (selectedBuildingId) {
        getDefaultParkingSection(selectedBuildingId);
      }
    }, [selectedBuildingId, apartmentComplexId, parkingId]);

    useEffect(() => {
      if (parkingId) {
        getInitParkingSection();
      }
      if ((parkingSection && !parkingId) || (prevParentId && prevParentId !== parkingId)) {
        onChangeParkingSection(null);
      }
    }, [parkingId, prevParentId, parkingSection]);

    const getInitParkingSection = async () => {
      if (!apartmentComplexId || !parkingId) return;
      try {
        setLoadingDefaultParkingSection(true);
        const res = await ParkingSectionApi.getParkingSectionList(String(apartmentComplexId), String(parkingId));
        const preparedParkingSection = res.items.map((parkingSection) => ({
          ...parkingSection,
          name: String(parkingSection.name),
        }));
        setDefaultParkingSection(preparedParkingSection);
      } finally {
        setLoadingDefaultParkingSection(false);
      }
    };

    const getDefaultParkingSection = async (parkingSectionId: number | null) => {
      if (apartmentComplexId && parkingId && parkingSectionId) {
        const {floors, ...rest} = await ParkingSectionApi.getEditingParkingSection(
          String(apartmentComplexId),
          String(parkingId),
          String(parkingSectionId),
        );
        // TODO: добавить преобразование ParkingSectionModel в ParkingSectionItemModel
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChangeParkingSection({...rest, floorsCount: floors.length});
      }
    };

    const onChangeParkingSection = (value: ParkingSectionItemModel | null) => {
      updateParkingSection(value);
    };

    return (
      <AutocompleteField<ParkingSectionItemModel>
        label="Название паркинга"
        classNames={sn('autocomplete', 'small')}
        value={parkingSection}
        updateValue={onChangeParkingSection}
        getOptions={searchParkingSection(apartmentComplexId, parkingId)}
        loadingDefaultOptions={loadingDefaultParkingSection}
        defaultOptions={defaultParkingSection}
        disabled={!parkingId}
        {...props}
      />
    );
  },
);
