export interface AccessDeviceCreateModel {
  name: string;
  triggerUrl: string;
  restartUrl: string | null;
  hidden: boolean;
  buildingId: number | null;
  entranceNumber: number | null;
  type: AccessDeviceType;
}

export type AccessDeviceUpdateModel = Omit<AccessDeviceCreateModel, 'type'> & {
  cameraId: number | null;
};

export interface AccessDeviceAddress {
  address: string;
  apartmentComplexId: number;
  courtyardId: number;
  buildingId: number;
  entranceNumber: number;
}

export interface AccessDeviceItemModel {
  accessDeviceId: number;
  name: string;
  restartable: boolean;
  linked: boolean;
  address: string;
}

export interface AccessDeviceModel {
  accessDeviceId: number;
  name: string;
  type: AccessDeviceType;
  triggerUrl: string;
  restartUrl: string;
  hidden: boolean;
  address: AccessDeviceAddress;
  cameraId: number | null;
}

export enum AccessDeviceType {
  barrier,
}
