import {ChangeEvent} from 'react';

import {SearchInput} from 'shared/components/SearchInput';
import {bem} from 'shared/utils';

import styles from '../../Intercoms.module.scss';

const sn = bem('intercoms', styles);

type Props = {
  nameSearch: string;
  sipSearch: string;
  addressSearch: string;
  updateNameSearch: (value: string) => void;
  updateSipSearch: (value: string) => void;
  updateAddressSearch: (value: string) => void;
};

export const SearchBlock = ({
  nameSearch,
  sipSearch,
  addressSearch,
  updateNameSearch,
  updateSipSearch,
  updateAddressSearch,
}: Props) => {
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateNameSearch(event.target.value);
  };

  const onSipChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateSipSearch(event.target.value);
  };

  const onAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateAddressSearch(event.target.value);
  };

  return (
    <div className={sn('search')}>
      <SearchInput placeholder="Название (мин 3 символа)" value={nameSearch} onChange={onNameChange} />
      <SearchInput placeholder="SIP-номер" value={sipSearch} onChange={onSipChange} />
      <SearchInput placeholder="Адрес" value={addressSearch} onChange={onAddressChange} />
    </div>
  );
};
