import {ApartmentsModel, EntryInfoModel} from 'shared/models';

export const getSortedEntries = (entries?: EntryInfoModel[]) => {
  if (Array.isArray(entries)) {
    return entries.sort((a, b) => (a.entryNumber > b.entryNumber ? 1 : -1));
  }
  return [];
};

export const addId = (apartments: ApartmentsModel[]) => apartments.map((a) => ({...a, id: a.apartmentId}));
