import {CameraLocationType, CameraProtocol, CameraType} from '../../models';

export const ERROR_NAME_NOT_AVAILABLE = 'Камера с таким названием уже существует';

export enum CameraAddressType {
  'courtyard',
  'gate',
  'house',
  'entry',
  'floor',
  'intercom',
  'parking',
  'parkingSection',
  'parkingSectionEntrance',
  'parkingSectionFloor',
}

export const initialCourtyardAddress = {
  models: {
    apartmentComplex: null,
    courtyard: null,
    courtyardGate: null,
    building: null,
    entryNumber: null,
    floorNumber: null,
  },
  ids: {
    apartmentComplexId: null,
    courtyardId: null,
    courtyardGateId: null,
    buildingId: null,
    entryNumber: null,
    floorNumber: null,
  },
};

export const initialParkingAddress = {
  models: {
    apartmentComplex: null,
    parking: null,
    parkingSection: null,
    entryNumber: null,
    floorNumber: null,
  },
  ids: {
    apartmentComplexId: null,
    parkingId: null,
    parkingSectionId: null,
    entryNumber: null,
    floorNumber: null,
  },
};

export const CAMERA_ADDRESS_TYPE_LIST = [
  {value: CameraAddressType.courtyard, name: 'Двор'},
  {value: CameraAddressType.gate, name: 'Калитка'},
  {value: CameraAddressType.house, name: 'Дом'},
  {value: CameraAddressType.entry, name: 'Подъезд'},
  {value: CameraAddressType.floor, name: 'Этаж дома'},
];

export const PARKING_ADDRESS_TYPE_LIST = [
  {value: CameraAddressType.parkingSection, name: 'Паркинг'},
  {value: CameraAddressType.parkingSectionEntrance, name: 'Въезд/Выезд'},
  {value: CameraAddressType.parkingSectionFloor, name: 'Этаж паркинга'},
];

export const URL_PATTERN = '{Protocol}://{Login}:{Password}@{Host}:{Port}{Path}';

export const initialValues = {
  name: '',
  protocol: CameraProtocol.standard,
  type: CameraType.common,
  location: CameraLocationType.courtyard,
  host: '',
  port: null,
  path: '',
  url: URL_PATTERN,
  login: '',
  password: '',
  courtyardId: null,
  courtyardGateId: null,
  buildingId: null,
  entryNumber: null,
  floorNumber: null,
};

export const URL_PATTERN_HELPER_TEXT = 'Пример: {Protocol}://{Login}:{Password}@{Host}:{Port}{Path} Шаблон для полного URL камеры. Может содержать в себе следующие элементы, которые будут заменены на соответствующие значения: {Protocol}, {Login}, {Password}, {Host}, {Port}, {Path}.'
