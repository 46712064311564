export interface ApartmentComplexesModel {
  id: number;
  name: string;
  city: string;
  activeApartmentsCount: number;
  address: string;
}

export interface ApartmentComplexInfoModel {
  apartmentComplexId?: number;
  name: string;
  streetId: number | null;
  streetAddress: string;
  streetNumber: string;
}

export interface AddApartmentComplexPayload {
  name: string;
  streetId?: number;
  streetNumber?: string;
  type?: ApartmentComplexType;
}

export enum ApartmentComplexType {
  apartmentComplex,
  parkingContainer,
}

export interface GetApartmentComplexesPayload {
  query?: string;
  page?: number;
  size?: number;
}

export interface SearchApartmentComplexes {
  query?: string;
}

export enum ReportType {
  townhouse = 0,
  condominium = 1,
  parkingLot= 5,
}
