import {ApartmentComplexesApi, CamerasApi, CourtyardsServiceApi, GatesApi, IntercomsApi} from 'api';
import {CourtyardType} from '../constants/common';

type TypeSearch = 'apartment' | 'courtyard' | 'gate' | 'camera' | 'intercom' | 'parking';

interface DefaultSetting {
  name: string;
}

interface CourtyardSettings extends DefaultSetting {
  apartmentComplexId: string;
}

interface GateSettings extends DefaultSetting {
  apartmentComplexId: string;
  courtyardId: string;
}

type SearchData<T extends TypeSearch> = T extends 'apartment' | 'camera' | 'intercom'
  ? DefaultSetting
  : T extends 'courtyard' | 'parking'
  ? CourtyardSettings
  : T extends 'gate'
  ? GateSettings
  : never;

export const checkAvailability = async <T extends TypeSearch>(
  data: SearchData<T>,
  type: T,
  setAvailable: (value: boolean) => void,
  setLoading?: (value: boolean) => void,
) => {
  const {name} = data;
  try {
    let response;
    switch (type) {
      case 'apartment': {
        response = await ApartmentComplexesApi.searchApartmentComplexes({query: name});
        break;
      }
      case 'courtyard': {
        const {apartmentComplexId: id} = data as CourtyardSettings;
        response = await CourtyardsServiceApi.getCourtyards(id, {
          courtyardName: name,
          courtyardType: CourtyardType.courtyard,
        });
        break;
      }
      case 'parking': {
        const {apartmentComplexId: id} = data as CourtyardSettings;
        response = await CourtyardsServiceApi.getCourtyards(id, {
          courtyardName: name,
          courtyardType: CourtyardType.parking,
        });
        break;
      }
      case 'gate': {
        const {apartmentComplexId, courtyardId} = data as GateSettings;
        response = await GatesApi.searchGates(apartmentComplexId, courtyardId, {name: name});
        break;
      }
      case 'camera': {
        response = await CamerasApi.getCameras({name: name});
        break;
      }
      case 'intercom': {
        response = await IntercomsApi.searchIntercoms({name: name});
        break;
      }
    }

    const names = response?.items.map((item) => item.name);
    const isAvailable = !names?.some((itemName) => itemName === name);

    setAvailable(isAvailable);
  } finally {
    setLoading && setLoading(false);
  }
};
