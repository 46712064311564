import {Button} from '@mui/material';
import {ChangeEvent, useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {ParkingSectionApi} from 'api';
import {SearchInput} from 'shared/components/SearchInput';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {routes} from 'shared/constants/routes';
import {useDebounce, useBreadcrumbs, useToast, usePrevious} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccountRoles, ParkingSectionItemModel, WithPagination} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {ParkingSectionTable} from './components/ParkingSectionTable';
import {failedDeleteMessage, getSuccessDeleteMessage} from './constants';
import styles from './ParkingSectionList.module.scss';
import {initialParkingSectionList} from './utils';

const sn = bem('parkingSectionList', styles);

export const ParkingSectionList = () => {
  const [parkingSectionList, setParkingSectionList] = useState<WithPagination<ParkingSectionItemModel>>(initialParkingSectionList);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);

  const navigate = useNavigate();
  const {apartmentComplexId, parkingId} = useParams<{apartmentComplexId: string; parkingId: string}>();
  const {showToast} = useToast();
  const {parkingSectionListBreadcrumbs} = useBreadcrumbs();
  const isActiveFilter = searchValue.length >= 3;
  const prevIsActiveFilter = usePrevious(isActiveFilter);

  useEffect(() => {
    if (!isActiveFilter) {
      getParkingSectionList();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchParkingSection();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [searchValue, isActiveFilter, paginationModel]);

  const goToNewParkingSection = () => {
    if (apartmentComplexId) {
      navigate(generatePath(routes.addParkingSection, {apartmentComplexId, parkingId}));
    }
  };

  const getParkingSectionList = async () => {
    if (apartmentComplexId && parkingId) {
      try {
        setLoading(true);
        const payload = {page: paginationModel.page + 1, size: paginationModel.pageSize};
        const res = await ParkingSectionApi.getParkingSectionList(apartmentComplexId, parkingId, payload);
        const withId = {...res, items: res.items.map((p) => ({...p, id: p.buildingId}))};
        setParkingSectionList(withId);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchParkingSection = useDebounce(async () => {
    if (apartmentComplexId && parkingId) {
      try {
        setLoading(true);
        const payload = {
          searchQuery: searchValue,
          size: paginationModel.pageSize,
          page: paginationModel.page + 1,
        };
        const res = await ParkingSectionApi.getParkingSectionList(apartmentComplexId, parkingId, payload);
        const parkingSectionWithId = {...res, items: res.items.map((p) => ({...p, id: p.buildingId}))};
        setParkingSectionList(parkingSectionWithId);
      } finally {
        setLoading(false);
      }
    }
  }, DELAY);

  const deleteParkingSection = async (values: ParkingSectionItemModel) => {
    if (apartmentComplexId && parkingId) {
      try {
        setLoading(true);
        await ParkingSectionApi.deleteParkingSection(apartmentComplexId, parkingId, values.buildingId);
        const filteredParkingSectionList = parkingSectionList.items.filter((p) => p.buildingId !== values.buildingId);
        setParkingSectionList({...parkingSectionList, items: filteredParkingSectionList});
        showToast(getSuccessDeleteMessage(values.name, values.address));
      } catch (err) {
        showToast(failedDeleteMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className={styles.parkingSectionList}>
      <TitleBlock
        title="Паркинги"
        breadcrumbs={parkingSectionListBreadcrumbs}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={goToNewParkingSection}
            disabled={!checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin])}
          >
            Добавить паркинг
          </Button>
        }
      />
      <div className={sn('search')}>
        <SearchInput placeholder="Найти паркинг (мин 3 символа)" onChange={onSearch} value={searchValue} />
      </div>
      <ParkingSectionTable
        parkingSectionList={parkingSectionList}
        deleteParkingSection={deleteParkingSection}
        loading={loading}
        paginationModel={paginationModel}
        updatePaginationModel={setPaginationModel}
      />
    </div>
  );
};
