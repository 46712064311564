import {useState} from 'react';

import {IntercomsApi} from 'api';
import {IntercomForm} from 'shared/components/IntercomForm/IntercomForm';
import {Modal} from 'shared/components/Modal';
import {useToast} from 'shared/hooks';
import {IntercomInfoModel, NewIntercom} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './EditIntercom.module.scss';

const sn = bem('editIntercom', styles);

type Props = {
  isOpen: boolean;
  loading: boolean;
  intercom: IntercomInfoModel | null;
  onClose: () => void;
  onAfterUpdate: () => void;
};

const EditIntercom = ({isOpen, loading, intercom, onClose, onAfterUpdate}: Props) => {
  const {showToast} = useToast();
  const [saving, setSaving] = useState(false);

  const onSave = async (payload: Partial<NewIntercom>, address?: string) => {
    if (intercom?.sipPanelId) {
      setSaving(true);
      try {
        await IntercomsApi.editIntercom(intercom?.sipPanelId, payload);
        showToast({
          title: 'Домофон отредактирован',
          message: `Домофон ${payload.name} успешно отредактирован.`,
        });
        onAfterUpdate();
        onClose();
      } catch (err) {
        showToast({
          title: 'Домофон не отредактирован',
          message: 'Ошибка редактирования домофона',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      title="Редактирование домофона"
      maxWidth="lg"
      disableSave={saving}
    >
      <IntercomForm saving={saving} intercom={intercom} loading={loading} onSave={onSave} onCancel={onClose} />
    </Modal>
  );
};

export {EditIntercom};
