import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {PaymentListsApi} from 'api/services/paymentListsService';
import {DELAY} from 'shared/constants/delay';
import {useDebounce, useToast} from 'shared/hooks';
import {bem} from 'shared/utils';

import {PaymentsListTable} from './components/PaymentsListTable/PaymentsListTable';
import {PaymentsHeader} from './components/PymentsHeader';
import {SearchBlock} from './components/SearchBlock/SearchBlock';
import styles from './PaymentsList.module.scss';
import {setPayments} from './store';

import {useRootSelector} from '../../store';

const sn = bem('paymentsList', styles);

export const PaymentsList = () => {
  const dispatch = useDispatch();
  const paginationModel = useRootSelector((state) => state.paymentsList.paginationModel);
  const searchPhone = useRootSelector((state) => state.paymentsList.searchPhone);
  const searchPaymentId = useRootSelector((state) => state.paymentsList.searchPaymentId);

  const [loading, setLoading] = useState(false);
  const {showToast} = useToast();

  useEffect(() => {
    if (searchPhone || searchPaymentId) {
      searchPayments();
    } else {
      getPayments();
    }
  }, [searchPhone, searchPaymentId, paginationModel]);

  const getPayments = async () => {
    if (searchPhone && searchPhone.length < 3) return;
    try {
      setLoading(true);
      const data = await PaymentListsApi.getPayments({
        phone: searchPhone || undefined,
        paymentId: searchPaymentId || undefined,
        page: paginationModel.page + 1,
        size: paginationModel.pageSize,
      });
      dispatch(setPayments(data));
    } catch (err) {
      showToast({
        title: 'Ошибка',
        message: 'Ошибка загрузки платежей',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const searchPayments = useDebounce(getPayments, DELAY);


  return (
    <div className={sn()}>
      <PaymentsHeader/>
      <SearchBlock/>
      <PaymentsListTable loading={loading}/>
    </div>
  );
};
