import {Button, Select} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {useFormikContext} from 'formik';

import {bem} from 'shared/utils';

import styles from './AccountForm.module.scss';
import {passwordHelperText, ROLES} from './constants';

import {NewAccount} from '../../models';
import {FormikTextField} from '../FormikTextField';
import {Loader} from '../Loader';

const sn = bem('accountForm', styles);

type Props = {
  loading?: boolean;
  isEditing?: boolean;
  onCancel: () => void;
};

export const AccountForm = ({onCancel, isEditing, loading}: Props) => {
  const {values, touched, errors, handleChange, submitForm} = useFormikContext<NewAccount>();

  return (
    <div className={styles.accountForm}>
      {loading ? (
        <div className={sn('loaderWrap')}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={sn('row')}>
            <Select
              id="speciality"
              name="role"
              fullWidth
              placeholder="Специальность*"
              value={values.role}
              onChange={handleChange}
              error={touched.role && !!errors.role}
            >
              {ROLES.map((role) => (
                <MenuItem key={role.name} value={role.value}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
            <FormikTextField classNames={sn('field')} name="username" label="Логин" fullWidth />
            <FormikTextField classNames={sn('field')} name="email" label="Email" fullWidth />
          </div>
          <div className={sn('row')}>
            <FormikTextField classNames={sn('field')} name="firstName" label="Имя" fullWidth />
            <FormikTextField classNames={sn('field')} name="lastName" label="Фамилия" fullWidth />
            <FormikTextField classNames={sn('field')} name="secondName" label="Отчество" fullWidth />
          </div>
          <div className={sn('row')}>
            <FormikTextField classNames={sn('field', {'phoneNumber': isEditing})} name="phoneNumber" label="Телефон" fullWidth />
            {!isEditing && <FormikTextField
              classNames={sn('field')}
              name="password"
              label="Пароль"
              fullWidth
              helperText={touched.password && errors.password ? undefined : passwordHelperText}
            />}
          </div>
          <div className={sn('actions')}>
            <Button variant="contained" onClick={onCancel}>
              Отменить
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={sn('submitBtn')}
              onClick={submitForm}
            >
              Сохранить
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
