import {ParkingSectionConstructor} from '../../ParkingSectionConstructor';
import {generatePath, useNavigate, useParams} from 'react-router';
import {useToast} from 'shared/hooks';
import {useState} from 'react';
import {ParkingSectionApi} from 'api';
import {routes} from 'shared/constants/routes';
import {bem} from 'shared/utils';
import styles from './NewParkingSection.module.scss';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {ParkingSectionCreateModel} from 'shared/models';
import {reset} from '../../ParkingSectionConstructor/store';
import {useRootDispatch} from 'store';

const sn = bem('newParkingSection', styles);

export const NewParkingSection = () => {
  const {apartmentComplexId, parkingId} = useParams<{
    apartmentComplexId: string;
    parkingId: string;
  }>();
  const navigate = useNavigate();
  const {showToast} = useToast();
  const [saving, setSaving] = useState(false);
  const dispatch = useRootDispatch();

  const onSave = async (payload: ParkingSectionCreateModel) => {
    if (apartmentComplexId && parkingId) {
      try {
        setSaving(true);
        const response = await ParkingSectionApi.addParkingSection(apartmentComplexId, parkingId, payload);
        showToast({
          title: 'Паринг добавлен',
          message: `Паркинг ${payload.name} успешно добавлен.`,
        });
        dispatch(reset());
        navigate(
          generatePath(routes.parkingPlacesList, {
            apartmentComplexId,
            parkingId,
            parkingSectionId: response.buildingId,
          }),
        );
      } catch (err) {
        showToast({
          title: 'Паркинг не добавлен',
          message: 'Ошибка добавления паркинга',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div className={sn()}>
      <TitleBlock title="Добавление паркинга" />
      <ParkingSectionConstructor onSave={onSave} onCancel={onCancel} saving={saving} />
    </div>
  );
};
