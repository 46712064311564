export const excludeEmptyField = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  fieldName: K,
): Omit<T, K> => {
  if (obj.hasOwnProperty(fieldName) && !obj[fieldName]) {
    const {[fieldName]: _, ...rest} = obj;
    return rest;
  }

  return obj;
};
