import {Apartment as ApartmentSvg, Edit as EditIcon, Delete as DeleteIcon} from '@mui/icons-material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import classNames from 'classnames';

import {bem} from 'shared/utils';

import styles from './ParkingSectionTable.module.scss';

const sn = bem('parkingSectionTable', styles);

type Props = {
  isDisabled: boolean;
};

export const getColumns = ({isDisabled}: Props): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Название паркинга',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      renderCell: (params: GridRenderCellParams) => (
        <div className={sn('cell', 'number')}>
          <ApartmentSvg className={sn('number_icon')} />
          {params.value}
        </div>
      ),
      width: 300,
    },
    {
      field: 'address',
      headerName: 'Адрес паркинга',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      flex: 1,
    },
    {
      field: 'number',
      headerName: 'Номер строения',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      width: 150,
    },
    {
      field: 'activeParkingSpacesCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во заселенных парк. мест',
      width: 230,
      sortable: false,
      filterable: false,
    },
    {
      field: 'edit',
      headerName: '',
      headerClassName: sn('emptyHeader'),
      cellClassName: sn('cell'),
      renderCell: () => (
        <div className={sn('edit', classNames({cell_disabled: isDisabled}))}>
          <EditIcon />
        </div>
      ),
      width: 50,
      sortable: false,
      filterable: false,
    },
    {
      field: 'delete',
      headerName: '',
      headerClassName: sn('emptyHeader'),
      cellClassName: sn('cell'),
      renderCell: () => (
        <div className={sn('delete', classNames({cell_disabled: isDisabled}))}>
          <DeleteIcon />
        </div>
      ),
      width: 50,
      sortable: false,
      filterable: false,
    },
  ];
};
