import {ParkingSectionConstructor} from '../../ParkingSectionConstructor';
import {generatePath, useNavigate, useParams} from 'react-router';
import {useToast} from 'shared/hooks';
import {useEffect, useState} from 'react';
import {ParkingSectionApi} from 'api';
import {routes} from 'shared/constants/routes';
import {bem} from 'shared/utils';
import styles from './EditParkingSection.module.scss';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {ParkingSectionCreateModel, ParkingSectionModel} from 'shared/models';
import {reset} from '../../ParkingSectionConstructor/store';
import {useRootDispatch} from 'store';

const sn = bem('editParkingSection', styles);

export const EditParkingSection = () => {
  const {apartmentComplexId, parkingId, parkingSectionId} = useParams<{
    apartmentComplexId: string;
    parkingId: string;
    parkingSectionId: string;
  }>();
  const navigate = useNavigate();
  const {showToast} = useToast();
  const [saving, setSaving] = useState(false);
  const [parkingSection, setParkingSection] = useState<ParkingSectionModel | null>(null);
  const dispatch = useRootDispatch();

  useEffect(() => {
    if (parkingSectionId) {
      getParkingSection();
    }
  }, [parkingSectionId]);

  const getParkingSection = async () => {
    if (apartmentComplexId && parkingId && parkingSectionId) {
      const response = await ParkingSectionApi.getEditingParkingSection(
        apartmentComplexId,
        parkingId,
        parkingSectionId,
      );
      setParkingSection(response);
    }
  };

  const onSave = async (payload: ParkingSectionCreateModel) => {
    if (apartmentComplexId && parkingId && parkingSectionId) {
      try {
        setSaving(true);
        await ParkingSectionApi.updateParkingSection(apartmentComplexId, parkingId, parkingSectionId, payload);
        showToast({
          title: 'Паринг обновлен',
          message: `Паркинг ${payload.name} успешно обновлен.`,
        });
        dispatch(reset());
        navigate(generatePath(routes.parkingPlacesList, {apartmentComplexId, parkingId, parkingSectionId}));
      } catch (err) {
        showToast({
          title: 'Паркинг не обновлен',
          message: 'Ошибка обновления паркинга',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div className={sn()}>
      <TitleBlock title="Редактирование паркинга" />
      <ParkingSectionConstructor onSave={onSave} onCancel={onCancel} saving={saving} parkingSection={parkingSection} />
    </div>
  );
};
