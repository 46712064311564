import React, {ChangeEvent} from 'react';
import {FormikTextField, Loader} from 'shared/components';
import styles from './AccessDeviceFrom.module.scss';
import {bem} from 'shared/utils';
import {useFormikContext} from 'formik';
import {AddressBlock} from '../AddressBlock';
import {Checkbox, FormControlLabel} from '@mui/material';
import {AccessDeviceFormValues} from '../../types';

const sn = bem('accessDeviceForm', styles);

type Props = {
  loading?: boolean;
};

export const AccessDeviceForm = ({loading}: Props) => {
  const {setFieldValue, values, errors} = useFormikContext<AccessDeviceFormValues>();

  const onToggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('hidden', e.target.checked);
  };

  return (
    <div>
      {loading ? (
        <div className={sn('loadingWrap')}>
          <Loader />
        </div>
      ) : (
        <div>
          <div className={sn('item')}>
            <FormikTextField
              name="name"
              label="Название устройства доступа"
              classNames={sn('field')}
              required
              error={!!errors.name}
            />
          </div>
          <div className={sn('item')}>
            <FormikTextField
              name="triggerUrl"
              label="Url"
              classNames={sn('field')}
              required
              error={!!errors.triggerUrl}
            />
          </div>
          <div className={sn('item')}>
            <FormikTextField name="restartUrl" label="Url для перезагрузки" classNames={sn('field')} />
          </div>
          <div className={sn('item hiddenCheckbox')}>
            <FormControlLabel
              control={<Checkbox checked={values.hidden} onChange={onToggleCheckbox} />}
              label="Скрыть устр-во доступа в приложении"
            />
          </div>
          <AddressBlock />
        </div>
      )}
    </div>
  );
};
