import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {FC} from 'react';

import {useRootDispatch} from 'store';
import {signOut} from 'store/authStore/actions';

import styles from './LogoutButton.module.scss';

export const LogoutButton: FC = () => {
  const dispatch = useRootDispatch();

  const onSignOut = () => {
    dispatch(signOut());
  };

  return (
    <button className={styles.logoutBtn} onClick={onSignOut}>
      <ExitToAppIcon />
      <span>Выход</span>
    </button>
  );
};
