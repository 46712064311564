import {GridCellParams, GridPaginationModel} from '@mui/x-data-grid';
import {useEffect, useState} from 'react';

import {CamerasApi} from 'api/services/camerasService';
import {Table} from 'shared/components';
import {CameraItemModel, CameraLocationType, WithPagination} from 'shared/models';

import {initialVideoCameraList} from './constants';
import {columns} from './utils';
import {bem} from 'shared/utils';
import styles from './VideoCameraListModal.module.scss';
import {Modal} from '../Modal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAddCamera: (ids: number[]) => void;
  courtyardGateId?: number;
  buildingId?: number;
  entryNumber?: number;
  cameraLocation: CameraLocationType;
};

export type CameraModelExtend = Omit<CameraItemModel, 'location'> & {
  id: number;
  checked: boolean;
};

const sn = bem('videoCameraListModal', styles);

export const VideoCameraListModal = ({
  onClose,
  isOpen,
  onAddCamera,
  courtyardGateId,
  entryNumber,
  buildingId,
  cameraLocation,
}: Props) => {
  const [cameras, setCameras] = useState<WithPagination<CameraModelExtend>>(initialVideoCameraList);
  const [selectedCamera, setSelectedCamera] = useState<CameraModelExtend | null>(null);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({pageSize: 50, page: 0});
  const title = cameraLocation === CameraLocationType.courtyard ? 'Список камер для видеозвонка' : 'Список камер';

  useEffect(() => {
    getCameras();
  }, [isOpen, paginationModel]);

  const onReset = () => {
    setSelectedCamera(null);
    setCameras(initialVideoCameraList);
  };

  const onCancel = () => {
    onClose();
    onReset();
  };

  const onSave = async () => {
    if (selectedCamera) {
      try {
        setLoading(true);
        const cameraIds = [selectedCamera.cameraId];
        await onAddCamera(cameraIds);
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  };

  function handleSwitchChange(params: GridCellParams<CameraModelExtend>) {
    const updatedData = cameras.items.map((item) => {
      if (item.id === params.row.id) {
        const camera = {...item, checked: !params.row.checked};
        setSelectedCamera(!params.row.checked ? camera : null);
        return camera;
      } else {
        return {...item, checked: false};
      }
    });
    setCameras({...cameras, items: updatedData});
  }

  const onCellClick = (params: GridCellParams<CameraModelExtend>) => {
    if (params.field === 'add') {
      handleSwitchChange(params);
    }
  };

  const getCameras = async () => {
    try {
      setLoading(true);
      const res = await CamerasApi.getCamerasForLinking({
        page: paginationModel.page + 1,
        size: paginationModel.pageSize,
        cameraLocation,
        courtyardGateId,
        buildingId,
        entryNumber,
      });
      const preparedCameras = res.items.map((camera) => ({...camera, id: camera.cameraId, checked: false}));
      setCameras({...res, items: preparedCameras});
    } finally {
      setLoading(false);
    }
  };

  const updatePaginationModel = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      title={title}
      maxWidth="lg"
      disableSave={!selectedCamera || loading}
      onSave={onSave}
      onCancel={onCancel}
    >
      {!buildingId && !courtyardGateId ? (
        <div className={sn('emptyAddress')}>
          Укажите адрес устройства, чтобы можно было загрузить камеры, относящиеся к данному адресу.
        </div>
      ) : (
        <div className={sn()}>
          <Table
            loading={loading}
            styles={sn('table')}
            data={cameras}
            columns={columns}
            onCellClick={onCellClick}
            paginationModel={paginationModel}
            updatePaginationModel={updatePaginationModel}
          />
        </div>
      )}
    </Modal>
  );
};
