import {FormValues} from './ApartmentComplexForm';

export const initialValues: FormValues = {
  name: '',
  streetId: null,
  streetAddress: '',
  streetNumber: '',
  type: undefined,
};

export const ERROR_NAME_NOT_AVAILABLE = 'ЖК с таким названием уже существует';
