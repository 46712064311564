import {Fence, Delete as DeleteSvg, Edit as EditSvg} from '@mui/icons-material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import classNames from 'classnames';

import styles from 'shared/components/Table/Table.module.scss';
import {bem} from 'shared/utils';

const sn = bem('table', styles);

type Props = {
  isDisabled: boolean;
};

export const getColumns = ({isDisabled}: Props): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Название парковки',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      renderCell: (params: GridRenderCellParams) => (
        <div className={sn('cell_name')}>
          <Fence />
          <div className={sn('name')}>{params.value}</div>
        </div>
      ),
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'buildingsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во паркингов ',
      width: 150,
    },
    {
      field: 'activeApartmentsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во исп-мых парков. мест',
      width: 230,
    },
    {
      field: 'edit',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: '',
      sortable: false,
      filterable: false,
      width: 50,
      renderCell: () => (
        <div className={sn('cell_edit', classNames({cell_disabled: isDisabled}))}>
          <EditSvg />
        </div>
      ),
    },
    {
      field: 'delete',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: '',
      sortable: false,
      filterable: false,
      width: 50,
      renderCell: () => (
        <div className={sn('cell_delete', classNames({cell_disabled: isDisabled}))}>
          <DeleteSvg />
        </div>
      ),
    },
  ];
};
