import {useState} from 'react';

import {OfficesApi} from 'api';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {useToast} from 'shared/hooks';
import {NewOffice} from 'shared/models';

import {OfficeForm} from '../OfficeForm';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  onAfterAdd: () => void;
};

const confirmPayload = {
  title: 'Подтверждение действия',
  description: 'Вы действительно хотите выйти из редактирования офиса без сохранения изменений?',
  acceptText: 'Выйти без сохранения',
};

export const AddOffice = ({isOpen, closeModal, onAfterAdd}: Props) => {
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const {showToast} = useToast();
  const {confirm} = useConfirm();

  const onSave = async (payload: NewOffice) => {
    try {
      setSaving(true);
      const res = await OfficesApi.addOffice(payload);
      showToast({
        title: 'Офис добавлен',
        message: `Офис по адресу ${res.address || ''} добавлен`,
        isError: false,
      });
      onAfterAdd();
      closeModal();
    } catch (err) {
      showToast({
        title: 'Офис не добавлен',
        message: 'Ошибка при добавлении офиса',
        isError: true,
      });
    } finally {
      setSaving(false);
    }
  };

  const onCancel = async () => {
    if (!isDirty) {
      closeModal();
      return;
    }
    if (await confirm(confirmPayload)) {
      closeModal();
    }
  };

  return (
    <OfficeForm
      saving={saving}
      updateDirty={setIsDirty}
      title="Добавление офиса"
      isOpen={isOpen}
      closeModal={onCancel}
      onSave={onSave}
    />
  );
};
