import {Delete as DeleteIcon, Edit as EditIcon} from '@mui/icons-material';
import {GridColDef} from '@mui/x-data-grid';
import classNames from 'classnames';

import {bem} from 'shared/utils';

import styles from './TenantsTable.module.scss';

const sn = bem('tenantsTable', styles);

type Props = {
  isDisabled: boolean;
};

export const getColumns = ({isDisabled}: Props): GridColDef[] => {
  return [
    {
      field: 'fullName',
      headerName: 'Пользователь',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'createDate',
      headerName: 'Дата регистрации',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      maxWidth: 180,
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'phoneNumber',
      headerName: 'Телефон',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      maxWidth: 200,
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      maxWidth: 200,
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'edit',
      headerName: '',
      headerClassName: sn('emptyHeader'),
      cellClassName: sn('cell'),
      renderCell: () => (
        <div className={sn('cell_edit', classNames({cell_disabled: isDisabled}))}>
          <EditIcon />
        </div>
      ),
      maxWidth: 50,
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    {
      field: 'delete',
      headerName: '',
      headerClassName: sn('emptyHeader'),
      cellClassName: sn('cell'),
      renderCell: () => (
        <div className={sn('cell_delete', classNames({cell_disabled: isDisabled}))}>
          <DeleteIcon />
        </div>
      ),
      maxWidth: 50,
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
  ];
};
