import * as Yup from 'yup';

import {passwordHelperText} from 'shared/components/AccountForm/constants';
import {REGEXP_PASSWORD, REGEXP_PHONE} from 'shared/constants/common';

export const validation = (isEditing: boolean) =>
  Yup.object().shape({
    username: Yup.string().required('Поле обязателено для заполнения'),
    email: Yup.string().required('Поле обязателено для заполнения'),
    phoneNumber: Yup.string()
      .test('phoneNumber', 'Номер может начинаться с 8 или 7, длина не должна превышать 11 символов ', (phoneNumber) => {
        const reg = new RegExp(REGEXP_PHONE);
        return phoneNumber ? !!reg.exec(phoneNumber) : false;
      })
      .required('Поле обязателено для заполнения'),
    password: isEditing
      ? Yup.string().notRequired()
      : Yup.string()
        .test('password', passwordHelperText, (password) => {
          const reg = new RegExp(REGEXP_PASSWORD);
          return password ? !!reg.exec(password)?.[0] : false;
        })
        .required('Поле обязателено для заполнения'),
    firstName: Yup.string().required('Поле обязателено для заполнения'),
    secondName: Yup.string().required('Поле обязателено для заполнения'),
    lastName: Yup.string().required('Поле обязателено для заполнения'),
  });
