import {CourtyardModel, ParkingModel} from 'shared/models';
import React, {useEffect, useState} from 'react';
import {CourtyardAutocomplete} from 'shared/components';
import {CourtyardType} from 'shared/constants/common';
import {CourtyardsServiceApi} from 'api';
import {useFormikContext} from 'formik';
import {AccessDeviceFormValues} from '../../../../types';

export const Parking = () => {
  const [parking, setParking] = useState<ParkingModel | null | undefined>(undefined);
  const {setFieldValue, values, errors, validateField} = useFormikContext<AccessDeviceFormValues>();

  useEffect(() => {
    if (parking === undefined && values.address.apartmentComplexId && values.address.courtyardId) {
      getParking(values.address.apartmentComplexId, values.address.courtyardId);
    }
  }, [parking, values.address.apartmentComplexId, values.address.courtyardId]);

  const getParking = async (apartmentComplexId: number, parkingId: number) => {
    const res = await CourtyardsServiceApi.getCourtyard(apartmentComplexId, parkingId);
    setParking(res);
  };

  const updateParking = (model: CourtyardModel | null) => {
    setFieldValue('address', {...values.address, courtyardId: model?.courtyardId || null});
    validateField('address.courtyardId');
    setParking(model);
  };

  return (
    <CourtyardAutocomplete
      courtyard={parking || null}
      updateCourtyard={updateParking}
      apartmentComplexId={values.address.apartmentComplexId}
      courtyardType={CourtyardType.parking}
      label="Название парковки"
      errorText={errors?.address?.courtyardId}
      disable={!values.address.apartmentComplexId}
    />
  );
};
