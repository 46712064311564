import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import React, {Dispatch, SetStateAction, useState} from 'react';

import {ApartmentComplexesModel, CourtyardModel, ParkingSectionItemModel} from '../../../../models';
import {bem} from '../../../../utils';
import {ApartmentAutocomplete} from '../../../ApartmentAutocomplete';
import {CourtyardAutocomplete} from '../../../CourtyardAutocomplete';
import styles from './ParkingAddress.module.scss';
import {CameraAddressType, PARKING_ADDRESS_TYPE_LIST} from '../../constants';
import {CourtyardType} from 'shared/constants/common';
import {ParkingSectionAutocomplete} from '../../../ParkingSectionAutocomplete';
import {ParkingSectionEntrance, ParkingSectionEntranceAutocomplete} from '../../../ParkingSectionEntranceAutocomplete';
import {AutocompleteField} from '../../../AutocompleteField';
import {CameraParkingAddress, ParkingAddressErrors, ParkingSectionFloor} from '../../types';

const sn = bem('parkingAddress', styles);

type Props = {
  cameraAddressType: CameraAddressType;
  updateCameraAddressType: (value: CameraAddressType) => void;
  selectedAddress: CameraParkingAddress;
  updateSelectedAddress: Dispatch<SetStateAction<CameraParkingAddress>>;
  parkingAddressErrors: ParkingAddressErrors;
  updateParkingAddressErrors: Dispatch<SetStateAction<ParkingAddressErrors>>;
};

// TODO: разбить на компоненты
export const ParkingAddress = ({
  cameraAddressType,
  updateCameraAddressType,
  updateSelectedAddress,
  selectedAddress,
  parkingAddressErrors,
  updateParkingAddressErrors,
}: Props) => {
  const [defaultFloorsOptions, setDefaultFloorsOptions] = useState<ParkingSectionFloor[]>([]);

  const {
    models: {apartmentComplex, parking, parkingSection, entryNumber: entryNumberModel, floorNumber: floorNumberModel},
    ids: {apartmentComplexId, parkingId, parkingSectionId, entryNumber},
  } = selectedAddress;

  const showParkingSection = ![CameraAddressType.parking].includes(cameraAddressType);
  const showEntrance = [CameraAddressType.parkingSectionEntrance].includes(cameraAddressType);
  const showFloor = [CameraAddressType.parkingSectionFloor].includes(cameraAddressType);

  const handleRadioChange = (value: CameraAddressType) => {
    updateCameraAddressType(value);
  };

  const updateApartmentComplex = (value: ApartmentComplexesModel | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, apartmentComplexId: value?.id || null},
      models: {...prev.models, apartmentComplex: value},
    }));
    if (apartmentComplex?.id !== value?.id) updateParking(null);
  };

  const updateParking = (value: CourtyardModel | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, parkingId: value?.courtyardId || null},
      models: {...prev.models, parking: value},
    }));
    if (parkingAddressErrors.parkingErrorText) {
      updateParkingAddressErrors((prev) => ({...prev, parkingErrorText: ''}));
    }
    if (parking?.courtyardId !== value?.courtyardId) updateParkingSection(null);
  };

  const updateParkingSection = async (value: ParkingSectionItemModel | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, parkingSectionId: value ? Number(value.buildingId) : null},
      models: {...prev.models, parkingSection: value},
    }));

    if (value) {
      // TODO: передвинуть в компонент с выбором этажей
      const preparedFloorsOptions = new Array(value.floorsCount)
        .fill(0)
        .map((_, i) => ({name: String(i + 1), value: String(i + 1), label: String(i + 1)}));
      setDefaultFloorsOptions(preparedFloorsOptions);
    }
    if (parkingAddressErrors.parkingSectionErrorText) {
      updateParkingAddressErrors((prev) => ({...prev, parkingSectionErrorText: ''}));
    }
    if (parkingSectionId !== value?.buildingId) updateEntrance(null);
  };

  const updateEntrance = (value: ParkingSectionEntrance | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, entryNumber: value ? Number(value?.value) : null},
      models: {...prev.models, entryNumber: value},
    }));
    if (parkingAddressErrors.entryNumberErrorText) {
      updateParkingAddressErrors((prev) => ({...prev, entryNumberErrorText: ''}));
    }
    if (entryNumber !== Number(value?.value)) updateFloor(null);
  };

  const updateFloor = (value: ParkingSectionFloor | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, floorNumber: value ? Number(value?.value) : null},
      models: {...prev.models, floorNumber: value},
    }));
    if (parkingAddressErrors.floorNumberErrorText) {
      updateParkingAddressErrors((prev) => ({...prev, floorNumberErrorText: ''}));
    }
  };

  return (
    <div>
      <div className={sn('radioGroupWrap')}>
        <RadioGroup className={sn('radioGroup')} name="cameraAddress" value={cameraAddressType}>
          {PARKING_ADDRESS_TYPE_LIST.map((type) => (
            <FormControlLabel
              key={type.value}
              value={type.value}
              onClick={() => handleRadioChange(type.value)}
              control={<Radio />}
              label={type.name}
            />
          ))}
        </RadioGroup>
      </div>
      <div className={sn('autocompleteRow')}>
        <ApartmentAutocomplete apartmentComplex={apartmentComplex} updateApartmentComplex={updateApartmentComplex} />
        <CourtyardAutocomplete
          courtyard={parking}
          updateCourtyard={updateParking}
          apartmentComplexId={apartmentComplex?.id}
          courtyardType={CourtyardType.parking}
          label="Название парковки"
          disable={!apartmentComplex?.id}
          errorText={parkingAddressErrors.parkingErrorText}
        />
      </div>
      <div className={sn('autocompleteRow')}>
        {showParkingSection && (
          <ParkingSectionAutocomplete
            selectedBuildingId={parkingSectionId}
            parkingSection={parkingSection}
            updateParkingSection={updateParkingSection}
            required={showParkingSection}
            apartmentComplexId={apartmentComplex?.id}
            parkingId={parking?.courtyardId}
            errorText={parkingAddressErrors.parkingSectionErrorText}
          />
        )}
        {showEntrance && (
          <ParkingSectionEntranceAutocomplete
            apartmentComplexId={apartmentComplexId || undefined}
            parkingId={parkingId || undefined}
            parkingSectionId={parkingSectionId || undefined}
            selectedEntranceNumber={entryNumber}
            entrance={entryNumberModel}
            updateEntrance={updateEntrance}
            required={showEntrance}
            disabled={!parkingSection?.buildingId}
            errorText={parkingAddressErrors.entryNumberErrorText}
          />
        )}
        {showFloor && (
          <AutocompleteField<ParkingSectionFloor>
            label="Номер этажа"
            value={floorNumberModel}
            updateValue={updateFloor}
            defaultOptions={defaultFloorsOptions}
            required={showFloor}
            disabled={!parkingSection?.buildingId}
            errorText={parkingAddressErrors.floorNumberErrorText}
          />
        )}
      </div>
    </div>
  );
};
