import {useRootDispatch, useRootSelector} from 'store';
import { setPaginationModel} from '../../store';
import {PaymentsTable} from '../PaymentsTable';
import {GridPaginationModel} from '@mui/x-data-grid';

type Props = {
  loading: boolean;
};

export const PaymentsListTable = ({loading}: Props) => {
  const data = useRootSelector((state) => state.paymentsList.payments);
  const paginationModel = useRootSelector((state) => state.paymentsList.paginationModel);
  const dispatch = useRootDispatch();


  const updatePaginationModel = (model: GridPaginationModel) => {
    dispatch(setPaginationModel(model));
  };


  return (
    <PaymentsTable
      loading={loading}
      data={data}
      paginationModel={paginationModel}
      updatePaginationModel={updatePaginationModel}
      onAfterDelete={() => {
      }}
      onClickEdit={() => {
      }}
    />
  );
};
