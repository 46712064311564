import {BreadCrumbs} from '../../components/BreadCrumbs';
import {bem} from '../../utils';
import {ReactElement} from 'react';
import styles from './TitleTabs.module.scss';
import {BreadcrumbsList} from '../../hooks';

const sn = bem('titleTabs', styles);

type Props = {
  action: ReactElement;
  breadcrumbs: BreadcrumbsList[];
  goToCourtyard: () => void;
  goToParking: () => void;
  activeTab: 'parking' | 'courtyard';
};

export const TitleTabs = ({action, breadcrumbs, goToCourtyard, goToParking, activeTab}: Props) => {
  return (
    <>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <div className={sn('container')}>
        <div className={sn('title')}>
          <h1 className={sn('title_color', 'title_link', activeTab === 'courtyard' && 'title_active')} onClick={goToCourtyard}>
            Дворы
          </h1>
          <h1 className={sn('title_color')}>/</h1>
          <h1 className={sn('title_color', 'title_link', activeTab === 'parking' && 'title_active')} onClick={goToParking}>
            Парковки
          </h1>
        </div>
        <div>{action}</div>
      </div>
    </>
  );
};
