import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import React, {Dispatch, SetStateAction} from 'react';

import {ApartmentComplexesModel, CourtyardModel, GateModel} from '../../../../models';
import {bem} from '../../../../utils';
import {ApartmentAutocomplete} from '../../../ApartmentAutocomplete';
import {EntryModelAutocomplete, FloorModelAutocomplete, HouseModelAutocomplete} from '../../../AutocompleteField/utils';
import {CourtyardAutocomplete} from '../../../CourtyardAutocomplete';
import {EntryAutocomplete} from '../../../EntryAutocomplete';
import {FloorAutocomplete} from '../../../FloorAutocomplete';
import {GateAutocomplete} from '../../../GateAutocomplete';
import {HouseAutocomplete} from '../../../HouseAutocomplete';
import {CAMERA_ADDRESS_TYPE_LIST, CameraAddressType} from '../../constants';
import styles from './CourtyardAddress.module.scss';
import {CameraCourtyardAddress, CourtyardAddressErrors} from '../../types';

const sn = bem('courtyardAddress', styles);

type Props = {
  selectedAddress: CameraCourtyardAddress;
  updateSelectedAddress: Dispatch<SetStateAction<CameraCourtyardAddress>>;
  cameraAddressType: CameraAddressType;
  updateCameraAddressType: (value: CameraAddressType) => void;
  courtyardAddressErrors: CourtyardAddressErrors;
  updateCourtyardAddressErrors: Dispatch<SetStateAction<CourtyardAddressErrors>>;
};

// TODO: разбить на компоненты
export const CourtyardAddress = ({
  selectedAddress,
  updateSelectedAddress,
  cameraAddressType,
  updateCameraAddressType,
  courtyardAddressErrors,
  updateCourtyardAddressErrors,
}: Props) => {
  const showHouse = [CameraAddressType.house, CameraAddressType.entry, CameraAddressType.floor].includes(
    cameraAddressType,
  );
  const showEntry = [CameraAddressType.entry, CameraAddressType.floor].includes(cameraAddressType);

  const {
    models: {
      apartmentComplex,
      courtyard,
      courtyardGate,
      building,
      entryNumber: entryNumberModel,
      floorNumber: floorNumberModel,
    },
    ids: {apartmentComplexId, courtyardId, buildingId, entryNumber, floorNumber},
  } = selectedAddress;

  const handleRadioChange = (value: CameraAddressType) => {
    updateCameraAddressType(value);
  };

  const updateApartmentComplex = (value: ApartmentComplexesModel | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, apartmentComplexId: value?.id || null},
      models: {...prev.models, apartmentComplex: value},
    }));
    updateCourtyard(null);
  };

  const updateCourtyard = (value: CourtyardModel | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, courtyardId: value?.courtyardId || null},
      models: {...prev.models, courtyard: value},
    }));
    if (courtyardAddressErrors.courtyardErrorText) {
      updateCourtyardAddressErrors((prev) => ({...prev, courtyardErrorText: ''}));
    }
    updateHouse(null);
  };

  const updateHouse = (value: HouseModelAutocomplete | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, buildingId: value ? Number(value.buildingId) : null},
      models: {...prev.models, building: value},
    }));
    if (courtyardAddressErrors.buildingErrorText) {
      updateCourtyardAddressErrors((prev) => ({...prev, buildingErrorText: ''}));
    }
    updateGate(null);
  };

  const updateGate = (value: GateModel | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, courtyardGateId: value?.courtyardGateId || null},
      models: {...prev.models, courtyardGate: value},
    }));
    if (courtyardAddressErrors.courtyardGateErrorText) {
      updateCourtyardAddressErrors((prev) => ({...prev, courtyardGateErrorText: ''}));
    }
    updateEntry(null);
  };

  const updateEntry = (value: EntryModelAutocomplete | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, entryNumber: value?.entryNumber || null},
      models: {...prev.models, entryNumber: value},
    }));
    if (courtyardAddressErrors.entryNumberErrorText) {
      updateCourtyardAddressErrors((prev) => ({...prev, entryNumberErrorText: ''}));
    }
    updateFloor(null);
  };

  const updateFloor = (value: FloorModelAutocomplete | null) => {
    updateSelectedAddress((prev) => ({
      ...prev,
      ids: {...prev.ids, floorNumber: value?.floorNumber || null},
      models: {...prev.models, floorNumber: value},
    }));
    if (courtyardAddressErrors.floorNumberErrorText) {
      updateCourtyardAddressErrors((prev) => ({...prev, floorNumberErrorText: ''}));
    }
  };

  return (
    <div className={sn()}>
      <div className={sn('radioGroupWrap')}>
        <RadioGroup name="cameraAddress" className={sn('radioGroup')} value={cameraAddressType}>
          {CAMERA_ADDRESS_TYPE_LIST.map((type) => (
            <FormControlLabel
              key={type.value}
              value={type.value}
              onClick={() => handleRadioChange(type.value)}
              control={<Radio />}
              label={type.name}
            />
          ))}
        </RadioGroup>
      </div>
      <div className={sn('autocompleteRow')}>
        <ApartmentAutocomplete apartmentComplex={apartmentComplex} updateApartmentComplex={updateApartmentComplex} />
        <CourtyardAutocomplete
          apartmentComplexId={apartmentComplexId}
          courtyard={courtyard}
          updateCourtyard={updateCourtyard}
          errorText={courtyardAddressErrors.courtyardErrorText}
        />
      </div>
      <div className={sn('autocompleteRow')}>
        {[CameraAddressType.gate].includes(cameraAddressType) && (
          <GateAutocomplete
            apartmentComplexId={apartmentComplexId || undefined}
            courtyardId={courtyardId || undefined}
            gate={courtyardGate}
            updateGate={updateGate}
            required={[CameraAddressType.gate].includes(cameraAddressType)}
            errorText={courtyardAddressErrors.courtyardGateErrorText}
          />
        )}
        {showHouse && (
          <HouseAutocomplete
            apartmentComplexId={apartmentComplexId || undefined}
            courtyardId={courtyardId || undefined}
            house={building}
            updateHouse={updateHouse}
            showHouse={showHouse}
            errorText={courtyardAddressErrors.buildingErrorText}
          />
        )}
        {showEntry && (
          <EntryAutocomplete
            apartmentComplexId={apartmentComplexId || undefined}
            courtyardId={courtyardId || undefined}
            houseId={buildingId ? String(buildingId) : undefined}
            selectedEntryNumber={entryNumber}
            entry={entryNumberModel}
            updateEntry={updateEntry}
            required={showEntry}
            errorText={courtyardAddressErrors.entryNumberErrorText}
          />
        )}
        {cameraAddressType === CameraAddressType.floor && (
          <FloorAutocomplete
            apartmentComplexId={apartmentComplexId || undefined}
            courtyardId={courtyardId || undefined}
            houseId={buildingId ? String(buildingId) : undefined}
            selectedFloorNumber={floorNumber}
            floor={floorNumberModel}
            updateFloor={updateFloor}
            entry={selectedAddress.models.entryNumber}
            cameraAddressType={cameraAddressType}
            required={cameraAddressType === CameraAddressType.floor}
            errorText={courtyardAddressErrors.floorNumberErrorText}
          />
        )}
      </div>
    </div>
  );
};
