import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AccessDevicesStore, PaginationModel} from './types';
import {AccessDeviceItemModel, WithPagination} from 'shared/models';

const slice = createSlice({
  name: 'accessDevicesReducer',
  initialState: {
    searchName: '',
    searchAddress: '',
    isAddAccessDevice: false,
    idEditAccessDevice: null,
    accessDevicesData: {
      total: 0,
      count: 0,
      items: [],
    },
    paginationModel: {pageSize: 15, page: 0},
  } as AccessDevicesStore,
  reducers: {
    setSearchName: (state, action: PayloadAction<string>) => {
      state.searchName = action.payload;
    },
    setSearchAddress: (state, action: PayloadAction<string>) => {
      state.searchAddress = action.payload;
    },
    setIsAddAccessDevice: (state, action: PayloadAction<boolean>) => {
      state.isAddAccessDevice = action.payload;
    },
    setIdEditAccessDevice: (state, action: PayloadAction<number | null>) => {
      state.idEditAccessDevice = action.payload;
    },
    setAccessDeviceData: (state, action: PayloadAction<WithPagination<AccessDeviceItemModel>>) => {
      state.accessDevicesData = action.payload;
    },
    setPaginationModel: (state, action: PayloadAction<PaginationModel>) => {
      state.paginationModel = action.payload;
    },
  },
});

export const {
  actions: {
    setSearchName,
    setSearchAddress,
    setAccessDeviceData,
    setIsAddAccessDevice,
    setIdEditAccessDevice,
    setPaginationModel,
  },
  reducer: accessDevicesReducer,
} = slice;
