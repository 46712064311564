import {number, object, string} from 'yup';
import {IntercomType} from 'shared/models';

export const validation = (type: IntercomType) =>
  object().shape({
    login: string().trim().required('Поле обязателено для заполнения'),
    password: string().trim().required('Поле обязателено для заполнения'),
    host: string().trim().required('Поле обязателено для заполнения'),
    port: string().trim().required('Поле обязателено для заполнения'),
    name: string()
      .trim()
      .max(50, 'Название не может быть больше 50 символов')
      .required('Поле обязателено для заполнения'),
    apartmentComplexId: number().required('Поле обязательно для заполнения'),
    courtyardId: number().required('Поле обязательно для заполнения'),
    buildingId: type === IntercomType.entry ? number().required('Поле обязательно для заполнения') : number().nullable(),
    courtyardGateId: type === IntercomType.gate ? number().required('Поле обязательно для заполнения') : number().nullable(),
    entryNumber: type === IntercomType.entry ? number().required('Поле обязательно для заполнения') : number().nullable(),
  });
