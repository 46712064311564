import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ParkingSectionConstructorStore} from './types';
import {ParkingSectionConstructorFloor, ParkingSectionConstructorModel} from 'shared/models';

const initialState = {
  parkingSection: {
    name: '',
    streetId: null,
    streetNumber: null,
    streetAddress: null,
    entrancesCount: 1,
    parkingSpacesPerFloorCount: 1,
    floors: [{floorNumber: 1, apartmentNumbers: [1], range: '1', quantitySeatsOnFloor: 1}],
  },
} as ParkingSectionConstructorStore;

const slice = createSlice({
  name: 'parkingSectionConstructorReducer',
  initialState: initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.parkingSection.name = action.payload;
    },
    setStreetId: (state, action: PayloadAction<number | null>) => {
      state.parkingSection.streetId = action.payload;
    },
    setStreetAddress: (state, action: PayloadAction<string>) => {
      state.parkingSection.streetAddress = action.payload;
    },
    setStreetNumber: (state, action: PayloadAction<string>) => {
      state.parkingSection.streetNumber = action.payload;
    },
    setEntrancesCount: (state, action: PayloadAction<number>) => {
      state.parkingSection.entrancesCount = action.payload;
    },
    setTariff: (state, action: PayloadAction<number>) => {
      state.parkingSection.tariff = action.payload;
    },
    setParkingSpacesPerFloorCount: (state, action: PayloadAction<number>) => {
      state.parkingSection.parkingSpacesPerFloorCount = action.payload;
    },
    setFloors: (state, action: PayloadAction<ParkingSectionConstructorFloor[]>) => {
      state.parkingSection.floors = action.payload;
    },
    setParkingSection: (state, action: PayloadAction<ParkingSectionConstructorModel>) => {
      state.parkingSection = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  actions: {
    setName,
    setFloors,
    setParkingSpacesPerFloorCount,
    setEntrancesCount,
    setStreetNumber,
    setStreetId,
    setStreetAddress,
    setParkingSection,
    reset,
    setTariff,
  },
  reducer: parkingSectionConstructorReducer,
} = slice;
