import {Entry, Floors, FloorType} from './types';

import {EntranceModel, FloorModel} from '../../models';

export const getUpdatedRestEntries = (entries: Entry[], startingIndex?: number) => {
  entries.forEach((e, index) => {
    if (!startingIndex || index > startingIndex) {
      const thisEntry = entries[index];
      if (index !== 0) {
        thisEntry.firstApartmentNumber = entries[index - 1].lastApartmentNumber + 1;
      }
      entries[index].entryNumber = index + 1;
      entries[index].id = index;
      entries[index] = getUpdatedEntry(thisEntry);
    }
  });

  return entries;
};

export const getApartmentNumbers = (first: number, last: number): number[] => {
  const result = [];
  let prev = first;
  while (prev <= last) {
    result.push(prev);
    prev += 1;
  }
  return result;
};

export const getUpdatedEntry = (entry: Entry): Entry => {
  let prev: null | number = null;
  entry.floors.forEach((floor, i) => {
    const thisFloor = entry.floors[i];
    if (floor.floorType === FloorType.residential) {
      if (!prev) prev = entry.firstApartmentNumber;
      const next = prev + thisFloor.quantityApartments;
      let apartmentRange = next - 1 > prev ? `${prev} — ${next - 1}` : String(prev);
      if (thisFloor.quantityApartments === 0) apartmentRange = '—';
      thisFloor.apartmentRange = apartmentRange;
      thisFloor.apartmentNumbers = getApartmentNumbers(prev, next - 1);
      prev = next;
    } else {
      thisFloor.apartmentRange = '—';
      thisFloor.apartmentNumbers = [];
    }
  });
  if (prev) {
    entry.lastApartmentNumber = prev - 1;
  }

  return entry;
};

export const generateFloors = (quantityFloors: number, quantityApartments: number, firstApartmentNumber: number) => {
  const floor = {
    floorType: FloorType.residential,
    quantityApartments: quantityApartments,
  };

  return new Array(quantityFloors).fill(quantityFloors).map((_, i) => {
    const first = firstApartmentNumber + quantityApartments * i;
    const last = firstApartmentNumber + quantityApartments * (i + 1) - 1;
    const apartmentRange = `${first} — ${last}`;
    const apartmentNumbers = getApartmentNumbers(first, last);
    return {...floor, apartmentRange, apartmentNumbers, floorNumber: i + 1, id: i};
  });
};

export const generateEntries = (quantityEntries: number, quantityFloors: number, quantityApartmentsOnFloor: number) => {
  const entry = {
    quantityFloors,
    quantityApartmentsOnFloor,
    sameNumberApartments: false,
  };

  return new Array(quantityEntries).fill(quantityEntries).map((_, i) => {
    const firstApartmentNumber = quantityFloors * quantityApartmentsOnFloor * i + 1;
    const lastApartmentNumber = quantityFloors * quantityApartmentsOnFloor * (i + 1);
    return {
      ...entry,
      id: i,
      entryNumber: i + 1,
      firstApartmentNumber,
      lastApartmentNumber,
      floors: generateFloors(quantityFloors, quantityApartmentsOnFloor, firstApartmentNumber),
    };
  });
};

export const preparedFloors = (floors: Floors[]): FloorModel[] =>
  floors.map(({floorNumber, apartmentNumbers, floorType}) => {
    return {floorNumber, apartmentNumbers, floorType};
  });

export const preparedEntries = (entries: Entry[]): EntranceModel[] =>
  entries.map(({entryNumber, floors}) => {
    return {entryNumber, floors: preparedFloors(floors)};
  });

export const initialHouseSettings = {
  address: '',
  streetId: null,
  streetNumber: '',
  quantityEntries: 4,
  quantityFloors: 10,
  quantityApartmentsOnFloor: 4,
  entries: generateEntries(4, 10, 4),
  tariff: null
};
