import {ReactNode, useState} from 'react';
import {createPortal} from 'react-dom';

import {ConfirmationContext} from './ConfirmationContext';
import styles from './ConfirmationPopup.module.scss';
import {ConfirmPayload, useConfirm} from './useConfirm';

import {bem} from '../../utils';
import {Modal} from '../Modal';

type Props = {
  children: ReactNode;
};

export const initConfirm = {
  title: 'Подтверждение действия',
  description: '',
  acceptText: 'Подтвердить',
  cancelText: 'Отменить',
};

const sn = bem('confirmationPopup', styles);

export const ConfirmationProvider = ({children}: Props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmPayload, setConfirmPayload] = useState<ConfirmPayload>(initConfirm);
  const {accept, cancel} = useConfirm();

  const onCancel = () => {
    setShowConfirm(false);
    cancel();
  };

  const onAccept = () => {
    setShowConfirm(false);
    accept();
  };

  const displayConfirm = (payload: ConfirmPayload) => {
    setConfirmPayload({...confirmPayload, ...payload});
    setShowConfirm(true);
  };
  return (
    <ConfirmationContext.Provider value={{displayConfirm}}>
      {children}
      {showConfirm &&
        createPortal(
          <Modal
            title={confirmPayload.title}
            onSave={onAccept}
            isOpen={showConfirm}
            onCancel={onCancel}
            onClose={onCancel}
            cancelButtonText={confirmPayload.cancelText}
            saveButtonText={confirmPayload.acceptText}
          >
            <div className={sn('description')} dangerouslySetInnerHTML={{__html: confirmPayload.description}} />
          </Modal>,
          document.body,
        )}
    </ConfirmationContext.Provider>
  );
};
