import {Switch} from '@mui/material';
import {GridRenderCellParams} from '@mui/x-data-grid';

import styles from 'shared/components/Table/Table.module.scss';
import {bem} from 'shared/utils';

import {CameraModelExtend} from './VideoCameraListModal';

const sn = bem('table', styles);

export const columns = [
  {
    field: 'name',
    headerName: 'Название камеры',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 300,
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'address',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Адрес',
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'add',
    headerName: 'Добавить',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 200,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<CameraModelExtend>) => (
      <span className={sn('item')}>
        <Switch checked={params.row.checked} />
      </span>
    ),
  },
];
