import {TextField} from '@mui/material';
import {ChangeEvent, useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {CourtyardsServiceApi} from 'api';
import {ERROR_LENGTH, ERROR_SYMBOLS, MAX_LENGTH} from 'modules/Courtyards/constants';
import {Modal} from 'shared/components/Modal';
import {useDebounce, useToast} from 'shared/hooks';
import {CourtyardModel, WithPagination} from 'shared/models';
import {checkAvailability, isInvalidWhitespaceInput} from 'shared/utils';
import {isOnlySpecialChars} from 'shared/utils/checkOnlySpecialChars';

type Props = {
  isOpen: boolean;
  courtyard: CourtyardModel | null;
  courtyards: WithPagination<CourtyardModel>;
  updateCourtyards: (courtyards: WithPagination<CourtyardModel>) => void;
  closeModal: () => void;
};

const ERROR_NAME_NOT_AVAIBLE = 'Двор с таким названием уже существует';

export const EditCourtyard = ({isOpen, courtyard, courtyards, updateCourtyards, closeModal}: Props) => {
  const [isNameAvailable, setIsNameAvailable] = useState(true);
  const [name, setName] = useState(courtyard?.name || '');
  const {apartmentComplexId} = useParams<{apartmentComplexId: string}>();
  const [saving, setSaving] = useState(false);
  const [errorText, setErrorText] = useState('');
  const {showToast} = useToast();

  const onClose = () => {
    if (name) setName('');
    closeModal();
  };

  useEffect(() => {
    if (courtyard?.name) setName(courtyard.name);
  }, [courtyard]);

  const updateCourtyardTable = (newCourtyard: CourtyardModel) => {
    const updatedCourtyards = courtyards.items.map((oldCourtyard) =>
      oldCourtyard.courtyardId === newCourtyard.courtyardId
        ? {...newCourtyard, id: newCourtyard.courtyardId}
        : oldCourtyard,
    );
    updateCourtyards({...courtyards, items: updatedCourtyards});
  };

  const onSave = async () => {
    if (isOnlySpecialChars(name)) {
      setErrorText(ERROR_SYMBOLS);
      return;
    }
    if (apartmentComplexId && courtyard?.courtyardId) {
      try {
        setSaving(true);
        const newCourtyard = await CourtyardsServiceApi.editCourtyard(
          apartmentComplexId,
          {name},
          courtyard.courtyardId,
        );
        if (newCourtyard) updateCourtyardTable(newCourtyard);
        onClose();
        showToast({
          title: 'Двор отредактирован',
          message: `Двор ${courtyard.name} успешно добавлен.`,
        });
      } catch (err) {
        showToast({
          title: 'Двор не отредактирован',
          message: 'Не удалось отредактировать двор.',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const searchName = useDebounce(async (value) => {
    if (!apartmentComplexId) return;
    checkAvailability(
      {name: value, apartmentComplexId: apartmentComplexId},
      'courtyard',
      setIsNameAvailable,
      setSaving,
    );
  }, 1000);

  const checkName = (value: string) => {
    if (value === courtyard?.name) {
      setSaving(false);
      return;
    }
    searchName(value);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (!isInvalidWhitespaceInput(value)) setName(value);
    if (value.length > MAX_LENGTH) setErrorText(ERROR_LENGTH);
    if (value.length <= MAX_LENGTH) setErrorText('');

    setSaving(true);
    setIsNameAvailable(true);
    checkName(value);
  };

  return (
    <Modal
      maxWidth="sm"
      isOpen={isOpen}
      onClose={closeModal}
      onSave={onSave}
      onCancel={onClose}
      title={'Редактирование двора'}
      closeButton
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      disableSave={!name || saving || Boolean(errorText) || !isNameAvailable}
    >
      <TextField
        autoFocus
        margin="dense"
        label="Название двора"
        type="text"
        fullWidth
        required
        value={name}
        error={Boolean(errorText) || !isNameAvailable}
        helperText={errorText || (!isNameAvailable && ERROR_NAME_NOT_AVAIBLE)}
        onChange={onChange}
      />
    </Modal>
  );
};
