import {apiClient} from 'api/client';
import {SignInPayload, SignInResponse} from 'shared/models';

class AuthService {
  private getAuthPath(value: string) {
    return `/auth/${value}`;
  }

  signIn(payload: SignInPayload): Promise<SignInResponse> {
    return apiClient.post(this.getAuthPath('sign-in'), payload).then((res) => res.data);
  }

  signOut() {
    return apiClient.post(this.getAuthPath('sign-out'), null);
  }

  async refreshTokens(): Promise<SignInResponse> {
    return await apiClient.post(this.getAuthPath('refresh'), {}).then((res) => res.data);
  }
}

export const AuthApi = new AuthService();
