import {createContext} from 'react';

import {ConfirmPayload} from './useConfirm';

type ConfirmationContextType = {
  displayConfirm: (payload: ConfirmPayload) => void;
};

export const ConfirmationContext = createContext<ConfirmationContextType>({
  displayConfirm: () => undefined,
});
