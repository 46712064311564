import {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {GatesApi} from 'api';
import {useToast} from 'shared/hooks';
import {GateInfoModel, GateModel, WithPagination} from 'shared/models';

import {FormValues, GateForm} from '../GateForm';

type Props = {
  isOpen: boolean;
  gates: WithPagination<GateModel>;
  editingGateId: number | null;
  updateGates: (courtyards: WithPagination<GateModel>) => void;
  closeModal: () => void;
};

export const EditGate = ({isOpen, gates, editingGateId, updateGates, closeModal}: Props) => {
  const {apartmentComplexId, courtyardId} = useParams<{apartmentComplexId: string; courtyardId: string}>();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gate, setGate] = useState<GateInfoModel | null>(null);
  const {showToast} = useToast();

  const getGate = async () => {
    if (apartmentComplexId && courtyardId && editingGateId) {
      try {
        setLoading(true);
        const gate = await GatesApi.getGate(apartmentComplexId, courtyardId, editingGateId);
        setGate(gate);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (editingGateId) {
      getGate();
    }
  }, [editingGateId]);

  const updateGateTable = (newCourtyard: GateModel) => {
    const updatedGates = gates.items.map((oldGate) =>
      oldGate.courtyardGateId === newCourtyard.courtyardGateId
        ? {...newCourtyard, id: newCourtyard.courtyardGateId}
        : oldGate,
    );
    updateGates({...gates, items: updatedGates});
  };

  const onEdit = async (values: FormValues) => {
    if (apartmentComplexId && courtyardId && editingGateId) {
      try {
        setSaving(true);
        const {streetAddress, ...payload} = values;
        const newGate = await GatesApi.editGate(apartmentComplexId, courtyardId, editingGateId, payload);
        if (newGate) updateGateTable(newGate);
        showToast({
          title: 'Калитка отредактирована',
          message: `Калитка ${values.name} по адресу ${streetAddress} успешно добавлена.`,
        });
        closeModal();
      } catch (err) {
        showToast({
          title: 'Калитка не отредактирована',
          message: 'Не удалось отредактировать калитку.',
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <GateForm
      isOpen={isOpen}
      title="Редактировать калитку"
      onSave={onEdit}
      loading={loading}
      saving={saving}
      closeModal={closeModal}
      initGate={gate}
      apartmentComplexId={apartmentComplexId}
      courtyardId={courtyardId}
    />
  );
};
