import {Checkbox, TextField} from '@mui/material';
import {useRootDispatch, useRootSelector} from 'store';
import {setFloors, setParkingSpacesPerFloorCount} from '../../../../store';
import {ChangeEvent, useState} from 'react';
import {bem} from 'shared/utils';
import styles from './SettingBar.module.scss';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {getInitFloors} from '../../../../utils';

const sn = bem('settingBar', styles);

const confirmPayload = {
  title: 'Одинаковое кол-во этажей в подъезде',
  description: 'Вы действительно хотите установить одинаковое количество парковочных мест на всех этажах?',
  acceptText: 'Установить одинаковое',
};

export const SettingBar = () => {
  const settings = useRootSelector((state) => state.parkingSectionConstructor.parkingSection);
  const dispatch = useRootDispatch();
  const [sameQuantity, setSameQuantity] = useState(true);
  const {confirm} = useConfirm();

  const onChangeQuantityFloors = (e: ChangeEvent<HTMLInputElement>) => {
    const floorsCount = Number(e.target.value);
    // TODO: добавить сообщение об ограничении кол-ва этажей
    if (isNaN(floorsCount) || floorsCount > 1000) return;
    const preparedFloors = getInitFloors(floorsCount, settings);
    dispatch(setFloors(preparedFloors));
  };

  const onChangeQuantitySeatsOnFloor = (e: ChangeEvent<HTMLInputElement>) => {
    const quantitySeats = Number(e.target.value);
    // TODO: добавить сообщение об ограничении кол-ва мест
    if (isNaN(quantitySeats) || quantitySeats > 5000) return;
    dispatch(setParkingSpacesPerFloorCount(quantitySeats));

    if (sameQuantity) {
      const updatedSettings = {...settings, parkingSpacesPerFloorCount: quantitySeats};
      const preparedFloors = getInitFloors(settings.floors.length, updatedSettings);
      dispatch(setFloors(preparedFloors));
    }
  };

  const onChangeSameQuantitySeats = async (value: boolean) => {
    if (value && (await confirm(confirmPayload))) {
      setSameQuantity(value);
      const preparedFloors = getInitFloors(settings.floors.length, settings);
      dispatch(setFloors(preparedFloors));
    } else {
      setSameQuantity(value);
    }
  };

  return (
    <div className={sn()}>
      <TextField
        name="quantityFloors"
        label="Кол-во этажей"
        margin="normal"
        value={settings.floors.length}
        onChange={onChangeQuantityFloors}
      />
      <TextField
        name="quantityApartmentsOnFloor"
        label="Кол-во парк. мест на этаже"
        margin="normal"
        value={settings.parkingSpacesPerFloorCount}
        onChange={onChangeQuantitySeatsOnFloor}
      />
      <div className={sn('sameQuantityApartments')}>
        <Checkbox checked={sameQuantity} onChange={(e) => onChangeSameQuantitySeats(e.target.checked)} />
        <p>Кол-во парк. мест на этажах одинаковое</p>
      </div>
    </div>
  );
};
