import {Button, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useFormik} from 'formik';
import {SyntheticEvent, useEffect} from 'react';

import {AddressAutocomplete} from 'shared/components';
import {FullStreetsModel} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './FirstStep.module.scss';

import {HouseConstructorTabs, HouseSettings} from '../../types';
import {generateEntries} from '../../utils';
import {firstStepSchema} from '../validation/firstStepSchema';

type Props = {
  changeTab: (value: number) => void;
  houseSettings: HouseSettings;
  updateHouseSettings: (value: HouseSettings) => void;
  onCancel: () => void;
};

type FormValues = {
  address: string;
  streetNumber: string;
  streetId: number | null;
  quantityEntries: number;
  quantityApartmentsOnFloor: number;
  quantityFloors: number;
  tariff: number | null
};

const getInitialValues = (houseSettings: HouseSettings): FormValues => {
  return {
    address: houseSettings.address,
    streetNumber: houseSettings.streetNumber,
    streetId: houseSettings.streetId,
    quantityEntries: houseSettings.quantityEntries,
    quantityApartmentsOnFloor: houseSettings.quantityApartmentsOnFloor,
    quantityFloors: houseSettings.quantityFloors,
    tariff: houseSettings.tariff,
  };
};

const sn = bem('firstStep', styles);

type AddressOptionType = FullStreetsModel & { fullAddress: string };

export const FirstStep = ({changeTab, houseSettings, updateHouseSettings, onCancel}: Props) => {
  const onNextStep = () => {
    changeTab(HouseConstructorTabs.second);
  };

  const {values, handleSubmit, setFieldValue, touched, errors, setValues} = useFormik({
    initialValues: getInitialValues(houseSettings),
    validationSchema: firstStepSchema,
    onSubmit: onNextStep,
  });

  const onAddressSelect = (e: SyntheticEvent<Element, Event>, newValue: unknown) => {
    const value = newValue as AddressOptionType;
    const address = value?.fullAddress || '';
    const streetId = value?.id || null;
    setFieldValue('address', address);
    setFieldValue('streetId', streetId);
    const updatedSettings = {...houseSettings, address, streetId};
    updateHouseSettings(updatedSettings);
  };

  const onAddressChange = (value: string) => {
    setValues({...values, address: value});
    setFieldValue('streetId', null);
    updateHouseSettings({...houseSettings, address: value});
  };

  const onChange = (fieldName: string, value: string) => {
    if (['quantityEntries', 'quantityApartmentsOnFloor', 'quantityFloors'].includes(fieldName)) {
      const preparedValue = value ? Number(value) : '';
      setValues({...values, [fieldName]: preparedValue});
    } else {
      setValues({...values, [fieldName]: value});
      updateHouseSettings({...houseSettings, [fieldName]: value});
    }
  };

  useEffect(() => {
    const isDiff =
      houseSettings.quantityEntries !== values.quantityEntries ||
      houseSettings.quantityFloors !== values.quantityFloors ||
      houseSettings.quantityApartmentsOnFloor !== values.quantityApartmentsOnFloor
    if (isDiff) {
      const {quantityEntries, quantityFloors, quantityApartmentsOnFloor} = values;
      houseSettings.quantityEntries = quantityEntries;
      houseSettings.quantityFloors = quantityFloors;
      houseSettings.quantityApartmentsOnFloor = quantityApartmentsOnFloor;
      houseSettings.entries = generateEntries(quantityEntries, quantityFloors, quantityApartmentsOnFloor);
      updateHouseSettings(houseSettings);
    }
  }, [values.quantityEntries, values.quantityApartmentsOnFloor, values.quantityFloors]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.firstStep}>
        <div className={sn('container')}>
          <div className={sn('formContainer')}>
            <AddressAutocomplete
              name="address"
              label="Адрес *"
              value={values.address}
              error={Boolean(errors.streetId)}
              onAddressChange={onAddressChange}
              onAddressSelect={onAddressSelect}
            />
            <div className={sn('row')}>
              <TextField
                name="streetNumber"
                label="Номер дома *"
                margin="normal"
                value={values.streetNumber}
                onChange={(e) => onChange('streetNumber', e.target.value)}
                error={touched.streetNumber && Boolean(errors.streetNumber)}
                helperText={touched.streetNumber && errors.streetNumber}
              />
              <TextField
                name="quantityEntries"
                label="Количество подъездов *"
                type="number"
                margin="normal"
                value={values.quantityEntries}
                onChange={(e) => onChange('quantityEntries', e.target.value)}
                error={touched.quantityEntries && Boolean(errors.quantityEntries)}
                helperText={touched.quantityEntries && errors.quantityEntries}
              />

            </div>
            <div className={sn('row')}>
              <TextField
                name="tariff"
                label="Тариф *"
                type="number"
                margin="normal"
                value={values.tariff }
                onChange={(e) => onChange('tariff', e.target.value)}
                error={touched.tariff && Boolean(errors.tariff)}
                helperText={touched.tariff && errors.tariff}
              />
            </div>
          </div>
          <div className={sn('defaultValue')}>
            <Typography variant="h4">Данные дома по умолчанию</Typography>
          </div>
          <div className={sn('formContainer')}>
            <div className={sn('row')}>
              <TextField
                name="quantityFloors"
                label="Количество этажей"
                type="number"
                margin="normal"
                value={values.quantityFloors}
                onChange={(e) => onChange('quantityFloors', e.target.value)}
              />
              <TextField
                name="quantityApartmentsOnFloor"
                label="Количество квартир на этаже"
                type="number"
                margin="normal"
                value={values.quantityApartmentsOnFloor}
                onChange={(e) => onChange('quantityApartmentsOnFloor', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={sn('footer')}>
          <div className={sn('footer__actions')}>
            <Button variant="contained" className={sn('footer__actions_cancel')} onClick={onCancel}>
              Отмена
            </Button>
            <Button type="submit" variant="contained" color="secondary">
              Следующий шаг
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
