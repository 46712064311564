import {TextField} from '@mui/material';
import {ChangeEvent, useState} from 'react';
import {useParams} from 'react-router';

import {CourtyardsServiceApi} from 'api';
import {ERROR_LENGTH, ERROR_SYMBOLS, MAX_LENGTH} from 'modules/Courtyards/constants';
import {Modal} from 'shared/components/Modal';
import {useToast, useDebounce} from 'shared/hooks';
import {CourtyardModel, WithPagination} from 'shared/models';
import {isInvalidWhitespaceInput, checkAvailability} from 'shared/utils';
import {isOnlySpecialChars} from 'shared/utils/checkOnlySpecialChars';
import {CourtyardType} from 'shared/constants/common';

type Props = {
  isOpen: boolean;
  courtyards: WithPagination<CourtyardModel>;
  updateCourtyards: (courtyards: WithPagination<CourtyardModel>) => void;
  closeModal: () => void;
};

const ERROR_NAME_NOT_AVAIBLE = 'Двор с таким названием уже существует';

export const AddCourtyard = ({isOpen, courtyards, updateCourtyards, closeModal}: Props) => {
  const [isNameAvailable, setIsNameAvailable] = useState(true);
  const [name, setName] = useState('');
  const {apartmentComplexId} = useParams<{apartmentComplexId: string}>();
  const [saving, setSaving] = useState(false);
  const [isNameChecking, setIsNameChecking] = useState(false);
  const [errorText, setErrorText] = useState('');
  const {showToast} = useToast();

  const onClose = () => {
    if (name) setName('');
    closeModal();
  };

  const addCourtyard = (newCourtyard: CourtyardModel) => {
    const updatedCourtyards = [...courtyards.items, {...newCourtyard, id: newCourtyard.courtyardId}];
    updateCourtyards({...courtyards, items: updatedCourtyards});
  };

  const onSave = async () => {
    if (isOnlySpecialChars(name)) {
      setErrorText(ERROR_SYMBOLS);
      return;
    }
    if (apartmentComplexId) {
      try {
        setSaving(true);
        const newCourtyard = await CourtyardsServiceApi.addCourtyard(apartmentComplexId, {
          name,
          type: CourtyardType.courtyard,
        });
        if (newCourtyard) addCourtyard(newCourtyard);
        onClose();
        showToast({
          title: 'Двор добавлен',
          message: `Двор ${name} успешно добавлен.`,
        });
      } catch (err) {
        showToast({
          title: 'Двор не добавлен',
          message: 'Не удалось добавить двор.',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const searchName = useDebounce(async (value) => {
    if (!apartmentComplexId) return;
    setIsNameChecking(true);
    try {
      checkAvailability(
        {name: value, apartmentComplexId: apartmentComplexId},
        'courtyard',
        setIsNameAvailable,
        setSaving,
      );
    } finally {
      setIsNameChecking(false);
    }
  }, 1000);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (!isInvalidWhitespaceInput(value)) setName(value);
    if (value.length > MAX_LENGTH) setErrorText(ERROR_LENGTH);
    if (value.length <= MAX_LENGTH) setErrorText('');

    setSaving(true);
    setIsNameAvailable(true);
    searchName(value);
  };

  return (
    <Modal
      maxWidth="sm"
      isOpen={isOpen}
      onClose={closeModal}
      onSave={onSave}
      onCancel={onClose}
      title={'Добавление двора'}
      closeButton
      loading={isNameChecking || saving}
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      disableSave={!name || saving || Boolean(errorText) || !isNameAvailable}
    >
      <TextField
        autoFocus
        margin="dense"
        label="Название двора"
        type="text"
        fullWidth
        required
        value={name}
        error={Boolean(errorText) || !isNameAvailable}
        helperText={errorText || (!isNameAvailable && ERROR_NAME_NOT_AVAIBLE)}
        onChange={onChange}
      />
    </Modal>
  );
};
