import {FC} from 'react';

import {ReportType} from 'shared/models';
import {LoadReport} from 'shared/components/LoadReport/LoadReport';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {bem} from 'shared/utils';
import styles from './ActiveParkingPlacesReport.module.scss';
import {useBreadcrumbs} from 'shared/hooks';

const sn = bem('activeParkingPlacesReport', styles);

export const ActiveParkingPlacesReport: FC = () => {
  const {activeParkingBreadcrumbs} = useBreadcrumbs();

  return (
    <div className={sn()}>
      <TitleBlock title="Отчет по активному паркингу" breadcrumbs={activeParkingBreadcrumbs} />
      <LoadReport reportType={ReportType.parkingLot} />
    </div>
  );
};
