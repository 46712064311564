import {Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {bem} from '../../../../utils';
import styles from './Footer.module.scss';

const sn = bem('footer', styles);

type Props = {
  onCancel: () => void;
  saving: boolean;
  onSubmit: () => void;
};

export const Footer = ({onCancel, saving, onSubmit}: Props) => {

  return (
    <div className={sn('actions')}>
      <Button variant="contained" onClick={onCancel}>
        Отменить
      </Button>
      <LoadingButton
        onClick={onSubmit}
        variant="contained"
        loading={saving}
        color="secondary"
        className={sn('submitBtn')}
        disabled={saving}
      >
        Сохранить
      </LoadingButton>
    </div>
  );
};
