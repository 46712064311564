import {FC} from 'react';

import {Dropdown} from 'shared/components/Dropdown';
import {Logo} from 'shared/components/Logo';
import {UserAvatar} from 'shared/components/UserAvatar';
import {UserInfo} from 'shared/components/UserInfo';
import {bem} from 'shared/utils';
import {useRootSelector} from 'store';

import {LogoutButton} from './components/LogoutButton';
import styles from './Header.module.scss';

const optionList = [{id: 1, option: <LogoutButton />}];

export const Header: FC = () => {
  const sn = bem('header', styles);
  const profile = useRootSelector((state) => state.auth.profile);

  return (
    <header className={styles.header}>
      <div className={sn('logoGroup')}>
        <Logo />
        <h1 className={sn('title')}>ГК “Регион”</h1>
      </div>
      <Dropdown info={profile ? <UserInfo profile={profile} /> : null} options={optionList}>
        <UserAvatar size="large" />
        <span className={sn('name')}>{profile?.username}</span>
      </Dropdown>
    </header>
  );
};
