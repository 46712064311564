import {Button} from '@mui/material';
import {Formik, FormikProps} from 'formik';
import {useEffect, useRef, useState} from 'react';

import styles from './IntercomForm.module.scss';
import {initialValues, NewIntercomFormValues} from 'modules/AddNewIntercom/utils';
import {validation} from 'modules/AddNewIntercom/validation';
import {useDebounce} from 'shared/hooks';

import {AddressBlock} from './components/AddressBlock';

import {IntercomInfoModel, IntercomType, NewIntercom} from '../../models';
import {bem, checkAvailability} from '../../utils';
import {FormikTextField} from '../FormikTextField';
import {Loader} from '../Loader';

type Props = {
  intercom?: IntercomInfoModel | null;
  loading?: boolean;
  onSave: (payload: Partial<NewIntercom>, address?: string) => void;
  onCancel: () => void;
  saving: boolean;
};

const getPreparedPayload = (values: NewIntercom, intercomType: IntercomType): Partial<NewIntercom> => {
  return {
    name: values.name,
    login: values.login,
    password: values.password,
    port: values.port,
    host: values.host,
    model: 0,
    type: intercomType,
    isInvisible: values.isInvisible,
  };
};

const sn = bem('intercomForm', styles);

const ERROR_NAME_NOT_AVAIBLE = 'Домофон с таким названием уже существует';

export const IntercomForm = ({intercom, onSave, loading, onCancel, saving}: Props) => {
  const [intercomType, setIntercomType] = useState<IntercomType>(IntercomType.entry);
  const [isNameAvailable, setIsNameAvailable] = useState(true);
  const [loadingName, setLoadingName] = useState(false);
  const formik = useRef<FormikProps<NewIntercomFormValues>>(null);
  const [address, setAddress] = useState('');
  const helperTextName =
    formik.current?.touched.name && (formik.current?.errors.name || (!isNameAvailable && ERROR_NAME_NOT_AVAIBLE));

  useEffect(() => {
    if (intercom?.address.buildingId) {
      setIntercomType(IntercomType.entry);
    } else {
      setIntercomType(IntercomType.gate);
    }
  }, [intercom]);

  useEffect(() => {
    if (intercom) {
      const preparedValues = {
        ...intercom,
        apartmentComplexId: intercom.address.apartmentComplexId,
        buildingId: intercom.address.buildingId ? String(intercom.address.buildingId) : null,
        courtyardId: intercom.address.courtyardId,
      };
      formik.current?.setValues(preparedValues);
      setAddress(intercom.address.address);
    }
  }, [intercom]);

  const onBeforeSave = (values: NewIntercom) => {
    const {sipNumber, buildingId, entryNumber, courtyardGateId} = values;
    const payload = getPreparedPayload(values, intercomType);

    if (sipNumber) {
      payload.sipNumber = sipNumber;
    }

    if (intercomType === IntercomType.entry && buildingId) {
      payload.buildingId = String(buildingId);
      payload.entryNumber = entryNumber;
    }
    if (intercomType === IntercomType.gate && courtyardGateId) {
      payload.courtyardGateId = courtyardGateId;
    }
    onSave(payload, address);
  };

  const searchName = useDebounce(async (value) => {
    checkAvailability({name: value}, 'intercom', setIsNameAvailable, setLoadingName);
  }, 1000);

  const checkName = (value: string) => {
    if (value === intercom?.name) {
      setLoadingName(false);
      return;
    }
    searchName(value);
  };

  const onNameChange = (value: string) => {
    formik.current?.setFieldValue('name', value);
    setLoadingName(true);
    setIsNameAvailable(true);
    checkName(value);
  };

  return (
    <Formik
      className={sn('form')}
      innerRef={formik}
      initialValues={initialValues}
      validationSchema={validation(intercomType)}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onBeforeSave}
    >
      {loading ? (
        <div className={sn('wrapLoader')}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={sn('row')}>
            <FormikTextField
              classNames={sn('input')}
              required
              name="name"
              label="Название"
              onChange={onNameChange}
              error={formik.current?.touched.name && (Boolean(formik.current?.errors.name) || !isNameAvailable)}
              helperText={helperTextName}
            />
            <FormikTextField classNames={sn('input')} name="login" label="Логин" required />
            <FormikTextField required classNames={sn('input')} name="password" label="Пароль" />
          </div>
          <div className={sn('row')}>
            {intercom && (
              <FormikTextField
                classNames={sn('input')}
                name="sipNumber"
                label="SIP-номер"
                inputProps={{readOnly: true}}
              />
            )}
            <FormikTextField required classNames={sn('input')} name="host" label="Host" />
            <FormikTextField required classNames={sn('input')} name="port" label="Port" />
          </div>
          <div className={sn('autocompleteGroup')}>
            <AddressBlock intercomType={intercomType} updateIntercomType={setIntercomType} intercom={intercom} />
          </div>
          <div className={sn('actions')}>
            <Button variant="contained" onClick={onCancel}>
              Отменить
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={sn('submitBtn')}
              disabled={formik.current?.isSubmitting || saving || loadingName || !isNameAvailable}
              onClick={formik.current?.submitForm}
            >
              Сохранить
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};
