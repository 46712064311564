import {AxiosPromise} from 'axios';

import {apiClient} from 'api/client';
import {
  AddApartmentComplexPayload,
  ApartmentComplexesModel,
  ApartmentComplexInfoModel,
  GetApartmentComplexesPayload, ReportType,
  RequestWithPagination,
  SearchApartmentComplexes,
  WithPagination,
} from 'shared/models';

class ApartmentComplexesService {
  private getBasePath(value: string) {
    return `/properties/apartment-complexes${value}`;
  }

  getApartmentComplex(apartmentComplexId: number): Promise<ApartmentComplexInfoModel> {
    return apiClient.get(this.getBasePath(`/${apartmentComplexId}`)).then((res) => res.data);
  }

  getApartmentComplexes(payload?: GetApartmentComplexesPayload): Promise<WithPagination<ApartmentComplexesModel>> {
    return apiClient.get(this.getBasePath(''), {params: payload}).then((res) => res.data);
  }

  updateApartmentComplexes(
    apartmentComplexId: number,
    payload: AddApartmentComplexPayload,
  ): Promise<ApartmentComplexesModel> {
    return apiClient
      .put(this.getBasePath(`/${apartmentComplexId}`), payload)
      .then((res) => res.data);
  }

  searchApartmentComplexes(
    payload: RequestWithPagination<SearchApartmentComplexes>,
  ): Promise<WithPagination<ApartmentComplexesModel>> {
    return apiClient.get(this.getBasePath('/search'), {params: payload}).then((res) => res.data);
  }

  addApartmentComplex(payload: AddApartmentComplexPayload): Promise<ApartmentComplexesModel> {
    return apiClient.post(this.getBasePath(''), payload).then((res) => res.data);
  }

  loadReport(params: string, reportType: ReportType): AxiosPromise {
    return apiClient.get(`/reporting/apartments?reportType=${reportType}&${params}`, {responseType: 'arraybuffer'});
  }

  deleteApartmentComplex(apartmentComplexId: string) {
    return apiClient.delete(this.getBasePath(`/${apartmentComplexId}`)).then((res) => res.data);
  }
}

export const ApartmentComplexesApi = new ApartmentComplexesService();
