import {Autocomplete, CircularProgress, TextField} from '@mui/material';
import {ChangeEvent, SyntheticEvent, useEffect, useMemo, useState} from 'react';

import {DELAY} from 'shared/constants/delay';
import {useDebounce} from 'shared/hooks';

type Props<T> = {
  label: string;
  classNames?: string;
  value: T | null;
  updateValue: (value: T | null) => void;
  getOptions?: (value: string) => Promise<T[]>;
  defaultOptions?: T[];
  required?: boolean;
  loadingDefaultOptions?: boolean;
  disabled?: boolean;
  errorText?: string;
};

export const AutocompleteField = <T extends {name: string}>({
  label,
  classNames,
  value,
  updateValue,
  getOptions,
  defaultOptions,
  required,
  loadingDefaultOptions,
  disabled,
  errorText,
}: Props<T>) => {
  const [options, setOption] = useState<T[]>(defaultOptions || []);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (defaultOptions) {
      const preparedOptions = defaultOptions.map((a) => ({...a, label: a.name}));
      setOption(preparedOptions);
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (value?.name && value?.name !== searchText) {
      setSearchText(value?.name);
    }
  }, [value, searchText]);

  useEffect(() => {
    if (!value) {
      setSearchText('');
    }
  }, [value]);

  const search = useDebounce(async (value) => {
    if (typeof getOptions !== 'function') return;
    try {
      setLoading(true);
      const options = await getOptions(value);
      const preparedOptions = options.map((a) => ({...a, label: a.name}));
      setOption(preparedOptions);
    } finally {
      setLoading(false);
    }
  }, DELAY);

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (value) updateValue(null);
    setSearchText(e.target.value);
    search(e.target.value);
  };

  const onSelectValue = (e: SyntheticEvent<Element, Event>, value: T | null) => {
    updateValue(value);
    setSearchText(value?.name || '');
  };

  const preparedValue = useMemo(() => {
    return value ? {...value, label: value.name} : value;
  }, [value]);

  return (
    <Autocomplete
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classNames}
          type="text"
          label={label}
          margin="dense"
          onChange={onChangeValue}
          value={searchText}
          required={required}
          error={!!errorText}
          helperText={errorText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading || loadingDefaultOptions ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      fullWidth
      loading={loading || loadingDefaultOptions}
      value={preparedValue}
      onChange={onSelectValue}
      options={options}
      inputValue={searchText}
    />
  );
};
