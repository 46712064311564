import {DatePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ruRU} from '@mui/x-date-pickers/locales';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {ru} from 'date-fns/locale';
import {FC} from 'react';

type Props = {
  value: Date | null | string;
  onDateChange: (date: Date | string | null) => void;
  disabled?: boolean;
};

export const LocalizedDatePicker: FC<Props> = ({value, onDateChange, disabled = false}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale={ru}
    >
      <DatePicker
        value={value}
        onChange={(newValue) => onDateChange(newValue)}
        slotProps={{textField: {size: 'small'}}}
        format="dd.MM.yyyy"
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};
