export enum ClientRoles {
  client = 1,
}
export interface ClientModel {
  id?: number;
  clientId?: number;
  clientName?: string;
  phoneNumber: string;
  email?: string;
  role: ClientRoles;
}

export interface ClientInfoModel {
  id?: number;
  clientId?: number;
  phoneNumber: string;
  email?: string;
  firstName: string;
  secondName: string;
  lastName: string;
  role: ClientRoles;
}

export interface CreateClientPayload {
  firstName: string;
  secondName: string;
  lastName: string;
  phoneNumber: string;
  apartmentId: number;
  email?: string;
  asOwner: boolean;
  role: ClientRoles;
}

export type CreateClientResponse = {
  client: ClientModel;
  isAddedToApartment: true;
  details: string;
};
