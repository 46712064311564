import React, {useEffect, useState} from 'react';

import {ApartmentComplexesApi} from 'api';
import styles from 'modules/AddNewIntercom/AddIntercom.module.scss';
import {ApartmentComplexesModel} from 'shared/models';

import {bem} from '../../utils';
import {AutocompleteField} from '../AutocompleteField';

const sn = bem('addNewIntercom', styles);

type Props = {
  apartmentComplex: ApartmentComplexesModel | null;
  updateApartmentComplex: (value: ApartmentComplexesModel | null) => void;
  errorText?: string;
};

export const ApartmentAutocomplete = ({apartmentComplex, updateApartmentComplex, ...props}: Props) => {
  const [defaultApartmentComplex, setDefaultApartmentComplex] = useState<ApartmentComplexesModel[]>([]);
  const [loadingDefaultApartmentComplexes, setLoadingDefaultApartmentComplexes] = useState(false);

  useEffect(() => {
    getInitApartmentComplexes();
  }, []);

  const getInitApartmentComplexes = async () => {
    try {
      setLoadingDefaultApartmentComplexes(true);
      const res = await ApartmentComplexesApi.getApartmentComplexes();
      setDefaultApartmentComplex(res.items);
    } finally {
      setLoadingDefaultApartmentComplexes(false);
    }
  };

  const searchApartmentComplexes = async (name: string) => {
    const res = await ApartmentComplexesApi.searchApartmentComplexes({query: name});
    return res.items;
  };

  return (
    <AutocompleteField<ApartmentComplexesModel>
      required
      label="Название ЖК"
      classNames={sn('autocomplete')}
      value={apartmentComplex}
      updateValue={updateApartmentComplex}
      getOptions={searchApartmentComplexes}
      loadingDefaultOptions={loadingDefaultApartmentComplexes}
      defaultOptions={defaultApartmentComplex}
      {...props}
    />
  );
};
