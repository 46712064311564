import {apiClient} from 'api/client';
import {
  CameraForLinkingItemModel,
  CameraInfoModel, CameraItemModel,
  CameraModel, CamerasForLinkingPayload,
  NewCamera,
  RequestWithPagination,
  SearchCameras,
  WithPagination,
} from 'shared/models';

class CamerasService {
  private getBasePath(value: string) {
    return `/managing/devices/cameras${value}`;
  }

  getCameras(payload?: RequestWithPagination<SearchCameras>): Promise<WithPagination<CameraItemModel>> {
    return apiClient.get(this.getBasePath(''), {params: payload}).then((res) => res.data);
  }

  getCamerasForLinking(payload?: RequestWithPagination<CamerasForLinkingPayload>): Promise<WithPagination<CameraForLinkingItemModel>> {
    return apiClient.get(this.getBasePath('/for-linking'), {params: payload}).then((res) => res.data);
  }

  getCamera(id: number): Promise<CameraInfoModel> {
    return apiClient.get(this.getBasePath(`/${id}`)).then((res) => res.data);
  }

  addCamera(payload: Partial<NewCamera>): Promise<CameraInfoModel> {
    return apiClient.post(this.getBasePath(''), payload).then((res) => res.data);
  }

  editCamera(id: number, payload: Partial<NewCamera>): Promise<CameraInfoModel> {
    return apiClient.put(this.getBasePath(`/${id}`), payload).then((res) => res.data);
  }

  deleteCamera(id: number) {
    return apiClient.delete(this.getBasePath(`/${id}`)).then((res) => res.data);
  }

  unlinkCamera(id: number) {
    return apiClient.post(this.getBasePath(`/${id}/unlink`)).then((res) => res.data);
  }

}

export const CamerasApi = new CamerasService();
