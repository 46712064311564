import {Tab, Tabs} from '@mui/material';
import {FC} from 'react';

import {EntryInfoModel} from 'shared/models';

import {TabLabel} from './components/TabLabel';
import styles from './EntryTabs.module.scss';

import {Entry} from '../HouseConstructor/types';

interface TabProps {
  entries: EntryInfoModel[];
  onDeleteTab?: (entry: Entry) => void;
  currentTab: number;
  onChangeTab: (tabIndex: number) => void;
}

export const EntryTabs: FC<TabProps> = ({entries, onDeleteTab, currentTab, onChangeTab}) => {
  const onChange = (tabIndex: number) => {
    onChangeTab(tabIndex);
  };

  return (
    <div className={styles.entryTabs}>
      <Tabs value={currentTab} variant="scrollable" scrollButtons="auto">
        {!!entries?.length &&
          entries.map((entry, index) => (
            <Tab
              key={entry.entryNumber}
              label={<TabLabel entry={entry} onDeleteTab={onDeleteTab} />}
              onClick={() => onChange(index)}
            />
          ))}
      </Tabs>
    </div>
  );
};
