import {WithPagination, AccountModel} from 'shared/models';

export const initialAccountList: WithPagination<AccountModel> = {
  count: 0,
  items: [],
  total: 0,
};

export const confirmResetPassword = {
  description: 'Пароль этого сотрудника будет сброшен. Вы уверены, что хотите продолжить?',
  acceptText: 'Сбросить',
};

export const confirmBlock = {
  description: 'Сотрудник будет заблокирован. Вы уверены, что хотите продолжить?',
  acceptText: 'Заблокировать',
};

export const confirmUnblock = {
  description: 'Сотрудник будет разблокирован. Вы уверены, что хотите продолжить?',
  acceptText: 'Разблокировать',
};

export const getSuccessDeleteMessage = (name: string) => ({
  title: 'Аккаунт удален',
  message: `Аккаунт ${name} был успешно удален`,
  isError: false,
});

export const failedDeleteMessage = {
  title: 'Аккаунт не удален',
  message: 'Ошибка удаления аккаунта',
  isError: true,
};

export const getSuccessResetPasswordMessage = (name: string) => ({
  title: 'Пароль сброшен',
  message: `Пароль для ${name} был успешно сброшен.`,
});

export const failedResetPasswordMessage = {
  title: 'Пароль не удален',
  message: 'Ошибка сброса пароля',
  isError: true,
};

export const getSuccessBlockAccountMessage = (name: string) => ({
  title: 'Аккаунт заблокирован',
  message: `Аккаунт ${name} был заблокирован`,
});

export const failedBlockAccountMessage = {
  title: 'Аккаунт не заблокирован',
  message: 'Ошибка блокировки аккаунта',
  isError: true,
};

export const getSuccessUnblockAccountMessage = (name: string) => ({
  title: 'Аккаунт разблокирован',
  message: `Аккаунт ${name} был разблокирован`,
});

export const failedUnblockAccountMessage = {
  title: 'Аккаунт не разблокирован',
  message: 'Ошибка разблокировки аккаунта',
  isError: true,
};
