import {Delete as DeleteSvg, Edit as EditSvg} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {GridCellParams, GridRenderCellParams} from '@mui/x-data-grid';

import {ReactComponent as CameraIcon} from 'assets/icons/camera.svg';
import {ReactComponent as CameraOffIcon} from 'assets/icons/off-camera.svg';
import styles from 'shared/components/Table/Table.module.scss';
import {CameraItemModel, CameraLocationType} from 'shared/models';
import {bem} from 'shared/utils';

const sn = bem('table', styles);

export const columns = [
  {
    field: 'name',
    headerName: 'Название',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 300,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <div className={sn(['cell_name', 'cell_default'])}>
        <CameraIcon className={sn('cell_icon')} />
        {params.value}
      </div>
    ),
  },
  {
    field: 'type',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Категория',
    renderCell: (params: GridRenderCellParams<CameraItemModel>) => (
      <div className={sn('cell_default')}>
        {params.row.location === CameraLocationType.courtyard ? 'Двор' : 'Парковка'}
      </div>
    ),
    width: 150,
  },
  {
    field: 'address',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Адрес',
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'unlink',
    headerName: '',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 60,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridCellParams<CameraItemModel>) => (
      <span className={sn('item', {item_disabled: !params.row.linkedToDoorPhone})}>
        <IconButton>
          <CameraOffIcon />
        </IconButton>
      </span>
    ),
  },
  {
    field: 'edit',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: '',
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 50,
    renderCell: () => (
      <div className={sn('cell_edit')}>
        <EditSvg />
      </div>
    ),
  },
  {
    field: 'delete',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: '',
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 50,
    renderCell: () => (
      <div className={sn('cell_delete')}>
        <DeleteSvg />
      </div>
    ),
  },
];
