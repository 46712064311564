import {Button, CircularProgress} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {OneApartmentApi} from 'api';
import {AddUser, Loader, ServiceCard, EditUser} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {useBreadcrumbs} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccountRoles, OneApartmentModel, OneApartmentTenants} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './Apartment.module.scss';
import {TenantsTable} from './components/TenantsTable';

const sn = bem('apartment', styles);

export const Apartment = () => {
  const {apartmentId = null} = useParams<{apartmentId: string}>();
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [apartment, setApartment] = useState<OneApartmentModel | null>(null);
  const [editingClientId, setEditingClientId] = useState<number | null>(null);
  const {confirm} = useConfirm();
  const {apartmentBreadcrumbs} = useBreadcrumbs();
  const title = `Квартира № ${apartment?.apartmentNumber}`;
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);

  const getApartmentData = async () => {
    if (apartmentId) {
      try {
        setLoading(true);
        const res = await OneApartmentApi.getApartment(apartmentId);
        setApartment(res);
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteTenant = async (clientId: number) => {
    if (apartmentId) {
      try {
        setDeleting(true);
        await OneApartmentApi.deleteTenant(apartmentId, clientId);
        if (apartment?.tenants.length) {
          const preparedTenants = apartment.tenants.filter((t) => t.clientId !== clientId);
          setApartment({...apartment, tenants: preparedTenants});
        }
      } finally {
        setDeleting(false);
      }
    }
  };

  useEffect(() => {
    getApartmentData();
  }, []);

  const onCellClick = async (params: GridCellParams<OneApartmentTenants>) => {
    if (params.field === 'edit') {
      if (isDisabled) return;
      setIsEditing(true);
      setEditingClientId(params.row.clientId);
      return;
    }
    if (params.field === 'delete') {
      if (isDisabled) return;
      const confirmPayload = {
        title: 'Удалить пользователя',
        description: `После удаления жилец не будет иметь доступ к домофонии. Удалить жильца ${params.row.fullName}?`,
        acceptText: 'Удалить',
      };
      if (await confirm(confirmPayload)) {
        deleteTenant(params.row.clientId);
      }
      return;
    }
  };

  const onCloseEditing = () => {
    setIsEditing(false);
    setEditingClientId(null);
  };

  const onAfterEditing = () => {
    getApartmentData();
  };

  const onCloseAdding = () => {
    setIsAdding(false);
  };

  const onAfterAdding = () => {
    getApartmentData();
  };

  const onAfterChangeService = () => {
    getApartmentData();
  };

  if (!apartment)
    return (
      <div className={sn('loaderWrap')}>
        <CircularProgress className={sn('loaderWrap__loader')} color="inherit" size={40} />
      </div>
    );

  return (
    <div className={styles.apartment}>
      <TitleBlock
        title={title}
        breadcrumbs={apartmentBreadcrumbs(title)}
        action={
          <Button
            variant="contained"
            color="secondary"
            disabled={isDisabled || apartment?.tenants?.length > 4}
            onClick={() => setIsAdding(true)}
          >
            Добавить доп. жильца
          </Button>
        }
      />
      <div className={sn('panel')}>
        <h3>Пользователи в квартире</h3>
      </div>
      <TenantsTable tenants={apartment?.tenants || []} onCellClick={onCellClick} loading={loading || deleting} />
      <div className={sn('panel')}>
        <h3>Управление услугами</h3>
      </div>
      {loading ? (
        <div className={sn('loaderWrap__services')}>
          <Loader />
        </div>
      ) : (
        <ServiceCard
          services={apartment.services?.[0]}
          onAfterChangeService={onAfterChangeService}
          id={String(apartmentId)}
          title="Домофон + Камера"
          pricePerMonth={apartment.pricePerMonth}
          tariffTemplates={apartment.tariffTemplates}
        />
      )}
      <EditUser
        isOpen={isEditing}
        onClose={onCloseEditing}
        clientId={editingClientId}
        onAfterEditing={onAfterEditing}
        title="Данные жильца"
      />
      <AddUser
        isOpen={isAdding}
        onClose={onCloseAdding}
        apartmentId={Number(apartmentId)}
        onAfterAdd={onAfterAdding}
        title="Добавление жильца"
      />
    </div>
  );
};
