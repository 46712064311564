import {useState} from 'react';

import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';

import {FirstStep} from './components/FirstStep';
import {SecondStep} from './components/SecondStep';
import styles from './HouseConstructor.module.scss';
import {HouseConstructorTabs, HouseSettings} from './types';

import {useConfirm} from '../Confirmation/useConfirm';
import {Loader} from '../Loader';

type Props = {
  title: string;
  saving: boolean;
  loading?: boolean;
  onSave: () => void;
  onCancel: () => void;
  houseSettings: HouseSettings;
  updateHouseSettings: (settings: HouseSettings) => void;
};

export const HouseConstructor = ({
  title,
  saving,
  loading,
  onSave,
  onCancel,
  houseSettings,
  updateHouseSettings,
}: Props) => {
  const [currentTab, setCurrentTab] = useState(HouseConstructorTabs.first);
  const {confirm} = useConfirm();

  const changeTab = (tab: HouseConstructorTabs) => {
    setCurrentTab(tab);
  };

  const onCancelClick = async () => {
    const confirmPayload = {
      title: 'Выйти без сохранения изменений',
      description: 'Вы действительно хотите выйти из редактирования дома без сохранения изменений?',
      acceptText: 'Выйти без сохранения',
    };
    if (await confirm(confirmPayload)) {
      onCancel();
    }
  };

  return (
    <div className={styles.addNewHome}>
      <TitleBlock title={title} />
      {loading || !houseSettings ? (
        <Loader />
      ) : (
        <>
          {currentTab === HouseConstructorTabs.first && (
            <FirstStep
              changeTab={changeTab}
              houseSettings={houseSettings}
              updateHouseSettings={updateHouseSettings}
              onCancel={onCancelClick}
            />
          )}
          {currentTab === HouseConstructorTabs.second && (
            <SecondStep
              saving={saving}
              changeTab={changeTab}
              houseSettings={houseSettings}
              updateHouseSettings={updateHouseSettings}
              onCancel={onCancelClick}
              onSave={onSave}
            />
          )}
        </>
      )}
    </div>
  );
};
