import {CameraCreateModel, CameraProtocol, FloorModel} from '../../models';
import {CameraAddressType} from './constants';
import {
  CameraAddress,
  CameraCourtyardAddress,
  CameraParkingAddress,
  CourtyardAddressErrors,
  ParkingAddressErrors,
} from './types';
import {ApartmentComplexesApi, CourtyardsServiceApi, GatesApi, HouseApi, ParkingSectionApi} from '../../../api';
import {HouseModelAutocomplete} from '../AutocompleteField/utils';

const REQUIRED_TEXT = 'Поле необходимо для заполнения';

export const getActiveCourtyardAddressType = (selectedAddress: CameraCourtyardAddress) => {
  if (selectedAddress.ids.floorNumber) return CameraAddressType.floor;
  if (selectedAddress.ids.entryNumber) return CameraAddressType.entry;
  if (selectedAddress.ids.buildingId) return CameraAddressType.house;
  if (selectedAddress.ids.courtyardGateId) return CameraAddressType.gate;
  return CameraAddressType.courtyard;
};

export const getActiveParkingAddressType = (selectedAddress: CameraParkingAddress) => {
  if (selectedAddress.ids.floorNumber) return CameraAddressType.parkingSectionFloor;
  if (selectedAddress.ids.entryNumber) return CameraAddressType.parkingSectionEntrance;
  return CameraAddressType.parkingSection;
};

export const preparedFloors = (floors: FloorModel[]) => {
  return floors.map((f) => ({...f, name: String(f.floorNumber)}));
};

export const preparePayload = (
  values: CameraCreateModel,
  cameraAddressType: CameraAddressType,
  address: CameraAddress,
) => {
  const {courtyardId, courtyardGateId, entryNumber, floorNumber, buildingId} = address;
  const payload: Partial<CameraCreateModel> = values;

  if ([CameraAddressType.courtyard, CameraAddressType.parking].includes(cameraAddressType)) {
    payload.courtyardId = courtyardId;
  }
  if (cameraAddressType === CameraAddressType.gate) {
    payload.courtyardId = courtyardId;
    payload.courtyardGateId = courtyardGateId;
  }
  if ([CameraAddressType.house, CameraAddressType.parkingSection].includes(cameraAddressType)) {
    payload.courtyardId = courtyardId;
    payload.buildingId = buildingId;
  }
  if ([CameraAddressType.entry, CameraAddressType.parkingSectionEntrance].includes(cameraAddressType)) {
    payload.courtyardId = courtyardId;
    payload.courtyardGateId = courtyardGateId;
    payload.buildingId = buildingId;
    payload.entryNumber = entryNumber;
  }
  if (cameraAddressType === CameraAddressType.parkingSectionFloor) {
    payload.courtyardId = courtyardId;
    payload.buildingId = buildingId;
    payload.floorNumber = floorNumber;
  }
  if (cameraAddressType === CameraAddressType.floor) {
    payload.courtyardId = courtyardId;
    payload.courtyardGateId = courtyardGateId;
    payload.buildingId = buildingId;
    payload.entryNumber = entryNumber;
    payload.floorNumber = floorNumber;
  }

  if (values.protocol === CameraProtocol.standard) {
    delete payload.url;
  }

  if (values.protocol === CameraProtocol.link) {
    delete payload.host;
    delete payload.login;
    delete payload.password;
    delete payload.path;
    delete payload.port;
  }

  return payload;
};

// TODO: разбить на функции
export const getAutocompleteCourtyardAddress = async (address: CameraCourtyardAddress) => {
  const {
    models: {
      apartmentComplex,
      courtyard,
      courtyardGate,
      building,
      entryNumber: entryNumberModel,
      floorNumber: floorNumberModel,
    },
    ids: {apartmentComplexId, courtyardId, courtyardGateId, buildingId, entryNumber, floorNumber},
  } = address;
  const initialAddress = {...address};

  if (!apartmentComplex && apartmentComplexId) {
    try {
      const res = await ApartmentComplexesApi.getApartmentComplex(apartmentComplexId);
      if (res.apartmentComplexId) {
        initialAddress.models.apartmentComplex = {
          id: res.apartmentComplexId,
          name: res.name,
          city: '',
          activeApartmentsCount: 0,
          address: '',
        };
      }
    } catch (err) {
      console.error(err);
    }
  }

  if (courtyardId && !courtyard && apartmentComplexId) {
    try {
      const res = await CourtyardsServiceApi.getCourtyard(apartmentComplexId, courtyardId);
      initialAddress.models.courtyard = res;
    } catch (err) {
      console.error(err);
    }
  }

  if (courtyardGateId && !courtyardGate && apartmentComplexId && courtyardId) {
    try {
      const res = await GatesApi.getGate(String(apartmentComplexId), String(courtyardId), courtyardGateId);
      initialAddress.models.courtyardGate = res;
    } catch (err) {
      console.error(err);
    }
  }

  if (buildingId && !building && apartmentComplexId && courtyardId) {
    try {
      const res = await HouseApi.getHouses(String(apartmentComplexId), String(courtyardId));
      const houses = res.items.map((house) => ({...house, name: String(house.number)})) as HouseModelAutocomplete[];
      const data = houses.find((item) => String(item.buildingId) === String(buildingId));
      if (data) {
        initialAddress.models.building = data;
      }
    } catch (err) {
      console.error(err);
    }
  }

  if (entryNumber && !entryNumberModel && apartmentComplexId && courtyardId && buildingId) {
    try {
      const res = await HouseApi.getEditingHouse(String(apartmentComplexId), String(courtyardId), String(buildingId));
      const entry = res.entries[entryNumber - 1];
      if (entry) {
        initialAddress.models.entryNumber = {...entry, name: String(entry.entryNumber)};
      }
      if (entry && floorNumber && !floorNumberModel) {
        const floor = entry.floors[floorNumber - 1];
        if (floor) {
          initialAddress.models.floorNumber = {...floor, name: String(floor.floorNumber)};
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  return initialAddress;
};

// TODO: разбить на функции
export const getAutocompleteParkingAddress = async (address: CameraParkingAddress) => {
  const {
    models: {apartmentComplex, parking, parkingSection, entryNumber: entryNumberModel, floorNumber: floorNumberModel},
    ids: {apartmentComplexId, parkingId, parkingSectionId, entryNumber, floorNumber},
  } = address;
  const initialAddress = {...address};

  if (!apartmentComplex && apartmentComplexId) {
    try {
      const res = await ApartmentComplexesApi.getApartmentComplex(apartmentComplexId);
      if (res.apartmentComplexId) {
        initialAddress.models.apartmentComplex = {
          id: res.apartmentComplexId,
          name: res.name,
          city: '',
          activeApartmentsCount: 0,
          address: '',
        };
      }
    } catch (err) {
      console.error(err);
    }
  }

  if (parkingId && !parking && apartmentComplexId) {
    try {
      const res = await CourtyardsServiceApi.getCourtyard(apartmentComplexId, parkingId);
      initialAddress.models.parking = res;
    } catch (err) {
      console.error(err);
    }
  }

  if (parkingSectionId && !parkingSection && apartmentComplexId && parkingId) {
    try {
      const res = await ParkingSectionApi.getParkingSectionList(String(apartmentComplexId), String(parkingId));
      const _parkingSection = res.items.find((item) => item.buildingId === parkingSectionId);
      if (_parkingSection) {
        initialAddress.models.parkingSection = _parkingSection;
      }
    } catch (err) {
      console.error(err);
    }
  }

  if (entryNumber && !entryNumberModel && apartmentComplexId && parkingId && parkingSectionId) {
    try {
      const res = await ParkingSectionApi.getParkingSectionEntrance(
        String(apartmentComplexId),
        String(parkingId),
        String(parkingSectionId),
      );
      const preparedEntranceOptions = res
        .sort((a, b) => (a > b ? 1 : -1))
        .map((entrance) => ({
          name: String(entrance),
          value: String(entrance),
          label: String(entrance),
        }));
      initialAddress.models.entryNumber = preparedEntranceOptions[entryNumber - 1];
    } catch (err) {
      console.error(err);
    }
  }

  if (floorNumber && !floorNumberModel && apartmentComplexId && parkingId && parkingSectionId) {
    try {
      const res = await ParkingSectionApi.getEditingParkingSection(
        String(apartmentComplexId),
        String(parkingId),
        String(parkingSectionId),
      );
      const preparedFloorsOptions = res.floors.map((floor) => ({
        name: String(floor.floorNumber),
        value: String(floor.floorNumber),
        label: String(floor.floorNumber),
      }));
      initialAddress.models.floorNumber = preparedFloorsOptions[floorNumber - 1];
    } catch (err) {
      console.error(err);
    }
  }

  return initialAddress;
};

export const parkingAddressValidate = (
  parkingAddressValues: CameraParkingAddress,
  updateParkingAddressErrors: (value: ParkingAddressErrors) => void,
  cameraAddressType: CameraAddressType,
) => {
  if (!parkingAddressValues.ids.apartmentComplexId) return true;

  const errorsText = {
    parkingErrorText: '',
    parkingSectionErrorText: '',
    entryNumberErrorText: '',
    floorNumberErrorText: '',
  };

  if (cameraAddressType === CameraAddressType.parkingSection) {
    if (!parkingAddressValues.ids.parkingId) errorsText.parkingErrorText = REQUIRED_TEXT;
    if (!parkingAddressValues.ids.parkingSectionId) errorsText.parkingSectionErrorText = REQUIRED_TEXT;
    updateParkingAddressErrors(errorsText);
    return !!(parkingAddressValues.ids.parkingId && parkingAddressValues.ids.parkingSectionId);
  }

  if (cameraAddressType === CameraAddressType.parkingSectionEntrance) {
    if (!parkingAddressValues.ids.parkingId) errorsText.parkingErrorText = REQUIRED_TEXT;
    if (!parkingAddressValues.ids.parkingSectionId) errorsText.parkingSectionErrorText = REQUIRED_TEXT;
    if (!parkingAddressValues.ids.entryNumber) errorsText.entryNumberErrorText = REQUIRED_TEXT;
    updateParkingAddressErrors(errorsText);
    return !!(
      parkingAddressValues.ids.parkingId &&
      parkingAddressValues.ids.parkingSectionId &&
      parkingAddressValues.ids.entryNumber
    );
  }

  if (cameraAddressType === CameraAddressType.parkingSectionFloor) {
    if (!parkingAddressValues.ids.parkingId) errorsText.parkingErrorText = REQUIRED_TEXT;
    if (!parkingAddressValues.ids.parkingSectionId) errorsText.parkingSectionErrorText = REQUIRED_TEXT;
    if (!parkingAddressValues.ids.floorNumber) errorsText.floorNumberErrorText = REQUIRED_TEXT;
    updateParkingAddressErrors(errorsText);
    return !!(
      parkingAddressValues.ids.parkingId &&
      parkingAddressValues.ids.parkingSectionId &&
      parkingAddressValues.ids.floorNumber
    );
  }

  return true;
};

export const courtyardAddressValidate = (
  courtyardAddressValues: CameraCourtyardAddress,
  updateCourtyardAddressErrors: (value: CourtyardAddressErrors) => void,
  cameraAddressType: CameraAddressType,
) => {
  if (!courtyardAddressValues.ids.apartmentComplexId) return true;

  const errorsText = {
    courtyardErrorText: '',
    courtyardGateErrorText: '',
    buildingErrorText: '',
    entryNumberErrorText: '',
    floorNumberErrorText: '',
  };

  if (cameraAddressType === CameraAddressType.courtyard) {
    if (!courtyardAddressValues.ids.courtyardId) errorsText.courtyardErrorText = REQUIRED_TEXT;
    updateCourtyardAddressErrors(errorsText);
    return !!courtyardAddressValues.ids.courtyardId;
  }

  if (cameraAddressType === CameraAddressType.house) {
    if (!courtyardAddressValues.ids.courtyardId) errorsText.courtyardErrorText = REQUIRED_TEXT;
    if (!courtyardAddressValues.ids.buildingId) errorsText.buildingErrorText = REQUIRED_TEXT;
    updateCourtyardAddressErrors(errorsText);
    return !!(courtyardAddressValues.ids.courtyardId && courtyardAddressValues.ids.buildingId);
  }

  if (cameraAddressType === CameraAddressType.gate) {
    if (!courtyardAddressValues.ids.courtyardId) errorsText.courtyardErrorText = REQUIRED_TEXT;
    if (!courtyardAddressValues.ids.courtyardGateId) errorsText.courtyardGateErrorText = REQUIRED_TEXT;
    updateCourtyardAddressErrors(errorsText);
    return !!(courtyardAddressValues.ids.courtyardId && courtyardAddressValues.ids.courtyardGateId);
  }

  if (cameraAddressType === CameraAddressType.entry) {
    if (!courtyardAddressValues.ids.courtyardId) errorsText.courtyardErrorText = REQUIRED_TEXT;
    if (!courtyardAddressValues.ids.buildingId) errorsText.buildingErrorText = REQUIRED_TEXT;
    if (!courtyardAddressValues.ids.entryNumber) errorsText.entryNumberErrorText = REQUIRED_TEXT;
    updateCourtyardAddressErrors(errorsText);
    return !!(
      courtyardAddressValues.ids.courtyardId &&
      courtyardAddressValues.ids.buildingId &&
      courtyardAddressValues.ids.entryNumber
    );
  }

  if (cameraAddressType === CameraAddressType.floor) {
    if (!courtyardAddressValues.ids.courtyardId) errorsText.courtyardErrorText = REQUIRED_TEXT;
    if (!courtyardAddressValues.ids.buildingId) errorsText.buildingErrorText = REQUIRED_TEXT;
    if (!courtyardAddressValues.ids.entryNumber) errorsText.entryNumberErrorText = REQUIRED_TEXT;
    if (!courtyardAddressValues.ids.floorNumber) errorsText.floorNumberErrorText = REQUIRED_TEXT;
    updateCourtyardAddressErrors(errorsText);
    return !!(
      courtyardAddressValues.ids.courtyardId &&
      courtyardAddressValues.ids.buildingId &&
      courtyardAddressValues.ids.entryNumber &&
      courtyardAddressValues.ids.floorNumber
    );
  }

  return true;
};
