import {useToast} from '../../hooks';
import {ChangeEvent, useState} from 'react';
import {HouseApi} from 'api';
import {BuildingType} from '../../models';
import {bem} from '../../utils';
import {downloadAsFile, getUtf8FileName} from '../../utils/downloadFile';
import {AxiosError} from 'axios';
import {Modal} from '../Modal';
import {LoadingButton} from '@mui/lab';
import {Button} from '@mui/material';
import styles from './UsersImport.module.scss';

const sn = bem('usersImport', styles);

type Props = {
  onAfterImport: () => void;
  id?: string;
  buildingType: BuildingType;
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

export const UsersImport = ({id, onAfterImport, buildingType, isOpen, title, onClose}: Props) => {
  const [templateLoading, setTemplateLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileWithError, setFileWithError] = useState<File | null>(null);
  const {showToast} = useToast();
  const [isEmptyPlaceNotify, setIsEmptyPlaceNotify] = useState(false);

  const downloadExample = async () => {
    if (id) {
      try {
        setTemplateLoading(true);
        const res = await HouseApi.getImportTemplate(id, buildingType);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (res.status === 204) {
          setIsEmptyPlaceNotify(true);
        } else {
          downloadAsFile(res.data, getUtf8FileName(res.headers['content-disposition']), fileType);
        }
      } finally {
        setTemplateLoading(false);
      }
    }
  };

  const onImportTenants = async () => {
    if (id && file) {
      try {
        setImportLoading(true);
        await HouseApi.importTenants(id, file, buildingType);
        showToast({
          title: 'Импорт завершен',
          message: `${buildingType === BuildingType.parkingLot ? 'Пользователи' : 'Жильцы'} успешно добавлены.`,
        });
        onAfterImport();
        onCloseModal();
      } catch (err: unknown) {
        const error = err as AxiosError;
        if (error.response?.config?.data) {
          if (error?.response?.data) {
            const _file = error.response.data as File;
            setFileWithError(_file);
          }
        }
      } finally {
        setImportLoading(false);
      }
    }
  };

  const downloadFileWithError = async () => {
    if (fileWithError) {
      const fileName = file?.name || 'Файл импорта';
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      downloadAsFile(fileWithError, fileName, fileType);
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return;
    setFile(e.target.files[0]);
  };

  const onCloseModal = () => {
    setFile(null);
    setFileWithError(null);
    setIsEmptyPlaceNotify(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} title={title} maxWidth="md">
      <div className={sn('wrap')}>
        <div className={sn('import')}>
          <LoadingButton
            loading={templateLoading}
            variant="contained"
            color="inherit"
            className={sn('import__example')}
            onClick={downloadExample}
          >
            Скачать пример
          </LoadingButton>
          <LoadingButton
            loading={importLoading}
            variant="contained"
            color="secondary"
            className={sn('import__load')}
            onClick={onImportTenants}
            disabled={!file}
          >
            Загрузить {buildingType === BuildingType.parkingLot ? 'пользователей' : 'жильцов'}
          </LoadingButton>
          <Button variant="contained" color="secondary" className={sn('import__choose')}>
            <input onChange={onChangeInput} className={sn('import__fileInput')} accept=".xlsx, .xls" type="file" />
            Выбрать файл
          </Button>
        </div>
        {file && (
          <div className={sn('file')}>
            <p>
              Название файла: <span className={sn('file__name')}>{file?.name}</span>
            </p>
          </div>
        )}
        {isEmptyPlaceNotify && (
          <div className={sn('empty-free-places')}>
            <p>Для данного строения отсутствуют квартиры без собственников.</p>
            <p>Для изменения данных воспользуйтесь сайтом.</p>
          </div>
        )}
        {fileWithError && (
          <div className={sn('file-error')}>
            <p>
              В загружаемом файле ошибка. Нажмите скачать, чтобы посмотреть:{' '}
              <Button color="primary" onClick={downloadFileWithError}>
                Скачать
              </Button>
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};
