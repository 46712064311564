import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {routes} from 'shared/constants/routes';
import {bem} from 'shared/utils';
import styles from './PaymentsHeader.module.scss';

const sn = bem('paymentsHeader', styles);

export const PaymentsHeader = () => {
  return (
    <TitleBlock
      title="Платежи"
      breadcrumbs={[{label: 'Платежи', href: routes.payments}]}
    />
  );
};
