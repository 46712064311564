import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Snackbar from '@mui/material/Snackbar';
import classNames from 'classnames';
import {FC} from 'react';
import {createPortal} from 'react-dom';

import {bem} from 'shared/utils';

import styles from './Toast.module.scss';

const sn = bem('toast', styles);

type Props = {
  isOpen: boolean;
  close: () => void;
  message?: string;
  title?: string;
  isError?: boolean;
};

export const Toast: FC<Props> = ({isOpen, message, close, title, isError = false}) => {
  if (!isOpen) return null;

  return createPortal(
    <Snackbar
      className={classNames(styles.toast, {[styles.toast_error]: isError})}
      open={isOpen}
      message={
        <div>
          {isError ? <ErrorOutlineIcon /> : <CheckCircleOutlineIcon />}
          <div className={sn('title')}>{title}</div>
          {message}
        </div>
      }
      autoHideDuration={3000}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      onClose={close}
    />,
    document.body,
  );
};
