import {Button} from '@mui/material';
import {ChangeEvent} from 'react';

import {SearchInput} from 'shared/components/SearchInput';
import {useBreadcrumbs} from 'shared/hooks';
import {AccountRoles} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './ParkingHeader.module.scss';
import {TitleTabs} from 'shared/ui/TitleTabs';
import {routes} from 'shared/constants/routes';
import {generatePath, useNavigate, useParams} from 'react-router';
import {useLocation} from 'react-router-dom';

type Props = {
  parkingSearch: string;
  updateParkingSearch: (value: string) => void;
  openModal: () => void;
};

const sn = bem('courtyards', styles);

export const ParkingHeader = ({parkingSearch, updateParkingSearch, openModal}: Props) => {
  const {parkingBreadcrumbs} = useBreadcrumbs();
  const {apartmentComplexId} = useParams<{
    apartmentComplexId: string;
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const activeTab = location.pathname === generatePath(routes.parking, {apartmentComplexId}) ? 'parking' : 'courtyard';

  const onSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    updateParkingSearch(event.target.value);
  };

  const goToCourtyard = () => {
    if (location.pathname !== routes.courtyards) {
      navigate(generatePath(routes.courtyards, {apartmentComplexId}));
    }
  };

  const goToParking = () => {
    if (location.pathname !== routes.parking) {
      navigate(generatePath(routes.parking, {apartmentComplexId}));
    }
  };

  return (
    <>
      <TitleTabs
        breadcrumbs={parkingBreadcrumbs}
        goToCourtyard={goToCourtyard}
        goToParking={goToParking}
        activeTab={activeTab}
        action={
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={openModal}
            disabled={!checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin])}
          >
            Добавить парковку
          </Button>
        }
      />
      <div className={sn('search')}>
        <SearchInput placeholder="Найти парковку (мин 3 символа)" onChange={onSearchInput} value={parkingSearch} />
      </div>
    </>
  );
};
