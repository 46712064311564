import {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {useBreadcrumbs} from '../../hooks';
import {ApartmentComplexesApi} from '../../../api';
import {downloadAsFile, getUtf8FileName} from '../../utils/downloadFile';
import TitleBlock from '../../layouts/Base/components/TitleBlock/TitleBlock';
import {AutocompleteSelectAll} from '../AutocompleteSelectAllListBox';
import {Autocomplete, Checkbox, TextField} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {LoadingButton} from '@mui/lab';
import {bem} from '../../utils';
import {ApartmentComplexesModel, ReportType} from '../../models';
import styles from './LoadReport.module.scss';

const sn = bem('loadReport', styles);

type ApartmentComplexesExtends = ApartmentComplexesModel & {selected: boolean; label: string};

type Props = {
  reportType: ReportType;
};

export const LoadReport = ({reportType}: Props) => {
  const [apartmentComplexes, setApartmentComplexes] = useState<ApartmentComplexesExtends[]>([]);
  const [selectedComplexes, setSelectedComplexes] = useState<ApartmentComplexesExtends[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [selectedComplexesIds, setSelectedComplexesIds] = useState<number[]>([]);
  const isSelectedAll = selectedComplexes.length === apartmentComplexes.length;

  const getApartmentComplexes = async () => {
    try {
      setLoading(true);
      const res = await ApartmentComplexesApi.getApartmentComplexes();
      const preparedItems = res.items.map((complex) => ({
        ...complex,
        label: complex.name,
        selected: selectedComplexesIds.includes(complex.id),
      }));
      setApartmentComplexes(preparedItems);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApartmentComplexes();
  }, []);

  const handleApartmentOptionChange = (
    event: SyntheticEvent<Element, Event>,
    values: ApartmentComplexesExtends[],
    reason: string,
    details?: unknown,
  ) => {
    const option = details ? (details as {option: ApartmentComplexesExtends}).option : null;
    let ids = selectedComplexesIds;
    let updatedValues: ApartmentComplexesExtends[] = [];

    if (reason === 'selectOption' && option && !option.selected) {
      ids = [...ids, option.id];
      updatedValues = values;
    }

    if (option && (reason === 'removeOption' || (reason === 'selectOption' && option.selected))) {
      ids = selectedComplexesIds.filter((id) => id !== option.id);
      updatedValues = values.filter((value) => value.id !== option.id);
    }

    if (reason === 'clear') {
      ids = [];
      updatedValues = [];
    }
    setSelectedComplexesIds(ids);
    setSelectedComplexes(updatedValues);
  };

  const loadReport = async () => {
    if (selectedComplexes.length) {
      try {
        setLoadingReport(true);
        const idsCollection = selectedComplexesIds.reduce((acc, id) => acc + `apartmentComplexId=${id}&`, '');
        const res = await ApartmentComplexesApi.loadReport(idsCollection, reportType);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        downloadAsFile(res.data, getUtf8FileName(res.headers['content-disposition']), fileType);
      } finally {
        setLoadingReport(false);
      }
    }
  };

  const preparedApartmentComplexesOptions = useMemo(() => {
    return apartmentComplexes.map((complex) => ({
      ...complex,
      selected: selectedComplexesIds.includes(complex.id),
    }));
  }, [selectedComplexesIds, apartmentComplexes]);

  return (
    <div className={sn('wrapper')}>
      <div className={sn('apartmentComplexes')}>
        <AutocompleteSelectAll.Provider
          value={{
            onSelectAll: (selectedAll) => {
              setSelectedComplexes(selectedAll ? [] : apartmentComplexes);
              setSelectedComplexesIds(selectedAll ? [] : apartmentComplexes.map((item) => item.id));
            },
            isSelectedAll,
            indeterminate: false,
          }}
        >
          <Autocomplete
            multiple
            options={preparedApartmentComplexesOptions}
            value={selectedComplexes}
            onChange={handleApartmentOptionChange}
            renderOption={(props, option) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{marginRight: 8}}
                  checked={option.selected}
                />
                {option.name}
              </li>
            )}
            loading={loading}
            renderInput={(params) => <TextField {...params} label="ЖК" />}
            disableCloseOnSelect
            ListboxComponent={AutocompleteSelectAll.ListBox}
          />
        </AutocompleteSelectAll.Provider>
      </div>
      <LoadingButton
        loading={loading}
        variant="contained"
        color="primary"
        disabled={loading || !selectedComplexes.length || loadingReport}
        onClick={loadReport}
      >
        Скачать отчет
      </LoadingButton>
    </div>
  );
};
