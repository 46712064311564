import {Address} from './utils';

export enum IntercomType {
  entry,
  gate,
}

export interface IntercomModel {
  id: number | null;
  sipPanelId: number;
  sipNumber: string;
  name: string;
  isConnected: boolean;
  model: number;
  address: Address;
}

export interface NewIntercom {
  sipNumber: string;
  name: string;
  login: string;
  password: string;
  port: string;
  host: string;
  model: number | null;
  type: IntercomType | null;
  courtyardGateId: number | null;
  buildingId: string | null;
  entryNumber: number | null;
  cameraIds?: number[];
  isInvisible: boolean;
}

export interface IntercomInfoModel {
  sipPanelId: number;
  sipNumber: string;
  name: string;
  model: number;
  login: string;
  password: string;
  port: string;
  host: string;
  type: number;
  address: Address;
  isConnected: boolean;
  buildingId: string;
  entryNumber: number;
  courtyardGateId: number;
  cameras: [
    {
      cameraId: number;
      name: string;
      address: string;
    },
  ];
  isInvisible: boolean;
}

export interface SearchIntercoms {
  name?: string;
  sipNumber?: string;
  address?: string;
}
