import {TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {AccessDevicesApi} from 'api/services/accessDevicesService';
import {useBreadcrumbs, useModal} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccessDeviceItemModel, AccessDeviceModel} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './AccessDeviceCard.module.scss';
import {AccessDeviceCamera} from './components/AccessDeviceCamera';

import {AccessDevicesTable} from '../../components/AccessDevicesTable';
import {EditAccessDevice} from '../../components/EditAccessDevice';
import {routes} from 'shared/constants/routes';

const sn = bem('accessDeviceCard', styles);

export const AccessDeviceCard = () => {
  const [loading, setLoading] = useState(false);
  const [accessDevice, setAccessDevice] = useState<AccessDeviceItemModel | null>(null);
  const [accessDeviceInfo, setAccessDeviceInfo] = useState<AccessDeviceModel | null>(null);

  const {isOpen, openModal, closeModal} = useModal();
  const {accessDeviceId} = useParams<{accessDeviceId: string}>();
  const items = accessDevice ? [accessDevice] : [];
  const {accessDeviceBreadcrumbs} = useBreadcrumbs();
  const navigate = useNavigate();

  useEffect(() => {
    getAccessDevice(accessDeviceId);
  }, [accessDeviceId]);

  const getAccessDevice = async (id?: string) => {
    if (accessDeviceId) {
      try {
        setLoading(true);
        // TODO: добавить в АПИ получение УД по id
        const accessDeviceInfo = await AccessDevicesApi.getAccessDevice(Number(id));
        setAccessDeviceInfo(accessDeviceInfo);
        const accessDeviceItems = await AccessDevicesApi.getAccessDevices({
          page: 1,
          size: 100,
          name: accessDeviceInfo.name.toLowerCase(),
        });
        const preparedAccessDevice = accessDeviceItems.items.find(
          (device) => device.accessDeviceId === Number(accessDeviceId),
        );
        if (preparedAccessDevice) {
          setAccessDevice(preparedAccessDevice);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const onClickEdit = () => {
    openModal();
  };

  const onAfterUpdate = () => {
    getAccessDevice(accessDeviceId);
  };

  const onAfterDelete = () => {
    navigate(generatePath(routes.accessDevices));
  };

  return (
    <div className={sn()}>
      <TitleBlock
        title={`Карточка устройства доступа ${accessDevice?.name || ''}`}
        breadcrumbs={accessDeviceBreadcrumbs(accessDevice?.name || '')}
      />
      <h3 className={sn('subtitle')}>Информация о устройстве доступа</h3>
      <AccessDevicesTable
        loading={loading}
        data={{total: 1, count: 1, items}}
        onClickEdit={onClickEdit}
        onAfterDelete={onAfterDelete}
      />
      <h3 className={sn('subtitle')}>Доступ</h3>
      <div className={sn('address')}>
        <TextField
          name="address"
          variant="outlined"
          fullWidth
          className={sn('address_disable')}
          value={accessDevice?.address || ''}
          label="Адрес"
          inputProps={{readOnly: true}}
        />
      </div>
      <AccessDeviceCamera loading={loading} accessDevice={accessDeviceInfo} onAfterUpdate={onAfterUpdate} />
      {isOpen && (
        <EditAccessDevice accessDeviceId={Number(accessDeviceId)} onClose={closeModal} onAfterUpdate={onAfterUpdate} />
      )}
    </div>
  );
};
