import {SettingBar} from './components/SettingBar';
import {ParkingSectionTable} from './components/ParkingSectionTable';
import {Button} from '@mui/material';
import {bem} from 'shared/utils';
import styles from './SecondStep.module.scss';
import {ParkingSectionTab} from '../../constants';
import {useRootSelector} from 'store';
import {ParkingSectionCreateModel} from 'shared/models';
import {LoadingButton} from '@mui/lab';

type Props = {
  saving: boolean;
  onSave: (payload: ParkingSectionCreateModel) => void;
  onCancel: () => void;
  onChangeTab: (tab: ParkingSectionTab) => void;
};

const sn = bem('secondStep', styles);

export const SecondStep = ({onSave, onCancel, onChangeTab, saving}: Props) => {
  const settings = useRootSelector((state) => state.parkingSectionConstructor.parkingSection);

  const onPrevStep = () => {
    onChangeTab(ParkingSectionTab.first);
  };

  const onBeforeSave = () => {
    const preparedFloors = settings.floors.map((f) => ({
      floorNumber: f.floorNumber,
      apartmentNumbers: f.apartmentNumbers,
    }));

    onSave({...settings, floors: preparedFloors});
  };

  return (
    <div className={sn()}>
      <div>
        <SettingBar />
        <ParkingSectionTable />
      </div>
      <div className={sn('footer')}>
        <Button variant="contained" onClick={onCancel}>
          Отмена
        </Button>
        <Button variant="contained" onClick={onPrevStep}>
          Предыдущий шаг
        </Button>
        <LoadingButton type="submit" variant="contained" color="secondary" loading={saving} onClick={onBeforeSave}>
          Сохранить
        </LoadingButton>
      </div>
    </div>
  );
};
