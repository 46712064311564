import React, {useEffect, useState} from 'react';

import {CourtyardsServiceApi} from 'api';
import styles from 'modules/AddNewIntercom/AddIntercom.module.scss';
import {CourtyardModel} from 'shared/models';

import {usePrevious} from '../../hooks';
import {bem} from '../../utils';
import {AutocompleteField} from '../AutocompleteField';
import {searchCourtyards} from '../AutocompleteField/utils';
import {CourtyardType} from '../../constants/common';

const sn = bem('addNewIntercom', styles);

type Props = {
  courtyard: CourtyardModel | null;
  updateCourtyard: (value: CourtyardModel | null) => void;
  apartmentComplexId?: number | null;
  courtyardType?: CourtyardType;
  label?: string;
  errorText?: string;
  disable?: boolean;
};

export const CourtyardAutocomplete = ({
  courtyard,
  updateCourtyard,
  apartmentComplexId,
  courtyardType = CourtyardType.courtyard,
  label = 'Название двора',
  disable,
  ...props
}: Props) => {
  const [defaultCourtyards, setDefaultCourtyards] = useState<CourtyardModel[]>([]);
  const [loadingDefaultCourtyards, setLoadingDefaultCourtyards] = useState(false);

  const prevParentId = usePrevious(apartmentComplexId);

  useEffect(() => {
    if ((!apartmentComplexId && courtyard) || (prevParentId && prevParentId !== apartmentComplexId)) {
      onChangeCourtyard(null);
    }
    if (apartmentComplexId) {
      getInitCourtyards();
    }
  }, [apartmentComplexId, courtyard, prevParentId]);

  const getInitCourtyards = async () => {
    try {
      setLoadingDefaultCourtyards(true);
      const res = await CourtyardsServiceApi.getCourtyards(String(apartmentComplexId), {courtyardType});
      setDefaultCourtyards(res.items);
    } finally {
      setLoadingDefaultCourtyards(false);
    }
  };

  const onChangeCourtyard = (value: CourtyardModel | null) => {
    updateCourtyard(value);
  };

  return (
    <AutocompleteField<CourtyardModel>
      label={label}
      classNames={sn('autocomplete')}
      value={courtyard}
      updateValue={onChangeCourtyard}
      getOptions={searchCourtyards(apartmentComplexId)}
      loadingDefaultOptions={loadingDefaultCourtyards}
      defaultOptions={defaultCourtyards}
      disabled={disable}
      {...props}
    />
  );
};
