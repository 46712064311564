import {GridCellParams, GridPaginationModel} from '@mui/x-data-grid';
import {generatePath, useNavigate, useParams} from 'react-router';

import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {routes} from 'shared/constants/routes';
import {AccountRoles, BaseHouseModel, WithPagination} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {getColumns} from './columns';
import styles from './HousesTable.module.scss';

type Props = {
  loading: boolean;
  houses: WithPagination<BaseHouseModel>;
  deleteHouse: (values: BaseHouseModel) => void;
  paginationModel: GridPaginationModel;
  updatePaginationModel: (value: GridPaginationModel) => void;
};

const sn = bem('housesTable', styles);

const HousesTable = ({loading, houses, deleteHouse, paginationModel, updatePaginationModel}: Props) => {
  const navigate = useNavigate();
  const {apartmentComplexId, courtyardId} = useParams<{
    apartmentComplexId: string;
    courtyardId: string;
  }>();
  const {confirm} = useConfirm();
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);

  const onCellClick = (params: GridCellParams<BaseHouseModel>) => {
    const pathParams = {apartmentComplexId, courtyardId, houseId: params.row.buildingId};
    if (params.field === 'number') {
      navigate(generatePath(routes.apartments, pathParams));
    }
    if (params.field === 'edit') {
      if (isDisabled) return;
      navigate(generatePath(routes.editHouse, pathParams));
    }

    if (params.field === 'delete') {
      if (isDisabled) return;
      onDeleteClick(params.row);
    }
  };

  const onDeleteClick = async (values: BaseHouseModel) => {
    const confirmPayload = {
      title: 'Удаление дома',
      description: 'Все данные этого дома будут безвозвратно удалены из системы',
      acceptText: 'Удалить',
    };
    if (await confirm(confirmPayload)) {
      deleteHouse(values);
    }
  };

  return (
    <div className={styles.housesTable}>
      <Table
        data={houses}
        loading={loading}
        className={sn('table')}
        columns={getColumns({isDisabled})}
        onCellClick={onCellClick}
        paginationModel={paginationModel}
        updatePaginationModel={updatePaginationModel}
      />
    </div>
  );
};
export {HousesTable};
