import {TextField} from '@mui/material';
import {useFormik} from 'formik';
import {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';

import {AddressAutocomplete, Loader} from 'shared/components';
import {Modal} from 'shared/components/Modal';
import {useDebounce} from 'shared/hooks';
import {AddressOptionType} from 'shared/models';
import {bem, isInvalidWhitespaceInput, checkAvailability} from 'shared/utils';

import {validation} from './validation';

import styles from '../../Gates.module.scss';

type Props = {
  isOpen: boolean;
  loading?: boolean;
  saving?: boolean;
  title: string;
  closeModal: () => void;
  initGate?: FormValues | null;
  onSave: (values: FormValues) => void;
  apartmentComplexId?: string;
  courtyardId?: string;
};

const initialValues: FormValues = {
  name: '',
  streetId: null,
  streetAddress: '',
  streetNumber: '',
};

export type FormValues = {
  name: string;
  streetId: number | null;
  streetAddress: string;
  streetNumber: string;
};

const ERROR_NAME_NOT_AVAIBLE = 'Калитка с таким названием уже существует';

const sn = bem('gates', styles);

export const GateForm = ({
  isOpen,
  closeModal,
  title,
  initGate,
  loading,
  saving,
  onSave,
  apartmentComplexId,
  courtyardId,
}: Props) => {
  const [isNameAvailable, setIsNameAvailable] = useState(true);
  const [loadingName, setLoadingName] = useState(false);
  const onSubmit = async (values: FormValues) => {
    await onSave(values);
    onCancel();
  };

  const {handleSubmit, values, setValues, touched, errors, resetForm, setFieldValue, handleBlur} = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (initGate) {
      setValues(initGate);
    }
  }, [initGate]);

  const onCancel = () => {
    resetForm();
    closeModal();
  };

  const onAddressChange = (value: string) => {
    setFieldValue('streetAddress', value);
    setFieldValue('streetId', null);
  };

  const onAddressSelect = (event: SyntheticEvent<Element, Event>, newValue: unknown) => {
    const value = newValue as AddressOptionType;
    if (value?.fullAddress) {
      setFieldValue('streetAddress', value.fullAddress);
      setFieldValue('streetId', Number(value.id));
    } else {
      setFieldValue('streetId', null);
      setFieldValue('streetAddress', '');
    }
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isInvalidWhitespaceInput(value)) setFieldValue('name', value);

    setLoadingName(true);
    setIsNameAvailable(true);
    checkName(value);
  };

  const onStreetNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isInvalidWhitespaceInput(value)) setFieldValue('streetNumber', value);
  };

  const searchName = useDebounce(async (value) => {
    if (apartmentComplexId && courtyardId) {
      checkAvailability({name: value, apartmentComplexId, courtyardId}, 'gate', setIsNameAvailable, setLoadingName);
    }
  }, 1000);

  const checkName = (value: string) => {
    if (value === initGate?.name) {
      setLoadingName(false);
      return;
    }
    searchName(value);
  };

  return (
    <Modal
      maxWidth="sm"
      isOpen={isOpen}
      onClose={closeModal}
      onSave={handleSubmit}
      onCancel={onCancel}
      title={title}
      closeButton
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      disableSave={
        loading || saving || loadingName || !values.streetId || !values.name || !values.streetNumber || !isNameAvailable
      }
    >
      {loading ? (
        <div className={sn('editing__wrapLoader')}>
          <Loader />
        </div>
      ) : (
        <>
          <TextField
            name="name"
            autoFocus
            margin="dense"
            label="Название калитки"
            type="text"
            fullWidth
            required
            value={values.name}
            onBlur={handleBlur}
            onChange={onNameChange}
            error={touched.name && (Boolean(errors.name) || !isNameAvailable)}
            helperText={touched.name && (errors.name || (!isNameAvailable && ERROR_NAME_NOT_AVAIBLE))}
          />
          <AddressAutocomplete
            name="streetAddress"
            label="Адрес *"
            value={values.streetAddress}
            onAddressChange={onAddressChange}
            onAddressSelect={onAddressSelect}
            error={Boolean(errors.streetId)}
            helperText={errors.streetId}
          />
          <TextField
            name="streetNumber"
            margin="dense"
            label="Номер дома"
            type="text"
            required
            fullWidth
            value={values.streetNumber}
            onBlur={handleBlur}
            onChange={onStreetNumberChange}
            error={touched.streetNumber && Boolean(errors.streetNumber)}
            helperText={touched.streetNumber && errors.streetNumber}
          />
        </>
      )}
    </Modal>
  );
};
