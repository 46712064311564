import {createContext, useState, ReactNode} from 'react';

import {Toast} from 'shared/components/Toast';

type ToastProps = {
  title: string;
  message: string;
  isError?: boolean;
};

type ToastContextType = {
  isToastVisible: boolean;
  showToast: (props: ToastProps) => void;
  hideToast: () => void;
  toastProps: ToastProps;
};

export const ToastContext = createContext<ToastContextType | undefined>(undefined);

type ToastProviderProps = {
  children: ReactNode;
};

export const ToastProvider = ({children}: ToastProviderProps) => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [toastProps, setToastProps] = useState<ToastProps>({
    title: '',
    message: '',
  });

  const showToast = (props: ToastProps) => {
    setToastProps(props);
    setIsToastVisible(true);
  };

  const hideToast = () => {
    setIsToastVisible(false);
  };

  return (
    <ToastContext.Provider value={{isToastVisible, showToast, hideToast, toastProps}}>
      {children}
      <Toast isOpen={isToastVisible} close={hideToast} {...toastProps} />
    </ToastContext.Provider>
  );
};
