import {useRootDispatch, useRootSelector} from 'store';
import {setAccessDeviceData, setIdEditAccessDevice, setPaginationModel} from '../../store';
import {AccessDevicesTable} from '../AccessDevicesTable';
import {GridPaginationModel} from '@mui/x-data-grid';

type Props = {
  loading: boolean;
};

export const AccessDevicesListTable = ({loading}: Props) => {
  const data = useRootSelector((state) => state.accessDevices.accessDevicesData);
  const paginationModel = useRootSelector((state) => state.accessDevices.paginationModel);
  const dispatch = useRootDispatch();

  const onClickEdit = (accessDeviceId: number) => dispatch(setIdEditAccessDevice(accessDeviceId));

  const updatePaginationModel = (model: GridPaginationModel) => {
    dispatch(setPaginationModel(model));
  };

  const onAfterDelete = (id: number) => {
    const filteredItems = data.items.filter(accessDevice => accessDevice.accessDeviceId !== id);
    dispatch(setAccessDeviceData({...data, items: filteredItems}));
  };

  return (
    <AccessDevicesTable
      loading={loading}
      onClickEdit={onClickEdit}
      data={data}
      paginationModel={paginationModel}
      updatePaginationModel={updatePaginationModel}
      onAfterDelete={onAfterDelete}
    />
  );
};
