import {AccessDeviceModal} from '../AccessDeviceModal';
import {useState} from 'react';
import {AccessDevicesApi} from 'api/services/accessDevicesService';
import {AccessDeviceCreateModel, AccessDeviceType} from 'shared/models';
import {useToast} from 'shared/hooks';
import {useRootDispatch, useRootSelector} from 'store';
import {setIsAddAccessDevice} from '../../store';
import {AccessDeviceFormValues} from "../AccessDeviceModal/types";

const getPreparedValues = (values: AccessDeviceFormValues): AccessDeviceCreateModel => {
  return {
    name: values.name,
    triggerUrl: values.triggerUrl,
    restartUrl: values.restartUrl || null,
    buildingId: values.address.buildingId,
    hidden: values.hidden,
    entranceNumber: values.address.entranceNumber,
    type: AccessDeviceType.barrier,
  };
};

type Props = {
  onAfterAdd: () => void;
}

export const AddAccessDevice = ({onAfterAdd}: Props) => {
  const [saving, setSaving] = useState(false);
  const {showToast} = useToast();
  const dispatch = useRootDispatch();
  const isOpen = useRootSelector((state) => state.accessDevices.isAddAccessDevice);

  const onSave = async (payload: AccessDeviceFormValues) => {
    try {
      const preparedPayload = getPreparedValues(payload);
      await AccessDevicesApi.addAccessDevice(preparedPayload);
      onAfterAdd();
      onClose();
      showToast({
        title: 'Устройство доступа добавлено',
        message: `Устройство доступа ${payload.name} успешно добавлено.`,
      });
    } catch (err) {
      showToast({
        title: 'Устройство доступа не добавлено',
        message: 'Ошибка создания устройства доступа',
        isError: true,
      });
    } finally {
      setSaving(false);
    }
  };

  const onClose = () => {
    dispatch(setIsAddAccessDevice(false));
  };

  return (
    <AccessDeviceModal
      title="Новое устройство доступа"
      onSave={onSave}
      onClose={onClose}
      saving={saving}
      isOpen={isOpen}
    />
  );
};
