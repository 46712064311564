import {AccessDeviceModal} from '../AccessDeviceModal';
import {useEffect, useState} from 'react';
import {useToast} from 'shared/hooks';
import {AccessDeviceModel, AccessDeviceUpdateModel} from 'shared/models';
import {AccessDevicesApi} from 'api/services/accessDevicesService';
import {AccessDeviceFormValues} from '../AccessDeviceModal/types';

const getPreparedPayload = (values: AccessDeviceFormValues): AccessDeviceUpdateModel => {
  return {
    name: values.name,
    triggerUrl: values.triggerUrl,
    restartUrl: values.restartUrl,
    buildingId: values.address.buildingId,
    hidden: values.hidden,
    entranceNumber: values.address.entranceNumber,
    cameraId: values.cameraId,
  };
};

type Props = {
  accessDeviceId: number | null;
  onAfterUpdate?: () => void;
  onClose: () => void;
};

export const EditAccessDevice = ({onAfterUpdate, accessDeviceId, onClose}: Props) => {
  const [accessDevice, setAccessDevice] = useState<AccessDeviceModel | null>(null);
  const [saving, setSaving] = useState(false);

  const {showToast} = useToast();

  useEffect(() => {
    getAccessDevice();
  }, [accessDeviceId]);

  const getAccessDevice = async () => {
    if (accessDeviceId) {
      const res = await AccessDevicesApi.getAccessDevice(accessDeviceId);
      setAccessDevice(res);
    }
  };

  const onSave = async (payload: AccessDeviceFormValues) => {
    if (accessDeviceId) {
      try {
        const preparedPayload = getPreparedPayload(payload);
        await AccessDevicesApi.updateAccessDevice(accessDeviceId, preparedPayload);
        if (onAfterUpdate) onAfterUpdate();
        onClose();
        showToast({
          title: 'Устройство доступа обновлено',
          message: `Устройство доступа ${payload.name} успешно обновлено.`,
        });
      } catch (err) {
        showToast({
          title: 'Устройство доступа не обновлено',
          message: 'Ошибка обновления устройства доступа',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <AccessDeviceModal
      title="Редактирование устройства доступа"
      onSave={onSave}
      onClose={onClose}
      saving={saving}
      isOpen={!!accessDeviceId}
      accessDevice={accessDevice}
    />
  );
};
