import {useCallback, useRef} from 'react';

import {debounce} from 'shared/utils/debounce';

// eslint-disable @typescript-eslint/no-explicit-any
export function useDebounce<F extends (...args: any[]) => void>(fn: F, delay: number) {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  return useCallback(
    debounce((...args: Parameters<F>) => {
      return typeof fnRef.current === 'function' && fnRef.current(...args);
    }, delay),
    [delay],
  );
}
