export const failedDeleteMessage = {
  title: 'Паркинг не удалён',
  message: 'Не удалось удалить паркинг.',
  isError: true,
};

export const getSuccessDeleteMessage = (name: string, address: string) => ({
  title: 'Паркинг удалён',
  message: `Паркинг ${name} ${address ? 'по адресу' + address : ''} успешно удалён.`,
});
