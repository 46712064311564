import * as Yup from 'yup';

import {lastName, secondName, firstName, phoneNumber, email} from 'shared/constants/validationRules';

export const validation = Yup.object({
  firstName: firstName,
  lastName: lastName,
  secondName: secondName,
  phoneNumber: phoneNumber,
  email: email,
});
