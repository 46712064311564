import {useEffect, useState} from 'react';

import {ApartmentComplexesApi} from 'api';
import {useToast} from 'shared/hooks';
import {ApartmentComplexesModel, ApartmentComplexInfoModel} from 'shared/models';

import {ApartmentComplexForm} from '../ApartmentComplexForm';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  apartmentComplexId: number | null;
  onAfterEditing: (editingComplex: ApartmentComplexesModel) => void;
};

export const EditApartmentComplex = ({isOpen, closeModal, apartmentComplexId, onAfterEditing}: Props) => {
  const [loading, setLoading] = useState(false);
  const [apartmentComplex, setApartmentComplex] = useState<ApartmentComplexInfoModel | null>(null);
  const {showToast} = useToast();

  useEffect(() => {
    getApartmentComplex();
  }, [apartmentComplexId]);

  const getApartmentComplex = async () => {
    if (apartmentComplexId) {
      try {
        setLoading(true);
        const res = await ApartmentComplexesApi.getApartmentComplex(apartmentComplexId);
        setApartmentComplex(res);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateApartmentComplexes = async (values: ApartmentComplexInfoModel) => {
    if (apartmentComplexId) {
      try {
        setLoading(true);
        const payload = {
          name: values.name,
          streetId: values.streetId || undefined,
          streetNumber: values.streetNumber || undefined,
        }
        const res = await ApartmentComplexesApi.updateApartmentComplexes(apartmentComplexId, payload);
        onAfterEditing(res);
        showToast({
          title: 'ЖК отредактирован',
          message: `ЖК ${values.name} по адресу ${values.streetAddress} успешно добавлен.`,
        });
      } catch (err) {
        showToast({
          title: 'ЖК не отредактирован',
          message: 'Не удалось отредактировать ЖК.',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ApartmentComplexForm
      loading={loading}
      isOpen={isOpen}
      title="Редактирование ЖК"
      closeModal={closeModal}
      initApartmentComplex={apartmentComplex}
      onSave={updateApartmentComplexes}
    />
  );
};
