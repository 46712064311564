import {apiClient} from 'api/client';
import {
  CameraItemModel,
  IntercomInfoModel,
  IntercomModel,
  NewIntercom,
  RequestWithPagination,
  SearchIntercoms,
  WithPagination
} from 'shared/models';

class IntercomsService {
  private getBasePath(value: string) {
    return `/managing/devices/sip-panels${value}`;
  }

  getIntercoms(payload?: RequestWithPagination): Promise<WithPagination<IntercomModel>> {
    return apiClient.get(this.getBasePath(''), {params: payload}).then((res) => res.data);
  }

  searchIntercoms(payload?: RequestWithPagination<SearchIntercoms>): Promise<WithPagination<IntercomModel>> {
    return apiClient.get(this.getBasePath('/search'), {params: payload}).then((res) => res.data);
  }

  getIntercom(id: number): Promise<IntercomInfoModel> {
    return apiClient.get(this.getBasePath(`/${id}`)).then((res) => res.data);
  }

  unlinkIntercom(id: number) {
    return apiClient.post(this.getBasePath(`/${id}/unlink`)).then((res) => res.data);
  }

  openDoor(id: number) {
    return apiClient.post(this.getBasePath(`/${id}/open-doors`)).then((res) => res.data);
  }

  restartIntercom(id: number) {
    return apiClient.post(this.getBasePath(`/${id}/restart`)).then((res) => res.data);
  }

  addIntercom(payload: Partial<NewIntercom>): Promise<IntercomInfoModel> {
    return apiClient.post(this.getBasePath(''), payload).then((res) => res.data);
  }

  editIntercom(id: number, payload: Partial<NewIntercom>): Promise<IntercomInfoModel> {
    return apiClient.patch(this.getBasePath(`/${id}`), payload).then((res) => res.data);
  }

  deleteIntercom(id: number) {
    return apiClient.delete(this.getBasePath(`/${id}`)).then((res) => res.data);
  }

  addIntercomCamera(id: number, payload: {cameraIds: number[]}): Promise<CameraItemModel> {
    return apiClient.patch(this.getBasePath(`/${id}/camera-set`), payload).then((res) => res.data);
  }

}

export const IntercomsApi = new IntercomsService();
