export const MAX_LENGTH = 100;
export const ERROR_LENGTH = `Название двора не может быть больше ${MAX_LENGTH} символов`;
export const ERROR_SYMBOLS = 'Название двора не может состоять только из специальных символов';

export const confirmDeletePayload = {
  title: 'Удаление двора',
  description: 'Все данные этого двора будут безвозвратно удалены из системы',
  acceptText: 'Удалить',
};

export const getSuccessDeleteMessage = (name: string) => ({
  title: 'Двор удалён',
  message: `Двор ${name} успешно удалён.`,
});

export const failedDeleteMessage = {
  title: 'Двор не удалён',
  message: 'Не удалось удалить двор.',
  isError: true,
}
