import React, {useEffect, useState} from 'react';

import {GatesApi} from 'api';
import styles from 'modules/AddNewIntercom/AddIntercom.module.scss';
import {GateModel} from 'shared/models';

import {usePrevious} from '../../hooks';
import {bem} from '../../utils';
import {AutocompleteField} from '../AutocompleteField';
import {searchGates} from '../AutocompleteField/utils';

const sn = bem('addNewIntercom', styles);

type Props = {
  gate: GateModel | null;
  updateGate: (value: GateModel | null) => void;
  apartmentComplexId?: number;
  courtyardId?: number;
  required?: boolean;
  errorText?: string;
};

export const GateAutocomplete = ({gate, updateGate, apartmentComplexId, courtyardId, required, errorText}: Props) => {
  const [defaultGates, setDefaultGates] = useState<GateModel[]>([]);
  const [loadingDefaultGates, setLoadingDefaultGates] = useState(false);

  const prevParentId = usePrevious(courtyardId);

  useEffect(() => {
    if (courtyardId) {
      getInitGates();
    }
    if ((!courtyardId && gate) || (prevParentId && courtyardId !== prevParentId)) {
      onChangeGate(null);
    }
  }, [courtyardId, prevParentId]);

  const onChangeGate = (value: GateModel | null) => {
    updateGate(value);
  };

  const getInitGates = async () => {
    if (!apartmentComplexId || !courtyardId) return;
    try {
      setLoadingDefaultGates(true);
      const res = await GatesApi.getGates(String(apartmentComplexId), String(courtyardId));
      setDefaultGates(res.items);
    } finally {
      setLoadingDefaultGates(false);
    }
  };

  return (
    <AutocompleteField<GateModel>
      label="Название калитки"
      classNames={sn('autocomplete')}
      value={gate}
      updateValue={onChangeGate}
      getOptions={searchGates(apartmentComplexId, courtyardId)}
      loadingDefaultOptions={loadingDefaultGates}
      defaultOptions={defaultGates}
      required={required}
      disabled={!courtyardId}
      errorText={errorText}
    />
  );
};
