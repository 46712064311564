import {Table} from 'shared/components';
import {columns} from './columns';
import {bem} from 'shared/utils';
import styles from './AccessDevicesTable.module.scss';
import {GridCellParams, GridPaginationModel} from '@mui/x-data-grid';
import {useMemo} from 'react';
import {AccessDeviceItemModel, WithPagination} from 'shared/models';
import {AccessDevicesApi} from 'api/services/accessDevicesService';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {useToast} from 'shared/hooks';
import {generatePath, useMatch, useNavigate} from 'react-router';
import {routes} from 'shared/constants/routes';

const sn = bem('accessDevicesTable', styles);

const openConfirmPayload = {
  title: 'Подтверждение действия',
  description: 'Вы действительно хотите открыть?',
  acceptText: 'Открыть',
};

const deleteConfirmPayload = {
  title: 'Подтверждение действия',
  description: 'Вы действительно хотите удалить устройство доступа?',
  acceptText: 'Удалить',
};

const reloadConfirmPayload = {
  title: 'Подтверждение действия',
  description: 'Вы действительно хотите перезагрузить?',
  acceptText: 'Перезагрузить',
};

type Props = {
  loading: boolean;
  data: WithPagination<AccessDeviceItemModel>;
  onClickEdit: (id: number) => void;
  paginationModel?: GridPaginationModel;
  updatePaginationModel?: (model: GridPaginationModel) => void;
  onAfterDelete: (id: number) => void;
};

export const AccessDevicesTable = ({loading, onClickEdit, data, paginationModel, updatePaginationModel, onAfterDelete}: Props) => {
  const {confirm} = useConfirm();
  const {showToast} = useToast();
  const navigate = useNavigate();
  const routeMatch = useMatch(routes.accessDeviceCard);
  const isAccessDeviceCard = routeMatch?.pattern?.path === routes.accessDeviceCard;

  const preparedData = useMemo(() => {
    const preparedItems = data.items.map((item) => ({...item, id: item.accessDeviceId}));
    return {...data, items: preparedItems};
  }, [data]);

  const onOpen = async (id: number, name: string) => {
    if (await confirm(openConfirmPayload)) {
      try {
        await AccessDevicesApi.openAccessDevice(id);
        showToast({
          title: 'Открыто',
          message: `Устройство доступа ${name} открыто`,
        });
      } catch (err) {
        showToast({
          title: 'Ошибка',
          message: 'Ошибка открытия устройства доступа',
          isError: true,
        });
      }
    }
  };

  const onDelete = async (id: number, name: string) => {
    if (await confirm(deleteConfirmPayload)) {
      try {
        await AccessDevicesApi.deleteAccessDevice(id);
        onAfterDelete(id);
        showToast({
          title: 'Удалено',
          message: `Устройство доступа ${name} удалено`,
        });
      } catch (err) {
        showToast({
          title: 'Ошибка',
          message: 'Ошибка удаления устройства доступа',
          isError: true,
        });
      }
    }
  };

  const onReload = async (id: number, name: string) => {
    if (await confirm(reloadConfirmPayload)) {
      try {
        await AccessDevicesApi.reloadAccessDevice(id);
        showToast({
          title: 'Перезагружено',
          message: `Устройство доступа ${name} перезагружено`,
        });
      } catch (err) {
        showToast({
          title: 'Ошибка',
          message: 'Ошибка перезагрузки устройства доступа',
          isError: true,
        });
      }
    }
  };

  const onCellClick = (params: GridCellParams<AccessDeviceItemModel>) => {
    if (params.field === 'name' && !isAccessDeviceCard) {
      navigate(generatePath(routes.accessDeviceCard, {accessDeviceId: params.row.accessDeviceId}));
    }
    if (params.field === 'edit') {
      onClickEdit(params.row.accessDeviceId);
    }
    if (params.field === 'open') {
      onOpen(params.row.accessDeviceId, params.row.name);
    }
    if (params.field === 'reload' && params.row.restartable) {
      onReload(params.row.accessDeviceId, params.row.name);
    }
    if (params.field === 'delete') {
      onDelete(params.row.accessDeviceId, params.row.name);
    }
  };

  return (
    <div className={sn()}>
      <Table
        loading={loading}
        styles={sn({'table': !isAccessDeviceCard})}
        data={preparedData}
        columns={columns({isCard: isAccessDeviceCard})}
        onCellClick={onCellClick}
        paginationModel={paginationModel}
        updatePaginationModel={updatePaginationModel}
      />
    </div>
  );
};
