import {useContext} from 'react';

import {ToastContext} from 'shared/utils/ToastContext';

export const useToast = () => {
  const toastContext = useContext(ToastContext);

  if (!toastContext) {
    throw new Error('useToastContext must be used within a ToastProvider');
  }

  return toastContext;
};
