import {AccountRoles} from 'shared/models';

import {NavMenuSubItem, NavMenu} from '../constants';

const getFilteredSubItems = (subItems: NavMenuSubItem[], role: AccountRoles) => {
  return subItems.filter((subItem) => {
    return subItem.accessRoles.includes(role);
  });
};

export const getFilteredMenu = (menu: NavMenu[], role?: AccountRoles) => {
  return role === undefined
    ? []
    : menu.filter((item) => {
      if (role !== undefined && item.subItems) {
        const filteredSubItems = getFilteredSubItems(item.subItems, role);
        return filteredSubItems.length > 0;
      }
    });
};
