import {FilterOptionsState} from '@mui/base/useAutocomplete/useAutocomplete';
import {TextField, Autocomplete, CircularProgress} from '@mui/material';
import {FC, useEffect, useState, SyntheticEvent} from 'react';

import {UtilsApi} from 'api';
import {DELAY} from 'shared/constants/delay';
import {bem} from 'shared/utils';

import styles from './AddressAutocomplete.module.scss';

import {useDebounce} from '../../hooks';
import {AddressOptionType, FullStreetsModel} from '../../models';

const sn = bem('autocompleteField', styles);

interface AutocompleteFieldProps {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  helperText?: string;
  onAddressChange: (value: string) => void;
  onAddressSelect: (e: SyntheticEvent<Element, Event>, newValue: FullStreetsModel | null) => void;
  classNames?: string;
  required?: boolean;
}

export const AddressAutocomplete: FC<AutocompleteFieldProps> = ({
  name,
  label,
  value,
  error,
  helperText,
  onAddressChange,
  onAddressSelect,
  classNames,
                                                                  required,
}) => {
  const [addressOptions, setAddressOptions] = useState<AddressOptionType[]>([]);
  const [loadingText, setLoadingText] = useState(false);

  useEffect(() => {
    if (value) {
      debounceGetFullStreet(value);
    }
  }, [value]);

  const debounceGetFullStreet = useDebounce(async (searchAddress: string) => {
    setLoadingText(true);
    const streets = await UtilsApi.getFullStreet({searchQuery: searchAddress});
    const preparedOptions = streets.items.map((item) => ({...item, label: item.fullAddress}));
    setAddressOptions(preparedOptions);
    setLoadingText(false);
  }, DELAY);

  const filterOptions = (options: AddressOptionType[], state: FilterOptionsState<AddressOptionType>) => {
    return addressOptions;
  };

  return (
    <Autocomplete
      loading={loadingText}
      fullWidth
      filterOptions={filterOptions}
      options={addressOptions}
      onChange={onAddressSelect}
      className={classNames || ''}
      inputValue={value}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          style={{marginTop: 0}}
          type="text"
          label={label}
          margin="dense"
          required={required}
          onChange={(e) => onAddressChange(e.target.value)}
          value={value}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingText ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
