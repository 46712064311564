import {CalendarMonth as CalendarMonthIcon, Edit as EditIcon} from '@mui/icons-material';
import {Button} from '@mui/material';
import {GridCellParams, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import classNames from 'classnames';

import {ApartmentsModel} from 'shared/models';
import {bem, formatDateToString} from 'shared/utils';

import styles from './ApartmentsTable.module.scss';

const sn = bem('apartmentsTable', styles);

type Props = {
  onAddOwnerClick: (params: GridCellParams<ApartmentsModel>) => void;
  isDisabled: boolean;
};

export const getColumns = ({onAddOwnerClick, isDisabled}: Props): GridColDef[] => {
  return [
    {
      field: 'floorNumber',
      headerName: 'Этаж',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      width: 80,
    },
    {
      field: 'apartmentNumber',
      headerName: 'Кв. №',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      width: 80,
      renderCell: (params: GridRenderCellParams<ApartmentsModel>) => params.value,
    },
    {
      field: 'phone',
      headerName: 'Телефон собственника',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      width: 200,
      renderCell: (params: GridRenderCellParams<ApartmentsModel>) => params?.row?.owner?.phoneNumber || '—',
    },
    {
      field: 'owner',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Собственник',
      renderCell: (params: GridRenderCellParams<ApartmentsModel>) => (
        <>
          {params?.row?.owner?.fullName ? (
            params.row.owner.fullName
          ) : (
            <div>
              <span>{'Нет жильцов. '}</span>
              <Button
                className={sn('addOwner', classNames({addOwner_disabled: isDisabled}))}
                onClick={() => onAddOwnerClick(params)}
                disabled={isDisabled}
              >
                Заселить собственника
              </Button>
            </div>
          )}
        </>
      ),
      sortable: false,
      width: 360,
    },
    {
      field: 'tenantsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во жильцов',
      width: 150,
      renderCell: (params: GridRenderCellParams<ApartmentsModel>) => params?.row?.tenantsCount || '—',
    },
    {
      field: 'additionalTenants',
      headerClassName: sn('column'),
      cellClassName: sn('cell', 'cell_list'),
      headerName: 'Дополнительные жильцы',
      flex: 1,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<ApartmentsModel>) => (
        <>
          {params.row?.tenantNames?.length ? (
            params.row.tenantNames.map((item: string, index: number) => (
              <span className={sn('item')} key={index}>
                {item}
              </span>
            ))
          ) : (
            <span className={sn('item')}>{'—'}</span>
          )}
        </>
      ),
    },
    {
      field: 'expirationDate',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Дата действия услуги',
      width: 170,
      renderCell: (params: GridRenderCellParams<ApartmentsModel>) => {
        const service = params?.row?.services[0];
        return service.expirationDate && service.isActive
          ? formatDateToString(params?.row?.services[0].expirationDate, '.')
          : '—';
      },
    },
    {
      field: 'editService',
      headerName: '',
      headerClassName: sn('emptyHeader'),
      cellClassName: sn('cell'),
      renderCell: () => (
        <div className={sn('cell_edit', classNames({cell_disabled: isDisabled}))}>
          <CalendarMonthIcon />
        </div>
      ),
      maxWidth: 50,
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    {
      field: 'edit',
      headerName: '',
      headerClassName: sn('emptyHeader'),
      cellClassName: sn('cell'),
      renderCell: () => (
        <div className={sn('cell_name')}>
          <EditIcon />
        </div>
      ),
      maxWidth: 50,
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
  ];
};
