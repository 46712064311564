export const failedDeleteMessage = {
  title: 'Дом не удалён',
  message: 'Не удалось удалить дом.',
  isError: true,
};

export const getSuccessDeleteMessage = (number: number, address: string) => ({
  title: 'Дом удалён',
  message: `Дом ${number} по адресу ${address} успешно удалён.`,
});
