import {AccessDeviceType} from 'shared/models';

export const initialValues = {
  name: '',
  cameraId: null,
  triggerUrl: '',
  restartUrl: '',
  hidden: false,
  address: {
    apartmentComplexId: null,
    courtyardId: null,
    buildingId: null,
    entranceNumber: null,
  },
  type: AccessDeviceType.barrier,
};


export const exitConfirmPayload = {
  title: 'Подтверждение действия',
  description: 'Вы действительно хотите выйти без сохранения изменений?',
  acceptText: 'Выйти без сохранения',
};