import {createContext} from 'react';

type ContextType = {
  isSelectedAll: boolean;
  onSelectAll: (isSelectedAll: boolean) => void;
  indeterminate?: boolean;
};

const contextDefaultValue: ContextType = {
  onSelectAll: (isSelectedAll: boolean) => void null,
  isSelectedAll: false,
  indeterminate: false,
};

export const AutocompleteSelectAllContext = createContext<ContextType>(contextDefaultValue);
