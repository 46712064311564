import * as Yup from 'yup';

export const validation = (isRTSPLink: boolean) => Yup.object().shape({
  name: Yup.string().max(50, 'Длина названия не должно превышать 50 символов').required('Поле обязателено для заполнения'),
  login: isRTSPLink ? Yup.string().notRequired() : Yup.string().required('Поле обязателено для заполнения'),
  path: isRTSPLink ? Yup.string().notRequired() : Yup.string().required('Поле обязателено для заполнения'),
  password: isRTSPLink ? Yup.string().notRequired() : Yup.string().required('Поле обязателено для заполнения'),
  host: isRTSPLink ? Yup.string().notRequired() : Yup.string().required('Поле обязателено для заполнения'),
  port: isRTSPLink ? Yup.string().notRequired() : Yup.number().required('Поле обязателено для заполнения').typeError('Порт должен быть числом'),
  url: isRTSPLink ? Yup.string().test('preview', 'В начале обязателено должен быть протокол rtsp://', (value) => {
    const reg = new RegExp('^(rtsp://)');
    return value ? !!reg.exec(value)?.[0] : false;
  }) : Yup.string().notRequired(),
});
