import {TextField} from '@mui/material';
import {ChangeEvent, useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {CourtyardsServiceApi} from 'api';
import {ERROR_LENGTH, ERROR_SYMBOLS, MAX_LENGTH, ERROR_NAME_NOT_AVAILABLE} from 'modules/Parking/constants';
import {Modal} from 'shared/components/Modal';
import {useDebounce, useToast} from 'shared/hooks';
import {ParkingModel} from 'shared/models';
import {checkAvailability, isInvalidWhitespaceInput} from 'shared/utils';
import {isOnlySpecialChars} from 'shared/utils/checkOnlySpecialChars';

type Props = {
  isOpen: boolean;
  parking: ParkingModel | null;
  onAfterSave: () => void;
  closeModal: () => void;
};

export const EditParking = ({isOpen, parking, onAfterSave, closeModal}: Props) => {
  const [isNameAvailable, setIsNameAvailable] = useState(true);
  const [name, setName] = useState(parking?.name || '');
  const {apartmentComplexId} = useParams<{apartmentComplexId: string}>();
  const [saving, setSaving] = useState(false);
  const [errorText, setErrorText] = useState('');
  const {showToast} = useToast();

  const onClose = () => {
    if (name) setName('');
    if (errorText) setErrorText('');
    setIsNameAvailable(true);
    closeModal();
  };

  useEffect(() => {
    if (parking?.name) setName(parking.name);
  }, [parking]);

  const onSave = async () => {
    if (isOnlySpecialChars(name)) {
      setErrorText(ERROR_SYMBOLS);
      return;
    }
    if (apartmentComplexId && parking?.courtyardId) {
      if (name === parking.name) {
        onClose();
        return;
      }
      try {
        setSaving(true);
        await CourtyardsServiceApi.editCourtyard(apartmentComplexId, {name}, parking.courtyardId);
        onAfterSave();
        onClose();
        showToast({
          title: 'Парковка отредактирована',
          message: `Парковка ${name} успешно отредактирована.`,
        });
      } catch (err) {
        showToast({
          title: 'Парковка не отредактирована',
          message: 'Не удалось отредактировать парковку.',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const searchName = useDebounce(async (value) => {
    if (!apartmentComplexId) return;
    checkAvailability(
      {name: value, apartmentComplexId},
      'parking',
      setIsNameAvailable,
      setSaving,
    );
  }, 1000);

  const checkName = (value: string) => {
    if (value === parking?.name) {
      setSaving(false);
      return;
    }
    searchName(value);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (!isInvalidWhitespaceInput(value)) setName(value);
    if (value.length > MAX_LENGTH) setErrorText(ERROR_LENGTH);
    if (value.length <= MAX_LENGTH) setErrorText('');

    setSaving(true);
    setIsNameAvailable(true);
    checkName(value)
  };

  return (
    <Modal
      maxWidth="sm"
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      onCancel={onClose}
      title="Редактирование парковки"
      closeButton
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      disableSave={!name || saving || Boolean(errorText) || !isNameAvailable}
    >
      <TextField
        autoFocus
        margin="dense"
        label="Название парковки"
        type="text"
        fullWidth
        required
        value={name}
        error={Boolean(errorText) || !isNameAvailable}
        helperText={errorText || (!isNameAvailable && ERROR_NAME_NOT_AVAILABLE)}
        onChange={onChange}
      />
    </Modal>
  );
};
