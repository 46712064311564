import {Fence, Delete as DeleteSvg, Edit as EditSvg, DoorSliding} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import classNames from 'classnames';

import styles from 'shared/components/Table/Table.module.scss';
import {bem} from 'shared/utils';

const sn = bem('table', styles);

type Props = {
  isDisabled: boolean;
};

export const getColumns = ({isDisabled}: Props): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Название двора',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      renderCell: (params: GridRenderCellParams) => (
        <div className={sn('cell_name')}>
          <Fence />
          {params.value}
        </div>
      ),
      flex: 1,
    },
    {
      field: 'buildingsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во домов',
      width: 120,
    },
    {
      field: 'activeApartmentsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во заселенных квартир',
      width: 210,
    },
    {
      field: 'gates',
      headerName: 'Калитки',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      sortable: false,
      width: 100,
      renderCell: () => (
        <div className={sn('cell_edit')}>
          <Tooltip title={'Перейти на станицу Калитки'}>
            <DoorSliding />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'edit',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: '',
      sortable: false,
      filterable: false,
      width: 50,
      renderCell: () => (
        <div className={sn('cell_edit', classNames({cell_disabled: isDisabled}))}>
          <EditSvg />
        </div>
      ),
    },
    {
      field: 'delete',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: '',
      sortable: false,
      filterable: false,
      width: 50,
      renderCell: () => (
        <div className={sn('cell_delete', classNames({cell_disabled: isDisabled}))}>
          <DeleteSvg />
        </div>
      ),
    },
  ];
};
