import {useState} from 'react';

interface UseModalProps {
  defaultOpen?: boolean;
}

interface Props {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useModal = ({defaultOpen = false}: UseModalProps = {}): Props => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return {
    isOpen,
    openModal,
    closeModal,
  };
};
