import * as Sentry from '@sentry/browser';
import {useEffect} from 'react';
import {Route, Routes} from 'react-router';
import {Navigate} from 'react-router-dom';

import {Accounts} from 'modules/Accounts';
import {ActiveApartmentsReport} from 'modules/ActiveApartmentsReport/ActiveApartmentsReport';
import {AddNewAccount} from 'modules/AddNewAccount';
import {AddNewCamera} from 'modules/AddNewCamera';
import {AddNewHouse} from 'modules/AddNewHouse';
import {AddNewIntercom} from 'modules/AddNewIntercom';
import {Apartment} from 'modules/Apartment';
import {ApartmentComplexes} from 'modules/ApartmentComplexes';
import {Apartments} from 'modules/Apartments';
import {Auth} from 'modules/Auth';
import {Cameras} from 'modules/Cameras';
import {IntercomCard} from 'modules/IntercomCard';
import {Intercoms} from 'modules/Intercoms';
import {Offices} from 'modules/Offices';
import {ProtectedRoute} from 'ProtectedRoute';
import {PrivateRoute} from 'shared/components/PrivateRoute';
import {routes} from 'shared/constants/routes';
import {BaseLayout} from 'shared/layouts/Base';
import {AccountRoles} from 'shared/models';
import {useRootSelector} from 'store';

import {AccessDevices} from './modules/AccessDevices';
import {Courtyards} from './modules/Courtyards';
import {EditHouse} from './modules/EditHouse';
import {Gates} from './modules/Gates';
import {Houses} from './modules/Houses';
import {Parking} from './modules/Parking';
import {ParkingSectionList} from './modules/ParkingSectionList';
import {NewParkingSection} from './modules/ParkingSectionList/NewParkingSection';
import {EditParkingSection} from './modules/ParkingSectionList/EditParkingSection';
import {ParkingPlacesList} from './modules/ParkingPlacesList';
import {ParkingPlace} from './modules/ParkingPlace';
import {ActiveParkingPlacesReport} from './modules/ActiveParkingPlacesReport';
import {AccessDeviceCard} from './modules/AccessDevices/pages';
import {PaymentsList} from './modules/PaymentsList';

export const MainRoutes = () => {
  const adminRoles = [AccountRoles.admin, AccountRoles.superAdmin];

  const profile = useRootSelector((store) => store.auth.profile);

  useEffect(() => {
    if (profile) {
      Sentry.setUser(profile);
    }
  }, [profile]);

  return (
    <Routes>
      <Route path={routes.auth} element={<Auth />} />
      <Route
        element={
          <PrivateRoute>
            <BaseLayout />
          </PrivateRoute>
        }
      >
        <Route path={routes.home} element={<Navigate to={routes.apartmentComplexes} replace={true} />} />
        <Route path={routes.apartmentComplexes} element={<ApartmentComplexes />} />
        <Route path={routes.courtyards} element={<Courtyards />} />
        <Route path={routes.gates} element={<Gates />} />
        <Route path={routes.parking} element={<Parking />} />
        <Route path={routes.houses} element={<Houses />} />
        <Route path={routes.addParkingSection} element={<NewParkingSection />} />
        <Route path={routes.editParkingSection} element={<EditParkingSection />} />
        <Route path={routes.parkingSectionList} element={<ParkingSectionList />} />
        <Route path={routes.apartments} element={<Apartments />} />
        <Route path={routes.parkingPlacesList} element={<ParkingPlacesList />} />
        <Route path={routes.parkingPlace} element={<ParkingPlace />} />
        <Route path={routes.apartment} element={<Apartment />} />
        <Route path={routes.addHouse} element={<AddNewHouse />} />
        <Route path={routes.editHouse} element={<EditHouse />} />
        <Route path={routes.employees} element={<ProtectedRoute accessRoles={adminRoles} component={<Accounts />} />} />
        <Route
          path={routes.addNewEmployees}
          element={<ProtectedRoute accessRoles={adminRoles} component={<AddNewAccount />} />}
        />
        <Route
          path={routes.payments}
          element={<ProtectedRoute accessRoles={adminRoles} component={<PaymentsList />} />}
        />
        <Route path={routes.offices} element={<ProtectedRoute accessRoles={adminRoles} component={<Offices />} />} />
        <Route
          path={routes.intercoms}
          element={<ProtectedRoute accessRoles={adminRoles} component={<Intercoms />} />}
        />
        <Route path={routes.cameras} element={<ProtectedRoute accessRoles={adminRoles} component={<Cameras />} />} />
        <Route
          path={routes.addNewCamera}
          element={<ProtectedRoute accessRoles={adminRoles} component={<AddNewCamera />} />}
        />
        <Route
          path={routes.intercom}
          element={<ProtectedRoute accessRoles={adminRoles} component={<IntercomCard />} />}
        />
        <Route
          path={routes.addNewIntercom}
          element={<ProtectedRoute accessRoles={adminRoles} component={<AddNewIntercom />} />}
        />
        <Route
          path={routes.accessDevices}
          element={<ProtectedRoute accessRoles={adminRoles} component={<AccessDevices />} />}
        />
        <Route
          path={routes.accessDeviceCard}
          element={<ProtectedRoute accessRoles={adminRoles} component={<AccessDeviceCard />} />}
        />
        <Route path={routes.activeApartments} element={<ActiveApartmentsReport />} />
        <Route path={routes.activeParkingPlaces} element={<ActiveParkingPlacesReport />} />
      </Route>
    </Routes>
  );
};
