import {FC, ReactElement, useEffect} from 'react';
import {useNavigate} from 'react-router';

import {ProfileApi} from 'api';
import {routes} from 'shared/constants/routes';
import {useRootDispatch, useRootSelector} from 'store';
import {setAuth, setProfile} from 'store/authStore';

interface Props {
  children: ReactElement;
}

export const PrivateRoute: FC<Props> = ({children}) => {
  const {isAuth} = useRootSelector((state) => state.auth);
  const dispatch = useRootDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (isAuth === false) {
      navigate(routes.auth);
    }
  }, [isAuth]);

  const getProfile = async () => {
    const profile = await ProfileApi.get();
    dispatch(setAuth(true));
    dispatch(setProfile(profile));
  };

  return isAuth ? <>{children}</> : null;
};
