import {apiClient} from 'api/client';
import {GateInfoModel, GateModel, NewGates, RequestWithPagination, SearchGates, WithPagination} from 'shared/models';

class GatesService {
  private getBasePath(apartmentComplexId: string, courtyardId: string, value: string) {
    return `/properties/apartment-complexes/${apartmentComplexId}/courtyards/${courtyardId}/gates${value}`;
  }

  getGates(
    apartmentComplexId: string,
    courtyardId: string,
    payload?: RequestWithPagination,
  ): Promise<WithPagination<GateModel>> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, courtyardId, ''), {params: payload})
      .then((res) => res.data);
  }

  searchGates(
    apartmentComplexId: string,
    courtyardId: string,
    payload: RequestWithPagination<SearchGates>,
  ): Promise<WithPagination<GateModel>> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, courtyardId, '/search'), {params: payload})
      .then((res) => res.data);
  }

  getGate(apartmentComplexId: string, courtyardId: string, gateId: number): Promise<GateInfoModel> {
    return apiClient.get(this.getBasePath(apartmentComplexId, courtyardId, `/${gateId}`)).then((res) => res.data);
  }

  addGate(apartmentComplexId: string, courtyardId: string, payload: NewGates): Promise<GateModel> {
    return apiClient.post(this.getBasePath(apartmentComplexId, courtyardId, ''), payload).then((res) => res.data);
  }

  editGate(apartmentComplexId: string, courtyardId: string, gateId: number, payload: NewGates): Promise<GateModel> {
    return apiClient
      .put(this.getBasePath(apartmentComplexId, courtyardId, `/${gateId}`), payload)
      .then((res) => res.data);
  }

  deleteGate(apartmentComplexId: string, courtyardId: string, gateId: number) {
    return apiClient.delete(this.getBasePath(apartmentComplexId, courtyardId, `/${gateId}`)).then((res) => res.data);
  }
}

export const GatesApi = new GatesService();
