import {Search as SearchIcon} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import {FC} from 'react';

import {bem} from 'shared/utils';

import styles from './NotFoundComponent.module.scss';

const sn = bem('notFoundComponent', styles);

const NotFoundComponent: FC = () => {
  return (
    <div className={styles.notFoundComponent}>
      <div className={sn('iconContainer')}>
        <SearchIcon fontSize="large" />
      </div>
      <Typography variant="h6">Ничего не найдено</Typography>
    </div>
  );
};

export {NotFoundComponent};
