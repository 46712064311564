import {Button, InputLabel, TextField} from '@mui/material';
import {useState} from 'react';
import {useParams} from 'react-router';

import {IntercomsApi} from 'api';
import {Loader} from 'shared/components';
import {OneLine} from 'shared/components/OneLine';
import {useModal, useToast} from 'shared/hooks';
import {CameraLocationType, IntercomInfoModel} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './VideoCameras.module.scss';

import {VideoCameraListModal} from 'shared/components/VideoCameraListModal';

const sn = bem('videoCameras', styles);

type Props = {
  loading: boolean;
  intercom: IntercomInfoModel | null;
  onAfterUpdateCamera: () => void;
};

export const VideoCameras = ({loading, intercom, onAfterUpdateCamera}: Props) => {
  const {isOpen, openModal, closeModal} = useModal();
  const [deleting, setDeleting] = useState(false);
  const [adding, setAdding] = useState(false);
  const {intercomId} = useParams<{intercomId: string}>();
  const {showToast} = useToast();
  const camera = intercom?.cameras?.[0];

  const onAddCamera = async (ids: number[]) => {
    if (intercomId) {
      try {
        setAdding(true);
        await IntercomsApi.addIntercomCamera(Number(intercomId), {cameraIds: ids});
        onAfterUpdateCamera();
        showToast({
          title: 'Камера добавлена',
          message: 'Камера успешно добавлена.',
          isError: false,
        });
      } catch (err) {
        showToast({
          title: 'Камера добавлена',
          message: 'Ошибка добавления камеры.',
          isError: true,
        });
      } finally {
        setAdding(false);
      }
    }
  };

  const deleteCamera = async () => {
    if (intercomId) {
      try {
        setDeleting(true);
        await IntercomsApi.addIntercomCamera(Number(intercomId), {cameraIds: []});
        onAfterUpdateCamera();
        showToast({
          title: 'Камера удалена',
          message: 'Камера успешно удалена.',
        });
      } catch (err) {
        showToast({
          title: 'Камера не удалена',
          message: 'Ошибка удаления камеры.',
          isError: true,
        });
      } finally {
        setDeleting(false);
      }
    }
  };

  return (
    <div className={styles.videoCameras}>
      <div className={sn('wrapper')}>
        <div className={sn('titleBlock')}>
          <h3 className={sn('subtitle')}>Камеры для видеозвонка</h3>
        </div>
        <div>
          <Button variant="contained" color="secondary" onClick={openModal}>
            {camera ? 'Изменить' : 'Добавить'} камеру
          </Button>
          {camera && (
            <Button variant="contained" color="error" className={sn('deleteCamera')} onClick={deleteCamera}>
              Отвязать камеру
            </Button>
          )}
        </div>
      </div>
      <OneLine />
      {loading || deleting || adding ? (
        <div className={sn('loaderWrap')}>
          <Loader />
        </div>
      ) : (
        <div className={sn('name')}>
          <InputLabel htmlFor="name-input">Камера</InputLabel>
          <TextField
            id="name-input"
            name="name"
            variant="outlined"
            fullWidth
            value={camera?.name}
            inputProps={{readOnly: true}}
          />
        </div>
      )}
      <VideoCameraListModal
        isOpen={isOpen}
        onClose={closeModal}
        onAddCamera={onAddCamera}
        cameraLocation={CameraLocationType.courtyard}
        courtyardGateId={Number(intercom?.address?.courtyardGateId) || undefined}
        buildingId={Number(intercom?.address?.buildingId) || undefined}
        entryNumber={Number(intercom?.address?.entryNumber) || undefined}
      />
    </div>
  );
};
