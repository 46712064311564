import {AccountCircle, Delete as DeleteSvg, NoAccounts} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {IconButton, Tooltip} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';

import ReloadIcon from 'assets/icons/refresh.svg';
import styles from 'shared/components/Table/Table.module.scss';
import {AccountModel} from 'shared/models';
import {bem} from 'shared/utils';

const sn = bem('table', styles);

export const columns = [
  {
    field: 'fullName',
    headerName: 'Сотрудник',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 400,
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'email',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Email',
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'phoneNumber',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Номер телефона',
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 150,
  },
  {
    field: 'access',
    headerName: '',
    headerClassName: sn('column'),
    cellClassName: sn('cell_edit'),
    maxWidth: 50,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridCellParams<AccountModel>) =>
      params.row.active ? (
        <Tooltip title={'Заблокировать сотрудника'}>
          <AccountCircle />
        </Tooltip>
      ) : (
        <Tooltip title={'Разблокировать сотрудника'}>
          <NoAccounts />
        </Tooltip>
      ),
  },
  {
    field: 'resetPassword',
    headerName: '',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 50,
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: () => (
      <Tooltip title={'Сбросить пароль сотрудника'}>
        <IconButton>
          <img src={ReloadIcon} />
        </IconButton>
      </Tooltip>
    ),
  },
  {
    field: 'edit',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: '',
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 50,
    renderCell: () => (
      <div className={sn('cell_edit')}>
        <Tooltip title={'Редактировать аккаунт'}>
          <EditIcon />
        </Tooltip>
      </div>
    ),
  },
  {
    field: 'delete',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: '',
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 50,
    renderCell: () => (
      <div className={sn('cell_delete')}>
        <Tooltip title={'Удалить аккаунт сотрудника'}>
          <DeleteSvg />
        </Tooltip>
      </div>
    ),
  },
];
