import {Form, Formik, FormikProps} from 'formik';
import {useState, FC, useRef} from 'react';

import {ClientsApi} from 'api';
import {FormikTextField} from 'shared/components/FormikTextField';
import {Modal} from 'shared/components/Modal';
import {ClientRoles, CreateClientPayload, TenantFormValues} from 'shared/models';
import {bem, excludeEmptyField} from 'shared/utils';

import styles from './AddUser.module.scss';
import {initialUser} from './constants';
import {validationAdditional, validationOwner} from './validation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  apartmentId: number | null;
  asOwner?: boolean;
  onAfterAdd?: () => void;
  title: string;
};

const sn = bem('addUser', styles);

export const AddUser: FC<Props> = ({isOpen, onClose, onAfterAdd, apartmentId, asOwner = false, title}) => {
  const [saving, setSaving] = useState(false);
  const formik = useRef<FormikProps<TenantFormValues>>(null);

  const onSave = async () => {
    if (!apartmentId || !formik.current) return;
    try {
      setSaving(true);
      await formik.current.validateForm();
      if (!formik.current.isValid) return;
      const payload = {
        ...excludeEmptyField(formik.current.values, 'email'),
        apartmentId,
        asOwner: asOwner,
        role: ClientRoles.client,
      } as CreateClientPayload;
      await ClientsApi.createClient(payload);
      if (onAfterAdd) onAfterAdd();
      onClose();
    } finally {
      setSaving(false);
    }
  };

  const onCancel = () => {
    formik.current?.resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      disableSave={saving}
      maxWidth="md"
      title={title}
      closeButton={true}
      cancelButtonText="Отмена"
      saveButtonText="Сохранить"
      onCancel={onCancel}
      onSave={onSave}
    >
      <Formik
        onSubmit={onSave}
        innerRef={formik}
        className={styles.addUser}
        initialValues={initialUser}
        validationSchema={asOwner ? validationOwner : validationAdditional}
      >
        <Form>
          <div className={sn('main')}>
            <FormikTextField name="lastName" label="Фамилия" required={asOwner} />
            <FormikTextField name="firstName" label="Имя" required />
            <FormikTextField name="secondName" label="Отчество" />
            <FormikTextField name="phoneNumber" label="Телефон" required />
            <FormikTextField name="email" label="Email" />
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
