import {AccountRoles, NewAccount} from 'shared/models/accountModel';

export const initialValues: NewAccount = {
  firstName: '',
  lastName: '',
  secondName: '',
  phoneNumber: '',
  email: '',
  username: '',
  password: '',
  role: AccountRoles.user,
};
