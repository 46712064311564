import {Button, InputLabel, TextField} from '@mui/material';
import {useEffect, useState} from 'react';

import {CamerasApi} from 'api';
import {Loader} from 'shared/components';
import {OneLine} from 'shared/components/OneLine';
import {useModal, useToast} from 'shared/hooks';
import {AccessDeviceModel, CameraInfoModel, CameraLocationType} from 'shared/models';
import {bem} from 'shared/utils';

import styles from './AccessDeviceCamera.module.scss';
import {VideoCameraListModal} from 'shared/components/VideoCameraListModal';
import {AccessDevicesApi} from 'api/services/accessDevicesService';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';

const sn = bem('videoCamera', styles);

type Props = {
  loading: boolean;
  onAfterUpdate: () => void;
  accessDevice: AccessDeviceModel | null;
};

const unlinkConfirmPayload = {
  title: 'Подтверждение действия',
  description: 'Вы действительно хотите отвязать камеру?',
  acceptText: 'Отвязать',
};

export const AccessDeviceCamera = ({loading, accessDevice, onAfterUpdate}: Props) => {
  const {isOpen, openModal, closeModal} = useModal();
  const [deleting, setDeleting] = useState(false);
  const [adding, setAdding] = useState(false);
  const {showToast} = useToast();
  const [camera, setCamera] = useState<CameraInfoModel | null>(null);
  const [cameraLoading, setCameraLoading] = useState(false);
  const {confirm} = useConfirm();

  useEffect(() => {
    if (accessDevice && accessDevice.cameraId) {
      getCameraInfo(accessDevice.cameraId);
    }
  }, [accessDevice]);

  const getCameraInfo = async (id: number) => {
    try {
      setCameraLoading(true);
      const camera = await CamerasApi.getCamera(id);
      setCamera(camera);
    } finally {
      setCameraLoading(false);
    }
  };

  const onAddCamera = async (ids: number[]) => {
    if (accessDevice) {
      try {
        setAdding(true);
        const {address, accessDeviceId, ...payload} = accessDevice;
        await AccessDevicesApi.updateAccessDevice(accessDevice.accessDeviceId, {
          ...payload,
          buildingId: address.buildingId,
          entranceNumber: address.entranceNumber,
          cameraId: ids[0],
        });
        await getCameraInfo(ids[0]);
        onAfterUpdate();
        showToast({
          title: 'Камера добавлена',
          message: 'Камера успешно добавлена.',
          isError: false,
        });
      } catch (err) {
        showToast({
          title: 'Камера добавлена',
          message: 'Ошибка добавления камеры.',
          isError: true,
        });
      } finally {
        setAdding(false);
      }
    }
  };

  const deleteCamera = async () => {
    if (accessDevice && (await confirm(unlinkConfirmPayload))) {
      try {
        setDeleting(true);
        const {address, ...payload} = accessDevice;
        await AccessDevicesApi.updateAccessDevice(accessDevice.accessDeviceId, {
          ...payload,
          buildingId: address.buildingId,
          entranceNumber: address.entranceNumber,
          cameraId: null,
        });
        onAfterUpdate();
        setCamera(null);
        showToast({
          title: 'Камера удалена',
          message: 'Камера успешно удалена.',
        });
      } catch (err) {
        showToast({
          title: 'Камера не удалена',
          message: 'Ошибка удаления камеры.',
          isError: true,
        });
      } finally {
        setDeleting(false);
      }
    }
  };

  return (
    <div className={styles.videoCameras}>
      <div className={sn('wrapper')}>
        <div className={sn('titleBlock')}>
          <h3 className={sn('subtitle')}>Камера устройства доступа</h3>
        </div>
        <div>
          <Button variant="contained" color="secondary" onClick={openModal}>
            {camera ? 'Изменить' : 'Добавить'} камеру
          </Button>
          {camera && (
            <Button variant="contained" color="error" className={sn('deleteCamera')} onClick={deleteCamera}>
              Отвязать камеру
            </Button>
          )}
        </div>
      </div>
      <OneLine />
      {loading || cameraLoading || deleting || adding ? (
        <div className={sn('loaderWrap')}>
          <Loader />
        </div>
      ) : (
        <div className={sn('name')}>
          <InputLabel htmlFor="name-input">Камера</InputLabel>
          <TextField
            id="name-input"
            name="name"
            variant="outlined"
            fullWidth
            value={camera?.name}
            inputProps={{readOnly: true}}
          />
        </div>
      )}
      <VideoCameraListModal
        isOpen={isOpen}
        onClose={closeModal}
        onAddCamera={onAddCamera}
        cameraLocation={CameraLocationType.parking}
        buildingId={accessDevice?.address?.buildingId}
        entryNumber={accessDevice?.address?.entranceNumber}
      />
    </div>
  );
};
