import {FC, ReactElement} from 'react';

import {AccountRoles} from 'shared/models';
import {useRootSelector} from 'store';

import {AccessDenied} from './modules/AccessDenied';

interface RolesAuthRouteProps {
  accessRoles: AccountRoles[];
  component: ReactElement;
}

export const ProtectedRoute: FC<RolesAuthRouteProps> = ({accessRoles, component}) => {
  const {profile} = useRootSelector((state) => state.auth);
  const isAccess = profile && accessRoles.includes(profile.role);

  return isAccess ? component : <AccessDenied />
};
