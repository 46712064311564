import {TextField} from '@mui/material';
import {useField} from 'formik';
import {ChangeEvent} from 'react';

import {bem} from 'shared/utils';

import styles from './FromikTextField.module.scss';

type Props = {
  name: string;
  label?: string;
  classNames?: string;
  type?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string | boolean;
  shrink?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
  inputProps?: {readOnly: boolean};
};

const sn = bem('formikTextField', styles);

export const FormikTextField = ({classNames, error, helperText, shrink, onChange, ...props}: Props) => {
  const [field, meta] = useField(props);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
    field.onChange(event);
  };

  return (
    <TextField
      {...props}
      autoComplete="new-password"
      className={classNames ? classNames : sn('field')}
      error={meta.touched && !!meta.error}
      helperText={helperText ? helperText : meta.error && meta.error}
      value={field.value}
      onChange={onChangeHandler}
      onBlur={field.onBlur}
      InputLabelProps={shrink ? {shrink: field.value} : {}}
    />
  );
};
