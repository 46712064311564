import {ChangeEvent} from 'react';

import {SearchInput} from 'shared/components/SearchInput';
import {bem} from 'shared/utils';
import {useRootDispatch, useRootSelector} from 'store';

import styles from './SearchBlock.module.scss';

import {setSearchPhone, setSearchPaymentId} from '../../store';

const sn = bem('searchBlock', styles);

export const SearchBlock = () => {
  const searchPhone = useRootSelector((state) => state.paymentsList.searchPhone);
  const searchPaymentId = useRootSelector((state) => state.paymentsList.searchPaymentId);
  const dispatch = useRootDispatch();

  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchPhone(e.target.value));
  };

  const onPaymentIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchPaymentId(e.target.value));
  };

  return (
    <div className={sn()}>
      <div>
        <SearchInput placeholder="Телефон" value={searchPhone} onChange={onPhoneChange} />
      </div>
      <div className={sn('address')}>
        <SearchInput placeholder="ID платежа" value={searchPaymentId} onChange={onPaymentIdChange} />
      </div>
    </div>
  );
};
