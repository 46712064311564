import {GridCellParams} from '@mui/x-data-grid';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {GatesApi} from 'api/services';
import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {useDebounce, useModal, usePrevious, useToast} from 'shared/hooks';
import {AccountRoles, GateModel, RequestWithPagination, SearchGates, WithPagination} from 'shared/models';
import {checkAccessForRole} from 'shared/utils/checkAccessForRole';

import {getColumns} from './columns';
import {AddGate} from './components/AddGate';
import {EditGate} from './components/EditGate';
import {GatesHeader} from './components/GatesHeader';
import {confirmDeletePayload, failedDeleteMessage, getSuccessDeleteMessage} from './constants';
import styles from './Gates.module.scss';
import {getPreparedGates, initialGates} from './utils';

export const Gates = () => {
  const [gates, setGates] = useState<WithPagination<GateModel>>(initialGates);
  const [nameSearch, setNameSearch] = useState('');
  const [addressSearch, setAddressSearch] = useState('');
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);
  const {apartmentComplexId, courtyardId} = useParams<{apartmentComplexId: string; courtyardId: string}>();
  const [loading, setLoading] = useState(false);
  const [editingGateId, setEditingGateId] = useState<number | null>(null);

  const {isOpen: isAdding, openModal: openAddingModal, closeModal: closeAddingModal} = useModal();
  const {isOpen: isEditing, openModal: openEditingModal, closeModal: closeEditingModal} = useModal();
  const isActiveFilter = nameSearch.length >= 3 || addressSearch;
  const prevIsActiveFilter = usePrevious(isActiveFilter);
  const {confirm} = useConfirm();
  const {showToast} = useToast();
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);

  useEffect(() => {
    if (!isActiveFilter) {
      getGates();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchGates();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [nameSearch, addressSearch, isActiveFilter, paginationModel]);

  const getGates = async () => {
    if (apartmentComplexId && courtyardId) {
      try {
        setLoading(true);
        const payload = {size: paginationModel.pageSize, page: paginationModel.page + 1};
        const res = await GatesApi.getGates(apartmentComplexId, courtyardId, payload);
        const preparedGates = getPreparedGates(res.items);
        setGates({...res, items: preparedGates});
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteGate = async (values: GateModel) => {
    if (apartmentComplexId && courtyardId) {
      try {
        setLoading(true);
        const res = await GatesApi.deleteGate(apartmentComplexId, courtyardId, values.courtyardGateId);
        const preparedGates = gates.items.filter((g) => g.courtyardGateId !== values.courtyardGateId);
        setGates({...res, items: preparedGates});
        showToast(getSuccessDeleteMessage(values.name, values.address));
      } catch (err) {
        showToast(failedDeleteMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchGates = useDebounce(async () => {
    if (apartmentComplexId && courtyardId) {
      try {
        setLoading(true);
        const payload = getPreparedPayload();
        const res = await GatesApi.searchGates(apartmentComplexId, courtyardId, payload);
        const preparedGates = getPreparedGates(res.items);
        setGates({...res, items: preparedGates});
      } finally {
        setLoading(false);
      }
    }
  }, DELAY);

  const getPreparedPayload = () => {
    const payload: RequestWithPagination<SearchGates> = {
      size: paginationModel.pageSize,
      page: paginationModel.page + 1,
      address: addressSearch,
    };
    if (nameSearch.length >= 3) payload.name = nameSearch;
    return payload;
  };

  const onCellClick = async (params: GridCellParams<GateModel>) => {
    if (params.field === 'edit') {
      if (isDisabled) return;
      openEditingModal();
      setEditingGateId(params.row.courtyardGateId);
      return;
    }
    if (params.field === 'delete') {
      if (isDisabled) return;
      if (await confirm(confirmDeletePayload)) {
        deleteGate(params.row);
      }
      return;
    }
  };

  const onCloseEditModal = () => {
    closeEditingModal();
    setEditingGateId(null);
  };

  return (
    <div className={styles.gates}>
      <GatesHeader
        nameSearch={nameSearch}
        updateNameSearch={setNameSearch}
        addressSearch={addressSearch}
        updateAddressSearch={setAddressSearch}
        openModal={openAddingModal}
      />
      <Table
        data={gates}
        loading={loading}
        columns={getColumns({isDisabled})}
        onCellClick={onCellClick}
        paginationModel={paginationModel}
        updatePaginationModel={setPaginationModel}
      />
      <AddGate isOpen={isAdding} closeModal={closeAddingModal} gates={gates} updateGates={setGates} />
      <EditGate
        isOpen={isEditing}
        editingGateId={editingGateId}
        closeModal={onCloseEditModal}
        gates={gates}
        updateGates={setGates}
      />
    </div>
  );
};
