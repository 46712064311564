import React, {useEffect, useState} from 'react';

import styles from 'modules/AddNewIntercom/AddIntercom.module.scss';

import {bem} from '../../utils';
import {AutocompleteField} from '../AutocompleteField';
import {EntryModelAutocomplete, FloorModelAutocomplete, getHouse} from '../AutocompleteField/utils';
import {CameraAddressType} from '../CameraForm/constants';
import {preparedFloors} from '../CameraForm/utils';

type Props = {
  selectedFloorNumber: number | null | undefined;
  entry: EntryModelAutocomplete | null;
  floor: FloorModelAutocomplete | null;
  updateFloor: (value: FloorModelAutocomplete | null) => void;
  cameraAddressType: CameraAddressType;
  apartmentComplexId?: number;
  courtyardId?: number;
  houseId?: string;
  required?: boolean;
  errorText?: string;
};

const sn = bem('addNewIntercom', styles);

export const FloorAutocomplete = ({
  selectedFloorNumber,
  entry,
  floor,
  updateFloor,
  apartmentComplexId,
  courtyardId,
  houseId,
  required,
  errorText,
}: Props) => {
  const [defaultFloorOptions, setDefaultFloorOptions] = useState<FloorModelAutocomplete[]>([]);

  useEffect(() => {
    prepareDefaultFloorOptions();
  }, [entry]);

  useEffect(() => {
    if (selectedFloorNumber) {
      onChangeFloor({
        floorNumber: selectedFloorNumber,
        floorType: 0,
        apartmentNumbers: [0],
        name: String(selectedFloorNumber),
      });
    }
  }, [selectedFloorNumber, houseId]);

  const prepareDefaultFloorOptions = async () => {
    if (entry?.entryNumber) {
      const house = await getHouse(apartmentComplexId, courtyardId, houseId);
      const _floors = house?.entries?.[entry.entryNumber - 1]?.floors;
      if (_floors) {
        setDefaultFloorOptions(preparedFloors(_floors));
      }
    }
  };

  const onChangeFloor = (value: FloorModelAutocomplete | null) => {
    updateFloor(value);
  };

  return (
    <AutocompleteField<FloorModelAutocomplete>
      label="Номер этажа"
      classNames={sn('autocomplete', 'small')}
      value={floor}
      updateValue={onChangeFloor}
      defaultOptions={defaultFloorOptions}
      required={required}
      disabled={!entry}
      errorText={errorText}
    />
  );
};
