import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Checkbox, TextField, FormControlLabel, Button} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router';

import {IntercomsApi} from 'api';
import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {routes} from 'shared/constants/routes';
import {useBreadcrumbs, useModal, useToast} from 'shared/hooks';
import {useDeviceModels} from 'shared/hooks/useDeviceModels';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {IntercomInfoModel, NewIntercom} from 'shared/models';
import {bem} from 'shared/utils';

import {EditIntercom} from './components/EditIntercom';
import {VideoCameras} from './components/VideoCameras';
import styles from './IntercomCard.module.scss';

import {getColumns} from '../Intercoms/components/IntercomsTable/columns';

const sn = bem('intercomCard', styles);

const confirmPayload = {
  title: 'Удаление домофона',
  description: 'Данные домофона будут безвозвратно удалены из системы',
  acceptText: 'Удалить',
};

export const IntercomCard: FC = () => {
  const [loading, setLoading] = useState(false);
  const [intercom, setIntercom] = useState<IntercomInfoModel | null>(null);
  const [isInvisibleOnMobile, setIsVisibleOnMobile] = useState<boolean>();
  const [isDisabledSaving, setIsDisabledSaving] = useState(true);

  const {showToast} = useToast();
  const {confirm} = useConfirm();
  const {isOpen, openModal, closeModal} = useModal();
  const {intercomId} = useParams<{intercomId: string}>();
  const navigate = useNavigate();
  const {intercomBreadcrumbs} = useBreadcrumbs();
  const deviceModels = useDeviceModels();

  const onChangeVisibleOnMobile = (event: ChangeEvent<HTMLInputElement>) => {
    setIsVisibleOnMobile(event.target.checked);
  };

  const changeVisibleOnMobile = async () => {
    try {
      if (intercom?.sipPanelId) {
        setIsDisabledSaving(true);
        const payload: Partial<NewIntercom> = {...intercom, isInvisible: isInvisibleOnMobile};
        await IntercomsApi.editIntercom(intercom.sipPanelId, payload);
        showToast({
          title: 'Сохранение изменений',
          message: 'Изменения успешно сохранены.',
        });
      }
    } catch {
      showToast({
        title: 'Сохранение изменений',
        message: 'При сохранении изменений произошла ошибка.',
        isError: true,
      });
    } finally {
      setIsDisabledSaving(false);
    }
  };

  const intercoms = useMemo(() => {
    const items = intercom ? [{...intercom, id: intercom.sipPanelId}] : [];
    return {total: 1, count: 1, items};
  }, [intercom]);

  const getIntercom = async (intercomId: string) => {
    try {
      setLoading(true);
      const intercom = await IntercomsApi.getIntercom(Number(intercomId));
      setIntercom(intercom);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (intercomId) {
      getIntercom(intercomId);
    }
  }, [intercomId]);

  useEffect(() => {
    if (intercom && isInvisibleOnMobile !== undefined) {
      setIsDisabledSaving(isInvisibleOnMobile === intercom.isInvisible);
    }
  }, [intercom, isInvisibleOnMobile]);

  useEffect(() => {
    if (intercom) {
      setIsVisibleOnMobile(intercom.isInvisible);
    }
  }, [intercom]);

  const deleteIntercom = async (id: number, name: string) => {
    try {
      setLoading(true);
      await IntercomsApi.deleteIntercom(id);
      showToast({
        title: 'Домофон удалён',
        message: `Домофон ${name} успешно удалён.`,
      });
      navigate(routes.intercoms);
    } catch (err) {
      showToast({
        title: 'Домофон не удалён',
        message: 'При удалении домофона произошла ошибка.',
        isError: true,
      });
    } finally {
      setLoading(true);
    }
  };

  const onCellClick = async (params: GridCellParams<IntercomInfoModel>) => {
    if (['edit', 'name'].includes(params.field)) {
      openModal();
      return;
    }
    if (params.field === 'delete') {
      if (await confirm(confirmPayload)) {
        deleteIntercom(params.row.sipPanelId, params.row.name);
        return;
      }
    }
  };

  const onAfterUpdate = () => {
    if (intercomId) {
      getIntercom(intercomId);
    }
  };

  return (
    <div className={styles.intercomCard}>
      <TitleBlock
        title={`Карточка домофона ${intercom?.name || ''}`}
        breadcrumbs={intercomBreadcrumbs(intercom?.name || '')}
      />
      <h3 className={sn('subtitle')}>Информация о домофоне</h3>
      <Table
        loading={loading}
        data={intercoms}
        onCellClick={onCellClick}
        columns={getColumns({isEdit: true, onAfterUpdate, isCard: true, deviceModels})}
      />
      <EditIntercom
        loading={loading}
        intercom={intercom}
        isOpen={isOpen}
        onClose={closeModal}
        onAfterUpdate={onAfterUpdate}
      />
      <h3 className={sn('subtitle')}>Доступ</h3>
      <div className={sn('address')}>
        <TextField
          name="address"
          variant="outlined"
          fullWidth
          value={intercom?.address?.address || ''}
          label="Адрес"
          inputProps={{readOnly: true}}
        />
      </div>
      <div className={sn('address')}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{marginRight: 8}}
              checked={Boolean(isInvisibleOnMobile)}
              onChange={onChangeVisibleOnMobile}
            />
          }
          label="Не отображать в мобильном приложении"
        />
        <Button variant="contained" size="small" disabled={isDisabledSaving} onClick={changeVisibleOnMobile}>
          Применить
        </Button>
      </div>
      <VideoCameras loading={loading} intercom={intercom} onAfterUpdateCamera={onAfterUpdate} />
    </div>
  );
};
