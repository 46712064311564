import {GridPaginationModel} from '@mui/x-data-grid';
import {useMemo} from 'react';

import {Table} from 'shared/components';
import { PaymentModel, WithPagination} from 'shared/models';
import {bem} from 'shared/utils';

import {columns} from './columns';
import styles from './PaymentsTable.module.scss';

const sn = bem('paymentsListTable', styles);

type Props = {
  loading: boolean;
  data: WithPagination<PaymentModel>;
  onClickEdit: (id: number) => void;
  paginationModel?: GridPaginationModel;
  updatePaginationModel?: (model: GridPaginationModel) => void;
  onAfterDelete: (id: number) => void;
};

export const PaymentsTable = ({loading, data, paginationModel, updatePaginationModel}: Props) => {

  const preparedData = useMemo(() => {
    const preparedItems = data.items.map((item) => ({...item, id: item.id}));
    return {...data, items: preparedItems};
  }, [data]);


  return (
    <div className={sn()}>
      <Table
        loading={loading}
        styles={sn()}
        data={preparedData}
        columns={columns()}
        paginationModel={paginationModel}
        updatePaginationModel={updatePaginationModel}
      />
    </div>
  );
};
