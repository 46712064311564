import {Button} from '@mui/material';
import {ChangeEvent} from 'react';

import {SearchInput} from 'shared/components/SearchInput';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccountRoles} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {useBreadcrumbs} from '../../../shared/hooks';
import styles from '../ApartmentComplexes.module.scss';

type Props = {
  residentialComplexSearch: string;
  updateResidentialComplexSearch: (value: string) => void;
  openModal: () => void;
};

const sn = bem('apartmentComplexes', styles);

const ApartmentsHeader = ({residentialComplexSearch, updateResidentialComplexSearch, openModal}: Props) => {
  const {apartmentComplexesBreadcrumbs} = useBreadcrumbs();

  const onSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    updateResidentialComplexSearch(event.target.value);
  };

  return (
    <>
      <TitleBlock
        title={'Жилые Комплексы'}
        breadcrumbs={apartmentComplexesBreadcrumbs}
        action={
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={openModal}
            disabled={!checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin])}
          >
            Добавить ЖК
          </Button>
        }
      />
      <div className={sn('search')}>
        <SearchInput placeholder="Найти ЖК (мин 3 символа)" onChange={onSearchInput} value={residentialComplexSearch} />
      </div>
    </>
  );
};

export default ApartmentsHeader;
