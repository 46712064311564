import {useContext} from 'react';

import {ConfirmationContext} from './ConfirmationContext';

let resolver: (value: unknown) => void;

export type ConfirmPayload = {
  title?: string;
  description: string;
  acceptText?: string;
  cancelText?: string;
}

export function useConfirm() {
  const {displayConfirm} = useContext(ConfirmationContext);

  const accept = () => {
    resolver(true);
  };

  const cancel = () => {
    resolver(false);
  };

  const confirm = (payload: ConfirmPayload) => {
    displayConfirm(payload);
    return new Promise((res) => {
      resolver = res;
    });
  };

  return {confirm, accept, cancel};
}
