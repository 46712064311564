import {FormObserver} from '../../../FormObserver';
import {Select, SelectChangeEvent, TextField} from '@mui/material';
import {CameraProtocol, CameraProtocols, NewCamera} from '../../../../models';
import MenuItem from '@mui/material/MenuItem';
import {FormikTextField} from '../../../FormikTextField';
import {ERROR_NAME_NOT_AVAILABLE, URL_PATTERN, URL_PATTERN_HELPER_TEXT} from '../../constants';
import React, {ChangeEvent, useState} from 'react';
import {FormikProps, useFormikContext} from 'formik';
import {useDebounce} from '../../../../hooks';
import {bem, checkAvailability} from '../../../../utils';
import styles from './FormValues.module.scss';

const sn = bem('formValues', styles);

export const FormValues = () => {
  const [isNameAvailable, setIsNameAvailable] = useState(true);
  const [loadingName, setLoadingName] = useState(false);
  const [urlPreview, setUrlPreview] = useState('');
  const {setFieldValue, values} = useFormikContext<NewCamera>();
  const protocolValue = values.protocol ? String(values.protocol) : '0';
  const isRTSPLink = values.protocol === CameraProtocol.link;

  const searchName = useDebounce(async (value) => {
    checkAvailability({name: value}, 'camera', setIsNameAvailable, setLoadingName);
  }, 1000);

  const checkName = (value: string) => {
    searchName(value);
  };

  const onNameChange = (value: string) => {
    setLoadingName(true);
    setIsNameAvailable(true);
    checkName(value);
  };

  const onSelectProtocol = (event: SelectChangeEvent) => {
    const protocolValue = Number(event.target.value);
    if (protocolValue === CameraProtocol.link) {
      setFieldValue('url', 'rtsp://');
    }
    if (protocolValue === CameraProtocol.custom) {
      setFieldValue('url', URL_PATTERN);
    }
    setFieldValue('protocol', Number(event.target.value));
  };

  const onChangeUrlPreview = (e: ChangeEvent<HTMLInputElement>) => {
    setUrlPreview(e.target.value);
  };

  const onChangeForm = (values: FormikProps<NewCamera>) => {
    if (values.values && values.values.url && !(values.values.protocol === CameraProtocol.link)) {
      const {
        values: {login = '', password = '', host = '', port, path = '', url = ''},
      } = values;
      const preparedUrlPreview = url
        .replace('{Protocol}', 'rtsp')
        .replace('{Login}', login)
        .replace('{Port}', String(port || ''))
        .replace('{Password}', password)
        .replace('{Host}', host)
        .replace('{Path}', path);
      setUrlPreview(preparedUrlPreview);
    }
  };

  return (
    <div className={styles.formValues}>
      <FormObserver onChange={onChangeForm} />
      <div className={sn('row')}>
        <Select
          id="protocol"
          label="protocol"
          value={protocolValue}
          onChange={onSelectProtocol}
          className={sn('textField')}
        >
          {CameraProtocols.map((c, index) => (
            <MenuItem key={c.description} value={index}>
              {c.description}
            </MenuItem>
          ))}
        </Select>
        {!isRTSPLink && <FormikTextField name="login" label="Логин" required classNames={sn('textField')} />}
        <FormikTextField
          name="name"
          label="Название"
          required
          classNames={sn('textField')}
          onChange={onNameChange}
          error={!isNameAvailable}
          helperText={!isNameAvailable ? ERROR_NAME_NOT_AVAILABLE : undefined}
        />
      </div>
      {!isRTSPLink && (
        <div className={sn('row')}>
          <FormikTextField name="password" type="password" required label="Пароль" classNames={sn('textField')} />
          <FormikTextField name="host" label="Host" required classNames={sn('textField')} />
          <FormikTextField name="port" type="number" label="Port" required classNames={sn('textField')} />
        </div>
      )}
      <div className={sn('row')}>
        {!isRTSPLink && <FormikTextField name="path" label="Path" required classNames={sn('textField')} />}
        {values.protocol !== CameraProtocol.standard && (
          <FormikTextField
            name="url"
            label="Url"
            classNames={sn('textField', 'url')}
            helperText={!isRTSPLink && URL_PATTERN_HELPER_TEXT}
          />
        )}
      </div>
      {!isRTSPLink && (
        <div className={sn('row', 'rowPreview')}>
          <TextField
            name="preview"
            className={sn('textField', 'urlPreview')}
            value={urlPreview}
            inputProps={{readOnly: true}}
            onChange={onChangeUrlPreview}
          />
        </div>
      )}
    </div>
  );
};
