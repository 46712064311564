const TOKEN_KEY_LS = 'token';

export const setTokenToLS = (token: string) => {
  localStorage.setItem(TOKEN_KEY_LS, JSON.stringify(token));
};

export const removeTokenFromLS = () => {
  localStorage.removeItem(TOKEN_KEY_LS);
};

export const getTokenFromLS = (): string => {
  const token = localStorage.getItem(TOKEN_KEY_LS);
  return token ? JSON.parse(token) : null;
};
