import {GridCellParams} from '@mui/x-data-grid';
import {useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router';

import {ApartmentComplexesApi} from 'api';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {Table} from 'shared/components/Table';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {routes} from 'shared/constants/routes';
import {useDebounce, usePrevious, useToast} from 'shared/hooks';
import {useModal} from 'shared/hooks/useModal';
import {AccountRoles, ApartmentComplexesModel, ApartmentComplexInfoModel} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {getColumns} from './columns';
import {AddApartmentComplex} from './components/AddApartmentComplex';
import ApartmentsHeader from './components/ApartmentComplexesHeader';
import {EditApartmentComplex} from './components/EditApartmentComplex';
import {confirmDeletePayload, failedDeleteMessage, getSuccessDeleteMessage} from './constants';
import {initialApartmentComplexes} from './utils';
import styles from './ApartmentComplexes.module.scss';

const sn = bem('apartmentComplexes', styles);

export const ApartmentComplexes = () => {
  const {isOpen, openModal, closeModal} = useModal();
  const [apartmentComplexes, setApartmentComplexes] = useState(initialApartmentComplexes);
  const [editingApartmentComplexId, setEditingApartmentComplexId] = useState<number | null>(null);
  const [residentialComplexSearch, setResidentialComplexSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);
  const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  const {confirm} = useConfirm();
  const {showToast} = useToast();
  const isActiveFilter = residentialComplexSearch.length >= 3;
  const prevIsActiveFilter = usePrevious(isActiveFilter);
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);

  useEffect(() => {
    if (!isActiveFilter) {
      getApartmentComplexes();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchApartmentComplexes();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [residentialComplexSearch, isActiveFilter, paginationModel]);

  const getApartmentComplexes = async () => {
    try {
      setLoading(true);
      const payload = {size: paginationModel.pageSize, page: paginationModel.page + 1};
      const res = await ApartmentComplexesApi.getApartmentComplexes(payload);
      setApartmentComplexes(res);
    } finally {
      setLoading(false);
    }
  };

  const deleteApartmentComplexes = async (id: number, values: ApartmentComplexInfoModel) => {
    setLoading(true);
    try {
      await ApartmentComplexesApi.deleteApartmentComplex(String(id));
      const filteredAllApartmentComplexes = apartmentComplexes.items.filter((item) => item.id !== id);
      setApartmentComplexes({
        ...apartmentComplexes,
        items: filteredAllApartmentComplexes,
        total: apartmentComplexes.total - 1,
      });
      showToast(getSuccessDeleteMessage(values.name, values.streetAddress));
    } catch (err) {
      showToast(failedDeleteMessage);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteClick = async (id: number, values: ApartmentComplexInfoModel) => {
    if (await confirm(confirmDeletePayload)) {
      deleteApartmentComplexes(id, values);
    }
  };

  const searchApartmentComplexes = useDebounce(async () => {
    if (residentialComplexSearch && residentialComplexSearch.length < 3) return;
    const payload = {
      size: paginationModel.pageSize,
      page: paginationModel.page + 1,
      query: residentialComplexSearch,
    };
    try {
      setLoading(true);
      const res = await ApartmentComplexesApi.searchApartmentComplexes(payload);
      setApartmentComplexes(res);
    } finally {
      setLoading(false);
    }
  }, DELAY);

  const onCellClick = async (params: GridCellParams<ApartmentComplexInfoModel>) => {
    if (params.field === 'delete') {
      if (isDisabled) return;
      await onDeleteClick(Number(params.id), params.row);
      return;
    }
    if (params.field === 'edit') {
      if (isDisabled) return;
      if (params.id) {
        setIsEdit(true);
        setEditingApartmentComplexId(Number(params.id));
        return;
      }
    }
    navigate(generatePath(routes.courtyards, {apartmentComplexId: params.id}));
  };

  const onAfterEditing = (editingComplex: ApartmentComplexesModel) => {
    const preparedApartmentComplexes = apartmentComplexes.items.map((complex) =>
      complex.id === editingComplex.id ? editingComplex : complex,
    );
    setApartmentComplexes({...apartmentComplexes, items: preparedApartmentComplexes});
  };

  const closeEditModal = () => {
    setIsEdit(false);
    setEditingApartmentComplexId(null);
  };

  return (
    <>
      <div className={styles.apartmentComplexes}>
        <ApartmentsHeader
          residentialComplexSearch={residentialComplexSearch}
          updateResidentialComplexSearch={setResidentialComplexSearch}
          openModal={openModal}
        />
        <Table
          data={apartmentComplexes}
          className={sn('table')}
          loading={loading}
          onCellClick={onCellClick}
          columns={getColumns({isDisabled})}
          paginationModel={paginationModel}
          updatePaginationModel={setPaginationModel}
        />
      </div>
      {isOpen && (
        <AddApartmentComplex
          isOpen={isOpen}
          closeModal={closeModal}
          apartmentComplexes={apartmentComplexes}
          updateApartmentComplexes={setApartmentComplexes}
        />
      )}
      {isEdit && (
        <EditApartmentComplex
          isOpen={isEdit}
          closeModal={closeEditModal}
          apartmentComplexId={editingApartmentComplexId}
          onAfterEditing={onAfterEditing}
        />
      )}
    </>
  );
};
