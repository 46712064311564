export const confirmDeletePayload = {
  title: 'Удаление калитки',
  description: 'Все данные этой калитки будут безвозвратно удалены из системы',
  acceptText: 'Удалить',
};

export const failedDeleteMessage = {
  title: 'Калитка не удалена',
  message: 'Не удалось удалить калитку.',
  isError: true,
};

export const getSuccessDeleteMessage = (name: string, address: string) => ({
  title: 'Калитка удалена',
  message: `Калитка ${name} по адресу ${address || ''} успешно удалена.`,
});
