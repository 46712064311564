import {bem} from 'shared/utils';
import styles from './AccessDevicesTable.module.scss';
import {
  Delete as DeleteSvg,
  Key as KeySvg,
  Replay as ReplaySvg,
  Edit as EditSvg,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import {GridCellParams, GridRenderCellParams} from '@mui/x-data-grid';
import {AccessDeviceItemModel} from 'shared/models';

const sn = bem('accessDevicesTable', styles);

type Props = {
  isCard?: boolean;
};

export const columns = ({isCard}: Props) => [
  {
    field: 'linked',
    headerName: 'Подключено',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    renderCell: (params: GridRenderCellParams) => (
      <div className={sn('checkbox')}>{params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</div>
    ),
    maxWidth: 150,
  },
  {
    field: 'name',
    headerName: 'Название устройства доступа',
    headerClassName: sn('column'),
    cellClassName: sn('cell', {cell_name: !isCard}),
    maxWidth: 400,
    sortable: true,
    flex: 1,
  },
  {
    field: 'address',
    headerName: 'Адрес',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    sortable: true,
    flex: 1,
  },
  {
    field: 'open',
    headerName: '',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    width: 50,
    renderCell: () => (
      <div className={sn('cell_action')}>
        <KeySvg />
      </div>
    ),
  },
  {
    field: 'reload',
    headerName: '',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    width: 50,
    renderCell: (params: GridCellParams<AccessDeviceItemModel>) => (
      <div className={sn('cell_action', {reload_disable: !params.row.restartable})}>
        <ReplaySvg />
      </div>
    ),
  },
  {
    field: 'edit',
    headerName: '',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    width: 50,
    renderCell: () => (
      <div className={sn('cell_action')}>
        <EditSvg />
      </div>
    ),
  },
  {
    field: 'delete',
    headerName: '',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    width: 50,
    renderCell: () => (
      <div className={sn('cell_delete')}>
        <DeleteSvg />
      </div>
    ),
  },
];
