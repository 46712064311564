import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Checkbox} from '@mui/material';
import {ForwardedRef, forwardRef, useContext, useImperativeHandle, useRef} from 'react';

import {AutocompleteSelectAllContext} from './AutocompleteSelectAllContext';

type ListBoxProps = React.HTMLAttributes<HTMLUListElement>;
type NullableUlElement = HTMLUListElement | null;

const AutocompleteSelectAllListBox = forwardRef((props: ListBoxProps, ref: ForwardedRef<HTMLUListElement>) => {
  const {children, ...rest} = props;

  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current);

  const {onSelectAll, isSelectedAll, indeterminate} = useContext(AutocompleteSelectAllContext);

  return (
    <>
      <ul {...rest} ref={innerRef} role="list-box">
        <li style={{marginLeft: 15}}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{marginRight: 8}}
            checked={isSelectedAll}
            onChange={(_e) => onSelectAll(isSelectedAll)}
            indeterminate={indeterminate}
          />
          Все
        </li>
        {children}
      </ul>
    </>
  );
});

export const AutocompleteSelectAll = {
  Provider: AutocompleteSelectAllContext.Provider,
  ListBox: AutocompleteSelectAllListBox,
};
