import {apiClient} from 'api/client';
import {
  AccessDeviceCreateModel,
  AccessDeviceItemModel,
  AccessDeviceModel,
  AccessDeviceUpdateModel,
  WithPagination,
} from 'shared/models';

class AccessDevicesService {
  private getBasePath(value: string) {
    return `managing/devices/access-devices${value}`;
  }

  getAccessDevices(params: {
    page: number;
    size: number;
    address?: string;
    name?: string;
  }): Promise<WithPagination<AccessDeviceItemModel>> {
    return apiClient.get(this.getBasePath(''), {params}).then((res) => res.data);
  }

  addAccessDevice(payload: AccessDeviceCreateModel): Promise<AccessDeviceModel> {
    return apiClient.post(this.getBasePath(''), payload).then((res) => res.data);
  }

  updateAccessDevice(accessDevicesId: number, payload: Partial<AccessDeviceUpdateModel>): Promise<AccessDeviceModel> {
    return apiClient.put(this.getBasePath(`/${accessDevicesId}`), payload).then((res) => res.data);
  }

  getAccessDevice(accessDeviceId: number): Promise<AccessDeviceModel> {
    return apiClient.get(this.getBasePath(`/${accessDeviceId}`)).then((res) => res.data);
  }

  deleteAccessDevice(accessDeviceId: number): Promise<AccessDeviceModel> {
    return apiClient.delete(this.getBasePath(`/${accessDeviceId}`)).then((res) => res.data);
  }

  reloadAccessDevice(accessDeviceId: number) {
    return apiClient.post(this.getBasePath(`/${accessDeviceId}/restart`)).then((res) => res.data);
  }

  openAccessDevice(accessDeviceId: number) {
    return apiClient.post(this.getBasePath(`/${accessDeviceId}/trigger`)).then((res) => res.data);
  }
}

export const AccessDevicesApi = new AccessDevicesService();
