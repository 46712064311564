import {bem} from 'shared/utils';
import styles from './ParkingSectionConstructor.module.scss';
import {FirstStep} from './components/FirstStep';
import {useEffect, useState} from 'react';
import {ParkingSectionTab} from './constants';
import {SecondStep} from './components/SecondStep';
import {ParkingSectionCreateModel, ParkingSectionModel} from 'shared/models';
import {useRootDispatch} from 'store';
import {reset, setParkingSection} from './store';

const sn = bem('parkingSectionConstructor', styles);

type Props = {
  onSave: (payload: ParkingSectionCreateModel) => void;
  onCancel: () => void;
  saving: boolean;
  parkingSection?: ParkingSectionModel | null;
};

export const ParkingSectionConstructor = ({onSave, onCancel, saving, parkingSection}: Props) => {
  const [activeTab, setActiveTab] = useState(ParkingSectionTab.first);
  const dispatch = useRootDispatch();

  useEffect(() => {
    if (parkingSection) {
      const preparedFloors = parkingSection.floors.map((f, i) => {
        return {
          ...f,
          quantitySeatsOnFloor: f.apartmentNumbers.length,
          range: `${f.apartmentNumbers[0]} — ${f.apartmentNumbers[f.apartmentNumbers.length - 1]}`,
        };
      });

      dispatch(setParkingSection({...parkingSection, floors: preparedFloors}));
    }

    return () => {
      dispatch(reset());
    };
  }, [parkingSection]);

  const onChangeTab = (tab: ParkingSectionTab) => {
    setActiveTab(tab);
  };

  return (
    <div className={sn()}>
      {activeTab === ParkingSectionTab.first ? (
        <FirstStep onChangeTab={onChangeTab} onCancel={onCancel} />
      ) : (
        <SecondStep saving={saving} onSave={onSave} onCancel={onCancel} onChangeTab={onChangeTab} />
      )}
    </div>
  );
};
