import {useRef, useState} from 'react';

import {Modal} from 'shared/components/Modal';
import {ServiceModel} from 'shared/models';

import {ServiceCardTimeExtension, ServiceCardAPI} from '../ServiceCard/ServiceCardTimExtension';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAfterChange: () => void;
  service: ServiceModel | null;
  id: number | null;
  title?: string;
};

export const EditService = ({isOpen, onClose, onAfterChange, service, id}: Props) => {
  const serviceCardApi = useRef<ServiceCardAPI>(null);
  const [isSaveEnable, setIsSaveEnable] = useState(false);

  const onSave = () => {
    if (serviceCardApi.current) {
      serviceCardApi.current.update();
    }
  };

  if (!service || !id) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      disableSave={!isSaveEnable}
      title="Управление услугами"
      closeButton={true}
      onCancel={onClose}
      maxWidth="lg"
    >
      <ServiceCardTimeExtension
        ref={serviceCardApi}
        id={String(id)}
        closeModal={onClose}
        services={service}
        onAfterChangeService={onAfterChange}
        setSave={(value) => setIsSaveEnable(value)}
      />
    </Modal>
  );
};
