import {apiClient} from 'api/client';
import {ProfileModel} from 'shared/models';

class ProfileService {
  get() {
    return apiClient.get<ProfileModel>('/profile').then((res) => res.data);
  }
}

export const ProfileApi = new ProfileService();
