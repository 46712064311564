import {Close} from '@mui/icons-material';

import {EntryInfoModel} from 'shared/models';
import {bem} from 'shared/utils';

import {Entry} from '../../../HouseConstructor/types';
import styles from '../../EntryTabs.module.scss';


type Props = {
  entry: EntryInfoModel | Entry;
  onDeleteTab?: (entry: Entry) => void;
};

const sn = bem('entryTabs', styles);

export const TabLabel = ({entry, onDeleteTab}: Props) => {
  const onClose = (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
    if (onDeleteTab) {
      event.stopPropagation();
      onDeleteTab(entry as Entry);
    }
  };

  return (
    <div className={sn('tab')}>
      <p className={sn('tab_text')}>{`Подъезд ${entry.entryNumber}`}</p>
      <span className={sn('tab_range')}>{`${entry.firstApartmentNumber}-${entry.lastApartmentNumber}`}</span>
      {onDeleteTab && <Close className={sn('tab_delete')} onClick={onClose} />}
    </div>
  );
};
