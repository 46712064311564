import {apiClient} from 'api/client';
import {
  AddCourtyardPayload,
  CourtyardModel,
  GetCourtyardsPayload,
  WithPagination,
} from 'shared/models';

class CourtyardsService {
  private getBasePath(apartmentComplexId: string | number, value: string) {
    return `/properties/apartment-complexes/${apartmentComplexId}/courtyards${value}`;
  }

  getCourtyard(apartmentComplexId: number, courtyardId: number): Promise<CourtyardModel> {
    return apiClient.get(this.getBasePath(apartmentComplexId, `/${courtyardId}`)).then((res) => res.data);
  }

  getCourtyards(apartmentComplexId: string, payload?: GetCourtyardsPayload): Promise<WithPagination<CourtyardModel>> {
    return apiClient
      .get(this.getBasePath(apartmentComplexId, ''), {params: payload})
      .then((res) => res.data);
  }

  addCourtyard(apartmentComplexId: string, payload: AddCourtyardPayload): Promise<CourtyardModel> {
    return apiClient.post(this.getBasePath(apartmentComplexId, ''), payload).then((res) => res.data);
  }

  editCourtyard(
    apartmentComplexId: string,
    payload: {name: string},
    courtyardId: number,
  ): Promise<CourtyardModel> {
    return apiClient.put(this.getBasePath(apartmentComplexId, `/${courtyardId}`), payload).then((res) => res.data);
  }

  deleteCourtyard(apartmentComplexId: string, courtyardId: number) {
    return apiClient.delete(this.getBasePath(apartmentComplexId, `/${courtyardId}`)).then((res) => res.data);
  }
}

export const CourtyardsServiceApi = new CourtyardsService();
