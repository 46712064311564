import {Typography} from '@mui/material';
import {ReactNode} from 'react';

import {BreadCrumbs} from 'shared/components/BreadCrumbs';
import {bem} from 'shared/utils';

import styles from './TitleBlock.module.scss';

import {BreadcrumbsList} from '../../../../hooks';

type Props = {
  title: string;
  subtitle?: string;
  action?: ReactNode;
  number?: string | number;
  breadcrumbs?: BreadcrumbsList[];
};

const sn = bem('titleBlock', styles);

const TitleBlock = ({title, number, subtitle, action, breadcrumbs}: Props) => {
  return (
    <>
      {breadcrumbs && <BreadCrumbs breadcrumbs={breadcrumbs} />}
      <div className={sn('container')}>
        <div className={sn('textBlock')}>
          <div className={sn('textWrapper')}>
            <h1 className={sn('textBlock__title')}>{title}</h1>
            <span className={sn('textBlock__number')}>{number}</span>
          </div>
          {subtitle && (
            <Typography variant="subtitle1" component="h2">
              {subtitle}
            </Typography>
          )}
        </div>
        {action && action}
      </div>
      <div className={sn('line')}></div>
    </>
  );
};

export default TitleBlock;
