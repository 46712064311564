import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ProfileModel} from 'shared/models/profileModel';

import {signIn, signOut} from './actions';

interface AuthSlice {
  isAuth: boolean | undefined;
  profile: ProfileModel | null;
}

const initialState = {
  isAuth: undefined,
  profile: null,
} as AuthSlice;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    setProfile(state, action: PayloadAction<ProfileModel>) {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.isAuth = false;
    });
  },
});

export const {
  reducer: authReducer,
  actions: {setAuth, setProfile},
} = authSlice;
