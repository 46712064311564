import {Breadcrumbs} from '@mui/material';
import {Link} from 'react-router-dom';

import styles from './BreadCrumbs.module.scss';

import {BreadcrumbsList} from '../../hooks';

interface Props {
  breadcrumbs: BreadcrumbsList[];
}

export const BreadCrumbs = ({breadcrumbs}: Props) => {
  return (
    <div className={styles.breadcrumbs}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map((item, index) => (
          item.href  ? (<Link key={index} to={item.href || ''}>
            {item.label}
          </Link>) : <span>{item.label}</span>
        ))}
      </Breadcrumbs>
    </div>
  );
};
