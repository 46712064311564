import {apiClient} from 'api/client';
import {CreateClientResponse, CreateClientPayload, ClientInfoModel} from 'shared/models';

class ClientsService {
  private getClientPath(value: string) {
    return `/managing/clients${value}`;
  }

  createClient(payload: CreateClientPayload): Promise<CreateClientResponse> {
    return apiClient.post(this.getClientPath(''), payload).then((res) => res.data);
  }

  getClient(clientId: number): Promise<ClientInfoModel> {
    return apiClient.get(this.getClientPath(`/${clientId}`)).then((res) => res.data);
  }

  updateClient(clientId: number, payload: ClientInfoModel): Promise<ClientInfoModel> {
    return apiClient.put(this.getClientPath(`/${clientId}`), payload).then((res) => res.data);
  }

}

export const ClientsApi = new ClientsService();
