import {number, string, object} from 'yup';

export const validation = object().shape({
  name: string().required('Введите название').max(255, 'Максимальное количество символов - 150'),
  triggerUrl: string().url('Url должен быть корректным').required('Введите url'),
  address: object().shape({
    apartmentComplexId: number().required('Введите название ЖК'),
    courtyardId: number().required('Введите название парковки'),
    buildingId: number().required('Введите название паркинга'),
    entranceNumber: number().required('Введите номер въезда'),
  })
});
