import {GridCellParams} from '@mui/x-data-grid';
import {useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {CourtyardsServiceApi} from 'api/services';
import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {routes} from 'shared/constants/routes';
import {useDebounce, useModal, usePrevious, useToast} from 'shared/hooks';
import {AccountRoles, CourtyardModel, ParkingModel} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {getColumns} from './columns';
import {confirmDeletePayload, failedDeleteMessage, getSuccessDeleteMessage} from './constants';
import styles from './Parking.module.scss';
import {getPreparedParking, initialParking} from './utils';
import {EditParking} from './components/EditParking';
import {AddParking} from './components/AddParking';
import {ParkingHeader} from './components/ParkingHeader';
import {CourtyardType} from 'shared/constants/common';

const sn = bem('parking', styles);

export const Parking = () => {
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);
  const [editingParking, setEditingParking] = useState<ParkingModel | null>(null);
  const [parkingList, setParkingList] = useState(initialParking);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parkingSearch, setParkingSearch] = useState('');

  const {apartmentComplexId} = useParams<{apartmentComplexId: string}>();
  const {isOpen, openModal, closeModal} = useModal();
  const navigate = useNavigate();
  const {confirm} = useConfirm();
  const {showToast} = useToast();
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);
  const isActiveFilter = parkingSearch.length >= 3;
  const prevIsActiveFilter = usePrevious(isActiveFilter);

  useEffect(() => {
    if (!isActiveFilter) {
      getParking();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchParking();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [isActiveFilter, parkingSearch, paginationModel]);

  const getParking = async () => {
    if (apartmentComplexId) {
      try {
        setLoading(true);
        const payload = {
          size: paginationModel.pageSize,
          page: paginationModel.page + 1,
          courtyardType: CourtyardType.parking,
        };
        const res = await CourtyardsServiceApi.getCourtyards(apartmentComplexId, payload);
        const preparedCourtyards = getPreparedParking(res.items);
        setParkingList({...res, items: preparedCourtyards});
      } finally {
        setLoading(false);
      }
    }
  };

  const searchParking = useDebounce(async () => {
    if (parkingSearch && parkingSearch.length < 3) return;
    if (apartmentComplexId) {
      try {
        setLoading(true);
        const payload = {
          size: paginationModel.pageSize,
          page: paginationModel.page + 1,
          courtyardName: parkingSearch,
          courtyardType: CourtyardType.parking,
        };
        const res = await CourtyardsServiceApi.getCourtyards(apartmentComplexId, payload);
        const preparedCourtyards = getPreparedParking(res.items);
        setParkingList({...res, items: preparedCourtyards});
      } finally {
        setLoading(false);
      }
    }
  }, DELAY);

  const deleteParking = async (values: ParkingModel) => {
    if (apartmentComplexId && values.id) {
      try {
        setDeleting(true);
        await CourtyardsServiceApi.deleteCourtyard(apartmentComplexId, values.id);
        const filteredAllParking = parkingList.items.filter((c) => c.courtyardId !== values.id);
        setParkingList({...parkingList, items: filteredAllParking});
        showToast(getSuccessDeleteMessage(values.name));
      } catch (err) {
        showToast(failedDeleteMessage);
      } finally {
        setDeleting(false);
      }
    }
  };

  const onCellClick = async (params: GridCellParams<CourtyardModel>) => {
    if (params.field === 'edit') {
      if (isDisabled) return;
      setEditingParking(params.row);
      return;
    }
    if (params.field === 'delete' && params.row.id) {
      if (isDisabled) return;
      if (await confirm(confirmDeletePayload)) {
        deleteParking(params.row);
      }
      return;
    }
    navigate(generatePath(routes.parkingSectionList, {apartmentComplexId, parkingId: params.id}));
  };

  const closeEditParking = () => {
    setEditingParking(null);
  };

  const onAfterSave = () => {
    getParking();
    setParkingSearch('');
  }

  return (
    <div className={styles.parking}>
      <ParkingHeader parkingSearch={parkingSearch} updateParkingSearch={setParkingSearch} openModal={openModal} />
      <Table
        data={parkingList}
        loading={loading || deleting}
        className={sn('table')}
        paginationModel={paginationModel}
        updatePaginationModel={setPaginationModel}
        onCellClick={onCellClick}
        columns={getColumns({isDisabled})}
      />
      <AddParking
        isOpen={isOpen}
        closeModal={closeModal}
        onAfterSave={onAfterSave}
      />
      <EditParking
        isOpen={!!editingParking}
        parking={editingParking}
        closeModal={closeEditParking}
        onAfterSave={onAfterSave}
      />
    </div>
  );
};
