import {TextField} from '@mui/material';
import {ChangeEvent, useState} from 'react';
import {useParams} from 'react-router';

import {ERROR_SYMBOLS, ERROR_NAME_NOT_AVAILABLE, MAX_LENGTH, ERROR_LENGTH} from 'modules/Parking/constants';
import {Modal} from 'shared/components/Modal';
import {useDebounce, useToast} from 'shared/hooks';
import {isOnlySpecialChars} from 'shared/utils/checkOnlySpecialChars';
import {CourtyardType} from 'shared/constants/common';
import {CourtyardsServiceApi} from 'api';
import {checkAvailability, isInvalidWhitespaceInput} from 'shared/utils';

type Props = {
  isOpen: boolean;
  onAfterSave: () => void;
  closeModal: () => void;
};

export const AddParking = ({isOpen, onAfterSave, closeModal}: Props) => {
  const [isNameAvailable, setIsNameAvailable] = useState(true);
  const [name, setName] = useState('');
  const {apartmentComplexId} = useParams<{apartmentComplexId: string}>();
  const [saving, setSaving] = useState(false);
  const [errorText, setErrorText] = useState('');
  const {showToast} = useToast();

  const onClose = () => {
    if (name) setName('');
    if (errorText) setErrorText('');
    setIsNameAvailable(true);
    closeModal();
  };

  const onSave = async () => {
    if (isOnlySpecialChars(name)) {
      setErrorText(ERROR_SYMBOLS);
      return;
    }
    if (apartmentComplexId) {
      try {
        setSaving(true);
        await CourtyardsServiceApi.addCourtyard(apartmentComplexId, {
          name,
          type: CourtyardType.parking,
        });
        onAfterSave();
        onClose();
        showToast({
          title: 'Парковка добавлена',
          message: `Парковка ${name} успешно добавлена.`,
        });
      } catch (err) {
        showToast({
          title: 'Парковка не добавлена',
          message: 'Не удалось добавить парковку.',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const searchName = useDebounce(async (value) => {
    if (!apartmentComplexId) return;
    checkAvailability({name: value, apartmentComplexId}, 'parking', setIsNameAvailable, setSaving);
  }, 1000);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (!isInvalidWhitespaceInput(value)) setName(value);
    if (value.length > MAX_LENGTH) setErrorText(ERROR_LENGTH);
    if (value.length <= MAX_LENGTH) setErrorText('');

    setSaving(true);
    setIsNameAvailable(true);
    searchName(value);
  };

  return (
    <Modal
      maxWidth="sm"
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      onCancel={onClose}
      title="Добавление парковки"
      closeButton
      cancelButtonText="Отменить"
      saveButtonText="Сохранить"
      disableSave={!name || saving || Boolean(errorText) || !isNameAvailable}
    >
      <TextField
        autoFocus
        margin="dense"
        label="Название парковки"
        type="text"
        fullWidth
        required
        value={name}
        error={Boolean(errorText) || !isNameAvailable}
        helperText={errorText || (!isNameAvailable && ERROR_NAME_NOT_AVAILABLE)}
        onChange={onChange}
      />
    </Modal>
  );
};
