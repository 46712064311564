import dayjs from 'dayjs';
import {useState, forwardRef, useImperativeHandle, useEffect} from 'react';

import {OneApartmentApi} from 'api';
import {LocalizedDatePicker} from 'shared/components/LocalizedDatePicker';
import {useToast} from 'shared/hooks';
import {AccountRoles, ServiceModel, ServiceType} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './ServiceCard.module.scss';

const sn = bem('serviceCard', styles);

type Props = {
  services: ServiceModel;
  onAfterChangeService: () => void;
  id: string;
  setSave?: (value: boolean) => void;
  closeModal: () => void;
};

export type ServiceCardAPI = {
  update: () => void;
};

const errorMessage = 'Ошибка при редактировании услуги';

export const ServiceCardTimeExtension = forwardRef<ServiceCardAPI, Props>(
  ({services, onAfterChangeService, id, setSave, closeModal}, ref) => {
    const [expirationDate, setExpirationDate] = useState<string | null>(services.expirationDate);
    const [duration, setDuratiion] = useState<bigint | null>(null);
    const [loading, setLoading] = useState(false);
    const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);
    const {showToast} = useToast();

    useEffect(() => {
      if (!setSave) return;
      const isExpirationDateChange = services.expirationDate !== expirationDate;
      setSave(isExpirationDateChange);
    }, [expirationDate]);

    const enableService = async () => {
      if (id && expirationDate) {
        try {
          setLoading(true);
          await OneApartmentApi.enableService(id, ServiceType.BaseFunctionsAccess, {expirationDate, duration});
          if (onAfterChangeService) onAfterChangeService();
          closeModal();
        } catch (err) {
          showToast({
            title: 'Ошибка',
            message: errorMessage,
            isError: true,
          });
        } finally {
          setLoading(false);
        }
      }
    };

    const disableService = async () => {
      if (id) {
        try {
          setLoading(true);
          await OneApartmentApi.disableService(id, ServiceType.BaseFunctionsAccess);
          setExpirationDate(null);
          if (onAfterChangeService) onAfterChangeService();
          closeModal();
        } catch (err) {
          showToast({
            title: 'Ошибка',
            message: errorMessage,
            isError: true,
          });
        } finally {
          setLoading(false);
        }
      }
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          update: expirationDate ? enableService : disableService,
        };
      },
      [expirationDate],
    );

    const onDateChange = (date: Date | string | null) => {
      const preparedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
      setExpirationDate(preparedDate);
    };

    return (
      <div className={styles.serviceCard}>
        <div className={sn('panel')}>
          <div className={sn('columns')}>
            <div className={sn('column')}>
              <span>Услуга {services.isActive ? 'подключена' : 'отключена'}</span>
              {services.isActive && <span>{services.startDate}</span>}
            </div>
            <div className={sn('column')}>
              <span>{services.isActive ? 'Услуга активна до' : 'Выберите дату окончания услуги'}</span>
              <span>
                <LocalizedDatePicker
                  value={services.isActive ? dayjs(services.expirationDate).toDate() : null}
                  onDateChange={onDateChange}
                  disabled={isDisabled && loading}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
