import {Tokens} from 'shared/models';
import {AuthApi} from '../services';

interface ITokenRefreshService {
  refreshTokens: () => Promise<Tokens>;
}

interface ApiRefreshToken {
  refreshTokens: () => Promise<Tokens>;
}

class TokenRefreshService implements ITokenRefreshService {
  constructor(private api: ApiRefreshToken) {
    this.prevRefreshRequest = undefined;
  }

  private prevRefreshRequest: Promise<Tokens> | undefined;

  async refreshTokens() {
    try {
      this.prevRefreshRequest = this.prevRefreshRequest || this.api.refreshTokens();
      return await this.prevRefreshRequest;
    } catch (err) {
      throw err;
    } finally {
      this.prevRefreshRequest = undefined;
    }
  }
}

export const tokenRefreshService = new TokenRefreshService(AuthApi);
