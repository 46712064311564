import {Button} from '@mui/material';
import {ChangeEvent, useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {HouseApi} from 'api';
import {SearchInput} from 'shared/components/SearchInput';
import {initPaginationModel} from 'shared/components/Table/constants';
import {DELAY} from 'shared/constants/delay';
import {routes} from 'shared/constants/routes';
import {useDebounce, useBreadcrumbs, useToast, usePrevious} from 'shared/hooks';
import TitleBlock from 'shared/layouts/Base/components/TitleBlock/TitleBlock';
import {AccountRoles, BaseHouseModel, WithPagination} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import {HousesTable} from './components/HousesTable';
import {failedDeleteMessage, getSuccessDeleteMessage} from './constants';
import styles from './Houses.module.scss';
import {initialHouses} from './utils';

const sn = bem('houses', styles);

export const Houses = () => {
  const [houses, setHouses] = useState<WithPagination<BaseHouseModel>>(initialHouses);
  const [loading, setLoading] = useState(false);
  const [searchHouse, setSearchHouse] = useState('');
  const [paginationModel, setPaginationModel] = useState(initPaginationModel);

  const navigate = useNavigate();
  const {apartmentComplexId, courtyardId} = useParams<{apartmentComplexId: string; courtyardId: string}>();
  const {showToast} = useToast();
  const {houseBreadcrumbs} = useBreadcrumbs();
  const isActiveFilter = searchHouse.length >= 3;
  const prevIsActiveFilter = usePrevious(isActiveFilter);

  useEffect(() => {
    if (!isActiveFilter) {
      getHouses();
    }
  }, [isActiveFilter, paginationModel]);

  useEffect(() => {
    if (isActiveFilter) {
      searchHouses();
    }
    if (isActiveFilter !== prevIsActiveFilter) {
      setPaginationModel(initPaginationModel);
    }
  }, [searchHouse, isActiveFilter, paginationModel]);

  const openAddNewHome = () => {
    if (apartmentComplexId) {
      navigate(generatePath(routes.addHouse, {apartmentComplexId, courtyardId}));
    }
  };

  const addId = (houses: BaseHouseModel[]) => houses.map((h) => ({...h, id: h.buildingId}));

  const getHouses = async () => {
    if (apartmentComplexId && courtyardId) {
      try {
        setLoading(true);
        const payload = {page: paginationModel.page + 1, size: paginationModel.pageSize};
        const res = await HouseApi.getHouses(apartmentComplexId, courtyardId, payload);
        const withId = {...res, items: addId(res.items)};
        setHouses(withId);
      } finally {
        setLoading(false);
      }
    }
  };

  const searchHouses = useDebounce(async () => {
    if (apartmentComplexId && courtyardId) {
      try {
        setLoading(true);
        const payload = {
          searchQuery: searchHouse,
          size: paginationModel.pageSize,
          page: paginationModel.page + 1,
        };
        const res = await HouseApi.getHouses(apartmentComplexId, courtyardId, payload);
        const housesWithId = {...res, items: addId(res.items)};
        setHouses(housesWithId);
      } finally {
        setLoading(false);
      }
    }
  }, DELAY);

  const deleteHouse = async (values: BaseHouseModel) => {
    if (apartmentComplexId && courtyardId) {
      try {
        setLoading(true);
        await HouseApi.deleteHouse(apartmentComplexId, courtyardId, values.buildingId);
        const preparedHouses = houses.items.filter((h) => h.buildingId !== values.buildingId);
        setHouses({...houses, items: preparedHouses});
        showToast(getSuccessDeleteMessage(values.number, values.address));
      } catch (err) {
        showToast(failedDeleteMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchHouse(event.target.value);
  };

  return (
    <div className={styles.houses}>
      <TitleBlock
        title="Дома"
        breadcrumbs={houseBreadcrumbs}
        action={
          <Button
            variant="contained"
            color="secondary"
            className={sn('addHome')}
            onClick={openAddNewHome}
            disabled={!checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin])}
          >
            Добавить дом
          </Button>
        }
      />
      <div className={sn('search')}>
        <SearchInput placeholder="Найти дом (мин 3 символа)" onChange={onSearch} value={searchHouse} />
      </div>
      <HousesTable
        houses={houses}
        deleteHouse={deleteHouse}
        loading={loading}
        paginationModel={paginationModel}
        updatePaginationModel={setPaginationModel}
      />
    </div>
  );
};
