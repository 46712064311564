import {useEffect, useState} from 'react';

import {UtilsApi} from '../../api';
import {DeviceModel} from '../models';

export const useDeviceModels = () => {
  const [deviceModels, setDeviceModels] = useState<DeviceModel[]>([]);

  useEffect(() => {
    UtilsApi.getDeviceModels().then((res) => {
      setDeviceModels(res);
    });
  }, []);

  return deviceModels;
};
