import {Apartment as ApartmentSvg, Edit as EditIcon, Delete as DeleteIcon} from '@mui/icons-material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import classNames from 'classnames';

import {bem} from 'shared/utils';

import styles from './HousesTable.module.scss';

const sn = bem('housesTable', styles);

type Props = {
  isDisabled: boolean;
};

export const getColumns = ({isDisabled}: Props): GridColDef[] => {
  return [
    {
      field: 'number',
      headerName: 'Номер дома с корпусом',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      renderCell: (params: GridRenderCellParams) => (
        <div className={sn('cell', 'number')}>
          <ApartmentSvg className={sn('number_icon')} />
          {params.value}
        </div>
      ),
      width: 300,
    },
    {
      field: 'address',
      headerName: 'Адрес дома',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      flex: 1,
    },
    {
      field: 'buildingId',
      headerName: 'SIP ID дома',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      renderCell: (params: GridRenderCellParams) => <div>{String(params.value).padStart(4, '0')}</div>,
      width: 110,
    },
    {
      field: 'entriesCount',
      headerName: 'Кол-во подъездов',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      renderCell: (params: GridRenderCellParams) => <div>{params?.value}</div>,
      width: 150,
    },
    {
      field: 'activeApartmentsCount',
      headerClassName: sn('column'),
      cellClassName: sn('cell'),
      headerName: 'Кол-во заселенных квартир',
      width: 210,
    },
    {
      field: 'edit',
      headerName: '',
      headerClassName: sn('emptyHeader'),
      cellClassName: sn('cell'),
      renderCell: () => (
        <div className={sn('edit', classNames({cell_disabled: isDisabled}))}>
          <EditIcon />
        </div>
      ),
      maxWidth: 50,
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    {
      field: 'delete',
      headerName: '',
      headerClassName: sn('emptyHeader'),
      cellClassName: sn('cell'),
      renderCell: () => (
        <div className={sn('delete', classNames({cell_disabled: isDisabled}))}>
          <DeleteIcon />
        </div>
      ),
      maxWidth: 50,
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
  ];
};
