import {Button} from '@mui/material';
import {useState} from 'react';

import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {EntryTabs} from 'shared/components/EntryTabs';
import {bem} from 'shared/utils';

import {EntrySetting} from './components/EntrySettings';
import {EntryTable} from './components/EntryTable';
import styles from './SecondStep.module.scss';

import {Entry, HouseConstructorTabs, HouseSettings} from '../../types';
import {getUpdatedRestEntries} from '../../utils';


type Props = {
  saving: boolean;
  changeTab: (value: number) => void;
  houseSettings: HouseSettings;
  updateHouseSettings: (value: HouseSettings) => void;
  onCancel: () => void;
  onSave: () => void;
};

const sn = bem('secondStep', styles);

export const SecondStep = ({saving, changeTab, houseSettings, updateHouseSettings, onCancel, onSave}: Props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const {confirm} = useConfirm();

  const onPrevStep = () => {
    changeTab(HouseConstructorTabs.first);
  };

  const onChangeTab = (value: number) => {
    setCurrentTab(value);
  };

  const onDeleteTab = async (deletingEntry: Entry) => {
    const confirmPayload = {
      title: `Удалить ${deletingEntry.entryNumber}-й подъезд`,
      description: `Вы действительно хотите удалить ${deletingEntry.entryNumber}-й подъезд?`,
      acceptText: 'Удалить',
    };
    if (await confirm(confirmPayload)) {
      if (houseSettings.entries.length === 1) return;
      const filteredEntries = houseSettings.entries.filter((entry) => entry.id !== deletingEntry.id);
      houseSettings.entries = getUpdatedRestEntries(filteredEntries);
      updateHouseSettings({...houseSettings});
    }
  };

  return (
    <div className={styles.secondStep}>
      <div className={sn('content', {content__disabled: saving})}>
        <div className={sn('tabs')}>
          <EntryTabs
            entries={houseSettings.entries}
            currentTab={currentTab}
            onChangeTab={onChangeTab}
            onDeleteTab={onDeleteTab}
          />
        </div>
        <div className={sn('entrySettings')}>
          <EntrySetting entry={houseSettings.entries[currentTab]} houseSettings={houseSettings} updateHouseSettings={updateHouseSettings} />
        </div>
        <div className={sn('table')}>
          <EntryTable entry={houseSettings.entries[currentTab]} houseSettings={houseSettings} updateHouseSettings={updateHouseSettings} />
        </div>
      </div>
      <div className={sn('footer')}>
        <Button variant="contained" onClick={onCancel}>
          Отмена
        </Button>
        <Button variant="contained" onClick={onPrevStep}>
          Предыдущий шаг
        </Button>
        <Button type="submit" variant="contained" color="secondary" onClick={onSave}>
          Сохранить
        </Button>
      </div>
    </div>
  );
};
