import {Checkbox, TextField} from '@mui/material';

import {bem} from 'shared/utils';

import styles from './EntrySettings.module.scss';

import {useConfirm} from '../../../../../Confirmation/useConfirm';
import {Entry, HouseSettings} from '../../../../types';
import {generateFloors, getUpdatedEntry, getUpdatedRestEntries} from '../../../../utils';

const sn = bem('entrySettings', styles);

type Props = {
  entry: Entry;
  houseSettings: HouseSettings;
  updateHouseSettings: (value: HouseSettings) => void;
};

const FIRST_ENTRY = 1;

export const EntrySetting = ({entry, houseSettings, updateHouseSettings}: Props) => {
  const {confirm} = useConfirm();
  const thisEntry = houseSettings.entries[entry.id];

  const onChangeQuantityApartmentsOnFloor = (value: number) => {
    thisEntry.quantityApartmentsOnFloor = value;
    if (entry.sameNumberApartments) {
      thisEntry.floors.forEach((floor, i) => (thisEntry.floors[i].quantityApartments = value));
      houseSettings.entries[entry.id] = getUpdatedEntry(thisEntry);
    }
    houseSettings.entries = getUpdatedRestEntries(houseSettings.entries, entry.id);
    updateHouseSettings({...houseSettings});
  };

  const onChangeQuantityFloors = (value: number) => {
    thisEntry.quantityFloors = value;
    thisEntry.floors = generateFloors(value, thisEntry.quantityApartmentsOnFloor, thisEntry.firstApartmentNumber);
    houseSettings.entries[entry.id] = getUpdatedEntry(thisEntry);
    houseSettings.entries = getUpdatedRestEntries(houseSettings.entries, entry.id);
    updateHouseSettings({...houseSettings});
  };

  const onChangeSameQuantityApartments = async (value: boolean) => {
    const confirmPayload = {
      title: 'Одинаковое кол-во этажей в подъезде',
      description: `Вы действительно хотите установить одинаковое количество квартир на всех этажах в подъезде? ${thisEntry.quantityApartmentsOnFloor} квартир(ы) на этаж.`,
      acceptText: 'Установить одинаковое',
    };
    if (value && (await confirm(confirmPayload))) {
      thisEntry.sameNumberApartments = true;
      thisEntry.floors.forEach((floor, i) => {
        thisEntry.floors[i].quantityApartments = thisEntry.quantityApartmentsOnFloor;
      });
      houseSettings.entries[entry.id] = getUpdatedEntry(thisEntry);
      houseSettings.entries = getUpdatedRestEntries(houseSettings.entries, entry.id);
    } else {
      thisEntry.sameNumberApartments = false;
    }
    updateHouseSettings({...houseSettings});
  };

  const onChangeFirstApartmentNumber = (value: number) => {
    thisEntry.firstApartmentNumber = value;
    houseSettings.entries[entry.id] = getUpdatedEntry(thisEntry);
    houseSettings.entries = getUpdatedRestEntries(houseSettings.entries, entry.id);
    updateHouseSettings({...houseSettings});
  };

  return (
    <div className={styles.entrySettings}>
      <TextField
        name="quantityFloors"
        label="Кол-во этажей в подъезде"
        margin="normal"
        value={entry.quantityFloors}
        onChange={(e) => onChangeQuantityFloors(Number(e.target.value))}
      />
      <TextField
        name="quantityApartmentsOnFloor"
        label="Кол-во квартир на этаже"
        margin="normal"
        value={entry.quantityApartmentsOnFloor}
        onChange={(e) => onChangeQuantityApartmentsOnFloor(Number(e.target.value))}
      />
      <div className={sn('sameQuantityApartments')}>
        <Checkbox
          checked={entry.sameNumberApartments}
          onChange={(e) => onChangeSameQuantityApartments(e.target.checked)}
        />
        <p>Кол-во квартир на этажах одинаковое</p>
      </div>
      {entry.entryNumber === FIRST_ENTRY && (
        <TextField
          name="firstApartmentNumber"
          label="Первая квартира дома"
          margin="normal"
          value={entry.firstApartmentNumber}
          onChange={(e) => onChangeFirstApartmentNumber(Number(e.target.value))}
        />
      )}
    </div>
  );
};
