import {TextField} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

import {bem} from 'shared/utils';

import styles from './ParkingSectionTable.module.scss';
import {ParkingSectionConstructorFloor} from 'shared/models';

const sn = bem('parkingSectionTable', styles);

interface Columns {
  updateApartmentQuantity: (floorIndex: number, value: number) => void;
}

export const columns = ({updateApartmentQuantity}: Columns): GridColDef[] => [
  {
    field: 'floorNumber',
    headerName: 'Этажи',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    maxWidth: 300,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <div className={sn('floorNumber')}>{params.value} этаж</div>,
  },
  {
    field: 'quantitySeatsOnFloor',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Кол-во парк. мест',
    maxWidth: 300,
    flex: 1,
    renderCell: (params: GridRenderCellParams<ParkingSectionConstructorFloor>) => (
      <TextField
        className={sn('quantityApartments')}
        variant="outlined"
        type="number"
        size="small"
        value={params.value?.toString()}
        onChange={(e) => updateApartmentQuantity(params.row.floorNumber, Number(e.target.value))}
      />
    ),
  },
  {
    field: 'range',
    headerClassName: sn('column'),
    cellClassName: sn('cell'),
    headerName: 'Парк. места',
    flex: 1,
    renderCell: (params: GridRenderCellParams<ParkingSectionConstructorFloor>) => (
      <div className={sn('seatsRange')}>
        {params.row.range}
      </div>
    ),
  },
];
