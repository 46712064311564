import Avatar from '@mui/material/Avatar';
import classNames from 'classnames';

import defaultAvatar from 'assets/icons/avatar.svg';

import styles from './UserAvatar.module.scss';

interface Props {
  src?: string;
  alt?: string;
  size?: 'small' | 'large';
}

export const UserAvatar = ({src, alt = '', size = 'large'}: Props) => {
  const isSmall = size === 'small';
  const avatarSrc = src ?? defaultAvatar;

  return (
    <div className={styles.avatar}>
      <Avatar
        alt={alt}
        src={avatarSrc}
        className={classNames(styles.avatar, {[styles.avatar_small]: isSmall}, {[styles.avatar_large]: !isSmall})}
      />
    </div>
  );
};
