import * as Yup from 'yup';

import {REGEXP_PHONE} from './common';

export const secondName = Yup.string().max(50, 'Отчество не может быть больше 50 символов');
export const lastName = Yup.string().max(50, 'Фамилия не может быть больше 50 символов');
export const email = Yup.string().max(50, 'Email не может быть больше 50 символов').email('Некорректный email');

export const firstName = Yup.string().max(50, 'Имя не может быть больше 50 символов').required('Укажите имя');
export const phoneNumber = Yup.string()
  .test('phoneNumber', 'Номер телефона должен начинаться с 7 или 8 и не может быть длиннее 11 символов', (phoneNumber) => {
    const reg = new RegExp(REGEXP_PHONE);
    return phoneNumber ? !!reg.exec(phoneNumber)?.[0] : false;
  })
  .required('Некорректный телефон');
