export const MAX_LENGTH = 100;
export const ERROR_LENGTH = `Название двора не может быть больше ${MAX_LENGTH} символов`;
export const ERROR_SYMBOLS = 'Название двора не может состоять только из специальных символов';
export const ERROR_NAME_NOT_AVAILABLE = 'Парковка с таким названием уже существует';

export const confirmDeletePayload = {
  title: 'Удаление парковки',
  description: 'Все данные этой парковки будут безвозвратно удалены из системы',
  acceptText: 'Удалить',
};

export const getSuccessDeleteMessage = (name: string) => ({
  title: 'Парковка удалена',
  message: `Парковка ${name} успешно удалёна.`,
});

export const failedDeleteMessage = {
  title: 'Парковка не удалена',
  message: 'Не удалось удалить парковку.',
  isError: true,
}
