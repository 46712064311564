import {GridCellParams, GridPaginationModel} from '@mui/x-data-grid';
import {useState} from 'react';
import {generatePath, useNavigate} from 'react-router';

import {IntercomsApi} from 'api';
import {Table} from 'shared/components';
import {useConfirm} from 'shared/components/Confirmation/useConfirm';
import {routes} from 'shared/constants/routes';
import {useToast} from 'shared/hooks';
import {IntercomModel, WithPagination} from 'shared/models';

import {getColumns} from './columns';
import styles from './IntercomsTable.module.scss';
import {bem} from "shared/utils";

type Props = {
  loading: boolean;
  intercoms: WithPagination<IntercomModel>;
  paginationModel: GridPaginationModel;
  updatePaginationModel: (paginationModel: GridPaginationModel) => void;
  onAfterUpdate: () => void;
};

const sn = bem('intercomsTable', styles);

export const IntercomsTable = ({loading, intercoms, paginationModel, updatePaginationModel, onAfterUpdate}: Props) => {
  const [actionsHandling, setActionHandling] = useState(false);

  const navigate = useNavigate();
  const {confirm} = useConfirm();
  const {showToast} = useToast();

  const onCellClick = (params: GridCellParams<IntercomModel>) => {
    if (params.field === 'name') {
      navigate(generatePath(routes.intercom, {intercomId: params.id}));
    }
    if (params.field === 'unlink' && !actionsHandling) {
      unlinkIntercom(params);
    }
    if (params.field === 'open' && !actionsHandling) {
      openOpenDoor(params);
    }
    if (params.field === 'reload' && !actionsHandling) {
      restartIntercom(params);
    }
  };

  const unlinkIntercom = async (params: GridCellParams<IntercomModel>) => {
    const confirmPayload = {
      title: 'Подтверждение действия',
      description: `Вы действительно хотите отвязать домофон - ${params.row.name} по адресу: ${params.row.address}?`,
      acceptText: 'Продолжить',
    };
    if (await confirm(confirmPayload)) {
      try {
        setActionHandling(true);
        await IntercomsApi.unlinkIntercom(params.row.sipPanelId);
        showToast({
          title: 'Домофон отвязан',
          message: `Домофон ${params.row.name} по адресу: ${params.row.address} успешно отвязан.`,
        });
        onAfterUpdate();
      } catch (err) {
        showToast({
          title: 'Домофон не отвязан',
          message: 'Произошла ошибка при отвязке домофона.',
          isError: true,
        });
      } finally {
        setActionHandling(false);
      }
    }
  };

  const openOpenDoor = async (params: GridCellParams<IntercomModel>) => {
    const confirmPayload = {
      title: 'Подтверждение действия',
      description: 'Вы действительно хотите открыть дверь?',
      acceptText: 'Открыть',
    };
    if (await confirm(confirmPayload)) {
      try {
        setActionHandling(true);
        await IntercomsApi.openDoor(params.row.sipPanelId);
        showToast({
          title: 'Дверь открыта',
          message: 'Дверь открыта.',
        });
      } catch (err) {
        showToast({
          title: 'Дверь не открыта',
          message: 'Произошла ошибка при открытии двери.',
          isError: true,
        });
      } finally {
        setActionHandling(false);
      }
    }
  };

  const restartIntercom = async (params: GridCellParams<IntercomModel>) => {
    const confirmPayload = {
      title: 'Подтверждение действия',
      description: `Вы действительно хотите перезапустить домофон - ${params.row.name} по адресу: ${params.row.address}?`,
      acceptText: 'Перезапустить',
    };
    if (await confirm(confirmPayload)) {
      try {
        setActionHandling(true);
        await IntercomsApi.restartIntercom(params.row.sipPanelId);
        showToast({
          title: 'Домофон перезапущен',
          message: 'Домофон успешно перезапущен',
        });
      } catch (err) {
        showToast({
          title: 'Домофон не перезапущен',
          message: 'Произошла ошибка при перезапуске домофона.',
          isError: true,
        });
      } finally {
        setActionHandling(false);
      }
    }
  };

  return (
    <div className={sn()}>
      <Table
        loading={loading}
        className={sn('table')}
        data={intercoms}
        onCellClick={onCellClick}
        columns={getColumns({onAfterUpdate})}
        paginationModel={paginationModel}
        updatePaginationModel={updatePaginationModel}
      />
    </div>
  );
};
