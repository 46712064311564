import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { PaymentListStore, PaginationModel} from './types';
import {PaymentModel, WithPagination} from '../../../shared/models';

const initialState: PaymentListStore = {
  searchPhone: '',
  searchPaymentId: '',
  paginationModel: {pageSize: 10, page: 0},
  payments: {
    total: 0,
    count: 0,
    items: []
  }
};

const slice = createSlice({
  name: 'paymentsReducer',
  initialState,
  reducers: {

    setSearchPhone: (state, action: PayloadAction<string>) => {
      state.searchPhone = action.payload;
    },
    setSearchPaymentId: (state, action: PayloadAction<string>) => {
      state.searchPaymentId = action.payload;
    },
    setPayments: (state, action:  PayloadAction<WithPagination<PaymentModel>>) => {
      state.payments = action.payload;
    },
    setPaginationModel: (state, action: PayloadAction<PaginationModel>) => {
      state.paginationModel = action.payload;
    },
  },
});

export const {
  actions: {
    setSearchPhone,
    setSearchPaymentId,
    setPaginationModel,
    setPayments,
  },
  reducer: paymentsReducer,
} = slice;