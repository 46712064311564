export interface AccountModel {
  id: number;
  email: string;
  phoneNumber: string;
  fullName: string;
  active?: boolean;
}

export interface BaseAccountModel {
  email: string;
  phoneNumber: string;
  firstName: string;
  secondName: string;
  lastName: string;
  role: AccountRoles;
}

export interface AccountInfoModel extends BaseAccountModel {
  id: number;
  username: string;
}

export enum AccountRoles {
  user,
  admin,
  superAdmin,
}

export interface NewAccount extends BaseAccountModel {
  username: string;
  password: string;
}

export type SearchAccounts = {
  searchQuery?: string;
  withBlocked?: boolean;
};
