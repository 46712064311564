import {Visibility, VisibilityOff} from '@mui/icons-material';
import {Button, IconButton, TextField} from '@mui/material';
import {FormikHelpers, useFormik} from 'formik';
import {useState} from 'react';
import {useNavigate} from 'react-router';

import {Logo} from 'shared/components/Logo';
import {routes} from 'shared/constants/routes';
import {useToast} from 'shared/hooks';
import {bem} from 'shared/utils';
import {useRootDispatch} from 'store';
import {signIn} from 'store/authStore/actions';

import styles from './Auth.module.scss';
import {authSchema} from './validation/authSchema';

const sn = bem('auth', styles);

interface FormValues {
  username: string;
  password: string;
}

const initialValues = {
  username: '',
  password: '',
};

export const Auth = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useRootDispatch();
  const {showToast} = useToast();

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const submit = async (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
    setSubmitting(true);
    try {
      await dispatch(signIn(values));
      navigate(routes.apartmentComplexes);
    } catch (err) {
      // TODO типизировать ошибку, выводить текст из сообщения об обшибке
      showToast({
        title: 'Не удалось войти',
        message: err as string,
        isError: true,
      });
    }
    setSubmitting(false);
  };

  const {handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting} = useFormik({
    initialValues,
    validationSchema: authSchema,
    onSubmit: submit,
  });

  return (
    <div className={styles.auth}>
      <form onSubmit={handleSubmit} className={sn('form')}>
        <Logo />
        <h2 className={sn('title')}>Вход в систему</h2>
        <TextField
          name="username"
          label="Логин"
          variant="outlined"
          className={sn('input')}
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.username && Boolean(errors.username)}
          helperText={touched.username && errors.username}
        />
        <TextField
          type={showPassword ? 'text' : 'password'}
          name="password"
          label="Пароль"
          variant="outlined"
          className={sn('input')}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
          InputProps={{
            endAdornment: (
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
        <Button type="submit" variant="contained" color="secondary" className={sn('submitBtn')} disabled={isSubmitting}>
          {isSubmitting ? 'Авторизация...' : 'Продолжить'}
        </Button>
      </form>
    </div>
  );
};
