import * as Yup from 'yup';

import {lastName, secondName, firstName, phoneNumber, email} from 'shared/constants/validationRules';

export const validationOwner = Yup.object({
  firstName: firstName,
  lastName: lastName.required('Укажите фамилию'),
  secondName: secondName,
  phoneNumber: phoneNumber,
  email: email,
  comment: Yup.string().max(100, 'Комментарий не может быть больше 100 символов'),
});

export const validationAdditional = validationOwner.shape({
  secondName: secondName,
  lastName: lastName,
});
